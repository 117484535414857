<template>
    <div class="layout-sidebar">
        <router-link :to="{ name: 'dashboard' }" class="app-logo">
            <div class="col flex flex-column align-items-center justify-content-center gap-2">
                <img src="@/assets/images/logo.png" alt="Vitali" width="50" />
                <div class="flex">{{ version }}</div>
                <div v-if="globalStore.dbIsDev" class="flex font-bold" style="font-size: medium">DEVELOPMENT</div>
                <!-- <Button v-if="!Utils.PWA" label="Install now!" icon="pi pi-download" class="p-button-outlined w-10rem" @click="onInstallPWA()" /> -->
                <div class="googleplay" @click="openGooglePlayStore"></div>
                <div class="appstore" @click="openAppStore"></div>
            </div>
        </router-link>
        <div class="layout-menu-container">
            <AppMenu />
            <a v-if="isStudent" download="Manual" class="download-manual" href="#" @click.prevent="openPDF">Οδηγίες χρήσης</a>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance, computed } from 'vue';
import AppMenu from '@/layout/AppMenu.vue';
import Utils from '@/utils/Utils';
import { useGlobalStore } from '@/stores/GlobalStore';

const globalStore = useGlobalStore();
const version = ref('v' + import.meta.env.VITE_VERSION);
const app = getCurrentInstance();

const onInstallPWA = () => {
    console.log('onInstallPWA');
    const window = app.appContext.config.globalProperties.window;
    console.log(window);
    let deferredPrompt = window.getDeferredPrompt();
    console.log(deferredPrompt);
    if (deferredPrompt != null) {
        deferredPrompt.prompt();
        deferredPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
                console.log('User accepted the install prompt');
            } else {
                console.log('User dismissed the install prompt');
            }
        });
    }
};

const isStudent = computed(() => {
    return Utils.UserInfo.role === Utils.Roles.Student;
});

const openGooglePlayStore = () => {
    window.open('https://play.google.com/store/apps/details?id=com.vitali.elearning', '_blank');
};

const openAppStore = () => {
    window.open('https://apps.apple.com/gr/app/vitali-e-learning/id6453159967', '_blank');
};

const openPDF = () => {
    window.open('https://storage.googleapis.com/vitali-backend.appspot.com/manual/manual.pdf', '_blank');
};
</script>

<style lang="scss" scoped>
.googleplay {
    background-image: url('@/assets/images/googleplay.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 3rem;
    cursor: pointer;
}

.appstore {
    background-image: url('@/assets/images/appstore.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 3rem;
    cursor: pointer;
}

.download-manual {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    margin: 30px 10px;
    background-color: #007bff; /* Changed to a blue shade */
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-family: Arial, sans-serif;
    font-size: 14px;
    text-align: center;
    transition: background-color 0.3s;

    &:before {
        content: '';
        display: block;
        background-image: url('@/assets/images/pdf-download.png');
        background-size: cover;
        width: 30px;
        height: 30px;
        margin: 0 auto 10px;
    }

    &:hover {
        background-color: #0056b3; /* Darker shade of blue for hover state */
    }
}
</style>
