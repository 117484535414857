<template>
    <Dialog
        v-model:visible="dialogShown"
        class="p-fluid"
        :close-on-escape="false"
        :dismissable-mask="false"
        :modal="true"
        :breakpoints="{ '960px': '75vw', '640px': '95vw' }"
        :style="{ width: '90vw' }"
    >
        <template #header>
            <h4>{{ headerTitle }}</h4>
        </template>

        <DataTable
            ref="dt"
            v-model:filters="tableFilters"
            :lazy="true"
            :total-records="totalRequests"
            :loading="loading"
            :row-hover="true"
            :value="requests"
            filter-display="row"
            data-key="id"
            responsive-layout="scroll"
            :rows="rowsPerPage"
            :paginator="true"
            @page="onPage($event)"
            @sort="onSort($event)"
            @filter="onFilter($event)"
        >
            <template #header>Σύνολο: {{ totalRequests }}</template>
            <template #empty> Δεν βρέθηκαν αιτήσεις. </template>
            <Column header="ID" field="id" style="min-width: 2rem">
                <template #body="{ data }">
                    {{ data.id }}
                </template>
            </Column>
            <Column header="Μάθημα" field="title" filter-field="title" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                <template #body="{ data }">
                    {{ data.title }}
                </template>
            </Column>
            <Column header="Σχολή" field="school" filter-field="school" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                <template #body="{ data }">
                    {{ data.school }}
                </template>
            </Column>
            <Column field="isPrivate" filter-field="isPrivate" header="Τύπος" data-type="boolean" body-class="text-left" style="min-width: 8rem">
                <template #body="{ data }">
                    <span class="private-badge" :class="`private-${data.isPrivate}`">{{ Utils.Types[Utils.FromBoolToTinyInt(data.isPrivate)].label }}</span>
                </template>
            </Column>
            <Column field="status" filter-field="status" header="Κατάσταση" body-class="text-left" style="min-width: 10rem" :show-filter-menu="false">
                <template #body="{ data }">
                    <span class="status-badge" :class="`status-${data.status}`">{{ Utils.RequestStatusToString(data.status) }}</span>
                </template>
            </Column>
            <Column header="Εξεταστική" field="examPeriod" filter-field="examPeriod" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                <template #body="{ data }">
                    {{ data.examsPeriodTitle }}
                </template>
            </Column>
            <Column header="Μαθητής" field="studentName" filter-field="studentName" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                <template #body="{ data }">
                    <a class="font-medium no-underline text-left cursor-pointer" style="color: blue" @click="showStudentDialog(data.studentID)">{{ data.studentName }}</a>
                </template>
            </Column>
        </DataTable>

        <template #footer> </template>
    </Dialog>
</template>

<script setup>
import { useToast } from 'primevue/usetoast';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import { ref, onMounted, watchEffect } from 'vue';
import { required, integer } from '@vuelidate/validators';
import { useDebounceFn } from '@vueuse/core';
import { useVuelidate } from '@vuelidate/core';
import moment from 'moment-timezone';
import useRPC from '@/composables/useRPC';
import { usePopupStore } from '@/stores/PopupStore.js';
import Utils from '@/utils/Utils';
const { RPCApi, RPCMethods } = useRPC();
const dialogShown = ref(false);
const headerTitle = ref('');
const totalRecords = ref(0);
const dt = ref(null);
const loading = ref(false);
const rowsPerPage = ref(10);
const closeCB = ref(null);
const requests = ref(null);
const totalRequests = ref(0);
const fetchParams = ref({});
const toast = useToast();
const accountables = ref([]);
const examPeriods = ref([]);
const categories = ref([]);
const schools = ref([]);
const filteredCategories = ref([]);
const filteredSubCategories1 = ref([]);
const filteredSubCategories2 = ref([]);
const filteredCourses = ref([]);
const popupStore = usePopupStore();

const tableFilters = ref({
    examPeriod: { value: null, matchMode: FilterMatchMode.CONTAINS },
    courseTitle: { value: null, matchMode: FilterMatchMode.EQUALS },
});

const onPage = useDebounceFn((event) => {
    console.log('onPage', event);
    fetchParams.value.paging = { offset: event.first, limit: rowsPerPage };
    fetch();
}, 300);

const onSort = useDebounceFn((event) => {
    console.log('onSort', event);
    if (event.sortField === 'fullname') event.sortField = 'lname';
    fetchParams.value.paging = { offset: 0, limit: rowsPerPage };
    fetchParams.value.sort = { sortField: event.sortField, sortOrder: event.sortOrder };
    fetch();
}, 300);

const show = async (data, onCloseCB) => {
    let { courseID, courseTitle, examPeriodTitle } = data;
    closeCB.value = onCloseCB;
    dialogShown.value = true;

    headerTitle.value = `Αιτήσεις για μάθημα: "${courseTitle}" - ${examPeriodTitle}`;

    fetchParams.value.paging = { offset: 0, limit: rowsPerPage };
    fetchParams.value.filters = {
        title: tableFilters.value.title,
        examsPeriodTitle: { value: examPeriodTitle, matchMode: FilterMatchMode.EQUALS },
        courseID: { value: courseID, matchMode: FilterMatchMode.EQUALS },
    };
    fetch();
};

const fetch = async () => {
    loading.value = true;
    RPCApi(RPCMethods.Request.FetchRequests, fetchParams.value).then((res) => {
        console.log(res);
        let d = [];
        if (res.status == 'ok') {
            const { total, requests: requestsData } = res.data.result.data;
            totalRequests.value = total;

            for (let obj of requestsData) {
                d.push({
                    id: obj.requestID,
                    title: obj.title,
                    school: obj.school,
                    studentName: obj.studentName,
                    studentID: obj.studentID,
                    examsPeriodTitle: obj.examsPeriodTitle,
                    isPrivate: obj.isPrivate === 1 ? true : false,
                    status: obj.status,
                });
            }
            requests.value = d;
        } else {
            toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Προσπαθήστε αργότερα!', life: 3000 });
        }
        loading.value = false;
    });
};

const showStudentDialog = (studentID) => {
    popupStore.show(Utils.Popups.StudentDialog, { studentID }, (action) => {
        console.log('onDialogClosed:' + action);
        if (action) fetch();
    });
};

const onHide = (action = false) => {
    dialogShown.value = false;
    closeCB.value(action);
};

defineExpose({
    show,
});
</script>
