<template>
    <Sidebar v-model:visible="layoutState.profileSidebarVisible.value" position="right" class="layout-profile-sidebar w-full sm:w-25rem">
        <div class="flex flex-column mx-auto md:mx-0">
            <div class="flex justify-content-center align-content-center">
                <img :src="Utils.GetGenericAvatar(Utils.UserInfo.id)" alt="Profile" class="w-3" />
            </div>
            <div class="flex justify-content-center align-content-center">
                <div class="col-md-12 mr-2">
                    <div class="col-md-6 text-center">
                        <span>{{ Utils.UserInfo.fname }} {{ Utils.UserInfo.lname }}</span>
                    </div>
                    <div class="col-md-6 text-center">
                        <span>({{ roleToString }})</span>
                    </div>
                </div>
            </div>

            <ul class="list-none m-0 p-0 mt-3">
                <li>
                    <a
                        class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150"
                        @click="openProfile()"
                    >
                        <span>
                            <i class="pi pi-user text-xl text-primary"></i>
                        </span>
                        <div class="ml-3">
                            <span class="mb-2 font-semibold">Προφίλ</span>
                            <p class="text-color-secondary m-0">Εμφάνιση προφίλ</p>
                        </div>
                    </a>
                </li>
                <!-- <li v-if="!isTeacher">
                    <router-link :to="{ name: 'economics' }" class="app-logo">
                        <a
                            class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150"
                            @click="openEconomics()"
                        >
                            <span>
                                <i class="pi pi-money-bill text-xl text-primary"></i>
                            </span>
                            <div class="ml-3">
                                <span class="mb-2 font-semibold">Οικονομικά</span>
                                <p class="text-color-secondary m-0">Εμφάνιση οικονομικών</p>
                            </div>
                        </a>
                    </router-link>
                </li> -->
                <!-- <li>
                    <a
                        class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150"
                        @click="openSettings()"
                    >
                        <span>
                            <i class="pi pi-cog text-xl text-primary"></i>
                        </span>
                        <div class="ml-3">
                            <span class="mb-2 font-semibold">Ρυθμίσεις</span>
                            <p class="text-color-secondary m-0">Εμφάνιση Ρυθμίσεων</p>
                        </div>
                    </a>
                </li> -->
                <li>
                    <a
                        class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150"
                        @click="logout()"
                    >
                        <span>
                            <i class="pi pi-power-off text-xl text-primary"></i>
                        </span>
                        <div class="ml-3">
                            <span class="mb-2 font-semibold">Logout</span>

                            <p class="text-color-secondary m-0">Έξοδος από την εφαρμόγη</p>
                        </div>
                    </a>
                </li>
            </ul>
        </div>

        <!-- <div class="flex flex-column mt-5 mx-auto md:mx-0">
            <span class="mb-2 font-semibold">Ειδοποιήσεις</span>
            <span class="text-color-secondary font-medium mb-5">Έχετε 3 ειδοποιήσεις</span>

            <ul class="list-none m-0 p-0">
                <li>
                    <a class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
                        <span>
                            <i class="pi pi-comment text-xl text-primary"></i>
                        </span>
                        <div class="ml-3">
                            <span class="mb-2 font-semibold">Your post has new comments</span>
                            <p class="text-color-secondary m-0">5 min ago</p>
                        </div>
                    </a>
                </li>
                <li>
                    <a class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
                        <span>
                            <i class="pi pi-trash text-xl text-primary"></i>
                        </span>
                        <div class="ml-3">
                            <span class="mb-2 font-semibold">Your post has been deleted</span>
                            <p class="text-color-secondary m-0">15min ago</p>
                        </div>
                    </a>
                </li>
                <li>
                    <a class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
                        <span>
                            <i class="pi pi-folder text-xl text-primary"></i>
                        </span>
                        <div class="ml-3">
                            <span class="mb-2 font-semibold">Post has been updated</span>
                            <p class="text-color-secondary m-0">3h ago</p>
                        </div>
                    </a>
                </li>
            </ul>
        </div> -->

        <!-- <div class="flex flex-column mt-5 mx-auto md:mx-0">
            <span class="mb-2 font-semibold">Messages</span>
            <span class="text-color-secondary font-medium mb-5">You have new messages</span>

            <ul class="list-none m-0 p-0">
                <li>
                    <a class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
                        <span> <img src="/demo/images/avatar/circle/avatar-m-8.png" alt="Avatar" class="w-2rem h-2rem" /> </span>
                        <div class="ml-3">
                            <span class="mb-2 font-semibold">James Robinson</span>
                            <p class="text-color-secondary m-0">10 min ago</p>
                        </div>
                        <Badge value="3" class="ml-auto"></Badge>
                    </a>
                </li>
                <li>
                    <a class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
                        <span> <img src="/demo/images/avatar/circle/avatar-f-8.png" alt="Avatar" class="w-2rem h-2rem" /> </span>
                        <div class="ml-3">
                            <span class="mb-2 font-semibold">Mary Watson</span>
                            <p class="text-color-secondary m-0">15min ago</p>
                        </div>
                        <Badge value="1" class="ml-auto"></Badge>
                    </a>
                </li>
                <li>
                    <a class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
                        <span> <img src="/demo/images/avatar/circle/avatar-f-4.png" alt="Avatar" class="w-2rem h-2rem" /> </span>
                        <div class="ml-3">
                            <span class="mb-2 font-semibold">Aisha Webb</span>
                            <p class="text-color-secondary m-0">3h ago</p>
                        </div>
                        <Badge value="2" class="ml-auto"></Badge>
                    </a>
                </li>
            </ul>
        </div> -->
    </Sidebar>
</template>

<script setup>
import { useLayout } from '@/layout/composables/layout';
import useRPC from '@/composables/useRPC';
import Utils from '@/utils/Utils';
import { computed } from 'vue';
import { usePopupStore } from '@/stores/PopupStore';

const { RPCLogout } = useRPC();

const { layoutState, onProfileSidebarToggle } = useLayout();

const isAdmin = computed(() => {
    return Utils.UserInfo?.role === Utils.Roles?.Admin;
});

const isOffice = computed(() => {
    return Utils.UserInfo?.role === Utils.Roles.Office;
});

const isStudent = computed(() => {
    return Utils.UserInfo && Utils.UserInfo.role === Utils.Roles.Student;
});

const isTeacher = computed(() => {
    return Utils.UserInfo && Utils.UserInfo.role === Utils.Roles.Teacher;
});

const isAdminOrOffice = computed(() => {
    return isAdmin.value || isOffice.value;
});

const roleToString = computed(() => {
    switch (Utils.UserInfo.role) {
        case Utils.Roles.Admin:
            return 'Admin';
        case Utils.Roles.Office:
            return 'Office';
        case Utils.Roles.Student:
            return 'Μαθητής';
        default:
            return 'Καθηγητής';
    }
});

const openProfile = () => {
    onProfileSidebarToggle();
    console.log('open profile');
    //show profile popup
    switch (Utils.UserInfo.role) {
        case Utils.Roles.Admin:
            usePopupStore().show(Utils.Popups.AdminDialog, { userID: Utils.UserInfo.id }, () => {});
            break;
        case Utils.Roles.Office:
            usePopupStore().show(Utils.Popups.OfficeDialog, { userID: Utils.UserInfo.id }, () => {});
            break;
        case Utils.Roles.Teacher:
            usePopupStore().show(Utils.Popups.TeacherDialog, { teacherID: Utils.UserInfo.id }, () => {});
            break;
        case Utils.Roles.Student:
            usePopupStore().show(Utils.Popups.StudentDialog, { studentID: Utils.UserInfo.id }, () => {});
            break;
    }
};

const openSettings = () => {
    onProfileSidebarToggle();
    console.log('open settings');
};

const openEconomics = () => {
    onProfileSidebarToggle();
    console.log('open economics');
};

const logout = () => {
    onProfileSidebarToggle();
    console.log('logout');
    RPCLogout();
};
</script>
