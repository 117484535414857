import { defineStore } from 'pinia';
import { ref } from 'vue';

export const usePopupStore = defineStore('PopupStore', {
    state: () => {
        return {
            popups: [],
        };
    },

    actions: {
        registerPopup(id, ref) {
            this.popups.push({ id: id, ref: ref.value });
        },

        unregisterAll() {
            console.log('unregisterAll');
            this.popups = [];
        },

        show(id, args, onCloseCB) {
            console.log('show popup:' + id);
            let popup = this.popups.find((popup) => popup.id === id);
            if (!popup) {
                console.error('popup not found: ' + id);
                return;
            }
            popup.ref.show(args, onCloseCB);
        },
    },
});
