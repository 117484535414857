<template>
    <Dialog
        v-model:visible="dialogShown"
        class="p-fluid"
        :close-on-escape="false"
        :dismissable-mask="false"
        :modal="true"
        :breakpoints="{ '960px': '75vw', '640px': '95vw' }"
        :style="{ width: '35vw' }"
    >
        <template #header>
            <h4>{{ headerTitle }}</h4>
        </template>
        <div class="col-12">
            <div class="field">
                <label :class="{ 'p-error': v$.teacher.$invalid && submitted }">Καθηγητής*</label>
                <Dropdown
                    v-model="v$.teacher.$model"
                    option-label="label"
                    :options="teachers"
                    placeholder="Επιλέξτε"
                    class="p-column-filter"
                    :class="{ 'p-invalid': v$.teacher.$invalid && submitted }"
                >
                    <template #value="slotProps">
                        <span v-if="slotProps.value != null">{{ slotProps.value.label }}</span>
                        <span v-else>Επιλέξτε</span>
                    </template>
                    <template #option="slotProps">
                        <span>{{ slotProps.option.label }}</span>
                    </template>
                </Dropdown>
                <small v-if="(v$.teacher.$invalid && submitted) || v$.teacher.$pending.$response" class="p-error">{{ v$.teacher.required.$message }}</small>
            </div>
            <div class="field">
                <label :class="{ 'p-error': v$.classroom.$invalid && submitted }">Αίθουσα*</label>
                <Dropdown
                    v-model="v$.classroom.$model"
                    option-label="label"
                    :options="classrooms"
                    placeholder="Επιλέξτε"
                    class="p-column-filter"
                    :class="{ 'p-invalid': v$.classroom.$invalid && submitted }"
                >
                    <template #value="slotProps">
                        <span v-if="slotProps.value != null">{{ slotProps.value.label }}</span>
                        <span v-else>Επιλέξτε</span>
                    </template>
                    <template #option="slotProps">
                        <span>{{ slotProps.option.label }}</span>
                    </template>
                </Dropdown>
                <small v-if="(v$.classroom.$invalid && submitted) || v$.classroom.$pending.$response" class="p-error">{{ v$.classroom.required.$message }}</small>
            </div>
            <div class="field">
                <label :class="{ 'p-error': v$.startDate.$invalid && submitted }">Ημέρα*</label>
                <Calendar v-model="v$.startDate.$model" date-format="dd/mm/yy" placeholder="dd/mm/yyyy" />
                <small v-if="(v$.startDate.$invalid && submitted) || v$.startDate.$pending.$response" class="p-error">{{ v$.startDate.required.$message }}</small>
            </div>
            <div class="field">
                <label :class="{ 'p-error': v$.startDatetime.$invalid && submitted }">Από*</label>
                <Calendar v-model="v$.startDatetime.$model" :show-time="true" :time-only="true" :stepMinute="30" />
                <small v-if="(v$.startDatetime.$invalid && submitted) || v$.startDatetime.$pending.$response" class="p-error">{{ v$.startDatetime.startMin.$message }}</small>
            </div>
            <div class="field">
                <label :class="{ 'p-error': v$.endDatetime.$invalid && submitted }">Μέχρι*</label>
                <Calendar v-model="v$.endDatetime.$model" :show-time="true" :time-only="true" :stepMinute="30" />
                <small v-if="(v$.endDatetime.$invalid && submitted) || v$.endDatetime.$pending.$response" class="p-error">{{ v$.endDatetime.endMax.$message }}</small>
            </div>
            <!-- <div class="field-checkbox">
                <Checkbox id="active" v-model="state.zoomMeeting" :binary="true" :disabled="editMode" />
                <label for="active">Zoom Meeting</label>
            </div> -->
            <!-- <div v-if="state.zoomMeeting && state.attendancesSubmited" class="field">
                <label for="zoomURL">Zoom URL</label>
                <InputText id="zoomURL" v-model="state.zoomURL" autocomplete="off" spellcheck="off" />
            </div> -->
        </div>
        <template #footer>
            <Button label="Άκυρο" icon="pi pi-times" class="p-button-outlined w-8rem mr-3" @click="onHide(false)" />
            <Button label="Αποθήκευση" icon="pi pi-check" class="p-button-primary w-10rem" :loading="loading" @click="handleSubmit(!v$.$invalid)" />
        </template>
    </Dialog>
</template>

<script setup>
import { useToast } from 'primevue/usetoast';
import moment from 'moment-timezone';
import { onMounted, reactive, ref, computed, watchEffect, toRefs } from 'vue';
import { required, integer, helpers } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import useRPC from '@/composables/useRPC';
import Utils from '@/utils/Utils';
const { RPCApi, RPCMethods } = useRPC();
const dialogShown = ref(false);
const submitted = ref(false);
const headerTitle = ref('');
const teachers = ref([]);
const state = reactive({
    id: null,
    classID: null,
    classroom: null,
    startDate: null,
    startDatetime: null,
    endDatetime: null,
    teacher: null,
    zoomMeeting: true,
    zoomURL: null,
    attendancesSubmited: false,
});
const rules = {
    classroom: { required },
    startDate: { required },
    teacher: { required },
    startDatetime: {
        required,
        startMin: helpers.withMessage(
            ({ $pending, $invalid, $params, $model }) => `Η ώρα έναρξης δεν μπορεί να είναι μεταγενέστερη από την ώρα λήξης.`,
            (val, { startDatetime, endDatetime }) => {
                const m1 = moment.duration(moment(startDatetime).format('HH:mm')).asMinutes();
                const m2 = moment.duration(moment(endDatetime).format('HH:mm')).asMinutes();
                return m1 < m2;
            }
        ),
    },
    endDatetime: {
        required,
        endMax: helpers.withMessage(
            ({ $pending, $invalid, $params, $model }) => `Η ώρα λήξης δεν μπορεί να είναι μικρότερη από την ώρα έναρξης.`,
            (val, { startDatetime, endDatetime }) => {
                const m1 = moment.duration(moment(endDatetime).format('HH:mm')).asMinutes();
                const m2 = moment.duration(moment(startDatetime).format('HH:mm')).asMinutes();
                return m1 > m2;
            }
        ),
    },
};
const v$ = useVuelidate(rules, state, { $stopPropagation: true });
const loading = ref(false);
const toast = useToast();
const editMode = ref(false);
const classrooms = ref([]);
const closeCB = ref(null);

const show = async (data, onCloseCB) => {
    const { lessonID, teacherID, classID, zoomURL, teacherStartTime, teacherEndTime, classroomID, attendancesSubmited } = data;
    console.warn('data:::::', data);
    resetForm();
    closeCB.value = onCloseCB;
    dialogShown.value = true;
    submitted.value = false;
    editMode.value = !!lessonID;
    state.id = lessonID;
    state.zoomURL = zoomURL;
    state.attendancesSubmited = attendancesSubmited;
    state.zoomMeeting = !editMode.value ? true : zoomURL != null;
    headerTitle.value = editMode.value ? `Τροποποίηση Μαθήματος (${lessonID})` : 'Νέo Mάθημα';
    let classroomsRes = await RPCApi(RPCMethods.Classrooms.Fetch);
    if (classroomsRes.status === 'ok') {
        const { classrooms: classroomsData } = classroomsRes.data.result.data;
        let c = [];
        for (let classroom of classroomsData) {
            c.push({
                label: `${classroom.name}`,
                value: classroom.id,
            });
        }
        classrooms.value = c;
        state.classroom = classrooms.value.find((c) => c.value === classroomID);
    }

    let teachersRes = await RPCApi(RPCMethods.User.FetchUsersByRole, { role: Utils.Roles.Teacher });
    if (teachersRes.status === 'ok') {
        let d = teachersRes.data.result.data.users.filter((u) => u.ban === 0);
        teachers.value = d.map((u) => ({ value: u.id, label: u.lname + ' ' + u.fname }));
    }

    state.teacher = teachers.value.find((u) => u.value === teacherID);
    if (teacherStartTime && teacherEndTime) {
        state.startDate = teacherStartTime;
        state.startDatetime = teacherStartTime;
        state.endDatetime = teacherEndTime;
    }

    if (editMode.value) {
        let lessonRes = await RPCApi(RPCMethods.Lessons.FetchLessonInfoById, { id: lessonID });
        if (lessonRes.status === 'ok') {
            const lessonData = lessonRes.data.result.data;
            state.classID = lessonData.classID;
            state.classroom = { value: lessonData.classroomID, label: lessonData.classroomName };
            let startedAt = teacherStartTime != null ? teacherStartTime : Utils.datetimeToDate(lessonData.lessonStartedAt);
            let endedAt = teacherEndTime != null ? teacherEndTime : Utils.datetimeToDate(lessonData.lessonEndedAt);
            state.startDate = startedAt;
            state.startDatetime = startedAt;
            state.endDatetime = endedAt;
        }
    } else {
        state.classID = classID;
    }
    loading.value = false;
};

const onHide = (action = false, payload = null) => {
    dialogShown.value = false;
    resetForm();
    closeCB.value(action, payload);
};

const handleSubmit = async (isFormValid) => {
    submitted.value = true;

    if (!isFormValid) return;

    console.log('ok submit');
    loading.value = true;
    let res;
    let lessonDateStart = moment(state.startDate)
        .set({ hour: moment(state.startDatetime).hours(), minute: moment(state.startDatetime).minutes() })
        .toDate();
    let lessonDateEnd = moment(state.startDate)
        .set({ hour: moment(state.endDatetime).hours(), minute: moment(state.endDatetime).minutes() })
        .toDate();
    console.log(lessonDateStart);
    console.log(lessonDateEnd);
    let payload = {
        classID: state.classID,
        classroomID: state.classroom.value,
        startedAt: lessonDateStart,
        endedAt: lessonDateEnd,
        teacherID: state.teacher.value,
        zoomMeeting: state.zoomMeeting,
        zoomURL: state.zoomURL,
    };

    if (editMode.value) {
        payload.id = state.id;
        payload.preserveAttendances = true;
        res = await RPCApi(RPCMethods.Lessons.UpdateLessonForClass, payload);
    } else {
        res = await RPCApi(RPCMethods.Lessons.AddLessonForClass, payload);
    }

    console.log('res::::', res);

    if (res.status === 'ok') {
        const { timeExceeded } = res.data.result.data;
        if (timeExceeded) {
            toast.add({
                severity: 'warn',
                summary: 'Η διαδικασία ολοκληρώθηκε',
                detail: `Έχει ξεπεραστεί το σύνολο ωρών: ${timeExceeded.hours}\nΣύνολο νέων ωρών: ${timeExceeded.phaseTotal}`,
            });
        } else {
            toast.add({ severity: 'success', summary: 'Η διαδικασία ολοκληρώθηκε', detail: 'Επιτυχής διαδικασία!', life: 3000 });
        }

        loading.value = false;
        if (editMode.value) await RPCApi(RPCMethods.Notifications.DeleteByLessonID, { lessonID: payload.id });
        onHide(true, { ...payload, zoomMeetingURL: res.data.result?.data?.zoomMeetingURL, lessonID: res.data.result?.data?.lessonID });
        return;
    }

    toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Προσπαθήστε ξανά!', life: 3000 });
    loading.value = false;
};
const resetForm = () => {
    for (let field in state) {
        state[field] = null;
    }

    submitted.value = false;
    loading.value = false;
};

defineExpose({
    show,
});
</script>
