import { Base64 } from 'js-base64';
import useStorage from '@/composables/useStorage';
import moment from 'moment-timezone';
import { getCurrentInstance } from 'vue';
import axios from 'axios';

const { GetStorage, StorageKeys, SetStorage } = useStorage();

export default class {
    static get TipsTypes() {
        return Object.freeze({
            Welcome: 0,
            Info: 1,
            Question: 2,
            Warning: 3,
        });
    }

    static get TipsTypesOptions() {
        let arr = [];
        for (let k in this.TipsTypes) {
            arr.push({
                label: this.TipsTypeToString(this.TipsTypes[k]),
                value: this.TipsTypes[k],
            });
        }
        return arr;
    }

    static TipsTypeToString(type) {
        switch (type) {
            case this.TipsTypes.Welcome:
                return 'Καλωσόρισμα';
            case this.TipsTypes.Info:
                return 'Πληροφορία';
            case this.TipsTypes.Question:
                return 'Ερώτηση';
            case this.TipsTypes.Warning:
                return 'Προειδοποίηση';
            default:
                return '';
        }
    }

    static get InvoiceStatus() {
        return Object.freeze({
            Commited: 0,
            Pending: 1,
        });
    }

    static InvoiceStatusToString(type) {
        switch (type) {
            case this.InvoiceStatus.Commited:
                return 'Εκδόθηκε';
            case this.InvoiceStatus.Pending:
                return 'Εκκρεμεί';
            default:
                return '';
        }
    }

    static get InvoiceStatusOptions() {
        let arr = [];
        for (let k in this.InvoiceStatus) {
            arr.push({
                label: this.InvoiceStatusToString(this.InvoiceStatus[k]),
                value: this.InvoiceStatus[k],
            });
        }
        return arr;
    }

    static get CustomerTypes() {
        return Object.freeze({
            Domestic_customer: 1,
            Intra_community_customer: 2,
            Foreign_customer: 3,
            Private_individual: 4,
        });
    }

    //ISO 3166-1 alpha-2
    static get CountriesCodesInGreek() {
        //https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
        return Object.freeze({
            US: 'ΗΠΑ',
            CA: 'Καναδάς',
            AF: 'Αφγανιστάν',
            AX: 'Νήσοι Όλαντ',
            AL: 'Αλβανία',
            DZ: 'Αλγερία',
            AS: 'Αμερικανική Σαμόα',
            AD: 'Ανδόρα',
            AO: 'Αγκόλα',
            AI: 'Αγκουίλα',
            AQ: 'Ανταρκτική',
            AG: 'Αντίγκουα και Μπαρμπούντα',
            AR: 'Αργεντινή',
            AM: 'Αρμενία',
            AW: 'Αρούμπα',
            AU: 'Αυστραλία',
            AT: 'Αυστρία',
            AZ: 'Αζερμπαϊτζάν',
            BS: 'Μπαχάμες',
            BH: 'Μπαχρέιν',
            BD: 'Μπαγκλαντές',
            BB: 'Μπαρμπάντος',
            BY: 'Λευκορωσία',
            BE: 'Βέλγιο',
            BZ: 'Μπελίζ',
            BJ: 'Μπενίν',
            BM: 'Βερμούδες',
            BT: 'Μπουτάν',
            BO: 'Βολιβία',
            BQ: 'Ολλανδία Καραϊβικής',
            BA: 'Βοσνία και Ερζεγοβίνη',
            BW: 'Μποτσουάνα',
            BV: 'Νήσος Μπουβέ',
            BR: 'Βραζιλία',
            IO: 'Βρετανική Ινδική Ωκεανία',
            VG: 'Βρετανικές Παρθένοι Νήσοι',
            BN: 'Μπρουνέι',
            BG: 'Βουλγαρία',
            BF: 'Μπουρκίνα Φάσο',
            BI: 'Μπουρούντι',
            KH: 'Καμπότζη',
            CM: 'Καμερούν',
            CV: 'Πράσινο Ακρωτήριο',
            KY: 'Νήσοι Κέιμαν',
            CF: 'Κεντροαφρικανική Δημοκρατία',
            TD: 'Τσαντ',
            CL: 'Χιλή',
            CN: 'Κίνα',
            CX: 'Νήσος των Χριστουγέννων',
            CC: 'Νήσοι Κόκος (Κίλινγκ)',
            MF: 'Άγιος Μαρτίνος',
            CO: 'Κολομβία',
            KM: 'Κομόρες',
            CG: 'Κονγκό - Μπραζαβίλ',
            CK: 'Νήσοι Κουκ',
            CR: 'Κόστα Ρίκα',
            HR: 'Κροατία',
            CU: 'Κούβα',
            CW: 'Κουρασάο',
            CY: 'Κύπρος',
            CZ: 'Τσεχία',
            CD: 'Κονγκό - Κινσάσα',
            DK: 'Δανία',
            DJ: 'Τζιμπουτί',
            DM: 'Ντομίνικα',
            DO: 'Δομινικανή Δημοκρατία',
            TL: 'Ανατολικό Τιμόρ',
            EC: 'Ισημερινός',
            EG: 'Αίγυπτος',
            SV: 'Ελ Σαλβαδόρ',
            GQ: 'Ισημερινή Γουινέα',
            ER: 'Ερυθραία',
            EE: 'Εσθονία',
            ET: 'Αιθιοπία',
            FK: 'Νήσοι Φόκλαντ',
            FO: 'Νήσοι Φερόες',
            FJ: 'Φίτζι',
            FI: 'Φινλανδία',
            FR: 'Γαλλία',
            GF: 'Γαλλική Γουιάνα',
            PF: 'Γαλλική Πολυνησία',
            TF: 'Γαλλικά Νότια Εδάφη',
            GA: 'Γκαμπόν',
            GM: 'Γκάμπια',
            GE: 'Γεωργία',
            DE: 'Γερμανία',
            GH: 'Γκάνα',
            GI: 'Γιβραλτάρ',
            GR: 'Ελλάδα',
            GL: 'Γροιλανδία',
            GD: 'Γρενάδα',
            GP: 'Γουαδελούπη',
            GU: 'Γκουάμ',
            GT: 'Γουατεμάλα',
            GG: 'Γκέρνζι',
            GN: 'Γουινέα',
            GW: 'Γουινέα-Μπισάου',
            GY: 'Γουιάνα',
            HT: 'Αϊτή',
            HM: 'Νήσοι Χερντ και Μακντόναλντ',
            HN: 'Ονδούρα',
            HK: 'Χονγκ Κονγκ ΕΔΠ Κίνας',
            HU: 'Ουγγαρία',
            IS: 'Ισλανδία',
            IM: 'Νήσος του Μαν',
            IN: 'Ινδία',
            ID: 'Ινδονησία',
            IQ: 'Ιράκ',
            IE: 'Ιρλανδία',
            IR: 'Ιράν',
            IL: 'Ισραήλ',
            IT: 'Ιταλία',
            CI: 'Ακτή Ελεφαντοστού',
            JM: 'Τζαμάικα',
            JP: 'Ιαπωνία',
            JE: 'Τζέρζι',
            JO: 'Ιορδανία',
            KZ: 'Καζακστάν',
            KE: 'Κένυα',
            KI: 'Κιριμπάτι',
            KP: 'Βόρεια Κορέα',
            KR: 'Νότια Κορέα',
            KW: 'Κουβέιτ',
            KG: 'Κιργιζία',
            LA: 'Λάος',
            LV: 'Λετονία',
            LB: 'Λίβανος',
            LS: 'Λεσότο',
            LR: 'Λιβερία',
            LY: 'Λιβύη',
            LI: 'Λιχτενστάιν',
            LT: 'Λιθουανία',
            LU: 'Λουξεμβούργο',
            MO: 'Μακάο ΕΔΠ Κίνας',
            MG: 'Μαδαγασκάρη',
            MW: 'Μαλάουι',
            MY: 'Μαλαισία',
            MV: 'Μαλδίβες',
            ML: 'Μάλι',
            MT: 'Μάλτα',
            MH: 'Νήσοι Μάρσαλ',
            MQ: 'Μαρτινίκα',
            MR: 'Μαυριτανία',
            MU: 'Μαυρίκιος',
            YT: 'Μαγιότ',
            MX: 'Μεξικό',
            FM: 'Μικρονησία',
            MD: 'Μολδαβία',
            MC: 'Μονακό',
            ME: 'Μαυροβούνιο',
            MN: 'Μογγολία',
            MS: 'Μονσεράτ',
            MA: 'Μαρόκο',
            MZ: 'Μοζαμβίκη',
            MM: 'Μιανμάρ',
            NA: 'Ναμίμπια',
            NR: 'Ναουρού',
            NP: 'Νεπάλ',
            NL: 'Ολλανδία',
            NC: 'Νέα Καληδονία',
            NZ: 'Νέα Ζηλανδία',
            NI: 'Νικαράγουα',
            NE: 'Νίγηρας',
            NG: 'Νιγηρία',
            NU: 'Νιούε',
            NF: 'Νήσος Νόρφολκ',
            MK: 'ΠΓΔΜ',
            MP: 'Βόρειες Μαριάνες Νήσοι',
            NO: 'Νορβηγία',
            OM: 'Ομάν',
            PK: 'Πακιστάν',
            PW: 'Παλάου',
            PA: 'Παναμάς',
            PG: 'Παπούα Νέα Γουινέα',
            PY: 'Παραγουάη',
            PE: 'Περού',
            PH: 'Φιλιππίνες',
            PN: 'Νήσοι Πίτκερν',
            PL: 'Πολωνία',
            PT: 'Πορτογαλία',
            PR: 'Πουέρτο Ρίκο',
            QA: 'Κατάρ',
            RE: 'Ρεϊνιόν',
            RO: 'Ρουμανία',
            RU: 'Ρωσία',
            RW: 'Ρουάντα',
            BL: 'Άγιος Βαρθολομαίος',
            LC: 'Αγία Λουκία',
            WS: 'Σαμόα',
            SM: 'Άγιος Μαρίνος',
            ST: 'Σάο Τομέ και Πρίνσιπε',
            SA: 'Σαουδική Αραβία',
            SN: 'Σενεγάλη',
            RS: 'Σερβία',
            SC: 'Σεϋχέλλες',
            SL: 'Σιέρα Λεόνε',
            SG: 'Σιγκαπούρη',
            SX: 'Άγιος Μαρτίνος (Ολλανδικό τμήμα)',
            SK: 'Σλοβακία',
            SI: 'Σλοβενία',
            SB: 'Νήσοι Σολομώντος',
            SO: 'Σομαλία',
            ZA: 'Νότια Αφρική',
            GS: 'Νότια Γεωργία και Νότιες Νήσοι Σάντουιτς',
            SS: 'Νότιο Σουδάν',
            ES: 'Ισπανία',
            LK: 'Σρι Λάνκα',
            SH: 'Αγία Ελένη',
            KN: 'Άγιος Χριστόφορος και Νέβις',
            PM: 'Άγιος Πέτρος και Μικελόν',
            VC: 'Άγιος Βικέντιος και Γρεναδίνες',
            PS: 'Παλαιστίνη',
            SD: 'Σουδάν',
            SR: 'Σουρινάμ',
            SJ: 'Σβάλμπαρντ και Γιαν Μαγιέν',
            SZ: 'Σουαζιλάνδη',
            SE: 'Σουηδία',
            CH: 'Ελβετία',
            SY: 'Συρία',
            TW: 'Ταϊβάν',
            TJ: 'Τατζικιστάν',
            TZ: 'Τανζανία',
            TH: 'Ταϊλάνδη',
            TG: 'Τόγκο',
            TK: 'Τοκελάου',
            TO: 'Τόνγκα',
            TT: 'Τρινιντάντ και Τομπάγκο',
            TN: 'Τυνησία',
            TR: 'Τουρκία',
            TM: 'Τουρκμενιστάν',
            TC: 'Νήσοι Τερκς και Κάικος',
            TV: 'Τουβαλού',
            UG: 'Ουγκάντα',
            UA: 'Ουκρανία',
            AE: 'Ηνωμένα Αραβικά Εμιράτα',
            GB: 'Ηνωμένο Βασίλειο',
            UM: 'Απομακρυσμένες Νησίδες ΗΠΑ',
            VI: 'Παρθένοι Νήσοι ΗΠΑ',
            OC: 'Ανατολική Ωκεανία',
            UY: 'Ουρουγουάη',
            UZ: 'Ουζμπεκιστάν',
            VU: 'Βανουάτου',
            VE: 'Βενεζουέλα',
            VA: 'Βατικανό',
            VN: 'Βιετνάμ',
            WF: 'Νήσοι Ουάλις και Φουτούνα',
            EH: 'Δυτική Σαχάρα',
            YE: 'Υεμένη',
            ZM: 'Ζάμπια',
            ZW: 'Ζιμπάμπουε',
        });
    }

    static get Languages() {
        return Object.freeze({
            el: 'Ελληνικά',
            en: 'Αγγλικά',
            'en-gb': 'Αγγλικά (Ηνωμένο Βασίλειο)',
            fr: 'Γαλλικά',
            de: 'Γερμανικά',
            it: 'Ιταλικά',
            es: 'Ισπανικά',
            bg: 'Βουλγαρικά',
        });
    }

    static get LanguagesOptions() {
        let arr = [];
        for (let k in this.Languages) {
            arr.push({
                label: this.Languages[k],
                value: k,
            });
        }
        //sort by label
        arr.sort((a, b) => {
            if (a.label < b.label) {
                return -1;
            }
            if (a.label > b.label) {
                return 1;
            }
            return 0;
        });
        return arr;
    }

    static get CountryOptions() {
        let arr = [];
        for (let k in this.CountriesCodesInGreek) {
            arr.push({
                label: this.CountriesCodesInGreek[k],
                value: k,
            });
        }
        //sort by label
        arr.sort((a, b) => {
            if (a.label < b.label) {
                return -1;
            }
            if (a.label > b.label) {
                return 1;
            }
            return 0;
        });
        return arr;
    }

    static get DoyTypes() {
        return Object.freeze({
            1129: 'Agiou Dimitriou',
            1111: 'Agiou Ioannou',
            1115: 'Agiou Konstantinou',
            1119: 'Agiou Nikolaou',
            1136: 'Agion Anargiron',
            1552: 'Agriniou',
            1101: "A' Athinon",
            1104: "D' Athinon",
            1106: "ST' Athinon",
            1112: "IB' Athinon",
            1113: "IG' Athinon",
            1114: "ID' Athinon",
            1117: "IZ' Athinon",
            1159: 'F.A.E. Athinon',
            1137: 'Aigaleo',
            2311: 'Aigiou',
            5211: 'Aleksandroupolis',
            2411: 'Amaliadas',
            1135: 'Amarousiou',
            4233: 'Ampelokipon',
            1912: 'Amfissas',
            9311: 'Argostoliou',
            2111: 'Argous',
            6111: 'Artas',
            1302: 'Axarnon',
            4112: 'Veroias',
            3321: 'Volou',
            1152: 'Vyronos',
            1179: 'Galatsiou',
            4621: 'Giannitson',
            1139: 'Glifadas',
            4521: 'Grevenon',
            5111: 'Dramas',
            4631: 'Edessas',
            1303: 'Elefsinas',
            9111: 'Zakinthou',
            6211: 'Igoumenitsas',
            1173: 'Ilioupolis',
            8110: 'Irakliou',
            4211: "A' Thessalonikis",
            4214: "D' Thessalonikis",
            4215: "E' Thessalonikis",
            4216: "ST' Thessalonikis",
            4217: "Z' Thessalonikis",
            4228: "H' Thessalonikis",
            4224: 'F.A.E. Thessalonikis',
            1411: 'Thivon',
            7121: 'Thiras',
            6311: 'Ioanninon',
            4234: 'Ionias Thessalonikis',
            5321: 'Kavalas',
            4232: 'Kalamarias',
            2711: 'Kalamatas',
            1130: 'Kallitheas',
            3111: 'Karditsas',
            1611: 'Karpenisiou',
            4311: 'Kastorias',
            4711: 'Katerinis',
            1125: 'Katoikon Eksoterikou',
            9211: 'Kerkiras',
            1153: 'Kifisias',
            4411: 'Kilkis',
            4541: 'Kozanis',
            5511: 'Komotinis',
            2513: 'Korinthou',
            1304: 'Koropiou',
            1722: 'Kimis',
            7531: 'Ko',
            1832: 'Lamias',
            3231: 'Larisas',
            4222: 'Lagada',
            9421: 'Lefkadas',
            1421: 'Livadias',
            1118: 'Megalon Epixeiriseon',
            1531: 'Mesologiou',
            1211: 'Moschatou',
            7172: 'Mikonou',
            7231: 'Mitilinis',
            3323: 'N. Ionias Volou',
            1145: 'N. Irakleiou',
            7151: 'Naksou',
            2131: 'Nafpliou',
            1131: 'Neas Ionias',
            1132: 'Neas Smyrnis',
            4923: 'Neon Moudanion',
            1220: 'Nikaias',
            5411: 'Ksanthis',
            5231: 'Orestiadas',
            1133: 'Palaiou Falirou',
            1312: 'Pallinis',
            7161: 'Parou',
            2331: "A' Patron",
            2334: "G' Patron",
            1201: "A' Peiraia",
            1203: "G' Peiraia",
            1204: "D' Peiraia",
            1205: "E' Peiraia",
            1207: 'Ploion Peiraia',
            1206: 'F.A.E. Peiraia',
            1158: 'Peristeriou',
            4922: 'Polygirou',
            6411: 'Prevezas',
            4531: 'Ptolemaidas',
            2412: 'Pirgou',
            8341: 'Rethimnou',
            7542: 'Rodou',
            7322: 'Samou',
            5621: 'Serron',
            2632: 'Spartis',
            7171: 'Sirou',
            3412: 'Trikalon',
            2231: 'Tripolis',
            4812: 'Florinas',
            1134: 'Chalandriou',
            1732: 'Chalkidikis',
            8431: 'Chania',
            7411: 'Chio',
            1151: 'Cholargou',
            1175: 'Psixikou',
            1116: "IST' Athinon",
            5341: 'Eleftheroupolis',
            8111: "A' Irakliou",
            8112: "A' Irakliou (Moiron)",
            3232: "B'-G' Larisas",
            3233: "G' Larisas",
            1138: "A' Peristeriou",
            1157: "B' Peristeriou",
            5611: "A' Serron",
        });
    }

    static get DoyOptions() {
        let arr = [];
        for (let k in this.DoyTypes) {
            arr.push({
                label: this.DoyTypes[k],
                value: k,
            });
        }
        //sort by label
        arr.sort((a, b) => {
            if (a.label < b.label) {
                return -1;
            }
            if (a.label > b.label) {
                return 1;
            }
            return 0;
        });
        return arr;
    }

    static CustomerTypeToString(type) {
        switch (type) {
            case this.CustomerTypes.Domestic_customer:
                return 'Πελάτης εσωτερικού';
            case this.CustomerTypes.Intra_community_customer:
                return 'Ενδοκοινοτικός πελάτης';
            case this.CustomerTypes.Foreign_customer:
                return 'Πελάτης εξωτερικού';
            case this.CustomerTypes.Private_individual:
                return 'Ιδιώτης';
            default:
                return '';
        }
    }

    static get CustomerTypesOptions() {
        let arr = [];
        for (let k in this.CustomerTypes) {
            arr.push({
                label: this.CustomerTypeToString(this.CustomerTypes[k]),
                value: this.CustomerTypes[k],
            });
        }
        return arr;
    }

    static get InvoiceTypes() {
        return Object.freeze({
            Invoice: 0,
            Receipt: 1,
        });
    }

    static InvoiceTypeToString(type) {
        switch (type) {
            case this.InvoiceTypes.Invoice:
                return 'Τιμολόγιο Παροχής Υπηρεσιών';
            case this.InvoiceTypes.Receipt:
                return 'Απόδειξη Παροχής Υπηρεσιών';
            default:
                return '';
        }
    }

    static InvoiceCreditTypeToString(type) {
        switch (type) {
            case this.InvoiceTypes.Invoice:
                return 'Πιστωτικό τιμολόγιο';
            case this.InvoiceTypes.Receipt:
                return 'Απόδειξη επιστροφής';
            default:
                return '';
        }
    }

    static get InvoiceTypesOptions() {
        let arr = [];
        for (let k in this.InvoiceTypes) {
            arr.push({
                label: this.InvoiceTypeToString(this.InvoiceTypes[k]),
                value: this.InvoiceTypes[k],
            });
        }
        return arr;
    }

    static get RequestRights() {
        return Object.freeze({
            Chat: 1,
            Files: 2,
            ZoomLinks: 4,
            Videos: 8,
            Quiz: 16,
            All: 31,
        });
    }

    static RequestRightToString(type) {
        switch (type) {
            case this.RequestRights.All:
                return 'Όλα';
            case this.RequestRights.Chat:
                return 'Chat';
            case this.RequestRights.Files:
                return 'Αρχεία';
            case this.RequestRights.Videos:
                return 'Videos';
            case this.RequestRights.ZoomLinks:
                return 'Zoom links';
            case this.RequestRights.Quiz:
                return 'Quiz';
            default:
                return '';
        }
    }

    static get RequestRightsTypes() {
        let arr = [];
        for (let k in this.RequestRights) {
            arr.push({
                label: this.RequestRightToString(this.RequestRights[k]),
                value: this.RequestRights[k],
            });
        }
        return arr;
    }

    static get Roles() {
        return Object.freeze({
            Admin: 1,
            Office: 2,
            Student: 3,
            Teacher: 4,
            Guest: 5,
        });
    }

    static get Popups() {
        return Object.freeze({
            ClassDialog: 'ClassDialog',
            TeacherDialog: 'TeacherDialog',
            RequestDialog: 'RequestDialog',
            StudentDialog: 'StudentDialog',
            AttendancesDialog: 'AttendancesDialog',
            LessonDialog: 'LessonDialog',
            NotificationDialog: 'NotificationDialog',
            StudentAttendancesDialog: 'StudentAttendancesDialog',
            OfferDialog: 'OfferDialog',
            GeneralDueDialog: 'GeneralDueDialog',
            PaymentDialog: 'PaymentDialog',
            InstallmentDialog: 'InstallmentDialog',
            ClassroomDialog: 'ClassroomDialog',
            CourseDialog: 'CourseDialog',
            CourseRequestsDialog: 'CourseRequestsDialog',
            PeriodDialog: 'PeriodDialog',
            SchoolDialog: 'SchoolDialog',
            CoursePeriodRequestsDialog: 'CoursePeriodRequestsDialog',
            CoursePeriodClassesDialog: 'CoursePeriodClassesDialog',
            OfficeDialog: 'OfficeDialog',
            TipsAnnouncementDialog: 'TipsAnnouncementDialog',
            VersionUpdateDialog: 'VersionUpdateDialog',
            PDFViewerDialog: 'PDFViewerDialog',
            InvoiceDialog: 'InvoiceDialog',
            AdminDialog: 'AdminDialog',
            TodoDialog: 'TodoDialog',
            TipsDialog: 'TipsDialog',
            InvoiceCreditDialog: 'InvoiceCreditDialog',
            JobPostingsCreateDialog: 'JobPostingsCreateDialog',
            StudentTempRequestDialog: 'StudentTempRequestDialog',
            JobPostingsTeacherDialog: 'JobPostingsTeacherDialog',
            JobPostingsEditDialog: 'JobPostingsEditDialog',
            QuizDialog: 'QuizDialog',
            QuizPlayDialog: 'QuizPlayDialog',
        });
    }

    static get ClassStatus() {
        return Object.freeze({
            Active: 1,
            Canceled: 2,
            Completed: 3,
        });
    }

    static get NotificationStatus() {
        return Object.freeze({
            Waiting: 0,
            Completed: 1,
            Error: 2,
        });
    }

    static get PaymentType() {
        return Object.freeze({
            Cash: 1,
            CreditCard: 2,
            CreditCardEmailInvoice: 3,
        });
    }

    static get ObsoleteTaskTypes() {
        return ['next_lesson_unset', 'pending_requests_for_class'];
    }

    static get TaskType() {
        return Object.freeze({
            Overdue: 'overdue',
            NextLessonUnset: 'next_lesson_unset',
            DuesWithoutInstallmentsActive: 'dues_without_installments_active',
            DuesWithoutInstallmentsInactive: 'dues_without_installments_inactive',
            PreRegistered: 'preregistered',
            ExpiredRequest: 'expired_request',
            PendingRequestsForClass: 'pending_requests_for_class',
            // PendingInvoice: 'pending_invoice',
            OverlappingLessons: 'overlapping_lessons',
            OverlappingLessonsTeachers: 'overlapping_lessons_teachers',
            OverlappingLessonsStudents: 'overlapping_lessons_students',
            InvoicesDiff: 'invoices_diff',
            OnHoldUsers: 'on_hold_users',
        });
    }

    static get TodoType() {
        return Object.freeze({
            Student: 'student',
            Teacher: 'teacher',
            Class: 'class',
            Other: 'other',
        });
    }

    static get RequestStatus() {
        return Object.freeze({
            Waiting: 0,
            Posted: 1,
            PreRegistered: 2,
            OnClass: 3,
            Completed: 4,
            Canceled: 5,
            Stopped: 6,
            NotServed: 7,
            OnHold: 8,
        });
    }

    static get JobPostingStatus() {
        return Object.freeze({
            Waiting: 0,
            Completed: 1,
            Canceled: 2,
        });
    }

    static get NotificationType() {
        return Object.freeze({
            Email: 'email',
            PushNotification: 'push_notification',
            Phone: 'phone',
        });
    }

    static NotificationTypeToString(status) {
        switch (status) {
            case this.NotificationType.Email:
                return 'Email';
            case this.NotificationType.PushNotification:
                return 'Push Notification (mobile app)';
            case this.NotificationType.Phone:
                return 'Τηλεφωνική Επικοινωνία';
            default:
                return '';
        }
    }

    static RequestStatusToString(status) {
        switch (status) {
            case this.RequestStatus.Waiting:
                return 'Αναμονή';
            case this.RequestStatus.Posted:
                return 'Ενημερώθηκε';
            case this.RequestStatus.PreRegistered:
                return 'Προεγγραφή';
            case this.RequestStatus.OnClass:
                return 'Σε Τμήμα';
            case this.RequestStatus.Completed:
                return 'Ολοκληρώθηκε';
            case this.RequestStatus.Canceled:
                return 'Ακυρώθηκε';
            case this.RequestStatus.Stopped:
                return 'Διέκοψε';
            case this.RequestStatus.NotServed:
                return 'Δεν Εξυπηρετήθηκε';
            case this.RequestStatus.OnHold:
                return 'Σε Φραγή';
            default:
                return '';
        }
    }

    static JobPostingStatusToString(status) {
        switch (status) {
            case this.JobPostingStatus.Waiting:
                return 'Αναμονή';
            case this.JobPostingStatus.Completed:
                return 'Ολοκληρώθηκε';
            case this.JobPostingStatus.Canceled:
                return 'Ακυρώθηκε';
            default:
                return '';
        }
    }

    static PaymentTypeToString(type) {
        switch (type) {
            case this.PaymentType.Cash:
                return 'Μετρητά';
            case this.PaymentType.CreditCard:
                return 'Πιστωτική κάρτα';
            case this.PaymentType.CreditCardEmailInvoice:
                return 'Πληρωμή με email';
            default:
                return '';
        }
    }

    static TaskTypeToString(type) {
        switch (type) {
            case this.TaskType.Overdue:
                return 'Εκπρόθεσμη οφειλή';
            case this.TaskType.NextLessonUnset:
                return 'Δεν έχει οριστεί μάθημα';
            case this.TaskType.DuesWithoutInstallmentsActive:
                return 'Οφειλές χωρίς ρύθμιση (Ενεργές)';
            case this.TaskType.DuesWithoutInstallmentsInactive:
                return 'Οφειλές χωρίς ρύθμιση (Ανενεργές)';
            case this.TaskType.PreRegistered:
                return 'Αίτηση σε Προεγγραφή';
            case this.TaskType.ExpiredRequest:
                return 'Ληγμένη Αίτηση';
            case this.TaskType.PendingRequestsForClass:
                return 'Αίτηση σε Αναμονή';
            case this.TaskType.PendingInvoice:
                return 'Παραστατικό σε Εκκρεμότητα';
            case this.TaskType.OverlappingLessons:
                return 'Επικαλυπτόμενα Μαθήματα (Τμήματα)';
            case this.TaskType.OverlappingLessonsTeachers:
                return 'Επικαλυπτόμενα Μαθήματα (Καθηγητές)';
            case this.TaskType.OverlappingLessonsStudents:
                return 'Επικαλυπτόμενα Μαθήματα (Μαθητές)';
            case this.TaskType.InvoicesDiff:
                return 'Υπόλοιπα παραστατικών (Μαθητές)';
            case this.TaskType.OnHoldUsers:
                return 'Μαθητές σε Φραγή';
            default:
                return '';
        }
    }

    static get TaskTypes() {
        let arr = [];
        for (let k in this.TaskType) {
            arr.push({
                label: this.TaskTypeToString(this.TaskType[k]),
                value: this.TaskType[k],
            });
        }
        return arr;
    }

    static TodoTypeToString(type) {
        switch (type) {
            case this.TodoType.Student:
                return 'Μαθητής';
            case this.TodoType.Teacher:
                return 'Καθηγητής';
            case this.TodoType.Class:
                return 'Τμήμα';
            case this.TodoType.Other:
                return 'Άλλο';
            default:
                return '';
        }
    }

    static get TodoTypes() {
        let arr = [];
        for (let k in this.TodoType) {
            arr.push({
                label: this.TodoTypeToString(this.TodoType[k]),
                value: this.TodoType[k],
            });
        }
        return arr;
    }

    static get NotificationTypes() {
        let arr = [];
        for (let k in this.NotificationType) {
            arr.push({
                label: this.NotificationTypeToString(this.NotificationType[k]),
                value: this.NotificationType[k],
            });
        }
        return arr;
    }

    static get RequestStatuses() {
        let arr = [];
        for (let k in this.RequestStatus) {
            arr.push({
                label: this.RequestStatusToString(this.RequestStatus[k]),
                value: this.RequestStatus[k],
            });
        }
        return arr;
    }

    static get JobPostingStatuses() {
        let arr = [];
        for (let k in this.JobPostingStatus) {
            arr.push({
                label: this.JobPostingStatusToString(this.JobPostingStatus[k]),
                value: this.JobPostingStatus[k],
            });
        }
        return arr;
    }

    static get PaymentTypes() {
        let arr = [];
        for (let k in this.PaymentType) {
            arr.push({
                label: this.PaymentTypeToString(this.PaymentType[k]),
                value: this.PaymentType[k],
            });
        }
        return arr;
    }

    static ClassStatusToString(status) {
        switch (status) {
            case this.ClassStatus.Active:
                return 'Σε Λειτουργία';
            case this.ClassStatus.Canceled:
                return 'Ακυρώθηκε';
            case this.ClassStatus.Completed:
                return 'Ολοκληρώθηκε';
            default:
                return '';
        }
    }

    static get ClassStatuses() {
        let arr = [];
        for (let k in this.ClassStatus) {
            arr.push({
                label: this.ClassStatusToString(this.ClassStatus[k]),
                value: this.ClassStatus[k],
            });
        }
        return arr;
    }

    static NotificationStatusToString(status) {
        switch (status) {
            case this.NotificationStatus.Waiting:
                return 'Προγραμματισμένο';
            case this.NotificationStatus.Completed:
                return 'Απεσταλμένο';
            case this.NotificationStatus.Error:
                return 'Σφάλμα';
            default:
                return '';
        }
    }

    static get GendersEnum() {
        return Object.freeze({
            Male: 1,
            Female: 2,
            Other: 3,
        });
    }

    static get Genders() {
        return [
            {
                label: 'Άνδρας',
                value: 1,
            },
            {
                label: 'Γυναίκα',
                value: 2,
            },
            {
                label: 'Άλλο',
                value: 3,
            },
        ];
    }

    static get Types() {
        return [
            {
                label: 'Κανονικό',
                value: 0,
            },
            {
                label: 'Ιδιαίτερο',
                value: 1,
            },
        ];
    }

    static get MailReadTypes() {
        return [
            {
                label: 'Δεν διαβάστηκε',
                value: 0,
            },
            {
                label: 'Διαβάστηκε',
                value: 1,
            },
        ];
    }

    static FromBoolToTinyInt(val) {
        return val ? 1 : 0;
    }

    static get UserInfo() {
        if (GetStorage(StorageKeys.AccessToken) == null) return null;

        let decodedJWT = Base64.decode(GetStorage(StorageKeys.AccessToken).split('.')[1]);
        return JSON.parse(decodedJWT);
    }

    static GetGenericAvatar(seed) {
        //https://api.dicebear.com/7.x/bottts/svg?seed=John
        if (this.UserInfo == null || seed == null) return `https://api.dicebear.com/7.x/bottts/svg?seed=${Date.now()}`;

        return `https://api.dicebear.com/7.x/bottts/svg?seed=${seed}`;
    }

    static GetGenericRoomAvatar(seed) {
        if (seed == null) return `https://api.dicebear.com/7.x/identicon/svg?seed=${Date.now()}`;

        return `https://api.dicebear.com/7.x/identicon/svg?seed=${seed}`;
    }

    static toEUR(value) {
        if (value == null) return '0,00 €';
        return value.toLocaleString('el-GR', { style: 'currency', currency: 'EUR' });
    }

    static formatDate(date) {
        if (date == null) return '';
        return date.toLocaleDateString('el-GR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    }

    static toHoursAndMinutes(hours) {
        let sumMinutes = hours * 60;
        let totalHours = Math.floor(sumMinutes / 60);
        let totalMinutes = Math.floor(sumMinutes % 60);
        if (totalHours === 0) return totalMinutes + ' λεπτά';
        if (totalMinutes === 0) {
            if (totalHours === 1) return totalHours + ' ώρα';
            return totalHours + ' ώρες';
        }
        if (totalHours === 1) return totalHours + ' ώρα και ' + totalMinutes + ' λεπτά';
        return totalHours + ' ώρες και ' + totalMinutes + ' λεπτά';
    }

    static datetimeToDate(datetime) {
        if (datetime == null) return null;
        return moment(datetime.slice(0, 19).replace('T', ' ')).tz('Europe/Athens').toDate();
    }

    static emptyToNull(val) {
        if (val == null || val.trim() === '') return null;
        return val;
    }

    static IsSameDay(d1, d2) {
        return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
    }

    static ParseTimestamp(timestamp, format = '') {
        if (!timestamp) return;

        const date = timestamp.seconds ? new Date(timestamp.seconds * 1000) : timestamp;

        if (format === 'HH:mm') {
            return `${this.zeroPad(date.getHours(), 2)}:${this.zeroPad(date.getMinutes(), 2)}`;
        } else if (format === 'DD MMMM YYYY') {
            const options = { month: 'long', year: 'numeric', day: 'numeric' };
            return `${new Intl.DateTimeFormat('el-GR', options).format(date)}`;
        } else if (format === 'DD/MM/YY') {
            const options = { month: 'numeric', year: 'numeric', day: 'numeric' };
            return `${new Intl.DateTimeFormat('el-GR', options).format(date)}`;
        } else if (format === 'DD MMMM, HH:mm') {
            const options = { month: 'long', day: 'numeric' };
            return `${new Intl.DateTimeFormat('el-GR', options).format(date)}, ${this.zeroPad(date.getHours(), 2)}:${this.zeroPad(date.getMinutes(), 2)}`;
        }

        return date;
    }

    static formatBytes(bytes, decimals = 2) {
        if (!+bytes) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    }

    static zeroPad(num, pad) {
        return String(num).padStart(pad, '0');
    }

    static get BASE() {
        return import.meta.env.VITE_BASE_URL;
    }

    static getRoute(route) {
        return import.meta.env.VITE_BASE_URL + route;
    }

    static get Domain() {
        return import.meta.env.VITE_DOMAIN;
    }

    static get ElorusPaymentMethods() {
        return Object.freeze({
            1: 'Λογαριασμός πληρωμών εσωτερικού',
            3: 'Μετρητά',
            7: 'POS / e-POS',
        });
    }

    static get ElorusPayments() {
        const arr = [];
        for (const k in this.ElorusPaymentMethods) {
            arr.push({
                label: this.ElorusPaymentMethods[k],
                value: k,
            });
        }
        return arr;
    }

    static getMyDATAPaymentMethodID(paymentCode) {
        if (paymentCode === 5 || paymentCode === 6 || paymentCode === 7) return 1;
        if (paymentCode === 1 || paymentCode === 2) return 3;
        if (paymentCode === 3 || paymentCode === 4 || paymentCode === 8) return 7;
    }

    static get PWA() {
        const app = getCurrentInstance();
        const window = app.appContext.config.globalProperties.window;
        return window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true;
    }

    static get BackendDomainURL() {
        let servingFromNGROK = window.location.href.indexOf('ngrok') > -1;
        const domain = import.meta.env.PROD ? 'https://vitali-backend.ey.r.appspot.com' : servingFromNGROK ? 'https://vitali-backend.eu.ngrok.io' : 'https://localhost:5001';

        return domain;
    }

    static get FrontendDomainURL() {
        let servingFromNGROK = window.location.href.indexOf('ngrok') > -1;
        const domain = import.meta.env.PROD ? 'https://webapp.vitali.gr' : servingFromNGROK ? 'https://vitali.eu.ngrok.io' : 'https://localhost:5174';

        return domain;
    }

    static get PostingsChoices() {
        return [
            { label: 'Μπορώ να αναλάβω', value: 1 },
            { label: 'Δεν μπορώ να αναλάβω', value: 0 },
            { label: 'Δεν έχω διαθέσιμο χρόνο', value: 2 },
            { label: 'Άλλο', value: 3 },
        ];
    }

    static Weekdays() {
        return ['Κυριακή', 'Δευτέρα', 'Τρίτη', 'Τετάρτη', 'Πέμπτη', 'Παρασκευή', 'Σάββατο'];
    }

    static generateUUID() {
        let d = new Date().getTime();
        if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
            d += performance.now();
        }
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            // eslint-disable-next-line no-bitwise
            const r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            // eslint-disable-next-line no-bitwise
            return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
        });
    }

    static get EducationLevel() {
        return Object.freeze({
            Degree: 0,
            Postgraduate: 1,
            Doctorate: 2,
        });
    }

    static EducationLevelToString(type) {
        switch (type) {
            case this.EducationLevel.Degree:
                return 'Πτυχίο';
            case this.EducationLevel.Postgraduate:
                return 'Μεταπτυχιακό';
            case this.EducationLevel.Doctorate:
                return 'Διδακτορικό';
            default:
                return '';
        }
    }

    static get EducationLevelOptions() {
        let arr = [];
        for (let k in this.EducationLevel) {
            arr.push({
                label: this.EducationLevelToString(this.EducationLevel[k]),
                value: this.EducationLevel[k],
            });
        }
        return arr;
    }

    static get FamilyStatus() {
        return Object.freeze({
            Single: 0,
            Married: 1,
        });
    }

    static FamilyStatusToString(type) {
        switch (type) {
            case this.FamilyStatus.Single:
                return 'Άγαμος/η';
            case this.FamilyStatus.Married:
                return 'Έγγαμος/η';
            default:
                return '';
        }
    }

    static get FamilyStatusOptions() {
        let arr = [];
        for (let k in this.FamilyStatus) {
            arr.push({
                label: this.FamilyStatusToString(this.FamilyStatus[k]),
                value: this.FamilyStatus[k],
            });
        }
        return arr;
    }

    static get BankChoice() {
        return Object.freeze({
            Alpha: 0,
            Eurobank: 1,
            National: 2,
            Piraeus: 3,
            Other: 4,
        });
    }

    static BankChoiceToString(type) {
        switch (type) {
            case this.BankChoice.Alpha:
                return 'ALPHA';
            case this.BankChoice.Eurobank:
                return 'Eurobank';
            case this.BankChoice.National:
                return 'Εθνική';
            case this.BankChoice.Piraeus:
                return 'Πειραιώς';
            case this.BankChoice.Other:
                return 'Άλλη';
            default:
                return '';
        }
    }

    static get BankChoiceOptions() {
        let arr = [];
        for (let k in this.BankChoice) {
            arr.push({
                label: this.BankChoiceToString(this.BankChoice[k]),
                value: this.BankChoice[k],
            });
        }
        return arr;
    }

    static get ChildrenChoices() {
        let arr = [];
        for (let i = 0; i <= 10; i++) {
            arr.push({
                label: `${i}`,
                value: i,
            });
        }
        return arr;
    }

    static get CooperationTypes() {
        return Object.freeze({
            Ika: 0,
            Tpy: 1,
            Aed: 2,
            Empty: 3,
        });
    }

    static CooperationTypesToString(type) {
        switch (type) {
            case this.CooperationTypes.Ika:
                return 'ΙΚΑ';
            case this.CooperationTypes.Tpy:
                return 'ΤΠΥ';
            case this.CooperationTypes.Aed:
                return 'ΑΕΔ';
            case this.CooperationTypes.Empty:
                return 'ΚΕΝΟ';
            default:
                return '';
        }
    }

    static get CooperationTypesOptions() {
        let arr = [];
        for (let k in this.CooperationTypes) {
            arr.push({
                label: this.CooperationTypesToString(this.CooperationTypes[k]),
                value: this.CooperationTypes[k],
            });
        }
        return arr;
    }

    static get GetBackendVersion() {
        let res = axios.get(`${this.BackendDomainURL}/version.txt?${Date.now()}`);
        return res;
    }

    static QuizFileUrl(fileID) {
        if (!fileID) return null;
        return `https://storage.googleapis.com/vitali-backend.appspot.com/quizzes/${fileID}`;
    }

    static QuizFileID(fileURL) {
        if (!fileURL) return null;
        return fileURL.split('/').pop();
    }

    static async urlToBlob(url) {
        const response = await fetch(url);
        const blob = await response.blob();
        return blob;
    }

    static async getBase64Image(image, callback) {
        const blob = await this.urlToBlob(image);

        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = function () {
            callback(reader.result);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
}
