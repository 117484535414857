<!-- eslint-disable vue/no-v-html -->
<template>
    <Dialog
        v-model:visible="dialogShown"
        :close-on-escape="false"
        :dismissable-mask="false"
        :style="myDialogStyle"
        :modal="true"
        :pt="{
            root: 'border-none',
            mask: {
                style: 'backdrop-filter: blur(2px)',
            },
        }"
    >
        <template #container="{ closeCallback }">
            <div v-if="loading" class="flex flex-row justify-content-between align-content-center w-full h-full absolute top-0 left-0 z-5 bg-black-alpha-20">
                <Vue3Lottie :animationData="loaderCircle" :loop="true" :autoPlay="true" style="width: 100%" />
            </div>

            <div class="flex flex-column px-5 py-5 gap-4 relative w-full h-full justify-content-between overflow-y-scroll">
                <div style="width: 100%; height: 100%" class="bgClass"></div>

                <div class="flex flex-row justify-content-between">
                    <div class="flex flex-column w-full" :class="headerCls">
                        <span :style="headerStyle">{{ headerTitle }} </span>
                        <div class="flex flex-row align-items-center justify-content-between gap-6">
                            <div class="flex flex-row gap-2">
                                <span v-if="isPlayingScreen || isResultsScreen" style="font-size: large; color: #000">Ερώτηση </span>
                                <span style="font-size: large; color: #000; font-weight: bold; white-space: nowrap">{{ quizInfo }} </span>
                                <span
                                    v-if="isResultsScreen"
                                    style="color: #6365f1; font-family: Roboto; font-size: 1.625rem; font-style: normal; font-weight: 500; line-height: normal; padding-left: 1rem; white-space: nowrap"
                                    >{{ scoreInfo }}
                                </span>
                            </div>

                            <div v-if="isPlayingScreen && unansweredQuizzes.length > 0" class="flex flex-row gap-2 w-full">
                                <div
                                    class="flex flex-row"
                                    style="color: #fb6767; font-family: Roboto; font-size: 1.625rem; font-style: normal; font-weight: 500; line-height: normal; align-self: center"
                                >
                                    Αναπάντητες:
                                </div>
                                <div>
                                    <Dropdown
                                        v-model="selectedUnplayedQuiz"
                                        optionLabel="index"
                                        optionValue="id"
                                        :options="unansweredQuizzes"
                                        placeholder="Επιλέξτε"
                                        style="width: 130px"
                                        @change="goToQuiz"
                                    />
                                </div>
                            </div>

                            <span
                                v-if="!statusSubmited && !isSettingsScreen && !previewMode"
                                class="flex align-content-center;"
                                style="color: #6365f1; text-align: right; font-family: Roboto; font-size: 1.625rem; font-style: normal; font-weight: 700; line-height: normal; white-space: nowrap"
                            >
                                Χρόνος: {{ remainingTime }}
                            </span>
                        </div>
                    </div>
                    <!-- <Button style="position: absolute; right: 10px; top: 10px" :icon="closeIcon" @click="onHide"> </Button> -->
                    <div style="position: absolute; right: 15px; top: 15px; cursor: pointer" @click="onHide">
                        <img :src="closeIcon" style="width: 20px; height: 20px" />
                    </div>
                </div>

                <div v-if="isSettingsScreen" class="flex flex-column justify-content-between gap-4">
                    <div class="flex flex-column align-items-center">
                        <div class="flex flex-column w-full">
                            <span style="height: 20px; align-content: center; color: black; font-size: larger">{{ isStudent ? 'Επιλογή Μαθημάτων' : 'Επιλογή Μαθήματος' }}</span>
                            <div v-if="isStudent" class="flex flex-column gap-2 pt-2 w-full max-h-20rem" style="scroll-behavior: smooth; overflow-y: scroll; background-color: #f9f9f9">
                                <div v-for="course in myCourses" :key="course.id" class="flex flex-row gap-2">
                                    <Checkbox v-model="selectedCourses" :value="course.id" style="align-self: center" />
                                    <span style="color: black; font-size: larger">{{ course.label }}</span>
                                </div>
                            </div>
                            <div v-if="isTeacher" class="flex flex-column gap-2 pt-2 w-full max-h-20rem" style="scroll-behavior: smooth; overflow-y: scroll; background-color: #f9f9f9">
                                <div v-for="course in myCourses" :key="course.id" class="flex flex-row gap-2">
                                    <RadioButton v-model="selectedTeacherCourse" :value="course" style="align-self: center" />
                                    <span style="color: black; font-size: larger">{{ course.label }}</span>
                                </div>
                            </div>
                            <div
                                v-if="selectedTeacherCourse"
                                class="flex flex-column gap-2 pt-2 w-full"
                                style="scroll-behavior: smooth; overflow-y: scroll; background-color: #f9f9f9; max-height: 750px"
                            >
                                <DataTable
                                    ref="dt"
                                    v-model:selection="selectedTeacherQuizzes"
                                    :value="availableQuizzesForCourse"
                                    data-key="id"
                                    responsive-layout="scroll"
                                    :paginator="true"
                                    :rows="5"
                                    editMode="cell"
                                    @cell-edit-complete="onCellEditComplete"
                                >
                                    <Column selection-mode="multiple" header-style="width: 3rem"></Column>
                                    <Column field="id" header="ID" />
                                    <Column field="order_num" header="Α/α" style="min-width: 10rem" :editable="true" :sortable="true">
                                        <template #body="{ data, field }">
                                            {{ data[field] }}
                                        </template>
                                        <template #editor="{ data, field }">
                                            <InputNumber v-model="data[field]" />
                                        </template>
                                    </Column>
                                    <Column field="question" header="Όνομα">
                                        <template #body="{ data }">
                                            <div>
                                                <span v-html="JSON.parse(data.question).text"></span>
                                            </div>
                                        </template>
                                    </Column>
                                </DataTable>
                            </div>
                        </div>
                    </div>

                    <div v-if="isStudent" class="flex flex-column align-items-left">
                        <div class="flex flex-column">
                            <span style="height: 20px; align-content: center; color: black; font-size: larger">Αριθμός Ερωτήσεων </span>
                            <Dropdown v-model="totalQuestions" option-label="label" :options="questionOptions" placeholder="Επιλέξτε αριθμό ερωτήσεων" style="width: 350px; font-size: larger" />
                        </div>
                    </div>

                    <div class="flex flex-column align-items-left">
                        <div class="flex flex-column">
                            <span style="height: 20px; align-content: center; color: black; font-size: larger">Χρόνος</span>
                            <Dropdown v-model="totalTime" option-label="label" :options="timeOptions" placeholder="Επιλέξτε χρόνο ολοκλήρωσης" style="width: 350px; font-size: larger" />
                        </div>
                    </div>

                    <div class="flex flex-column align-items-center pb-4">
                        <div class="flex flex-row align-items-center justify-content-start w-full gap-2">
                            <Checkbox v-model="negativeMarking" class="w-100" :binary="true" />
                            <span style="height: 20px; align-content: center; color: black; font-size: larger">Αρνητική βαθμολογία</span>
                        </div>
                    </div>
                </div>

                <div v-else-if="isPlayingScreen" id="quiz-container" class="flex flex-column justify-content-evenly h-full">
                    <div class="flex flex-column justify-content-center w-full" style="border-radius: 5px; border-color: #d3d3d3; border-style: solid; border-width: 2px; min-height: 8.5rem">
                        <div
                            class="flex p-4"
                            style="color: #000; font-family: Roboto; font-size: 1.625rem; font-style: normal; font-weight: 400; line-height: normal"
                            v-html="pickedQuestionData.question"
                        ></div>
                    </div>
                    <div class="choices-grid">
                        <div v-for="(choice, index) in shuffledChoices" :key="choice.id" class="choice-container" @click="selectChoice(choice.value)">
                            <div class="flex items-center gap-1 flex-column h-full">
                                <div class="flex flex-row">
                                    <input :id="choice.id" v-model="studentAnswers[studentQuizData.current]" type="radio" :value="choice.value" class="hidden" :disabled="statusSubmited" />
                                    <!-- <label :for="choice.id" class="choice-label" style="font-weight: bold">Επιλογή {{ indexToLetter(index + 1) }}</label> -->
                                </div>
                                <div
                                    class="flex flex-column justify-content-center align-content-center w-full h-full"
                                    style="
                                        border-radius: 0.5625rem;
                                        border: 3px solid #9598ff;
                                        background: #fff;
                                        padding-left: 3.56rem;
                                        padding-top: 0.75rem;
                                        padding-bottom: 0.75rem;
                                        min-height: 100px;
                                        cursor: pointer;
                                    "
                                    :class="{ selected: studentAnswers[studentQuizData.current] === choice.value }"
                                >
                                    <span class="indicator">{{ indexToLetter(index + 1) }}</span>
                                    <span
                                        :for="choice.id"
                                        class="choice-content"
                                        style="color: #5d61f0; font-family: Roboto; font-size: 1.625rem; font-style: normal; font-weight: 500; line-height: normal; align-self: center"
                                        v-html="pickedQuestionData[choice.value]"
                                    ></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-else-if="isGameOverScreen" class="flex flex-column justify-content-evenly h-full align-items-center">
                    <span
                        v-if="studentQuizData.score >= 80"
                        style="align-self: center; color: #2aa000; text-align: center; font-family: Roboto; font-size: 2.6875rem; font-style: normal; font-weight: 700; line-height: normal"
                    >
                        ΣΥΓΧΑΡΗΤΗΡΙΑ!
                    </span>
                    <div class="flex flex-column gap-1">
                        <Vue3Lottie v-if="studentQuizData.score < 50" :animationData="failAnimation" :loop="true" :autoPlay="true" style="height: 400px" />
                        <Vue3Lottie v-else-if="studentQuizData.score < 75" :animationData="okAnimation" :loop="true" :autoPlay="true" style="height: 400px" />
                        <Vue3Lottie v-else :animationData="successAnimation" :loop="true" :autoPlay="true" style="height: 400px" />
                        <span style="align-self: center; color: #000; text-align: center; font-family: Roboto; font-size: 2.6875rem; font-style: normal; font-weight: 500; line-height: normal">
                            Το ΣΚΟΡ σου είναι:
                        </span>
                        <span style="align-self: center; color: #5d61f0; text-align: center; font-family: Roboto; font-size: 8rem; font-style: normal; font-weight: 700; line-height: normal"
                            >{{ studentQuizData.score }}%
                        </span>
                    </div>
                </div>

                <div v-else-if="isResultsScreen" class="flex flex-column justify-content-evenly h-full">
                    <div class="flex flex-column justify-content-center w-full" style="border-radius: 5px; border-color: #d3d3d3; border-style: solid; border-width: 2px; min-height: 8.5rem">
                        <div
                            class="flex p-4"
                            style="color: #000; font-family: Roboto; font-size: 1.625rem; font-style: normal; font-weight: 400; line-height: normal"
                            v-html="pickedQuestionData.question"
                        ></div>
                    </div>

                    <div class="choices-grid">
                        <div
                            v-for="(choice, index) in shuffledChoices"
                            :key="choice.id"
                            class="choice-container"
                            :class="{
                                correct: choice.value === pickedQuestionData.answer,
                                incorrect: studentAnswers[studentQuizData.current] !== pickedQuestionData.answer && studentAnswers[studentQuizData.current] === choice.value,
                                'highlight-correct': studentAnswers[studentQuizData.current] !== pickedQuestionData.answer && choice.value === pickedQuestionData.answer,
                            }"
                            style="pointer-events: none; position: relative"
                        >
                            <div class="flex items-center gap-1 flex-column h-full">
                                <div class="flex flex-row">
                                    <input :id="choice.id" v-model="studentAnswers[studentQuizData.current]" type="radio" :value="choice.value" class="hidden" :disabled="statusSubmited" />
                                    <!-- <label :for="choice.id" class="choice-label" style="font-weight: bold">Επιλογή {{ indexToLetter(index + 1) }}</label> -->
                                </div>
                                <div
                                    class="flex flex-column justify-content-center align-content-center w-full h-full"
                                    style="
                                        border-radius: 0.5625rem;
                                        background: #fff;
                                        padding-left: 3.56rem;
                                        padding-right: 3.56rem;
                                        padding-top: 0.75rem;
                                        padding-bottom: 0.75rem;
                                        min-height: 100px;
                                        cursor: pointer;
                                    "
                                    :style="resultItemStyle(choice.value)"
                                >
                                    <span class="indicator" :style="resultIndicatorStyle(choice.value)">{{ indexToLetter(index + 1) }}</span>
                                    <span
                                        :for="choice.id"
                                        class="choice-content"
                                        style="color: #5d61f0; font-family: Roboto; font-size: 1.625rem; font-style: normal; font-weight: 500; line-height: normal; align-self: center"
                                        :style="resultTextStyle(choice.value)"
                                        v-html="pickedQuestionData[choice.value]"
                                    ></span>
                                </div>
                            </div>
                            <img v-if="choice.value === pickedQuestionData.answer" :src="correctIcon" class="choice-icon correct-icon" />
                            <img
                                v-else-if="studentAnswers[studentQuizData.current] === choice.value && choice.value !== pickedQuestionData.answer"
                                :src="wrongIcon"
                                class="choice-icon incorrect-icon"
                            />
                        </div>
                    </div>

                    <div class="flex flex-row w-full gap-2 align-items-center">
                        <div v-if="studentAnswers[studentQuizData.current] != null">
                            <span style="color: #000; font-family: Roboto; font-size: 1.625rem; font-style: normal; font-weight: 500; line-height: normal">Η απάντησή σας είναι </span>
                            <span
                                :style="{
                                    color: studentAnswers[studentQuizData.current] === pickedQuestionData.answer ? '#2aa000' : '#fb6767',
                                    fontFamily: 'Roboto',
                                    fontSize: '1.625rem',
                                    fontStyle: 'normal',
                                    fontWeight: 700,
                                    lineHeight: 'normal',
                                }"
                            >
                                {{ studentAnswers[studentQuizData.current] === pickedQuestionData.answer ? 'Σωστή' : 'Λάθος' }}
                            </span>
                        </div>
                        <div v-else>
                            <span style="color: #000; font-family: Roboto; font-size: 1.625rem; font-style: normal; font-weight: 500; line-height: normal">Δεν απαντήσατε </span>
                        </div>
                        <div v-if="!loading">
                            <Vue3Lottie v-if="studentAnswers[studentQuizData.current] == null" :animationData="emojiSurprised" :loop="true" :autoPlay="true" style="height: 4.875rem" />
                            <Vue3Lottie
                                v-else-if="studentAnswers[studentQuizData.current] === pickedQuestionData.answer"
                                :animationData="happyEmoji"
                                :loop="true"
                                :autoPlay="true"
                                style="height: 4.875rem"
                            />
                            <Vue3Lottie v-else :animationData="sadEmoji" :loop="true" :autoPlay="true" style="height: 4rem" />
                        </div>
                    </div>

                    <div v-if="pickedQuestionData.description != null" class="flex flex-column w-full">
                        <span style="color: #000; font-family: Roboto; font-size: 1.6rem; font-style: normal; font-weight: 700; line-height: normal"> Επεξήγηση </span>
                        <div
                            class="flex flex-column justify-content-center w-full"
                            style="border-radius: 5px; border-color: #d3d3d3; border-style: solid; border-width: 2px; min-height: 8.5rem; margin-top: 1rem"
                        >
                            <div
                                style="color: #000; font-family: Roboto; font-size: 1.4rem; font-style: normal; font-weight: 500; line-height: normal; padding: 0.5rem"
                                v-html="pickedQuestionData.description"
                            ></div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-row w-full">
                    <div v-if="isGameOverScreen" class="flex flex-row w-full justify-content-center">
                        <Button label="Εμφάνιση Απαντήσεων" class="p-button" size="large" @click="showScoreDetails" />
                    </div>
                    <div v-else-if="isPlayingScreen" class="flex flex-row justify-content-between gap-3 w-full">
                        <Button v-if="!statusSubmited && !previewMode" label="Υποβολή" icon="pi pi-check" class="p-button" size="large" @click="submitAnswers" />
                        <div class="flex flex-row gap-3">
                            <Button
                                v-if="!isFirstQuiz && unansweredQuizzes.length > 0"
                                icon="pi pi-arrow-left"
                                class="p-button-outlined"
                                label="Προηγούμενη"
                                size="large"
                                :disabled="remainingTimeEnded"
                                @click="previousQuiz"
                            />
                            <Button
                                v-if="!isLastQuiz && unansweredQuizzes.length > 0"
                                icon="pi pi-arrow-right"
                                class="p-button-outlined"
                                label="Επόμενη"
                                iconPos="right"
                                size="large"
                                :disabled="remainingTimeEnded"
                                @click="nextQuiz"
                            />
                        </div>
                    </div>
                    <div v-else-if="isResultsScreen" class="flex flex-row w-full justify-content-end gap-3 w-full">
                        <Button v-if="!isFirstQuiz" icon="pi pi-arrow-left" class="p-button-outlined" size="large" label="Προηγούμενη" @click="previousQuiz" />
                        <Button v-if="!isLastQuiz" icon="pi pi-arrow-right" class="p-button-outlined" size="large" label="Επόμενη" iconPos="right" @click="nextQuiz" />
                    </div>
                    <div v-else-if="isSettingsScreen" class="flex flex-row w-full justify-content-end gap-3 w-full">
                        <Button
                            label="Ακύρωση"
                            size="large"
                            icon="pi pi-times"
                            class="p-button-outlined w-10rem"
                            style="border-color: #fb6767 !important; color: #fb6767"
                            @click="closeCallback(false)"
                        />
                        <Button
                            label="Έναρξη"
                            size="large"
                            icon="pi pi-play"
                            class="p-button-outlined w-10rem"
                            style="border-color: #5d61f0 !important"
                            :loading="loading"
                            :disabled="!startBtnEnabled"
                            @click="createQuiz"
                        />
                    </div>
                </div>
            </div>
        </template>
    </Dialog>

    <ConfirmDialog
        group="templating"
        :pt="{
            title: {
                style: 'font-size: 1.5rem; color: #000',
            },
        }"
    >
        <template #message="slotProps">
            <div class="flex flex-column align-items-center w-full gap-3">
                <i :class="slotProps.message.icon" class="text-6xl text-primary-500"></i>
                <span style="font-size: 1.5rem">{{ slotProps.message.message }}</span>
                <InputText v-model="quizName" placeholder="Όνομα Quiz" class="mt-1 mb-1 w-full" size="large" />
            </div>
        </template>
    </ConfirmDialog>

    <ConfirmDialog
        group="templating2"
        :pt="{
            title: {
                style: 'font-size: 1.5rem; color: #000',
            },
        }"
    >
        <template #message="slotProps">
            <div class="flex flex-column align-items-center w-full gap-3">
                <span style="font-size: 1.5rem">{{ slotProps.message.message }}</span>
            </div>
        </template>
    </ConfirmDialog>
</template>

<script setup>
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import { useToast } from 'primevue/usetoast';
import moment from 'moment-timezone';
import { onMounted, reactive, ref, computed, watchEffect, toRefs, nextTick, onBeforeMount, watch } from 'vue';
import { required, integer, helpers } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import useRPC from '@/composables/useRPC';
import { usePopupStore } from '@/stores/PopupStore';
import Utils from '@/utils/Utils';
import loaderCircle from '@/assets/lottie/loaderCircle.json';
import { useConfirm } from 'primevue/useconfirm';
import happyEmoji from '@/assets/lottie/happyEmoji.json';
import emojiSurprised from '@/assets/lottie/emojiSurprised.json';
import sadEmoji from '@/assets/lottie/sadEmoji.json';
import closeIcon from '@/assets/images/close.svg';
import failAnimation from '@/assets/lottie/failAnimation.json';
import okAnimation from '@/assets/lottie/okAnimation.json';
import successAnimation from '@/assets/lottie/successAnimation.json';
import correctIcon from '@/assets/images/correct.svg';
import wrongIcon from '@/assets/images/wrong.svg';
import * as htmlToImage from 'html-to-image';
import RadioButton from 'primevue/radiobutton';

const dt = ref(null);
const selectedTeacherQuizzes = ref([]);
const availableQuizzesForCourse = ref([]);
const selectedTeacherCourse = ref(null);
const countdownInterval = ref(null);
const confirm = useConfirm();
const selectedCourses = ref([]);
const { FetchFilesInFolder, DeleteFileInFolder, UploadFileInFolder, EditFileMetadata, User } = useRPC();
const choices = ref([1, 2, 3, 4]);
const popupStore = usePopupStore();
const { RPCApi, RPCMethods } = useRPC();
const dialogShown = ref(false);
const loading = ref(false);
const toast = useToast();
const closeCB = ref(null);
const screenName = ref('settings');
const myCourses = ref([]);
const negativeMarking = ref(false);
const studentQuizData = ref({});
const selectedUnplayedQuiz = ref(null);
const previewMode = ref(false);
const startBtnEnabled = computed(() => {
    return selectedCourses.value.length > 0 || selectedTeacherCourse.value != null;
});
const selectChoice = (value) => {
    studentAnswers.value[studentQuizData.value.current] = value;
};

const headerCls = computed(() => {
    if (isSettingsScreen.value) {
        return 'flex flex-column w-full align-items-center';
    }

    return 'flex flex-row w-full';
});

const onCellEditComplete = async (e) => {
    console.log(e);
    let quiz = e.data;
    console.warn(quiz);
    let newOrder = e.newValue;
    console.log('newOrder' + newOrder);
    let res = await RPCApi(RPCMethods.Quizzes.UpdateQuizOrderNum, { id: quiz.id, order_num: newOrder });
    console.log(res);
    if (res.status === 'ok') {
        toast.add({ severity: 'success', summary: 'Επιτυχία', detail: 'Η αλλαγή αποθηκεύτηκε!', life: 3000 });
        //edit the local data
        let quizIndex = availableQuizzesForCourse.value.findIndex((item) => item.id === quiz.id);
        availableQuizzesForCourse.value[quizIndex].order_num = newOrder;
    } else {
        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Η αλλαγή δεν αποθηκεύτηκε!', life: 3000 });
    }
};

onMounted(() => {
    //reset selected courses
    selectedCourses.value = [];
    selectedTeacherCourse.value = null;
    selectedTeacherQuizzes.value = [];
});

watch(selectedTeacherCourse, async (newVal) => {
    console.warn(newVal);
    if (newVal) {
        //go to first page
        await nextTick();

        selectedTeacherQuizzes.value = [];
        availableQuizzesForCourse.value = newVal.quizzes;
    }
});

const isStudent = computed(() => {
    return Utils.UserInfo?.role === Utils.Roles.Student;
});

const isTeacher = computed(() => {
    return Utils.UserInfo?.role === Utils.Roles.Teacher;
});

const resultItemStyle = computed(() => {
    return (choice) => {
        if (choice === pickedQuestionData.value.answer) {
            return {
                border: '3px solid #2aa000',
            };
        } else if (choice === studentAnswers.value[studentQuizData.value.current]) {
            return {
                border: '3px solid #FB6767',
            };
        } else {
            return {
                border: '3px solid #9598ff',
            };
        }
    };
});

const resultIndicatorStyle = computed(() => {
    return (choice) => {
        if (choice === pickedQuestionData.value.answer) {
            return {
                background: '#2aa000',
            };
        } else if (choice === studentAnswers.value[studentQuizData.value.current]) {
            return {
                background: '#FB6767',
            };
        } else {
            return {
                background: '#5d61f0',
            };
        }
    };
});

const resultTextStyle = computed(() => {
    return (choice) => {
        if (choice === pickedQuestionData.value.answer) {
            return {
                color: '#2aa000',
            };
        } else if (choice === studentAnswers.value[studentQuizData.value.current]) {
            return {
                color: '#FB6767',
            };
        } else {
            return {
                color: '#5d61f0',
            };
        }
    };
});

const headerStyle = computed(() => {
    if (isSettingsScreen.value) {
        return {
            fontSize: 'x-large',
            alignContent: 'center',
            color: 'black',
        };
    }

    if (isGameOverScreen.value) {
        return {
            fontSize: 'x-large',
            alignContent: 'center',
            'text-align': 'center',
            color: 'black',
            'font-family': 'Roboto',
            'font-style': 'normal',
        };
    }

    return {
        fontSize: 'x-large',
        alignContent: 'center',
        color: 'black',
        'font-family': 'Roboto',
        'font-weight': 700,
    };
});

const myDialogStyle = computed(() => {
    if (screenName.value === 'settings') {
        return {
            minWidth: '200px',
            maxWidth: '95%',
        };
    }

    return { width: '90%', height: '90%' };
});
const pickedQuestionData = ref({
    question: '',
    choices: [],
    answer: null,
    negativeMarking: false,
    time: 0,
});
const quizName = ref(null);
const questionOptions = [
    { label: '5', value: 5 },
    { label: '10', value: 10 },
    { label: '15', value: 15 },
    { label: '20', value: 20 },
    { label: '25', value: 25 },
    { label: '30', value: 30 },
    { label: '35', value: 35 },
    { label: '40', value: 40 },
    { label: '45', value: 45 },
    { label: '50', value: 50 },
    { label: '55', value: 55 },
    { label: '60', value: 60 },
    { label: '65', value: 65 },
    { label: '70', value: 70 },
    { label: '75', value: 75 },
    { label: '80', value: 80 },
    { label: '85', value: 85 },
    { label: '90', value: 90 },
    { label: '95', value: 95 },
    { label: '100', value: 100 },
];

const timeOptions = [
    { label: '10 λεπτά', value: 10 },
    { label: '15 λεπτά', value: 15 },
    { label: '20 λεπτά', value: 20 },
    { label: '30 λεπτά', value: 30 },
    { label: '45 λεπτά', value: 45 },
    { label: '60 λεπτά', value: 60 },
    { label: '1 ώρα 30 λεπτά', value: 90 },
    { label: '2 ώρες', value: 120 },
    { label: '2 ώρες 30 λεπτά', value: 150 },
    { label: '3 ώρες', value: 180 },
    { label: '3 ώρες 30 λεπτά', value: 210 },
    { label: '4 ώρες', value: 240 },
];

const totalQuestions = ref(questionOptions[questionOptions.length - 1]);
const totalTime = ref(timeOptions[timeOptions.length - 1]);

// const dialogComputedStyle = computed(() => {
//     if (screenName.value === 'settings') {
//         return {
//             minWidth: '600px',
//         };
//     }

//     return { width: '800px', height: '600px' };
// });

const unansweredQuizzes = ref([]);

const remainingTime = ref('--');
const studentAnswers = ref({});
const isSettingsScreen = computed(() => screenName.value === 'settings');
const isPlayingScreen = computed(() => screenName.value === 'playing');
const isGameOverScreen = computed(() => screenName.value === 'gameover');
const isResultsScreen = computed(() => screenName.value === 'results');
const isFirstQuiz = computed(() => studentQuizData.value.current === 0);
const isLastQuiz = computed(() => studentQuizData.value.current === studentQuizData.value.batch.length - 1);
const headerTitle = computed(() => {
    if (isGameOverScreen.value) {
        return 'To Quiz ολοκληρώθηκε!';
    }

    if (isSettingsScreen.value) {
        return 'Ρυθμίσεις Quiz';
    }

    if (isPlayingScreen.value || isResultsScreen.value) {
        return 'Quiz ' + (studentQuizData.value?.name ? ` ${studentQuizData.value.name}` : '');
    }
    return '';
});

const quizInfo = computed(() => {
    if (isPlayingScreen.value || isResultsScreen.value) {
        let quizOrderNum = pickedQuestionData.value.order_num;
        return studentQuizData.value.current + 1 + ' από ' + studentQuizData.value.batch.length + ` (Α/α: ${quizOrderNum})`;
    }
    return '';
});

const scoreInfo = computed(() => {
    if (isResultsScreen.value) {
        return `Σκορ: ${studentQuizData.value.score}%`;
    }
    return '';
});
const statusSubmited = computed(() => studentQuizData.value.status === 1);

watch(
    studentAnswers,
    (value) => {
        if (!studentQuizData.value.batch) return;
        let quizID = studentQuizData.value.batch[studentQuizData.value.current].id;
        if (value[studentQuizData.value.current] != null) {
            unansweredQuizzes.value = unansweredQuizzes.value.filter((item) => item.id !== quizID);
        }
    },
    { deep: true }
);

const shuffledChoices = ref([]);
const shuffleArray = (array) => {
    let currentIndex = array.length,
        randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex != 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }

    return array;
};

const indexToLetter = (index) => {
    switch (index) {
        case 1:
            return 'Α';
        case 2:
            return 'Β';
        case 3:
            return 'Γ';
        case 4:
            return 'Δ';
        case 5:
            return 'Ε';
        default:
            return '';
    }
};

const choicesData = [
    { id: 'choice1', value: 'choice1', label: 'Επιλογή Α' },
    { id: 'choice2', value: 'choice2', label: 'Επιλογή Β' },
    { id: 'choice3', value: 'choice3', label: 'Επιλογή Γ' },
    { id: 'choice4', value: 'choice4', label: 'Επιλογή Δ' },
    { id: 'choice5', value: 'choice5', label: 'Επιλογή Ε', conditional: true },
];

const shuffleChoices = () => {
    console.log('shuffleChoices');
    shuffledChoices.value = shuffleArray(choicesData.filter((choice) => !choice.conditional || pickedQuestionData.value[choice.value]));
};

onBeforeMount(() => {
    shuffleChoices();
});

const remainingTimeEnded = ref(false);

const startCountdown = () => {
    if (statusSubmited.value) return;

    countdownInterval.value = setInterval(() => {
        studentQuizData.value.elapsedTime++;
        let timeEnded = studentQuizData.value.elapsedTime >= studentQuizData.value.totalTime;
        remainingTimeEnded.value = timeEnded;
        if (timeEnded) {
            clearInterval(countdownInterval.value);
            remainingTime.value = '--';
            toast.add({ severity: 'success', summary: 'Ολοκλήρωση', detail: 'Ο χρόνος ολοκλήρωσης του quiz έληξε!', life: 3000 });
        } else {
            let remaining = studentQuizData.value.totalTime - studentQuizData.value.elapsedTime;
            let minutes = Math.floor(remaining / 60);
            let seconds = remaining % 60;
            if (seconds < 10) {
                remainingTime.value = `${minutes}:0${seconds}`;
            } else remainingTime.value = `${minutes}:${seconds}`;
        }
    }, 1000);
};

const show = async (data, onCloseCB) => {
    console.warn('data:', data);

    previewMode.value = data?.previewMode;
    remainingTimeEnded.value = false;
    unansweredQuizzes.value = [];
    quizName.value = '';
    studentAnswers.value = {};
    selectedCourses.value = [];
    studentQuizData.value = {
        current: 0,
        elapsedTime: 0,
        status: 0,
    };
    totalQuestions.value = questionOptions[1];
    totalTime.value = timeOptions[1];
    negativeMarking.value = false;
    pickedQuestionData.value = {
        question: '',
        choices: [],
        answer: null,
        negativeMarking: false,
        time: 0,
    };

    closeCB.value = onCloseCB;
    dialogShown.value = true;

    if (data == null) {
        screenName.value = 'settings';
        await fetchCoursesWithQuiz();
    } else {
        screenName.value = data.status === 1 ? 'results' : 'playing';
        studentQuizData.value.id = data.id;
        studentQuizData.value.status = data.status;
        studentQuizData.value.batch = data.quizzes;
        studentQuizData.value.score = data.score;
        studentQuizData.value.totalTime = data.available_time * 60;
        studentQuizData.value.name = data.name;
        unansweredQuizzes.value = data.quizzes.map((item, index) => {
            return { id: item.id, index: index + 1 };
        });
        await fetchQuizData();
        startCountdown();
    }
};

const nextQuiz = async () => {
    console.log('nextQuiz');
    studentQuizData.value.current++;
    if (studentQuizData.value.current >= studentQuizData.value.batch.length) {
        studentQuizData.value.current = 0;
    }

    await fetchQuizData();
};

const previousQuiz = async () => {
    console.log('previousQuiz');
    studentQuizData.value.current--;
    if (studentQuizData.value.current < 0) {
        studentQuizData.value.current = studentQuizData.value.batch.length - 1;
    }
    await fetchQuizData();
};

const fetchCoursesWithQuiz = async () => {
    loading.value = true;
    const method = isTeacher.value ? RPCMethods.Quizzes.FetchTeacherCoursesWithQuiz : RPCMethods.Quizzes.FetchStudentCoursesWithQuiz;
    let res = await RPCApi(method);
    console.log(res);
    let d = [];
    if (res.status == 'ok') {
        const data = res.data.result.data;

        for (let obj of data) {
            if (isStudent.value) {
                d.push({
                    label: obj.courseTitle,
                    id: obj.courseID,
                });
            } else {
                d.push({
                    label: obj.courseTitle,
                    id: obj.courseID,
                    quizzes: obj.quizzes,
                });
            }
        }
        myCourses.value = d;

        if (isTeacher.value && myCourses.value.length > 0) {
            selectedTeacherCourse.value = myCourses.value[0];
        }
    } else {
        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Προσπαθήστε αργότερα!', life: 3000 });
    }

    loading.value = false;
};

const onHide = (action = false, payload = null) => {
    console.log('onHide', action, payload);
    if (screenName.value === 'playing' && !statusSubmited.value && !previewMode.value) {
        confirm.require({
            message: 'Είστε σίγουροι ότι θέλετε να σταματήσετε το quiz;',
            header: 'Επιβεβαίωση',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Έξοδος',
            rejectLabel: 'Άκυρο',
            acceptClass: 'p-button-danger',
            rejectClass: 'p-button-secondary',
            accept: async () => {
                console.log('yes');
                closeMe();
            },
            reject: () => {
                console.log('no');
            },
        });
        return;
    }
    closeMe();
};

const fetchQuizData = async () => {
    let quizzesData = studentQuizData.value.batch.map((item) => {
        return {
            id: item.id,
            answer: item.answer,
        };
    });
    let id = quizzesData[studentQuizData.value.current].id;
    console.log('quizzesData : ', quizzesData);
    loading.value = true;
    try {
        let res = await RPCApi(RPCMethods.Quizzes.FetchQuizById, {
            id: id,
        });
        console.log(res);
        if (res.status === 'ok') {
            let q = res.data.result.data.quiz;
            let data = {
                id: q.id,
                question: JSON.parse(q.question).text,
                choice1: JSON.parse(q.choice1).text,
                choice2: JSON.parse(q.choice2).text,
                choice3: JSON.parse(q.choice3).text,
                choice4: JSON.parse(q.choice4).text,
                description: q.description ? JSON.parse(q.description).text : null,
                answer: q.answer,
                order_num: q.order_num,
            };
            if (q.choice5) {
                data.choice5 = JSON.parse(q.choice5).text;
            }
            pickedQuestionData.value = formatData(data);

            if (quizzesData[studentQuizData.value.current].answer) {
                studentAnswers.value[studentQuizData.value.current] = quizzesData[studentQuizData.value.current].answer;
            }
        } else {
            toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Προσπαθήστε αργότερα!', life: 3000 });
        }
    } catch (e) {
        console.error(e);
        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Προσπαθήστε αργότερα!', life: 3000 });
    }
    loading.value = false;
};

//search all elements and their children depth first and remove all style attributes
const removeStylesAndStrongTags = (element) => {
    element.style.fontWeight = null;
    element.style.fontFamily = null;
    element.style.fontSize = null;
    element.style.color = null;
    if (element.tagName.toLowerCase() === 'strong' || element.tagName.toLowerCase() === 'b') {
        element.outerHTML = element.innerHTML; // Remove <strong> tags
    }
    for (let child of element.children) {
        removeStylesAndStrongTags(child);
    }
};

const formatData = (data) => {
    for (let key in data) {
        if (key === 'question' || key === 'choice1' || key === 'choice2' || key === 'choice3' || key === 'choice4' || key === 'choice5' || key === 'description') {
            if (data[key] == null) continue;
            let parser = new DOMParser();
            let doc = parser.parseFromString(data[key], 'text/html');
            let imgTags = doc.getElementsByTagName('img');
            for (let imgTag of imgTags) {
                imgTag.setAttribute('style', 'max-height: 150px;');
            }
            //search all elements and their children depth first and remove all style attributes
            let allElements = doc.getElementsByTagName('*');
            for (let element of allElements) {
                removeStylesAndStrongTags(element);
            }

            data[key] = doc.body.innerHTML;
        }
    }

    return data;
};

const closeMe = (action, payload) => {
    clearInterval(countdownInterval.value);
    dialogShown.value = false;
    closeCB.value(action, payload);
};

const createQuiz = async () => {
    if (isTeacher.value && selectedTeacherQuizzes.value.length === 0) {
        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Παρακαλώ επιλέξτε τουλάχιστον ένα quiz!', life: 3000 });
        return;
    }

    if (isTeacher.value && selectedTeacherCourse.value == null) {
        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Παρακαλώ επιλέξτε τουλάχιστον ένα μάθημα!', life: 3000 });
        return;
    }

    const method = isTeacher.value ? RPCMethods.Quizzes.CreateTeacherQuizBatch : RPCMethods.Quizzes.CreateQuizBatch;

    let payload = null;
    if (isStudent.value) {
        payload = {
            courseIDs: selectedCourses.value,
            totalQuestions: totalQuestions.value.value,
            totalTime: totalTime.value.value,
            negativeMarking: negativeMarking.value,
        };
    } else {
        payload = {
            courseID: selectedTeacherCourse.value.id,
            quizzes: selectedTeacherQuizzes.value,
            totalTime: totalTime.value.value,
            negativeMarking: negativeMarking.value,
        };
    }
    loading.value = true;
    try {
        let res = await RPCApi(method, payload);
        console.log(res);

        if (res.status === 'ok') {
            toast.add({ severity: 'success', summary: 'Επιτυχία', detail: 'Το Quiz δημιουργήθηκε!', life: 3000 });
            let { data } = res.data.result;
            let formattedData = {};
            for (let key in data) {
                if (key === 'batch') {
                    formattedData[key] = [];
                    let jsonBatch = JSON.parse(data[key]);
                    for (let courseID in jsonBatch) {
                        formattedData[key].push(...jsonBatch[courseID]);
                    }
                } else {
                    formattedData[key] = data[key];
                }
                formattedData.totalTime = data.available_time * 60;
            }
            console.log('formattedData:', formattedData);
            studentQuizData.value = formattedData;
            console.log('studentQuizData:', studentQuizData.value);
            unansweredQuizzes.value = studentQuizData.value.batch.map((item, index) => {
                return { id: item.id, index: index + 1 };
            });
            startQuizSequence();
        } else if (res.status === 'no_quizzes_available') {
            toast.add({ severity: 'error', summary: 'Ενημέρωση', detail: 'Δεν υπάρχουν διαθέσιμα quiz για τα μαθήματα σας!', life: 3000 });
        } else {
            toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Προσπαθήστε αργότερα!', life: 3000 });
        }
    } catch (e) {
        console.error(e);
        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Προσπαθήστε αργότερα!', life: 3000 });
    }
    loading.value = false;
};

const startQuizSequence = async () => {
    console.log('startQuizSequence');

    studentQuizData.value.current = unansweredQuizzes.value.find((item) => item.id === studentQuizData.value.batch[0].id).index - 1;
    studentQuizData.value.elapsedTime = 0;
    await fetchQuizData();

    screenName.value = 'playing';
    startCountdown();
};

const submitAnswers = async () => {
    confirm.require({
        group: 'templating2',
        header: 'Υποβολή Quiz',
        message: 'Είστε σίγουροι ότι θέλετε να υποβάλετε τις απαντήσεις σας; ',
        icon: 'pi pi-exclamation-triangle',
        rejectClass: 'p-button-outlined p-button-lg',
        acceptClass: 'p-button-lg',
        rejectLabel: 'Άκυρο',
        acceptLabel: 'Συνέχεια',
        accept: async () => {
            console.log('submitAnswers');
            let quizzesData = studentQuizData.value.batch.map((item) => {
                return {
                    id: item.id,
                    answer: item.answer,
                };
            });
            let answers = quizzesData.map((item, index) => {
                return {
                    id: item.id,
                    answer: studentAnswers.value[index],
                };
            });
            console.log('answers:', answers);

            confirm.require({
                group: 'templating',
                header: 'Επιβεβαίωση',
                message: 'Παρακαλώ δώστε ένα όνομα στο quiz',
                icon: 'pi pi-exclamation-circle',
                acceptIcon: 'pi pi-check',
                rejectIcon: 'pi pi-times',
                rejectClass: 'p-button-outlined p-button-lg',
                acceptClass: 'p-button-lg',
                rejectLabel: 'Άκυρο',
                acceptLabel: 'Αποθήκευση',
                accept: async () => {
                    loading.value = true;
                    try {
                        let res = await RPCApi(RPCMethods.Quizzes.SubmitQuizAnswers, {
                            id: studentQuizData.value.id,
                            answers: answers,
                            quizName: quizName.value,
                            userTime: studentQuizData.value.elapsedTime,
                        });
                        console.log(res);
                        if (res.status === 'ok') {
                            toast.add({ severity: 'success', summary: 'Επιτυχία', detail: 'Οι απαντήσεις σας καταχωρήθηκαν!', life: 3000 });
                            //show the score to the user
                            let data = res.data.result.data;
                            console.log('data:', data);
                            screenName.value = 'gameover';
                            studentQuizData.value.status = 1;
                            studentQuizData.value.score = data.score;
                        } else {
                            toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Προσπαθήστε αργότερα!', life: 3000 });
                        }
                    } catch (e) {
                        console.error(e);
                        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Προσπαθήστε αργότερα!', life: 3000 });
                    }
                    loading.value = false;
                    quizName.value = '';
                },
                reject: () => {
                    quizName.value = '';
                },
            });
        },
        reject: () => {},
    });
};

const goToQuiz = (data) => {
    console.log('Go to quiz:', data.value);
    let index = studentQuizData.value.batch.findIndex((item) => item.id === data.value);
    if (index !== -1) {
        studentQuizData.value.current = unansweredQuizzes.value.find((item) => item.id === data.value).index - 1;
        fetchQuizData();
    }
};

const showScoreDetails = async () => {
    studentQuizData.value.current = 0;
    screenName.value = 'results';
    await fetchQuizData();
};

defineExpose({
    show,
});
</script>

<style lang="scss" scoped>
.bgClass {
    background-image: url('@/assets/images/LogoFullDark.png');
    background-size: contain;
    background-repeat: repeat;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.03;
    pointer-events: none;
}
.choices-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}

.indicator {
    position: absolute;
    top: 3px;
    left: 0;
    padding: 0.5rem 0.7rem;
    border-radius: 0.5625rem 0rem;
    background: #5d61f0;
    color: #fff;
    font-family: Roboto;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.choice-container {
    cursor: pointer;
    position: relative; /* For positioning the icon */

    &:hover {
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }
}

.selected {
    background: #d1d3ff !important;
}

.hidden {
    display: none;
}

.choice-label,
.choice-content {
    pointer-events: none;
    display: flex;
}

.highlight-correct {
    border-color: green; /* Or any other visual cue for correct answers */
    background-color: #ebfbee; /* Light green background for emphasis */
}

.correct {
    border-color: green;
}

.incorrect {
    border-color: red;
}

.choice-icon {
    position: absolute;
    top: 0.4rem;
    right: 0.2rem;
    font-size: 20px; /* Adjust size as needed */
}

.correct-icon {
    color: green;
}
</style>
