<template>
    <Dialog
        v-model:visible="dialogShown"
        class="p-fluid"
        :close-on-escape="false"
        :dismissable-mask="false"
        :modal="true"
        :breakpoints="{ '960px': '75vw', '640px': '95vw' }"
        :style="{ width: '40vw' }"
    >
        <template #header>
            <h4>{{ headerTitle }}</h4>
        </template>

        <div class="col-12">
            <div class="field">
                <label :class="{ 'p-error': v$.amount.$invalid && submitted }">Ποσό (σε ευρώ)*</label>
                <InputNumber
                    v-model="v$.amount.$model"
                    mode="decimal"
                    :min-fraction-digits="2"
                    locale="el-GR"
                    autocomplete="off"
                    spellcheck="off"
                    placeholder="0"
                    :class="{ 'p-invalid': v$.amount.$invalid && submitted }"
                />
                <small v-if="(v$.amount.$invalid && submitted) || v$.amount.$pending.$response" class="p-error">{{ v$.amount.required.$message }}</small>
            </div>
            <div class="field">
                <label :class="{ 'p-error': v$.code.$invalid && submitted }">Τρόπος Πληρωμής*</label>
                <div class="flex flex-row flex-wrap">
                    <Dropdown
                        v-model="v$.code.$model"
                        option-label="label"
                        :options="paymentCodes"
                        :class="{ 'p-invalid': v$.code.$invalid && submitted }"
                        placeholder="Επιλογή"
                        class="p-column-filter"
                    >
                        <template #value="slotProps">
                            <span v-if="slotProps.value != null">{{ slotProps.value.label }}</span>
                            <span v-else>Επιλογή</span>
                        </template>
                        <template #option="slotProps">
                            <span>{{ slotProps.option.label }}</span>
                        </template>
                    </Dropdown>
                </div>
                <small v-if="(v$.code.$invalid && submitted) || v$.code.$pending.$response" class="p-error">{{ v$.code.required.$message }}</small>
            </div>
            <div class="field">
                <label :class="{ 'p-error': v$.date.$invalid && submitted }">Ημερομηνία*</label>
                <Calendar v-model="v$.date.$model" date-format="dd/mm/yy" placeholder="dd/mm/yyyy" :class="{ 'p-invalid': v$.date.$invalid && submitted }" />
                <small v-if="(v$.date.$invalid && submitted) || v$.date.$pending.$response" class="p-error">{{ v$.date.required.$message }}</small>
            </div>
            <div class="field">
                <label>Σχόλιο*</label>
                <Textarea v-model="state.comment" autocomplete="off" spellcheck="off" placeholder="Σχόλιο" rows="3" cols="30" />
            </div>
        </div>

        <template #footer>
            <Button label="Άκυρο" icon="pi pi-times" class="p-button-outlined w-8rem mr-3" @click="onHide(false)" />
            <Button label="Αποθήκευση" icon="pi pi-check" class="p-button-primary w-10rem" :loading="loading" @click="handleSubmit(!v$.$invalid)" />
        </template>
    </Dialog>
    <Dialog v-model:visible="invoiceDialog" header="Επιλέξτε ενέργεια" :modal="true" :closeOnEscape="false" :closable="invoiceDialogClosable" @hide="onHide(false)">
        <template #footer>
            <Button icon="pi pi-plus" label="ΑΠΥ" class="mr-2 mb-2 p-button-danger" @click="addReceiptImmediate" />
            <!-- <Button icon="pi pi-plus" label="ΑΠΥ (Προγραμματισμένη)" class="mr-2 mb-2 p-button-warning" @click="addReceiptScheduled" /> -->
            <Button icon="pi pi-plus" label="ΤΠΥ" class="mr-2 mb-2 p-button-info" @click="addInvoiceImmediate" />
            <Button label="Άκυρο" icon="pi pi-times" class="p-button-outlined w-8rem mr-2 mb-2" @click="onHide(false)" />
        </template>
    </Dialog>
</template>

<script setup>
import { useToast } from 'primevue/usetoast';
import { onMounted, reactive, ref, computed, watchEffect, toRefs } from 'vue';
import { required, email } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import moment from 'moment-timezone';
import useRPC from '@/composables/useRPC';
import Utils from '@/utils/Utils';
import { useConfirm } from 'primevue/useconfirm';
import { usePopupStore } from '@/stores/PopupStore';
const { RPCApi, RPCMethods } = useRPC();
const dialogShown = ref(false);
const submitted = ref(false);
const headerTitle = ref('');
const confirm = useConfirm();
const popupStore = usePopupStore();
const invoiceDialog = ref(false);
const invoiceDialogClosable = ref(true);
const state = reactive({
    id: null,
    amount: null,
    code: null,
    date: null,
    comment: null,
});
const rules = {
    amount: { required },
    code: { required },
    date: { required },
};
const v$ = useVuelidate(rules, state);
const toast = useToast();
const editMode = ref(false);
const userID = ref(null);
const closeCB = ref(null);

const show = async (data, onCloseCB) => {
    let { payment, userId } = data;
    closeCB.value = onCloseCB;
    console.log('payment:', payment);
    resetForm();
    dialogShown.value = true;
    submitted.value = false;
    editMode.value = !!payment;
    userID.value = userId;
    headerTitle.value = editMode.value ? `Εμφάνιση πληρωμής` : `Νέα πληρωμή`;

    let paymentCodesRes = await RPCApi(RPCMethods.Payments.FetchPaymentCodes);
    if (paymentCodesRes.status === 'ok') {
        paymentCodes.value = paymentCodesRes.data.result.data.map((item) => {
            return {
                value: item.id,
                label: item.name,
            };
        });
    }

    if (editMode.value) {
        state.id = payment.id;
        state.amount = payment.amount;
        state.code = paymentCodes.value.find((x) => x.value === payment.codeID);
        state.date = Utils.datetimeToDate(payment.createdAt);
        state.comment = payment.comment;
    }
};
const loading = ref(false);
const paymentCodes = ref([]);

const onHide = (action = false) => {
    dialogShown.value = false;
    resetForm();
    closeCB.value(action);
};

const completeSuccess = () => {
    toast.add({ severity: 'success', summary: 'Η διαδικασία ολοκληρώθηκε', detail: 'Επιτυχής διαδικασία!', life: 3000 });
    loading.value = false;
    onHide(true);
};

const handleSubmit = async (isFormValid) => {
    submitted.value = true;

    if (!isFormValid) return;

    console.log('ok submit');
    loading.value = true;
    let res;
    let payload = {
        amount: state.amount,
        code: state.code.value,
        date: state.date,
        type: Utils.PaymentType.Cash,
        comment: state.comment,
        userID: userID.value,
    };

    console.log(payload);

    if (editMode.value) {
        payload.id = state.id;
        res = await RPCApi(RPCMethods.Payments.UpdatePayment, payload);
    } else {
        res = await RPCApi(RPCMethods.Payments.AddPayment, payload);
    }

    if (res.status !== 'ok') {
        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Προσπαθήστε ξανά!', life: 3000 });
        loading.value = false;
        return;
    }

    if (editMode.value) {
        completeSuccess();
        return;
    }

    if (state.code.value !== 1 && state.code.value !== 2 && state.code.value !== 7 && state.code.value !== 9 && state.code.value !== 10) {
        invoiceDialogClosable.value = false;
    } else {
        invoiceDialogClosable.value = true;
    }

    invoiceDialog.value = true;
};

const addReceiptImmediate = async () => {
    console.log('addReceiptImmediate');
    popupStore.show(Utils.Popups.InvoiceDialog, { userID: userID.value, type: 'receipt', date: null, amount: state.amount, code: state.code.value }, () => {
        completeSuccess();
    });
};

const addReceiptScheduled = async () => {
    console.log('addReceiptScheduled');
    //tomorrow date
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    popupStore.show(Utils.Popups.InvoiceDialog, { userID: userID.value, type: 'receipt', date: tomorrow, amount: state.amount, code: state.code.value }, () => {
        completeSuccess();
    });
};

const addInvoiceImmediate = async () => {
    console.log('addInvoiceImmediate');
    popupStore.show(Utils.Popups.InvoiceDialog, { userID: userID.value, type: 'invoice', date: null, amount: state.amount, code: state.code.value }, () => {
        completeSuccess();
    });
};

const resetForm = () => {
    for (let k in state) state[k] = null;

    state.date = new Date();

    submitted.value = false;
    loading.value = false;
    invoiceDialog.value = false;
};

defineExpose({
    show,
});
</script>
