<template>
    <Dialog
        v-model:visible="dialogShown"
        class="p-fluid"
        :maximizable="true"
        :close-on-escape="false"
        :dismissable-mask="false"
        :modal="true"
        :breakpoints="{ '960px': '75vw', '640px': '95vw' }"
        :style="{ width: '100vw' }"
    >
        <template #header>
            <h4>{{ headerTitle }}</h4>
        </template>
        <div class="relative">
            <div class="relative" style="width: 100vw; height: 100vw">
                <div class="absolute left-0 top-0 text-white font-bold border-round">
                    <iframe :src="videoUrl" style="border: 0; width: 95vw; height: 95vw" allow="autoplay; picture-in-picture" />
                </div>
                <img
                    class="watermark1"
                    src="https://firebasestorage.googleapis.com/v0/b/vitali-backend.appspot.com/o/logo.png?alt=media&token=d93e41b1-6051-45a8-8d5c-e87579c47fd0"
                    style="max-width: 100px; margin: 0 auto"
                />
                <img
                    class="watermark2"
                    src="https://firebasestorage.googleapis.com/v0/b/vitali-backend.appspot.com/o/logo.png?alt=media&token=d93e41b1-6051-45a8-8d5c-e87579c47fd0"
                    style="max-width: 100px; margin: 0 auto"
                />
                <img
                    class="watermark3"
                    src="https://firebasestorage.googleapis.com/v0/b/vitali-backend.appspot.com/o/logo.png?alt=media&token=d93e41b1-6051-45a8-8d5c-e87579c47fd0"
                    style="max-width: 100px; margin: 0 auto"
                />
                <img
                    class="watermark4"
                    src="https://firebasestorage.googleapis.com/v0/b/vitali-backend.appspot.com/o/logo.png?alt=media&token=d93e41b1-6051-45a8-8d5c-e87579c47fd0"
                    style="max-width: 100px; margin: 0 auto"
                />
                <img
                    class="watermark5"
                    src="https://firebasestorage.googleapis.com/v0/b/vitali-backend.appspot.com/o/logo.png?alt=media&token=d93e41b1-6051-45a8-8d5c-e87579c47fd0"
                    style="max-width: 100px; margin: 0 auto"
                />
                <img
                    class="watermark6"
                    src="https://firebasestorage.googleapis.com/v0/b/vitali-backend.appspot.com/o/logo.png?alt=media&token=d93e41b1-6051-45a8-8d5c-e87579c47fd0"
                    style="max-width: 100px; margin: 0 auto"
                />
                <img
                    class="watermark7"
                    src="https://firebasestorage.googleapis.com/v0/b/vitali-backend.appspot.com/o/logo.png?alt=media&token=d93e41b1-6051-45a8-8d5c-e87579c47fd0"
                    style="max-width: 100px; margin: 0 auto"
                />
                <img
                    class="watermark8"
                    src="https://firebasestorage.googleapis.com/v0/b/vitali-backend.appspot.com/o/logo.png?alt=media&token=d93e41b1-6051-45a8-8d5c-e87579c47fd0"
                    style="max-width: 100px; margin: 0 auto"
                />
                <div class="info"><b>Οποιαδήποτε παράνομη καταγραφή εικόνας ή ήχου δεν επιτρέπεται και μπορεί να διωχθεί ποινικά</b></div>
            </div>
        </div>
        <template #footer> </template>
    </Dialog>
</template>

<script setup>
import axios from 'axios';
import { useToast } from 'primevue/usetoast';
import moment from 'moment-timezone';
import { onMounted, reactive, ref, computed, watchEffect, toRefs } from 'vue';
import { required, integer, helpers } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import useRPC from '@/composables/useRPC';
import Utils from '@/utils/Utils';
import logo from '@/assets/images/LogoFullDark.png';
const { RPCApi, RPCMethods } = useRPC();
const dialogShown = ref(false);
const headerTitle = ref('');
const closeCB = ref(null);
const videoSrc = ref('');
const videoPoster = ref('');
const toast = useToast();
const videoUrl = ref(null);

const show = (title, src, cb) => {
    headerTitle.value = title;
    videoUrl.value = src;
    closeCB.value = cb;
    dialogShown.value = true;
};

const onHide = (action = false) => {
    dialogShown.value = false;
    closeCB.value(action);
};

onMounted(() => {
    // console.log('ZoomVideoDialog mounted');
});

defineExpose({
    show,
});
</script>

<style>
.watermark1 {
    position: fixed;
    left: 200px;
    top: 200px;
    transform: rotate(-45deg);
    opacity: 0.2;
}
.watermark2 {
    position: fixed;
    left: 950px;
    top: 200px;
    transform: rotate(-45deg);
    opacity: 0.2;
}
.watermark3 {
    position: fixed;
    left: 650px;
    top: 200px;
    transform: rotate(-45deg);
    opacity: 0.2;
}
.watermark4 {
    position: fixed;
    left: 500px;
    top: 700px;
    transform: rotate(-45deg);
    opacity: 0.2;
}
.watermark5 {
    position: fixed;
    left: 800px;
    top: 600px;
    transform: rotate(-45deg);
    opacity: 0.2;
}
.watermark6 {
    position: fixed;
    left: 600px;
    top: 400px;
    transform: rotate(-45deg);
    opacity: 0.2;
}
.watermark7 {
    position: fixed;
    left: 200px;
    top: 600px;
    transform: rotate(-45deg);
    opacity: 0.2;
}
.watermark8 {
    position: fixed;
    left: 200px;
    top: 600px;
    transform: rotate(-45deg);
    opacity: 0.2;
}
.info {
    background-color: red;
    color: white;
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    position: fixed;
    width: 100%;
    bottom: 0px;
    left: 0px;
}
</style>
