<template>
    <Dialog v-model:visible="dialogShown" :close-on-escape="false" :dismissable-mask="false" :modal="true" @hide="onHide">
        <template #header>
            <div class="flex flex-row justify-content-between align-items-center gap-2">
                <span style="font-size: 1.5rem">Ανάθεση Μαθήματος {{ state.id ? 'NO. ' + state.id : '' }}</span>
                <div>
                    <span class="status-badge" :class="`status-${state.status}`">{{ Utils.JobPostingStatusToString(state.status) }}</span>
                </div>
            </div>
        </template>

        <div class="flex flex-column" style="gap: 1rem">
            <div class="flex flex-row w-full" style="gap: 1.81rem">
                <div class="box w-6 flex flex-row" style="height: 12.125rem">
                    <div class="flex flex-column" style="padding-left: 1rem; padding-top: 1.6rem">
                        <h5>Σχολή</h5>
                        <div class="flex flex-row gap-8 pt-1">
                            <div class="flex flex-column gap-3">
                                <span style="font-weight: 800">Κατηγορία:</span>
                                <span style="font-weight: 800">Υποκατηγορία Ι:</span>
                                <span style="font-weight: 800">Υποκατηγορία ΙΙ:</span>
                            </div>
                            <div class="flex flex-column gap-3">
                                <span>{{ state.category }}</span>
                                <span>{{ state.subCateg1 }}</span>
                                <span>{{ state.subCateg2 }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box flex-1" style="height: 12.125rem">
                    <div class="flex flex-column" style="padding-left: 1rem; padding-top: 1.6rem">
                        <h5>Μάθημα</h5>
                        <div class="flex flex-row gap-8 pt-1">
                            <div class="flex flex-column gap-3">
                                <span style="font-weight: 800">Τίτλος:</span>
                                <span style="font-weight: 800">Εξεταστική:</span>
                                <span style="font-weight: 800">Αρχεία:</span>
                            </div>
                            <div class="flex flex-column gap-3">
                                <span>{{ state.course?.title }}</span>
                                <span>{{ state.examPeriod?.title }}</span>
                                <a class="font-medium no-underline text-left cursor-pointer" style="color: blue" @click="showLessonFiles(state.course)">Αρχεία Μαθήματος</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-row w-full">
                <div class="box w-full">
                    <div class="flex flex-column" style="padding-left: 1rem; padding-top: 1.6rem; padding-bottom: 1.6rem">
                        <h5>Γενικά στοιχεία</h5>
                        <div class="flex flex-row justify-content-between gap-4 pr-4">
                            <div class="flex flex-column align-content-center">
                                <div class="flex flex-row">
                                    <span style="font-weight: 800; width: 22rem; align-self: center">Δημιουργήθηκε από:</span>
                                    <div style="width: 100%; align-self: center">
                                        <span style="align-self: center">{{ state.createdBy || '--' }}</span>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <span style="font-weight: 800; width: 22rem; align-self: center">Μαθητές σε αναμονή:</span>
                                    <div style="width: 100%; align-self: center">
                                        <span style="align-self: center">{{ state.totalRequests?.length }}</span>
                                    </div>
                                </div>

                                <div class="flex flex-row gap-2 max-w-17rem flex-wrap">
                                    <span style="font-weight: 800; align-self: center">Αιτήσεις:</span>
                                    <div v-for="(requestID, index) in state.totalRequests" :key="requestID" class="flex flex-row gap-2">
                                        <span>
                                            <a class="font-medium no-underline text-left cursor-pointer" style="color: blue; align-self: center" @click="showRequestDialog(requestID)">{{
                                                requestID
                                            }}</a>
                                            <span v-if="index < state.totalRequests.length - 1" style="align-self: center">,</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div style="align-self: center">
                                <Button v-if="state.status === 0" label="Διαχείριση αιτήσεων" icon="pi pi-copy" class="p-button-primary w-16rem" @click="openRequestsForLesson" />
                            </div>
                            <div class="flex flex-row gap-4">
                                <span style="font-weight: 800; align-self: center">Απάντηση μέχρι:</span>
                                <div style="align-self: center">
                                    <Calendar
                                        v-model="state.deadline"
                                        :showIcon="true"
                                        :showButtonBar="true"
                                        :monthNavigator="false"
                                        :yearNavigator="false"
                                        date-format="dd/mm/yy"
                                        hour-format="24"
                                        placeholder="dd/mm/yyyy"
                                        :show-time="true"
                                        :time-only="false"
                                        :stepMinute="30"
                                        :disabled="state.status !== Utils.JobPostingStatus.Waiting"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex flex-row w-full">
                <div class="box w-full">
                    <div class="flex flex-column gap-2" style="padding-left: 1rem; padding-top: 1.6rem; padding-right: 1.6rem; padding-bottom: 1.6rem">
                        <h5>Υποψήφιοι Καθηγητές</h5>
                        <Button v-if="state.status === 0" label="Διαχείριση καθηγητών" icon="pi pi-users" class="p-button-help w-16rem" @click="updateTeachersToPosting" />
                        <div class="flex flex-row justify-content-between w-full gap-6">
                            <div class="flex flex-column gap-2 w-30rem">
                                <div class="scroller">
                                    <div class="virtual-scroller">
                                        <div class="virtual-scroller-content">
                                            <div v-for="item in jobPostingsApplications" :key="item.teacherID">
                                                <div
                                                    class="item"
                                                    :class="{ selected: selectedApplication != null && selectedApplication.teacherID === item.teacherID }"
                                                    @click="onShowTeacherJobApplication(item.teacherID)"
                                                >
                                                    <ChatUser
                                                        :user="{
                                                            id: item.teacherID,
                                                            role: Utils.Roles.Teacher,
                                                        }"
                                                        size="2.5rem"
                                                    >
                                                    </ChatUser>
                                                    <div class="flex flex-column">
                                                        <div class="username">
                                                            {{ item.teacherName }}
                                                        </div>
                                                        <div v-if="selectedApplication" class="flex flex-row gap-1">
                                                            <span>ενεργά: {{ getActiveClasses(item.teacherID) }} - </span>
                                                            <span>ίδια: {{ getSameClasses(item.teacherID) }} - </span>
                                                            <span>θετικές: {{ getPositiveAnswers(item.teacherID) }} </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="selectedApplication != null" class="flex flex-column gap-4">
                                <div class="flex flex-row">
                                    <div style="width: 100%">
                                        <a class="font-medium no-underline text-left cursor-pointer" style="color: blue; align-self: center" @click="showTeacherDialog(selectedApplication.teacherID)">
                                            {{ selectedApplication.teacherName }}
                                        </a>
                                    </div>
                                </div>
                                <div class="flex flex-row">
                                    <span style="font-weight: 800; align-self: center">Ανάληψη:</span>
                                    <div style="width: 100%; padding-left: 1rem">
                                        <span v-if="selectedApplication.apply === -1">Δεν απάντησε</span>
                                        <span v-else>{{ Utils.PostingsChoices.find((x) => x.value === selectedApplication.apply)?.label }}</span>
                                    </div>
                                </div>

                                <div v-if="selectedApplication.hoursFrom != null" class="flex flex-row">
                                    <span style="font-weight: 800; width: 18rem; align-self: center">Πρόταση ωρών (από):</span>
                                    <div style="width: 100%">
                                        <span>{{ selectedApplication.hoursFrom }} ώρες</span>
                                    </div>
                                </div>

                                <div v-if="selectedApplication.hoursTo != null" class="flex flex-row">
                                    <span style="font-weight: 800; width: 18rem; align-self: center">Πρόταση ωρών (μέχρι):</span>
                                    <div style="width: 100%">
                                        <span>{{ selectedApplication.hoursTo }} ώρες</span>
                                    </div>
                                </div>

                                <div v-if="selectedApplication.startDateA != null" class="flex flex-row">
                                    <span style="font-weight: 800; width: 18rem; align-self: center">Προτεινόμενη έναρξη A:</span>
                                    <div style="width: 100%">
                                        <span>{{ moment(Utils.datetimeToDate(selectedApplication.startDateA)).format('DD/MM/YYYY HH:mm') }}</span>
                                    </div>
                                </div>

                                <div v-if="selectedApplication.startDateB != null" class="flex flex-row">
                                    <span style="font-weight: 800; width: 18rem; align-self: center">Προτεινόμενη έναρξη B:</span>
                                    <div style="width: 100%">
                                        <span>{{ moment(Utils.datetimeToDate(selectedApplication.startDateB)).format('DD/MM/YYYY HH:mm') }}</span>
                                    </div>
                                </div>

                                <div class="flex flex-row gap-3">
                                    <span style="font-weight: 800">Μηνύματα:</span>
                                    <div class="flex flex-column gap-0">
                                        <div ref="chatContainer" class="chat-container">
                                            <div class="virtual-scroller-content">
                                                <div class="messages-container">
                                                    <div
                                                        v-for="message in messages"
                                                        :key="message.id"
                                                        class="message"
                                                        :data-value="isMyMsg(message.text) ? 'mine' : 'other'"
                                                        :style="{ display: message.text.trim().length === 0 ? 'none' : 'flex' }"
                                                    >
                                                        <div class="flex flex-row gap-2">
                                                            <span>{{ pureMsg(message.text) }}</span>
                                                            <span v-if="message.date" style="font-size: 0.7rem; color: #999999; text-align: end; align-self: center">
                                                                ({{ moment(Utils.datetimeToDate(message.date)).format('DD/MM/YYYY HH:mm') }})
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="input-container">
                                            <input v-model="newMessage" type="text" placeholder="Πληκτρολόγησε ένα μήνυμα" @keyup.enter="appendAndSendMsg" />
                                            <Button label="" icon="pi pi-send" @click="appendAndSendMsg" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-row w-full gap-2">
                <div class="box p-4 w-full">
                    <h5>Τελική επιλογή καθηγητή</h5>
                    <div class="flex flex-row pt-2 gap-2">
                        <Dropdown
                            v-model="pickedTeacherId"
                            :options="jobPostingsApplications"
                            option-label="teacherName"
                            option-value="teacherID"
                            :placeholder="'Επιλογή'"
                            style="width: 30rem"
                            :disabled="state.status !== Utils.JobPostingStatus.Waiting"
                        />
                    </div>
                </div>
                <div class="box p-4 w-full">
                    <h5>Κατάσταση ανάθεσης</h5>
                    <div class="flex flex-row pt-2 gap-2">
                        <Dropdown v-model="state.status" :options="Utils.JobPostingStatuses" option-label="label" option-value="value" :placeholder="'Επιλογή'" style="width: 30rem" />
                    </div>
                </div>
            </div>
            <div class="box p-4 w-full">
                <h5>Σχόλια (office/admin)</h5>
                <div class="flex flex-row pt-2 gap-2">
                    <Textarea v-model="state.comments" class="w-full" :rows="5" />
                </div>
            </div>
        </div>

        <template #footer>
            <div class="flex flex-row justify-content-between mt-4 gap-2">
                <Button label="Δημιουργία τμήματος" icon="pi pi-plus" class="p-button-warning" @click="onCreateClass" />
                <Button label="Αποθήκευση" icon="pi pi-save" class="p-button-primary" @click="onSaveChanges" />
            </div>
        </template>

        <div v-if="loading" class="flex flex-row justify-content-between align-content-center w-full h-full absolute top-0 left-0 z-5 bg-black-alpha-20">
            <Vue3Lottie :animationData="loaderCircle" :loop="true" :autoPlay="true" style="width: 100%" />
        </div>
    </Dialog>

    <Dialog v-model:visible="showUploaderDialog" modal :style="{ width: '80vw' }">
        <template #header>
            <h3>{{ uploaderTitle }}</h3>
        </template>
        <Uploader ref="uploader" :viewMode="true" />
    </Dialog>

    <Dialog v-model:visible="showTeachersDialog" modal style="max-width: 95vw" @hide="onTeachersDialogHide">
        <template #header>
            <div>
                <h5>Προσθέστε καθηγητές προς ανάθεση</h5>
            </div>
        </template>
        <DataTable
            v-model:filters="tableTeachersFilters"
            v-model:selection="selectedTeachers"
            :total-records="totalTeachers"
            :lazy="true"
            :loading="teachersLoading"
            :row-hover="true"
            :value="teachers"
            filter-display="row"
            data-key="id"
            responsive-layout="scroll"
            @sort="onTeachersSort($event)"
            @filter="onTeachersFilter($event)"
        >
            <template #empty> Δεν βρέθηκαν καθηγητές. </template>
            <Column selection-mode="multiple" header-style="width: 3rem"></Column>
            <Column header="Ονοματεπώνυμο" field="fullname" filter-field="lname" :sortable="true" :show-filter-menu="false">
                <template #body="{ data }">
                    <div class="flex cursor-pointer" style="color: blue" @click="showTeacherDialog(data.id)">
                        <Avatar :image="Utils.GetGenericAvatar(data.id)" class="flex align-items-center border-1 border-300 ml-4" size="medium" shape="circle" />
                        <span class="flex-initial flex align-items-center justify-content-center ml-2">{{ data.fullname }}</span>
                    </div>
                </template>
                <template #filter="{ filterModel, filterCallback }">
                    <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Εύρεση βάση ονόματος" @input="filterCallback()" />
                </template>
            </Column>
            <Column header="Ειδικότητα" field="departments" filter-field="departments" :sortable="true" :show-filter-menu="false" style="max-width: 22rem">
                <template #body="{ data }">
                    <span v-for="(item, index) in data.departments" :key="item.departmentID">{{ item.departmentName }} <span v-if="index < data.departments.length - 1">, </span></span>
                </template>
                <template #filter="{ filterModel, filterCallback }">
                    <MultiSelect v-model="filterModel.value" :options="departments" placeholder="Όλα" class="p-column-filter" :showClear="true" @change="filterCallback()">
                        <template #value="slotProps">
                            <span v-if="slotProps.value && slotProps.value.length">
                                {{ slotProps.value.map((option) => option.label).join(', ') }}
                            </span>
                            <span v-else>Όλα</span>
                        </template>
                        <template #option="slotProps">
                            <span>{{ slotProps.option?.label }}</span>
                        </template>
                    </MultiSelect>
                </template>
            </Column>
            <Column header="Μαθήματα" field="departmentCourses" filter-field="departmentCourses" :show-filter-menu="false">
                <template #body="{ data }">
                    {{ data.departmentCourses }}
                </template>
                <template #filter="{ filterModel, filterCallback }">
                    <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Εύρεση βάση μαθήματος" @input="filterCallback()" />
                </template>
            </Column>
            <Column header="Ενεργά τμήματα" :show-filter-menu="false">
                <template #body="{ data }">
                    <div class="flex flex-row gap-2 flex-wrap">
                        {{ data.activeClasses?.length }}
                    </div>
                </template>
            </Column>
            <Column header="Ίδια τμήματα" field="classesForCourse" :show-filter-menu="false">
                <template #body="{ data }">
                    <div class="flex flex-row gap-2 flex-wrap">
                        {{ data.classesForCourse?.length }}
                    </div>
                </template>
            </Column>
            <Column header="Θετικές απαντήσεις" :show-filter-menu="false">
                <template #body="{ data }"> {{ data.positiveApplications }} </template>
            </Column>
        </DataTable>
        <template #footer>
            <Button label="Ακύρωση" icon="pi pi-times" class="p-button-outlined" @click="onTeachersDialogHide" />
            <Button label="Προσθήκη" icon="pi pi-check" class="p-button-primary" @click="onTeachersDialogAdd" />
        </template>
    </Dialog>
</template>

<script setup>
import { useToast } from 'primevue/usetoast';
import { required, integer, email } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { useDebounceFn } from '@vueuse/core';
import { usePopupStore } from '@/stores/PopupStore.js';
import useRPC from '@/composables/useRPC';
import Utils from '@/utils/Utils';
import moment from 'moment-timezone';
import { onMounted, onUnmounted, reactive, ref, computed, watchEffect, toRefs, nextTick, watch } from 'vue';
import useTemplates from '@/composables/useTemplates';
import { useConfirm } from 'primevue/useconfirm';
import { FilterMatchMode, FilterOperator, FilterService } from 'primevue/api';
import loaderCircle from '@/assets/lottie/loaderCircle.json';
import Uploader from '@/components/Uploader.vue';
import ChatUser from '@/components/ChatUser.vue';
import Textarea from 'primevue/textarea';

const teachersLoading = ref(false);
const selectedTeachers = ref([]);
const totalTeachers = ref(0);
const teachers = ref([]);
const rowsPerPage = ref(10);
const requestsPerPage = 10;
const pickedTeacherId = ref(null);
const selectedApplication = ref(null);
const jobPostingsApplications = ref([]);
const teachersContainer = ref(null);
const chatContainer = ref(null);
const messages = ref([]);
const newMessage = ref('');
const showUploaderDialog = ref(false);
const uploader = ref(null);
const uploaderTitle = ref('');
const submitted = ref(false);
const state = reactive({
    id: null,
    category: null,
    subCateg1: null,
    subCateg2: null,
    course: null,
    examPeriod: null,
    totalRequests: null,
    deadline: null,
    answer: null,
    hoursFrom: null,
    hoursTo: null,
    startDateA: null,
    startDateB: null,
    status: null,
    comments: null,
    createdBy: null,
});
const { RPCApi, RPCMethods } = useRPC();
const headerTitle = ref('');
const loading = ref(false);
const popupStore = usePopupStore();
const confirm = useConfirm();
const toast = useToast();
const dialogShown = ref(false);
const closeCB = ref(null);
const showTeachersDialog = ref(false);
const departments = ref([]);
const tableTeachersFilters = ref({
    lname: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    mobile: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    ban: { value: true, matchMode: FilterMatchMode.EQUALS },
    departments: { value: null, matchMode: FilterMatchMode.IN },
    lastLoginDatetime: { value: null, matchMode: FilterMatchMode.EQUALS },
    departmentCourses: { value: null, matchMode: FilterMatchMode.CONTAINS },
});
const fetchTeachersParams = ref({
    paging: { offset: 0, limit: rowsPerPage },
    sort: null,
    filters: tableTeachersFilters.value,
});

const getActiveClasses = (teacherID) => {
    if (!jobPostingsApplications.value) return;
    return jobPostingsApplications.value.find((x) => x.teacherID === teacherID).activeClasses.length || 0;
};

const getSameClasses = (teacherID) => {
    if (!jobPostingsApplications.value) return;
    return jobPostingsApplications.value.find((x) => x.teacherID === teacherID).classesForCourse.length || 0;
};

const getPositiveAnswers = (teacherID) => {
    if (!jobPostingsApplications.value) return;
    return jobPostingsApplications.value.find((x) => x.teacherID === teacherID).positiveApplications || 0;
};

const show = async (data, onCloseCB) => {
    closeCB.value = onCloseCB;
    dialogShown.value = true;
    let { id } = data;
    state.id = id;

    await fetchPostingInfo(id);
    let departmentsRes = await RPCApi(RPCMethods.Departments.Fetch);
    let arr = [];
    if (departmentsRes.status == 'ok') {
        for (let s of departmentsRes.data.result.data) {
            if (!s.title || !s.title.trim()) continue;
            s.label = s.title;
            s.value = s.id;
            arr.push(s);
        }
    }
    departments.value = arr;
};

const fetchPostingInfo = async (id) => {
    loading.value = true;
    try {
        let res = await RPCApi(RPCMethods.JobPostings.ReadJobPosting, { id: id });
        console.warn('res', res);
        let { category, subCateg1, subCateg2, courseTitle, courseId, examPeriodId, examPeriodTitle, totalRequests, deadline, title, body, pickedTeacher, status, comments, createdBy, applications } =
            res.data.result.data;
        state.category = category;
        state.subCateg1 = subCateg1;
        state.subCateg2 = subCateg2;
        state.course = {
            title: courseTitle,
            id: courseId,
        };
        state.examPeriod = {
            title: examPeriodTitle,
            id: examPeriodId,
        };
        state.totalRequests = totalRequests;
        state.deadline = Utils.datetimeToDate(deadline);
        state.title = title;
        state.body = body;
        state.status = status;
        state.comments = comments;
        state.createdBy = createdBy;
        pickedTeacherId.value = pickedTeacher;

        if (applications) {
            jobPostingsApplications.value = applications;
            console.warn(applications);
            await nextTick();
            onShowTeacherJobApplication(applications[0].teacherID);
        }
    } catch (e) {
        console.log(e);
        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Σφάλμα κατά την ανάκτηση των στοιχείων της ανάθεσης' });
    } finally {
        loading.value = false;
    }

    loading.value = false;
};

const breakCommentsToChatMessages = (comments) => {
    if (!comments) return;
    messages.value = [];
    let msgs = comments.split('\n');
    msgs.forEach((msg) => {
        messages.value.push({
            id: Utils.generateUUID(),
            text: msg.includes('_DATE_:') ? msg.substring(0, msg.indexOf('_DATE_:')) : msg,
            date: msg.includes('_DATE_:') ? msg.substring(msg.indexOf('_DATE_:') + 7) : null,
        });
    });
};

const isMyMsg = (msg) => {
    return msg.startsWith('_ADMIN_:');
};

const pureMsg = (msg) => {
    return msg
        .replace(/_([0-9]+)_:/g, '')
        .replace(/\n/g, '')
        .replace(/_ADMIN_:/g, '');
};

const appendAndSendMsg = async () => {
    loading.value = true;
    if (newMessage.value == null || newMessage.value.trim() === '') return;
    let tempMsg = newMessage.value;
    let msg = {
        id: Utils.generateUUID(),
        text: '_' + Utils.UserInfo.id + '_:' + newMessage.value,
    };
    newMessage.value = '';

    let sendRes = await RPCApi(RPCMethods.JobPostings.UpdateJobPostingApplicationComments, { id: state.id, msg: tempMsg, teacherId: selectedApplication.value.teacherID });
    console.warn('sendRes', sendRes);
    if (sendRes.status != 'ok') {
        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Σφάλμα κατά την αποστολή του μηνύματος' });
        return;
    }
    jobPostingsApplications.value.find((x) => x.teacherID === selectedApplication.value.teacherID).comments = sendRes.data.result.data.comments;
    breakCommentsToChatMessages(sendRes.data.result.data.comments);
    console.warn('sendRes', sendRes);

    await nextTick();
    scrollToBottom();
    loading.value = false;
};

const scrollToBottom = () => {
    if (chatContainer.value == null) return;
    chatContainer.value.scrollTop = chatContainer.value.scrollHeight;
};

const updateTeachersToPosting = async (event) => {
    console.log('updateTeachersToPosting');
    showTeachersDialog.value = true;
    await fetchTeachers();
    selectedTeachers.value = jobPostingsApplications.value.map((x) => {
        return { id: x.teacherID };
    });
};

const openRequestsForLesson = () => {
    let courseID = state.course?.id;
    popupStore.show(
        Utils.Popups.CourseRequestsDialog,
        {
            courseID,
            totalSelectedRequests: state.totalRequests,
        },
        handleRequestsDialogClosed
    );
};

const handleRequestsDialogClosed = async (action, selectedRequests) => {
    console.log('selectedRequests:', selectedRequests);
    if (selectedRequests.length === 0) return;
    loading.value = true;
    try {
        await RPCApi(RPCMethods.JobPostings.UpdateRequestsToJobPosting, { jobPostingId: state.id, requests: selectedRequests });
        toast.add({ severity: 'success', summary: 'Επιτυχία', detail: 'Οι αιτήσεις προστέθηκαν με επιτυχία!', life: 5000 });
    } catch (e) {
        console.warn(e);
        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Σφάλμα κατά την προσθήκη των αιτήσεων!', life: 5000 });
    } finally {
        loading.value = false;
        await fetchPostingInfo(state.id);
    }
};

const onSaveChanges = async () => {
    console.log('onSaveChanges');
    loading.value = true;

    let data = {
        id: state.id,
        deadline: state.deadline,
        pickedTeacherId: pickedTeacherId.value,
        status: state.status,
        comments: state.comments,
    };

    console.log('data', data);

    try {
        let res = await RPCApi(RPCMethods.JobPostings.UpdateJobPosting, data);
        console.log('res', res);

        if (res.status === 'ok') {
            toast.add({ severity: 'success', summary: 'Επιτυχία', detail: 'Η ανάθεση αποθηκεύτηκε με επιτυχία!', life: 5000 });
            onHide(true);
        } else {
            toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Σφάλμα κατά την αποθήκευση της ανάθεσης!', life: 5000 });
        }
    } catch (e) {
        console.warn(e);
        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Σφάλμα κατά την ενημέρωση της ανάθεσης!', life: 5000 });
    } finally {
        loading.value = false;
    }
};

const onHide = (action = false) => {
    console.log('onHide');
    dialogShown.value = false;
    resetForm();
    closeCB.value(action);
};

const showLessonFiles = async (course) => {
    console.log('showLessonFiles for course: ' + course.id + ' and title: ' + course.title);
    showUploaderDialog.value = true;
    uploaderTitle.value = 'Αρχεία μαθήματος: ' + course.title;
    await nextTick();
    uploader.value.fetchFiles(`courses/${course.id}/files`);
};

const resetForm = () => {
    console.warn('resetForm');
    messages.value = [];
    newMessage.value = '';
    state.answer = null;
    state.hoursFrom = null;
    state.hoursTo = null;
    state.startDateA = null;
    state.startDateB = null;
    state.comments = null;
    selectedApplication.value = null;
    pickedTeacherId.value = null;
};

const onShowTeacherJobApplication = async (teacherID) => {
    console.log('onShowTeacherJobApplication:' + teacherID);
    messages.value = [];
    selectedApplication.value = jobPostingsApplications.value.find((x) => x.teacherID === teacherID);
    breakCommentsToChatMessages(selectedApplication.value.comments);
    await nextTick();
    scrollToBottom();
};

const fetchTeachers = async () => {
    teachersLoading.value = true;
    const res = await RPCApi(RPCMethods.User.FetchTeachersWithParams, { ...fetchTeachersParams.value, extraInfo: { courseID: state.course.id, examPeriod: state.examPeriod.id } });
    if (res.status == 'ok') {
        let d = [];
        if (res.status == 'ok') {
            const { teachersData, total } = res.data.result.data;
            totalTeachers.value = total;
            for (let obj of teachersData) {
                d.push({
                    id: obj.id,
                    fullname: obj.lname + ' ' + obj.fname,
                    lname: obj.lname,
                    email: obj.email,
                    mobile: obj.mobile,
                    school: obj.school,
                    ban: obj.ban === 1 ? true : false,
                    departments: obj.departments,
                    activeClasses: obj.activeClasses,
                    departmentCourses: obj.courses ? obj.courses.join(', ').replace(/,\s*$/, '') : '',
                    classesForCourse: obj.classesForCourse,
                    positiveApplications: obj.positiveApplications,
                    lastLoginDatetime: obj.lastLoginDatetime
                        ? moment(Utils.datetimeToDate(obj.lastLoginDatetime)).format('DD/MM/YYYY') + ' - ' + moment(Utils.datetimeToDate(obj.lastLoginDatetime)).format('HH:mm')
                        : null,
                });
            }
            teachers.value = d;
        } else {
            toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Προσπαθήστε αργότερα!', life: 3000 });
        }
    }
    teachersLoading.value = false;
};

const onTeachersSort = useDebounceFn((event) => {
    console.log('onSort', event);
    if (event.sortField === 'fullname') event.sortField = 'lname';
    fetchTeachersParams.value.paging = { offset: 0, limit: rowsPerPage };
    fetchTeachersParams.value.sort = { sortField: event.sortField, sortOrder: event.sortOrder };
    fetchTeachers();
}, 300);

const onTeachersFilter = useDebounceFn(() => {
    console.log('onFilter', tableTeachersFilters.value);
    fetchTeachersParams.value.paging = { offset: 0, limit: rowsPerPage };
    fetchTeachersParams.value.filters = {
        ...tableTeachersFilters.value,
        departments: { value: tableTeachersFilters.value.departments.value?.map((d) => d.value), matchMode: FilterMatchMode.IN },
        ban:
            tableTeachersFilters.value.ban.value == null
                ? { value: null, matchMode: FilterMatchMode.EQUALS }
                : tableTeachersFilters.value.ban.value === true
                ? { value: 0, matchMode: FilterMatchMode.EQUALS }
                : { value: 1, matchMode: FilterMatchMode.EQUALS },
        lastLoginDatetime:
            tableTeachersFilters.value.lastLoginDatetime.value == null
                ? { value: null, matchMode: FilterMatchMode.EQUALS }
                : tableTeachersFilters.value.lastLoginDatetime.value === true
                ? { value: 1, matchMode: FilterMatchMode.EQUALS }
                : { value: 0, matchMode: FilterMatchMode.EQUALS },
    };
    fetchTeachers();
}, 1000);

const showRequestDialog = (id) => {
    popupStore.show(Utils.Popups.RequestDialog, { requestID: id }, (action) => {
        console.log('onDialogClosed:' + action);
        if (action) fetch();
    });
};

const showTeacherDialog = (teacherID) => {
    popupStore.show(Utils.Popups.TeacherDialog, { teacherID }, () => {
        console.log('teacher dialog closed');
    });
};

const onTeachersDialogHide = () => {
    console.log('onTeachersDialogHide');
    showTeachersDialog.value = false;
    selectedTeachers.value = [];
    totalTeachers.value = 0;
    teachers.value = [];
    tableTeachersFilters.value = {
        lname: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        mobile: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        ban: { value: true, matchMode: FilterMatchMode.EQUALS },
        departments: { value: null, matchMode: FilterMatchMode.IN },
        lastLoginDatetime: { value: null, matchMode: FilterMatchMode.EQUALS },
        departmentCourses: { value: null, matchMode: FilterMatchMode.CONTAINS },
    };
    fetchTeachersParams.value = {
        paging: { offset: 0, limit: rowsPerPage },
        sort: null,
        filters: tableTeachersFilters.value,
    };
};

const onTeachersDialogAdd = async () => {
    console.log('onTeachersDialogAdd', selectedTeachers.value);
    if (selectedTeachers.value.length === 0) {
        toast.add({ severity: 'error', summary: 'Προσοχή', detail: 'Δεν έχετε επιλέξει κανέναν καθηγητή!', life: 5000 });
        return;
    }

    loading.value = true;
    try {
        await RPCApi(RPCMethods.JobPostings.UpdateTeachersToJobPosting, { jobPostingId: state.id, teachers: selectedTeachers.value.map((x) => x.id) });
        toast.add({ severity: 'success', summary: 'Επιτυχία', detail: 'Οι καθηγητές προστέθηκαν με επιτυχία!', life: 5000 });
    } catch (e) {
        console.warn(e);
        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Σφάλμα κατά την προσθήκη των καθηγητών!', life: 5000 });
    } finally {
        loading.value = false;
        onTeachersDialogHide();
        await fetchPostingInfo(state.id);
    }
};

const onCreateClass = async () => {
    console.log('onCreateClass');
    onHide();
    popupStore.show(
        Utils.Popups.ClassDialog,
        {
            courseInfo: {
                id: state.course.id,
                title: state.course.title,
                semester: state.examPeriod.id,
                category: state.category,
            },
            requests: state.totalRequests,
        },
        (action) => {
            console.log('onDialogClosed:' + action);
        }
    );
};

defineExpose({
    show,
});
</script>

<style lang="scss" scoped>
.box {
    border-radius: 0.75rem;
    border: 2px solid #e3e8ef;
    background: #fff;
    overflow: hidden;
}

.chat-container {
    display: flex;
    flex-direction: column;
    width: 600px;
    height: 330px;
    margin: auto;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    overflow-y: scroll;
    position: relative;

    .virtual-scroller-content {
        position: absolute;
        top: 0;
        left: 0;
        min-height: 100%;
        min-width: 100%;
        will-change: transform;
    }
}

.messages-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 15px;
    overflow-y: auto;
}

.message {
    max-width: 70%;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 10px;
    background-color: #e0f7fa;
    word-break: break-word;

    &[data-value='mine'] {
        align-self: flex-end;
        background-color: #b2ebf2;
    }

    &[data-value='other'] {
        align-self: flex-start;
        background-color: #eceff1;
    }
}

.input-container {
    display: flex;
    align-items: center;
    padding-top: 5px;

    input {
        flex-grow: 1;
        padding: 10px;
        border: 1px solid #e0e0e0;
        border-radius: 4px;
        margin-right: 10px;
    }

    button {
        padding: 10px 15px;
        background-color: #26a69a;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }

    button:hover {
        background-color: #00796b;
    }
}

.scroller {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    border: 1px solid #efefef;
    padding: 0.5rem;

    .virtual-scroller {
        position: relative;
        overflow: auto;
        height: 100%;
        .virtual-scroller-content {
            position: absolute;
            top: 0;
            left: 0;
            min-height: 100%;
            min-width: 100%;
            will-change: transform;

            .item {
                position: relative;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: start;
                align-content: center;
                gap: 0.5rem;
                width: 100%;
                height: 3rem;
                padding: 0 5px;
                margin-bottom: 0.8rem;
                cursor: pointer;
                border-radius: 0.5625rem 0rem 0rem 0.5625rem;

                &:hover {
                    background: #efefef;
                }

                &.selected {
                    background: #e0e0e0;
                }

                .username {
                    flex: 1;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    color: #000;
                    font-size: 1rem;
                    font-style: normal;
                    line-height: normal;
                }
            }
        }
    }
}
</style>
