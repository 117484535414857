<template>
    <div class="layout-topbar">
        <div class="topbar-start">
            <button type="button" class="topbar-menubutton p-link p-trigger" @click="onMenuToggle">
                <i class="pi pi-bars"></i>
            </button>

            <AppBreadcrumb class="topbar-breadcrumb"></AppBreadcrumb>
        </div>

        <div class="topbar-end">
            <ul class="topbar-menu">
                <!-- <li class="topbar-search">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <InputText type="text" placeholder="Search" class="w-12rem sm:w-full" />
                    </span>
                </li> -->

                <li class="topbar-profile">
                    <div class="col-md-12 mr-2">
                        <div class="col-md-6">
                            <span>{{ Utils.UserInfo.fname }} {{ Utils.UserInfo.lname }}</span>
                        </div>
                        <div class="col-md-6">
                            <i v-if="panicModeEnabled" class="pi pi-shield text-red-800 pr-2"></i><span>({{ roleToString }})</span>
                        </div>
                    </div>
                    <button type="button" class="p-link w-3rem h-3rem posit" @click="showProfileSidebar">
                        <ChatUser :user="Utils.UserInfo" size="3rem" />
                    </button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup>
import ChatUser from '@/components/ChatUser.vue';
import AppBreadcrumb from '@/layout/AppBreadcrumb.vue';
import { ref, onMounted, onBeforeUnmount, computed } from 'vue';
import { useLayout } from '@/layout/composables/layout';
import Utils from '@/utils/Utils';
import { useGlobalStore } from '@/stores/GlobalStore';

const { onMenuToggle, onProfileSidebarToggle } = useLayout();

const outsideClickListener = ref(null);
const topbarMenuActive = ref(false);
const globalStore = useGlobalStore();

const roleToString = computed(() => {
    switch (Utils.UserInfo.role) {
        case Utils.Roles.Admin:
            return 'Admin';
        case Utils.Roles.Office:
            return 'Office';
        case Utils.Roles.Student:
            return 'Μαθητής';
        default:
            return 'Καθηγητής';
    }
});

onMounted(() => {
    bindOutsideClickListener();
});

onBeforeUnmount(() => {
    unbindOutsideClickListener();
});

const panicModeEnabled = computed(() => {
    return globalStore.hasPanicMode;
});

const bindOutsideClickListener = () => {
    if (!outsideClickListener.value) {
        outsideClickListener.value = (event) => {
            if (isOutsideClicked(event)) {
                topbarMenuActive.value = false;
            }
        };
        document.addEventListener('click', outsideClickListener.value);
    }
};
const unbindOutsideClickListener = () => {
    if (outsideClickListener.value) {
        document.removeEventListener('click', outsideClickListener);
        outsideClickListener.value = null;
    }
};
const isOutsideClicked = (event) => {
    if (!topbarMenuActive.value) return;

    const sidebarEl = document.querySelector('.layout-topbar-menu');
    const topbarEl = document.querySelector('.layout-topbar-menu-button');

    return !(sidebarEl.isSameNode(event.target) || sidebarEl.contains(event.target) || topbarEl.isSameNode(event.target) || topbarEl.contains(event.target));
};

const showProfileSidebar = () => {
    onProfileSidebarToggle();
};
</script>
