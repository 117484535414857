<template>
    <h5>Σύνολο μηνυμάτων: {{ totalNotifications }}</h5>
    <DataTable
        ref="notificationsTable"
        :total-records="totalNotifications"
        :loading="loadingNotifications"
        :row-hover="true"
        :value="notifications"
        data-key="id"
        responsive-layout="scroll"
        :rows="notificationsRowsPerPage"
        :paginator="true"
    >
        <template #header>
            <div class="flex flex-row justify-content-between">
                <div class="flex-none">
                    <Button icon="pi pi-plus" label="Νέο μήνυμα" class="mr-2 mb-2" @click="addNotification" />
                </div>
            </div>
        </template>
        <template #empty> Δεν βρέθηκαν μηνύματα </template>
        <Column header="ID" field="id" style="min-width: 2rem">
            <template #body="{ data }">
                {{ data.id }}
            </template>
        </Column>
        <Column field="title" filter-field="title" sort-field="title" header="Τίτλος" body-class="text-left" style="min-width: 8rem" :sortable="true">
            <template #body="{ data }">
                <span>{{ data.title }}</span>
            </template>
        </Column>
        <Column field="runAt" filter-field="runAt" sort-field="runAt" header="Ημερομηνία" body-class="text-left" style="min-width: 8rem" :sortable="true">
            <template #body="{ data }">
                <span>{{ data.runAt }}</span>
            </template>
        </Column>
        <Column field="status" filter-field="status" sort-field="status" header="Κατάσταση" body-class="text-left" style="min-width: 8rem" :sortable="true">
            <template #body="slotProps">
                <span class="notification-status-badge" :class="`status-${slotProps.data.status}`">{{ Utils.NotificationStatusToString(slotProps.data.status) }}</span>
            </template>
        </Column>
        <Column field="recipientReadDate" filter-field="recipientReadDate" sort-field="recipientReadDate" header="Διαβάστηκε" body-class="text-left" style="min-width: 8rem" :sortable="true">
            <template #body="slotProps">
                <i class="pi" :class="{ 'true-icon pi-check-circle': slotProps.data.recipientReadDate != null, 'false-icon pi-times-circle': slotProps.data.recipientReadDate == null }"></i>
                <!-- <span v-if="slotProps.data.recipientReadDate != null" style="margin-left: 15px" class="dues-badge" :class="`status-negative`">{{ slotProps.data.recipientReadDate }}</span> -->
            </template>
        </Column>
        <Column header-style="width: 4rem; text-align: center" body-style="text-align: center; overflow: visible">
            <template #body="{ data }">
                <Button v-if="data.status === 1" type="button" icon="pi pi-eye" class="p-button-text" @click="showNotificationDialog(data)"></Button>
            </template>
        </Column>
        <Column header-style="width: 4rem; text-align: center" body-style="text-align: center; overflow: visible">
            <template #body="{ data }">
                <Button type="button" icon="pi pi-copy" class="p-button-text" @click="cloneNotification(data)"></Button>
            </template>
        </Column>
        <Column header-style="width: 4rem; text-align: center" body-style="text-align: center; overflow: visible">
            <template #body="{ data }">
                <Button :disabled="data.status === 1" type="button" icon="pi pi-pencil" class="p-button-text" @click="editNotification(data)"></Button>
            </template>
        </Column>
        <Column header-style="width: 4rem; text-align: center" body-style="text-align: center; overflow: visible">
            <template #body="{ data }">
                <Button :disabled="data.status === 1" type="button" icon="pi pi-trash" class="p-button-text p-button-danger" @click="showDeleteNotification(data.id)"></Button>
            </template>
        </Column>
    </DataTable>
</template>

<script setup>
import moment from 'moment-timezone';
import { onMounted, reactive, ref, computed, watchEffect, toRefs, nextTick } from 'vue';
import { required, integer, helpers } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import useRPC from '@/composables/useRPC';
import useTemplates from '@/composables/useTemplates';
import Utils from '@/utils/Utils';
import { useDebounceFn } from '@vueuse/core';
const { RPCApi, RPCMethods } = useRPC();
const headerTitle = ref('');
const loading = ref(false);
const emit = defineEmits(['addNotification', 'editNotification', 'cloneNotification', 'deleteNotification', 'showNotification']);
const totalNotifications = ref(0);
const loadingNotifications = ref(false);
const notifications = ref([]);
const notificationsRowsPerPage = ref(10);

const fetchNotifications = async (userId) => {
    console.log('fetchNotifications' + userId);
    loadingNotifications.value = true;
    let res = await RPCApi(RPCMethods.Notifications.FetchInboxForUser, { id: userId });
    if (res.status == 'ok') {
        const notificationsData = res.data.result.data;
        let d = [];

        for (let obj of notificationsData) {
            d.push({
                id: obj.id,
                title: obj.title,
                type: obj.type,
                runAt: moment(Utils.datetimeToDate(obj.runAt)).format('DD/MM/YYYY') + ' - ' + moment(Utils.datetimeToDate(obj.runAt)).format('HH:mm'),
                status: obj.status,
                recipientReadDate: obj.recipientReadDate
                    ? moment(Utils.datetimeToDate(obj.recipientReadDate)).format('DD/MM/YYYY') + ' - ' + moment(Utils.datetimeToDate(obj.recipientReadDate)).format('HH:mm')
                    : null,
            });
        }
        notifications.value = d;
        totalNotifications.value = d.length;
    }
    console.log(notifications.value);
    loadingNotifications.value = false;
};

const addNotification = () => {
    console.log('ADD NOTIFICATION FOR USER');
    emit('addNotification');
};

const editNotification = (data) => {
    console.log('EDIT NOTIFICATION :', data);
    emit('editNotification', data);
};

const cloneNotification = (data) => {
    emit('cloneNotification', data);
};

const showNotificationDialog = (data) => {
    emit('showNotification', data);
};

const showDeleteNotification = (id) => {
    console.log('SHOW DELETE NOTIFICATION:' + id);
    emit('deleteNotification', id);
};

defineExpose({
    fetchNotifications,
});
</script>
