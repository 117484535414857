<template>
    <Dialog
        v-model:visible="dialogShown"
        class="p-fluid"
        :close-on-escape="false"
        :dismissable-mask="false"
        :modal="true"
        :breakpoints="{ '960px': '75vw', '640px': '95vw' }"
        :style="{ width: '60vw' }"
    >
        <template #header>
            <h4>{{ headerTitle }}</h4>
        </template>
        <div class="col-12">
            <DataTable
                ref="attendancesTable"
                :total-records="totalAttendances"
                :loading="loading"
                :row-hover="true"
                :value="attendances"
                data-key="id"
                responsive-layout="scroll"
                :rows="rowsPerPage"
                :paginator="true"
            >
                <template #empty> Δεν βρέθηκαν παρουσίες. </template>
                <Column field="classroomName" header="Αίθουσα" body-class="text-left" style="min-width: 8rem">
                    <template #body="{ data }">
                        <span>{{ data.classroomName }}</span>
                    </template>
                </Column>
                <Column field="datetime" sort-field="datetime" header="Ημέρα" body-class="text-left" style="min-width: 8rem">
                    <template #body="{ data }">
                        <span>{{ data.datetime }}</span>
                    </template>
                </Column>
                <Column field="hours" sort-field="hours" header="Διάρκεια" body-class="text-left" style="min-width: 8rem">
                    <template #body="{ data }">
                        <span>{{ data.hours }}</span>
                    </template>
                </Column>
            </DataTable>
        </div>
    </Dialog>
</template>

<script setup>
import moment from 'moment-timezone';
import { useToast } from 'primevue/usetoast';
import { onMounted, reactive, ref, computed, watchEffect, toRefs } from 'vue';
import { required, integer } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import useRPC from '@/composables/useRPC';
import Utils from '@/utils/Utils';
const { RPCApi, RPCMethods } = useRPC();
const dialogShown = ref(false);
const submitted = ref(false);
const headerTitle = ref('');
const attendancesTable = ref(null);
const totalAttendances = ref(0);
const loading = ref(false);
const attendances = ref([]);
const rowsPerPage = ref(10);
const state = reactive({
    id: null,
});
const closeCB = ref(null);

const show = async (data, onCloseCB) => {
    let { userID, classID, username } = data;
    closeCB.value = onCloseCB;
    loading.value = true;
    dialogShown.value = true;
    submitted.value = false;
    headerTitle.value = `Ιστορικό παρουσιών (${username})`;
    let arr = [];
    let attendancesRes = await RPCApi(RPCMethods.Attendances.FetchClassAttendancesForUser, { classID, userID });
    if (attendancesRes.status == 'ok') {
        let data = attendancesRes.data.result.data;
        for (let obj of data) {
            arr.push({
                id: obj.id,
                classroomName: obj.classroomName,
                datetime: moment(Utils.datetimeToDate(obj.startDate)).format('DD/MM/YYYY'),
                hours: moment(Utils.datetimeToDate(obj.startDate)).format('HH:mm') + ' - ' + moment(Utils.datetimeToDate(obj.endDate)).format('HH:mm'),
            });
        }
        attendances.value = arr;
    }
    console.log(attendancesRes);
    loading.value = false;
};

const onHide = (action = false) => {
    dialogShown.value = false;
    closeCB.value(action);
};

defineExpose({
    show,
});
</script>
