<template>
    <Dialog v-model:visible="dialogShown" :close-on-escape="false" :dismissable-mask="false" :modal="true" :breakpoints="{ '960px': '75vw', '640px': '95vw' }" :style="{ width: '45vw' }">
        <template #header>
            <div class="flex flex-row justify-items-center align-items-center">
                <h4 style="margin: 0">Νέα Αίτηση</h4>
            </div>
        </template>
        <div class="formgrid grid p-fluid">
            <div class="col-12">
                <div class="field">
                    <label for="body" :class="{ 'p-error': v$.body.$invalid && submitted }">Κείμενο*</label>
                    <Textarea
                        id="body"
                        v-model="v$.body.$model"
                        autocomplete="off"
                        spellcheck="off"
                        :class="{ 'p-invalid': v$.body.$invalid && submitted }"
                        placeholder="Περιγράψτε το μάθημα που θα θέλατε να παρακολουθήσετε"
                        rows="5"
                    />
                    <small v-if="(v$.body.$invalid && submitted) || v$.body.$pending.$response" class="p-error">{{ v$.body.required.$message }}</small>
                </div>
            </div>
        </div>

        <template #footer>
            <Button label="Άκυρο" icon="pi pi-times" class="p-button-outlined w-8rem mr-3" @click="onHide(false)" />
            <Button label="Αποθήκευση" icon="pi pi-check" class="p-button-primary w-10rem" :loading="loading" @click="handleSubmit(!v$.$invalid)" />
        </template>
    </Dialog>
</template>

<script setup>
import { useToast } from 'primevue/usetoast';
import { onMounted, reactive, ref, computed, watch } from 'vue';
import { required, integer, email } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import { useDebounceFn } from '@vueuse/core';
import useRPC from '@/composables/useRPC';
import Utils from '@/utils/Utils';
import moment from 'moment-timezone';
import { usePopupStore } from '@/stores/PopupStore';
import Textarea from 'primevue/textarea';
import InputText from 'primevue/inputtext';
const { RPCApi, RPCMethods } = useRPC();
const dialogShown = ref(false);
const submitted = ref(false);
const headerTitle = ref('');
const popupStore = usePopupStore();
const closeCB = ref(null);

const rules = {
    body: { required },
};

const state = reactive({
    body: null,
});

const v$ = useVuelidate(rules, state, { $stopPropagation: true });
const loading = ref(false);
const toast = useToast();

const show = async (data, onCloseCB) => {
    closeCB.value = onCloseCB;
    console.warn('data', data);
    dialogShown.value = true;
    submitted.value = false;
};

const onHide = (action = false) => {
    dialogShown.value = false;
    state.body = null;
    closeCB.value(action);
};

const handleSubmit = async (isFormValid) => {
    console.log('isFormValid??' + isFormValid);
    submitted.value = true;

    if (!isFormValid) return;

    console.log('ok submit');

    loading.value = true;

    let res = await RPCApi(RPCMethods.Request.Add, {
        userId: Utils.UserInfo.id,
        studentComments: state.body,
        dateCreated: Date.now(),
        status: Utils.RequestStatus.Waiting,
    });

    if (res.status !== 'ok') {
        if (res.status === 'user_has_already_request_in_class') {
            toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Ο χρήστης έχει ήδη αίτηση σε αυτό το τμήμα!', life: 3000 });
        } else {
            toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Παρακαλώ προσπαθήστε αργότερα', life: 3000 });
        }

        loading.value = false;
        return;
    }
    toast.add({ severity: 'success', summary: 'Η αίτησή σας καταχωρήθηκε με επιτυχία', detail: 'Επιτυχής διαδικασία!', life: 3000 });

    loading.value = false;
    onHide(true);
};

defineExpose({
    show,
});
</script>
