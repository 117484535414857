<template>
    <VueDraggableNext
        v-if="videos.length > 0 && isAdminOrOffice"
        :list="videos"
        item-key="id"
        :animation="200"
        ghost-class="ghost-class"
        handle=".video-header"
        class="video-grid"
        :class="{ isAdmin }"
        @change="updateVideoOrder"
    >
        <div v-for="video in videos" :key="video.id">
            <div class="video-tile">
                <div class="video-header">
                    <h3 class="video-title">{{ video.recordingTitle }}</h3>
                    <div v-if="isAdminOrOffice" class="video-actions">
                        <button v-if="video.referer_lesson_id == null" class="action-button clone-button" @click.stop="openCloneDialog(video)">
                            <i class="pi pi-copy"></i>
                        </button>
                        <button v-if="isAdmin" class="action-button delete-button" @click.stop="confirmDelete(video)">
                            <i class="pi pi-trash"></i>
                        </button>
                    </div>
                </div>
                <div class="video-player">
                    <div class="thumbnail-container" @click="openVideoPopup(video)">
                        <img :src="video.thumbnailUrl" :alt="video.recordingTitle" class="video-thumbnail" />
                        <div class="play-overlay">
                            <i class="pi pi-play"></i>
                        </div>
                    </div>
                </div>
                <div class="video-info">
                    <dl class="video-details">
                        <div class="detail-item">
                            <dt>Ημερομηνία:</dt>
                            <dd>{{ moment(Utils.datetimeToDate(video.startedAt)).format('DD/MM/YYYY') }}</dd>
                        </div>
                        <div class="detail-item">
                            <dt>Διάρκεια:</dt>
                            <dd>{{ Utils.toHoursAndMinutes(video.duration) }}</dd>
                        </div>
                        <div class="detail-item">
                            <dt>Περιγραφή:</dt>
                            <dd>{{ video.description || 'Χωρίς περιγραφή' }}</dd>
                        </div>
                    </dl>
                    <div v-if="isAdminOrOffice" class="video-stats">
                        <div v-for="(stat, i) in video.stats" :key="i" class="stat-item">
                            <span>{{ stat.studentName }}:</span>
                            <span>{{ stat.views }} θεάσεις</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </VueDraggableNext>
    <div v-else-if="videos.length > 0" class="video-grid">
        <div v-for="video in videos" :key="video.id" class="video-tile">
            <div class="video-header">
                <h3 class="video-title">{{ video.recordingTitle }}</h3>
                <div v-if="isAdminOrOffice" class="video-actions">
                    <button v-if="video.referer_lesson_id == null" class="action-button clone-button" @click.stop="openCloneDialog(video)">
                        <i class="pi pi-copy"></i>
                    </button>
                    <button class="action-button delete-button" @click.stop="confirmDelete(video)">
                        <i class="pi pi-trash"></i>
                    </button>
                </div>
            </div>
            <div class="video-player">
                <div class="thumbnail-container" @click="openVideoPopup(video)">
                    <img :src="video.thumbnailUrl" :alt="video.recordingTitle" class="video-thumbnail" />
                    <div class="play-overlay">
                        <i class="pi pi-play"></i>
                    </div>
                </div>
            </div>
            <div class="video-info">
                <dl class="video-details">
                    <div class="detail-item">
                        <dt>Ημερομηνία:</dt>
                        <dd>{{ moment(Utils.datetimeToDate(video.startedAt)).format('DD/MM/YYYY') }}</dd>
                    </div>
                    <div class="detail-item">
                        <dt>Διάρκεια:</dt>
                        <dd>{{ Utils.toHoursAndMinutes(video.duration) }}</dd>
                    </div>
                    <div class="detail-item">
                        <dt>Περιγραφή:</dt>
                        <dd>{{ video.description || 'Χωρίς περιγραφή' }}</dd>
                    </div>
                </dl>
                <div v-if="isAdminOrOffice" class="video-stats">
                    <div v-for="(stat, i) in video.stats" :key="i" class="stat-item">
                        <span>{{ stat.studentName }}:</span>
                        <span>{{ stat.views }} θεάσεις</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else-if="!loading" class="empty-message">
        <Vue3Lottie :animationData="NoEntries" :loop="true" :autoPlay="true" style="width: 30rem" />
    </div>

    <div v-else class="loader-container">
        <Vue3Lottie :animationData="loaderCircle" :loop="true" :autoPlay="true" style="width: 100%" />
    </div>

    <Dialog v-model:visible="cloneDialogVisible" header="Clone Video" :style="{ width: '350px' }">
        <div class="p-fluid">
            <div class="p-field">
                <label for="targetClassId">Target Class ID</label>
                <InputText id="targetClassId" v-model="targetClassId" required autofocus />
            </div>
        </div>
        <template #footer>
            <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="closeCloneDialog" />
            <Button label="Clone" icon="pi pi-check" autofocus @click="cloneVideo" />
        </template>
    </Dialog>
</template>

<script setup>
import { useConfirm } from 'primevue/useconfirm';
import { ref, onMounted, computed, onUnmounted, nextTick, watch } from 'vue';
import useFirebase from '@/composables/useFirebase';
import logo from '@/assets/images/logo.png';
import Utils from '@/utils/Utils';
import useRPC from '@/composables/useRPC';
import moment from 'moment-timezone';
import { usePopupStore } from '@/stores/PopupStore';
import { useToast } from 'primevue/usetoast';
import NoEntries from '@/assets/lottie/empty_animation.json';
import loaderCircle from '@/assets/lottie/loaderCircle.json';
import { VueDraggableNext } from 'vue-draggable-next';

const watchedVideos = ref({});
const toast = useToast();
const classId = ref(null);
const rowsPerPage = ref(10);
const videos = ref([]);
const totalVideos = ref(0);
const loading = ref(false);
const confirm = useConfirm();
const { RPCApi, RPCMethods } = useRPC();
const popupStore = usePopupStore();
const showFirstVideoOnly = ref(false);
const isAdmin = computed(() => {
    return Utils.UserInfo.role === Utils.Roles.Admin;
});

const cloneDialogVisible = ref(false);
const targetClassId = ref('');
const selectedVideo = ref(null);

const props = defineProps({
    showFirstOnly: {
        type: Boolean,
        default: false,
    },
});

const isOffice = computed(() => {
    return Utils.UserInfo.role === Utils.Roles.Office;
});

const isStudent = computed(() => {
    return Utils.UserInfo.role === Utils.Roles.Student;
});

const isTeacher = computed(() => {
    return Utils.UserInfo.role === Utils.Roles.Teacher;
});

const isAdminOrOffice = computed(() => {
    return isAdmin.value || isOffice.value;
});

const updateVideoOrder = async () => {
    try {
        const videoIds = videos.value.map((video) => video.id);
        console.log('videoIds:::', videoIds);

        // Update the titles based on new positions
        videos.value.forEach((video, index) => {
            video.recordingTitle = `Μάθημα ${index + 1}`;
        });

        const res = await RPCApi(RPCMethods.Lessons.UpdateVideoOrder, { videoIds });
        if (res.status === 'ok') {
            toast.add({ severity: 'success', summary: 'Success', detail: 'Video order updated successfully', life: 3000 });
        } else {
            throw new Error(res.message || 'Failed to update video order');
        }
    } catch (error) {
        console.error('Error updating video order:', error);
        toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to update video order', life: 3000 });
    }
};

const openCloneDialog = (video) => {
    console.log('openCloneDialog::', video);
    selectedVideo.value = video;
    cloneDialogVisible.value = true;
};

const closeCloneDialog = () => {
    cloneDialogVisible.value = false;
    targetClassId.value = '';
    selectedVideo.value = null;
};

const cloneVideo = async () => {
    if (!targetClassId.value) {
        toast.add({ severity: 'error', summary: 'Error', detail: 'Please enter a target class ID', life: 3000 });
        return;
    }

    try {
        const res = await RPCApi(RPCMethods.Lessons.CloneVideoForClass, {
            id: selectedVideo.value.id,
            targetClassId: targetClassId.value,
        });

        if (res.status === 'ok') {
            toast.add({ severity: 'success', summary: 'Success', detail: 'Video cloned successfully', life: 3000 });
            closeCloneDialog();
        } else {
            throw new Error(res.message || 'Failed to clone video');
        }
    } catch (error) {
        console.error('Error cloning video:', error);
        toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to clone video', life: 3000 });
    }
};

const confirmDelete = (video) => {
    confirm.require({
        message: 'Are you sure you want to delete this video?',
        header: 'Delete Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => deleteVideo(video),
        reject: () => {
            /* optional reject callback */
        },
    });
};

const deleteVideo = async (video) => {
    try {
        const res = await RPCApi(RPCMethods.Lessons.DeleteVideoForClass, { id: video.id });
        if (res.status === 'ok') {
            videos.value = videos.value.filter((v) => v.id !== video.id);
            toast.add({ severity: 'success', summary: 'Success', detail: 'Video deleted successfully', life: 3000 });
        } else {
            throw new Error(res.message || 'Failed to delete video');
        }
    } catch (error) {
        console.error('Error deleting video:', error);
        toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to delete video', life: 3000 });
    }
};

const fetchVideos = async (classID, onlyFirstVideo) => {
    console.log('fetchVideos::' + classID);
    classId.value = classID;
    showFirstVideoOnly.value = onlyFirstVideo;
    loading.value = true;
    try {
        let res = await RPCApi(RPCMethods.Lessons.FetchRecordedVideosForClass, { classID });
        console.log(res);
        if (res.status === 'ok') {
            let recordings = res.data.result.data;
            let arr = [];
            let index = 1;
            for (let rec of recordings) {
                const { id, class_id, description, lesson, library_id, video_id, title, stats, referer_lesson_id, referer_class_id } = rec;
                let startedAt = lesson.startedAt;
                let endedAt = lesson.endedAt;
                let duration = moment.duration(moment(endedAt).diff(moment(startedAt))).asHours();
                let bunnyStreamUrl = `https://iframe.mediadelivery.net/embed/${library_id}/${video_id}?autoplay=false&loop=false&muted=false&preload=false&responsive=true`;
                let thumbnailUrl = `https://vz-878d80b9-7ad.b-cdn.net/${video_id}/thumbnail.jpg`;

                let recordingTitle = lesson ? 'Μάθημα ' + index : title;

                arr.push({ id, recordingTitle, bunnyStreamUrl, thumbnailUrl, startedAt, duration, stats, description, lesson, referer_lesson_id, referer_class_id });

                index++;
            }

            if (showFirstVideoOnly.value && arr.length > 0) {
                videos.value = arr
                    .sort((a, b) => {
                        return Utils.datetimeToDate(a.startedAt) > Utils.datetimeToDate(b.startedAt) ? 1 : -1;
                    })
                    .slice(0, 1);
            } else {
                videos.value = arr;
            }
        }

        loading.value = false;
    } catch (err) {
        console.error(err);
    } finally {
        loading.value = false;
    }
};

const openVideoPopup = (video) => {
    const popupWidth = 1280; // 16:9 aspect ratio width
    const popupHeight = 720; // 16:9 aspect ratio height
    const left = (window.screen.width - popupWidth) / 2;
    const top = (window.screen.height - popupHeight) / 2;

    const popupWindow = window.open('', 'VideoPlayer', `width=${popupWidth},height=${popupHeight},left=${left},top=${top},resizable=yes,scrollbars=no,toolbar=no,menubar=no,location=no,status=no`);

    if (popupWindow) {
        popupWindow.document.write(`
      <html>
        <head>
          <title>${video.recordingTitle}</title>
          <style>
            body, html { margin: 0; padding: 0; height: 100%; overflow: hidden; }
            iframe { width: 100%; height: 100%; border: 0; }
          </style>
        </head>
        <body>
          <iframe
            src="${video.bunnyStreamUrl}"
            allow="accelerometer;gyroscope;autoplay;encrypted-media;"
            allowfullscreen="true"
            id="videoIframe"
          ></iframe>
          <script type="text/javascript" src="//cdn.embed.ly/player-0.1.0.min.js"><\/script>
          <script>
            function initializePlayer() {
              var iframe = document.getElementById('videoIframe');
              var player = new playerjs.Player(iframe);
              
              player.on('ready', function() {
                console.log('player ready');
                player.on('play', function() {
                  console.log('Video started playing');
                  window.opener.postMessage({ type: 'videoStarted', videoId: '${video.id}' }, '*');
                });
              });
            }

            if (typeof playerjs !== 'undefined') {
              initializePlayer();
            } else {
              window.addEventListener('load', function() {
                if (typeof playerjs !== 'undefined') {
                  initializePlayer();
                } else {
                  console.error('Player.js library failed to load');
                }
              });
            }
          <\/script>
        </body>
      </html>
    `);
        popupWindow.document.close();

        // Listen for messages from the popup
        window.addEventListener('message', handleVideoMessage);

        // Add an interval to check if the popup window has been closed
        const checkClosedInterval = setInterval(() => {
            if (popupWindow.closed) {
                clearInterval(checkClosedInterval);
                // Reset the watched state for this video
                watchedVideos.value[video.id] = false;
                console.log(`Popup closed. Reset watched state for video ${video.id}`);
            }
        }, 1000); // Check every second
    } else {
        toast.add({ severity: 'error', summary: 'Error', detail: 'Please allow popups for this site to view the video.', life: 3000 });
    }
};

const handleVideoMessage = async (event) => {
    if (event.data.type === 'videoStarted') {
        const fileId = event.data.videoId;
        if (!watchedVideos.value[fileId]) {
            let res = await RPCApi(RPCMethods.User.WatchedRecordedVideo, { fileId, classId: classId.value });
            console.log('WatchedRecordedVideo res:::', res);
            if (res.status === 'ok') {
                watchedVideos.value[fileId] = true;
            }
        }
    }
};

onUnmounted(() => {
    window.removeEventListener('message', handleVideoMessage);
});

defineExpose({
    fetchVideos,
});
</script>

<style scoped>
.ghost-class {
    opacity: 0.5;
    background: #c8ebfb;
}

.video-header {
    cursor: default;
}

.isAdmin .video-header {
    cursor: move;
}

.video-actions {
    display: flex;
    gap: 0.5rem;
}

.action-button {
    background-color: transparent;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.action-button:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.action-button i {
    font-size: 1rem;
}

.clone-button i {
    color: #061155;
}

.delete-button i {
    color: #ff4d4d;
}

.video-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 2rem;
    padding: 2rem;
}

.video-tile {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
}

.video-tile:hover {
    transform: translateY(-5px);
}

.video-player {
    position: relative;
}

.video-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #f5f5f5;
}

.video-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0;
    flex-grow: 1;
}

.delete-button {
    background-color: transparent;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.delete-button:hover {
    background-color: rgba(255, 0, 0, 0.1);
}

.delete-button i {
    color: #ff4d4d;
    font-size: 1rem;
}

.video-player iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.video-info {
    background-color: #f5f5f5;
    padding: 1rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.video-details {
    margin: 0 0 1rem 0;
}

.detail-item {
    display: flex;
    margin-bottom: 0.5rem;
}

.detail-item dt {
    font-weight: bold;
    color: #555;
    width: 100px;
    flex-shrink: 0;
}

.detail-item dd {
    margin: 0;
}

.video-stats {
    border-top: 1px solid #e0e0e0;
    padding-top: 1rem;
    margin-top: auto;
}

.stat-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.25rem;
}

.empty-message {
    text-align: center;
    padding: 2rem;
    font-style: italic;
    color: #666;
}

.loader-container {
    position: fixed; /* or absolute */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7); /* semi-transparent white */
    z-index: 1000; /* high z-index to cover the whole screen */
}

.thumbnail-container {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.video-thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.thumbnail-container:hover .video-thumbnail {
    transform: scale(1.05);
}

.play-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.3s ease;
}

.thumbnail-container:hover .play-overlay {
    opacity: 1;
}

.play-overlay i {
    font-size: 3rem;
    color: white;
}
</style>
