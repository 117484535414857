<template>
    <router-view />

    <CourseRequestsDialog ref="courseRequestsDialog" />
    <ClassDialog ref="classDialog" />
    <TeacherDialog ref="teacherDialog" />
    <RequestDialog ref="requestDialog" />
    <NotificationDialog ref="notificationDialog" />
    <AttendancesDialog ref="attendancesDialog" />
    <ClassroomDialog ref="classroomDialog" />
    <StudentDialog ref="studentDialog" />
    <LessonDialog ref="lessonDialog" />
    <StudentAttendancesDialog ref="studentAttendancesDialog" />
    <OfferDialog ref="offerDialog" />
    <CourseDialog ref="courseDialog" />
    <PeriodDialog ref="periodDialog" />
    <SchoolDialog ref="schoolDialog" />
    <GeneralDueDialog ref="generalDueDialog" />
    <PaymentDialog ref="paymentDialog" />
    <InstallmentDialog ref="installmentDialog" />
    <CoursePeriodRequestsDialog ref="coursePeriodRequestsDialog" />
    <CoursePeriodClassesDialog ref="coursePeriodClassesDialog" />
    <OfficeDialog ref="officeDialog" />
    <TipsAnnouncementDialog ref="tipsAnnouncementDialog" />
    <ZoomVideoDialog ref="zoomVideoDialog" />
    <InvoiceDialog ref="invoiceDialog" />
    <PDFViewerDialog ref="pdfViewerDialog" />
    <AdminDialog ref="adminDialog" />
    <TodoDialog ref="todoDialog" />
    <TipsDialog ref="tipsDialog" />
    <InvoiceCreditDialog ref="invoiceCreditDialog" />
    <JobPostingsCreateDialog ref="jobPostingsCreateDialog" />
    <StudentTempRequestDialog ref="studentTempRequestDialog" />
    <JobPostingsTeacherDialog ref="jobPostingsTeacherDialog" />
    <JobPostingsEditDialog ref="jobPostingsEditDialog" />
    <VersionUpdateDialog ref="versionUpdateDialog" />
    <QuizDialog ref="quizDialog" />
    <QuizPlayDialog ref="quizPlayDialog" />

    <ConfirmDialog></ConfirmDialog>
    <Toast />
</template>

<script setup>
import { useMagicKeys } from '@vueuse/core';
import AttendancesDialog from '@/dialogs/AttendancesDialog.vue';
import RequestDialog from '@/dialogs/RequestDialog.vue';
import ClassDialog from '@/dialogs/ClassDialog.vue';
import TeacherDialog from '@/dialogs/TeacherDialog.vue';
import NotificationDialog from '@/dialogs/NotificationDialog.vue';
import StudentDialog from '@/dialogs/StudentDialog.vue';
import ClassroomDialog from '@/dialogs/ClassroomDialog.vue';
import CourseDialog from '@/dialogs/CourseDialog.vue';
import GeneralDueDialog from '@/dialogs/GeneralDueDialog.vue';
import OfferDialog from '@/dialogs/OfferDialog.vue';
import PaymentDialog from '@/dialogs/PaymentDialog.vue';
import InstallmentDialog from '@/dialogs/InstallmentDialog.vue';
import PeriodDialog from '@/dialogs/PeriodDialog.vue';
import SchoolDialog from '@/dialogs/SchoolDialog.vue';
import CourseRequestsDialog from '@/dialogs/CourseRequestsDialog.vue';
import StudentAttendancesDialog from '@/dialogs/StudentAttendancesDialog.vue';
import CoursePeriodRequestsDialog from '@/dialogs/CoursePeriodRequestsDialog.vue';
import CoursePeriodClassesDialog from '@/dialogs/CoursePeriodClassesDialog.vue';
import OfficeDialog from '@/dialogs/OfficeDialog.vue';
import TipsAnnouncementDialog from '@/dialogs/TipsAnnouncementDialog.vue';
import ZoomVideoDialog from '@/dialogs/ZoomVideoDialog.vue';
import LessonDialog from '@/dialogs/LessonDialog.vue';
import useRPC from '@/composables/useRPC';
import { watch, ref, computed, onBeforeUpdate, onMounted, nextTick, onUnmounted } from 'vue';
import Utils from './utils/Utils';
import useFirebase from '@/composables/useFirebase';
import { useRouter } from 'vue-router';
import VersionUpdateDialog from '@/dialogs/VersionUpdateDialog.vue';
import axios from 'axios';

import { usePopupStore } from '@/stores/PopupStore';
import useStorage from '@/composables/useStorage';
import PDFViewerDialog from './dialogs/PDFViewerDialog.vue';
import InvoiceDialog from './dialogs/InvoiceDialog.vue';
import AdminDialog from './dialogs/AdminDialog.vue';
import { useGlobalStore } from './stores/GlobalStore';
import TodoDialog from './dialogs/TodoDialog.vue';
import TipsDialog from './dialogs/TipsDialog.vue';
import InvoiceCreditDialog from './dialogs/InvoiceCreditDialog.vue';
import JobPostingsCreateDialog from './dialogs/JobPostingsCreateDialog.vue';
import JobPostingsTeacherDialog from './dialogs/JobPostingsTeacherDialog.vue';
import StudentTempRequestDialog from './dialogs/StudentTempRequestDialog.vue';
import JobPostingsEditDialog from './dialogs/JobPostingsEditDialog.vue';
import QuizDialog from './dialogs/QuizDialog.vue';
import QuizPlayDialog from './dialogs/QuizPlayDialog.vue';

import '../node_modules/@syncfusion/ej2-base/styles/material.css';
import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
import '../node_modules/@syncfusion/ej2-vue-richtexteditor/styles/material.css';
import { registerLicense } from '@syncfusion/ej2-base';

const { SetStorage, GetStorage, StorageKeys, ClearStorage } = useStorage();
const zIndex = ref(1);
const periodDialog = ref(null);
const installmentDialog = ref(null);
const paymentDialog = ref(null);
const offerDialog = ref(null);
const generalDueDialog = ref(null);
const courseDialog = ref(null);
const studentDialog = ref(null);
const lessonDialog = ref(null);
const courseRequestsDialog = ref(null);
const studentAttendancesDialog = ref(null);
const attendancesDialog = ref(null);
const requestDialog = ref(null);
const notificationDialog = ref(null);
const globalStore = useGlobalStore();

const classDialog = ref(null);
const teacherDialog = ref(null);
const classroomDialog = ref(null);
const schoolDialog = ref(null);
const coursePeriodRequestsDialog = ref(null);
const coursePeriodClassesDialog = ref(null);
const officeDialog = ref(null);
const router = useRouter();

const popupStore = usePopupStore();
const zoomVideoDialog = ref(null);
const pdfViewerDialog = ref(null);
const invoiceDialog = ref(null);
const { firebaseInit } = useFirebase();
const { RPCApi, RPCMethods, FetchSQLDBInfo } = useRPC();
const keys = useMagicKeys();
const shiftEscape = keys['Shift+Escape'];
const adminDialog = ref(null);
const todoDialog = ref(null);
const tipsDialog = ref(null);
const invoiceCreditDialog = ref(null);
const jobPostingsCreateDialog = ref(null);
const jobPostingsTeacherDialog = ref(null);
const studentTempRequestDialog = ref(null);
const jobPostingsEditDialog = ref(null);
const versionUpdateDialog = ref(null);
const quizDialog = ref(null);
const quizPlayDialog = ref(null);
const tipsAnnouncementDialog = ref(null);

registerLicense('ORg4AjUWIQA/Gnt2UFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5QdERjXHpZcnxdQGhZ');

watch(shiftEscape, (v) => {
    if (v) {
        console.log('Shift + Escape have been pressed');
        globalStore.togglePanicMode();
    }
});

const mobileMenuActive = ref(false);

onUnmounted(() => {
    popupStore.unregisterAll();
});

onMounted(async () => {
    await firebaseInit();
    await globalStore.init();
    await RPCApi(RPCMethods.User.FcmTokenUpdate, { fcm_token: GetStorage(StorageKeys.FcmToken) });

    let dbInfo = await FetchSQLDBInfo();
    globalStore.setDBInfo(dbInfo.data);

    popupStore.registerPopup(Utils.Popups.TodoDialog, todoDialog);
    popupStore.registerPopup(Utils.Popups.TipsDialog, tipsDialog);
    popupStore.registerPopup(Utils.Popups.ClassDialog, classDialog);
    popupStore.registerPopup(Utils.Popups.TeacherDialog, teacherDialog);
    popupStore.registerPopup(Utils.Popups.RequestDialog, requestDialog);
    popupStore.registerPopup(Utils.Popups.StudentDialog, studentDialog);
    popupStore.registerPopup(Utils.Popups.AttendancesDialog, attendancesDialog);
    popupStore.registerPopup(Utils.Popups.LessonDialog, lessonDialog);
    popupStore.registerPopup(Utils.Popups.NotificationDialog, notificationDialog);
    popupStore.registerPopup(Utils.Popups.StudentAttendancesDialog, studentAttendancesDialog);
    popupStore.registerPopup(Utils.Popups.OfferDialog, offerDialog);
    popupStore.registerPopup(Utils.Popups.GeneralDueDialog, generalDueDialog);
    popupStore.registerPopup(Utils.Popups.PaymentDialog, paymentDialog);
    popupStore.registerPopup(Utils.Popups.InstallmentDialog, installmentDialog);
    popupStore.registerPopup(Utils.Popups.ClassroomDialog, classroomDialog);
    popupStore.registerPopup(Utils.Popups.CourseDialog, courseDialog);
    popupStore.registerPopup(Utils.Popups.CourseRequestsDialog, courseRequestsDialog);
    popupStore.registerPopup(Utils.Popups.PeriodDialog, periodDialog);
    popupStore.registerPopup(Utils.Popups.SchoolDialog, schoolDialog);
    popupStore.registerPopup(Utils.Popups.CoursePeriodRequestsDialog, coursePeriodRequestsDialog);
    popupStore.registerPopup(Utils.Popups.CoursePeriodClassesDialog, coursePeriodClassesDialog);
    popupStore.registerPopup(Utils.Popups.OfficeDialog, officeDialog);
    popupStore.registerPopup(Utils.Popups.TipsAnnouncementDialog, tipsAnnouncementDialog);
    popupStore.registerPopup(Utils.Popups.VersionUpdateDialog, versionUpdateDialog);
    popupStore.registerPopup(Utils.Popups.ZoomVideoDialog, zoomVideoDialog);
    popupStore.registerPopup(Utils.Popups.PDFViewerDialog, pdfViewerDialog);
    popupStore.registerPopup(Utils.Popups.InvoiceDialog, invoiceDialog);
    popupStore.registerPopup(Utils.Popups.AdminDialog, adminDialog);
    popupStore.registerPopup(Utils.Popups.InvoiceCreditDialog, invoiceCreditDialog);
    popupStore.registerPopup(Utils.Popups.JobPostingsCreateDialog, jobPostingsCreateDialog);
    popupStore.registerPopup(Utils.Popups.JobPostingsTeacherDialog, jobPostingsTeacherDialog);
    popupStore.registerPopup(Utils.Popups.StudentTempRequestDialog, studentTempRequestDialog);
    popupStore.registerPopup(Utils.Popups.JobPostingsEditDialog, jobPostingsEditDialog);
    popupStore.registerPopup(Utils.Popups.QuizDialog, quizDialog);
    popupStore.registerPopup(Utils.Popups.QuizPlayDialog, quizPlayDialog);

    // let versionRes = await Utils.GetBackendVersion;
    // if (versionRes.status == 200) {
    //     console.log('Backend version: ', versionRes.data);
    //     console.log('Client version: ', import.meta.env.VITE_VERSION);
    //     if (import.meta.env.VITE_VERSION != versionRes.data) {
    //         popupStore.show(
    //             Utils.Popups.VersionUpdateDialog,
    //             () => {
    //                 location.reload(true);
    //             },
    //             versionRes.data
    //         );
    //     }
    // }
});

const removeClass = (element, className) => {
    if (element.classList) element.classList.remove(className);
    else element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
};

const addClass = (element, className) => {
    if (element.classList) element.classList.add(className);
    else element.className += ' ' + className;
};

onBeforeUpdate(() => {
    if (mobileMenuActive.value) addClass(document.body, 'body-overflow-hidden');
    else removeClass(document.body, 'body-overflow-hidden');
});
</script>

<style>
.vac-media-preview {
    z-index: 100000 !important;
}
</style>
