import logo from '@/assets/images/LogoFullDark.png';
import useRPC from '@/composables/useRPC';
import pdfMake from 'pdfmake/build/pdfmake.js';
import Utils from '@/utils/Utils';
import { vfs } from '@/assets/fonts/vfs_fonts.js';

pdfMake.vfs = vfs;

const { RPCApi, RPCMethods } = useRPC();

const company = {
    row1: 'ΠΑΝΕΠΙΣΤΗΜΙΑΚΑ ΦΡΟΝΤΙΣΤΗΡΙΑ ΒΙΤΑΛΗ',
    row2: "ΑΦΜ: 998094229, ΔΟΥ: Δ' Αθηνών",
    row3: 'Αρ. ΓΕΜΗ: 69927603000',
    row4: 'Νίκης 2 & Καραγεώργη Σερβίας, ΑΘΗΝΑ 10563 ΣΥΝΤΑΓΜΑ, Ελλάδα',
    row5: 'Τηλ: 2103001299',
};

async function convertImageToDataURL(logo) {
    const response = await fetch(logo);
    const blob = await response.blob();
    const fileReader = new FileReader();
    return new Promise((resolve, reject) => {
        fileReader.onerror = () => {
            fileReader.abort();
            reject(new DOMException('Problem parsing input file.'));
        };
        fileReader.onload = () => {
            resolve(fileReader.result);
        };
        fileReader.readAsDataURL(blob);
    });
}

export default async function generateInvoicePDF(data) {
    console.warn('data:', data);
    const logoRes = await convertImageToDataURL(logo);
    const toUserInfoRes = await RPCApi(RPCMethods.User.FetchShortInfo, { user_id: data.userId });
    if (toUserInfoRes.status != 'ok') {
        console.error(toUserInfoRes);
        return;
    }

    let userInfo = toUserInfoRes.data.result.data[0];

    const invoice = {
        toUser: { ...userInfo },
        items: [{ description: 'Δίδακτρα μαθήματος', quantity: 1, price: data.amount, total: data.amount }],
        subtotal: '0',
        paid: data.amount,
        invoiceNumber: data.elorusSequenceNumber,
        logo: logoRes,
        date: data.scheduledAt,
    };

    // Specify the document definition
    const docDefinition = {
        content: [
            {
                columns: [
                    [
                        {
                            image: invoice.logo,
                            width: 180,
                        },
                    ],
                    [
                        {
                            text: data.typeName,
                            fontSize: 16,
                            color: '#4487D4',
                            bold: true,
                            alignment: 'right',
                            margin: [0, 0, 0, 20],
                        },
                        {
                            text: `${data.typeName} #` + invoice.invoiceNumber,
                            fontSize: 12,
                            bold: false,
                            alignment: 'right',
                        },
                        {
                            text: 'ΗΜΕΡΟΜΗΝΙΑ: ' + invoice.date,
                            fontSize: 12,
                            bold: false,
                            alignment: 'right',
                        },
                    ],
                ],
            },
            {
                margin: [0, 50, 0, 0],
                columns: [
                    [
                        {
                            text: 'ΑΠΟ',
                            bold: true,
                            color: '#4487D4',
                            margin: [0, 0, 0, 10],
                            fontSize: 16,
                        },
                        { text: company.row1, fontSize: 10, bold: true, margin: [0, 0, 0, 10] },
                        { text: company.row2, fontSize: 10 },
                        { text: company.row3, fontSize: 10 },
                        { text: company.row4, fontSize: 10 },
                        { text: company.row5, fontSize: 10 },
                    ],
                    [
                        {
                            text: 'ΠΕΛΑΤΗΣ',
                            bold: true,
                            color: '#4487D4',
                            margin: [0, 0, 0, 10],
                            fontSize: 16,
                        },
                        { text: invoice.toUser.username, fontSize: 10, bold: true, margin: [0, 0, 0, 10] },
                        { text: 'Πληροφορίες επικοινωνίας', fontSize: 10, bold: true },
                        { text: invoice.toUser.address, fontSize: 10 },
                        { text: 'Email: ' + invoice.toUser.email, fontSize: 10 },
                        { text: 'Τηλ: ' + invoice.toUser.mobile, fontSize: 10 },
                    ],
                ],
            },
            {
                margin: [0, 40, 0, 0],
                table: {
                    headerRows: 1,
                    widths: ['*', 'auto', 'auto', 'auto'],
                    body: [
                        [
                            { text: 'Είδος', style: 'tableHeader' },
                            { text: 'Ποσ.', style: 'tableHeader' },
                            { text: 'Τιμή μονάδας', style: 'tableHeader' },
                            { text: 'Καθαρή αξία', style: 'tableHeader' },
                        ],
                        ...invoice.items.map((item) => [item.description, item.quantity, Utils.toEUR(item.price), Utils.toEUR(item.total)]),
                    ],
                },
                layout: {
                    fillColor: function (i, node) {
                        return i === 0 ? '#E9EAEB' : null;
                    },
                },
            },
            {
                margin: [0, 50, 0, 0],
                columns: [
                    [
                        { text: 'Συνολική καθαρή αξία:', alignment: 'left', margin: [0, 0, 10, 10], fontSize: 12 },
                        { text: 'ΦΠΑ (0%):', alignment: 'left', fontSize: 12, margin: [0, 0, 10, 10] },
                        { text: 'Τελική αξία:', alignment: 'left', fontSize: 12, margin: [0, 0, 10, 10] },
                    ],
                    [
                        { text: Utils.toEUR(invoice.paid), bold: false, margin: [0, 0, 0, 10], fontSize: 12 },
                        { text: '0,00€', bold: false, margin: [0, 0, 0, 10], fontSize: 12 },
                        { text: Utils.toEUR(invoice.paid), bold: false, color: '#4487D4', margin: [0, 0, 0, 10], fontSize: 12 },
                    ],
                ],
            },
            {
                text: 'ΟΡΟΙ & ΠΡΟΫΠΟΘΕΣΕΙΣ',
                bold: true,
                margin: [0, 20, 0, 10],
            },
            {
                text: 'Απαλλαγή από ΦΠΑ σύμφωνα με το άρθρο 22.',
                fontSize: 12,
                margin: [0, 0, 0, 20],
            },
        ],
        styles: {
            tableHeader: {
                bold: true,
                fontSize: 12,
                color: 'black',
            },
        },
    };

    pdfMake.createPdf(docDefinition).download(`ΑΠΥ_${invoice.invoiceNumber}.pdf`);
}
