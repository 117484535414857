<template>
    <Dialog
        v-model:visible="dialogShown"
        class="p-fluid"
        :close-on-escape="false"
        :dismissable-mask="false"
        :modal="true"
        :breakpoints="{ '960px': '75vw', '640px': '95vw' }"
        :style="{ width: '50vw' }"
    >
        <template #header>
            <h4>{{ headerTitle }}</h4>
        </template>
        <div class="field">
            <label for="title" :class="{ 'p-error': v$.title.$invalid && submitted }">Τίτλος*</label>
            <InputText id="title" v-model="v$.title.$model" autocomplete="off" spellcheck="off" :class="{ 'p-invalid': v$.title.$invalid && submitted }" placeholder="Τίτλος" maxlength="100" />
            <small v-if="(v$.title.$invalid && submitted) || v$.title.$pending.$response" class="p-error">
                {{ v$.title.required.$message }}
            </small>
        </div>

        <div class="field">
            <label for="body" :class="{ 'p-error': v$.body.$invalid && submitted }">Κείμενο*</label>
            <SyncfusionRTE v-model="v$.body.$model" height="320" />
            <small v-if="(v$.body.$invalid && submitted) || v$.body.$pending.$response" class="p-error">{{ v$.body.required.$message }}</small>
        </div>

        <div class="field">
            <label for="description">Περιγραφή</label>
            <InputText id="description" v-model="state.description" autocomplete="off" spellcheck="off" placeholder="Περιγραφή" maxlength="120" />
        </div>

        <div class="field">
            <label for="audience" :class="{ 'p-error': v$.audience.$invalid && submitted }">Παραλήπτες*</label>
            <MultiSelect
                id="accountables"
                v-model="v$.audience.$model"
                :options="audienceOptions"
                optionLabel="label"
                :class="{ 'p-invalid': v$.audience.$invalid && submitted }"
                placeholder="Επιλέξτε παραλήπτες"
            />
            <small v-if="(v$.audience.$invalid && submitted) || v$.audience.$pending.$response" class="p-error">{{ v$.audience.required.$message }}</small>
        </div>
        <div class="field">
            <label for="priority" :class="{ 'p-error': v$.priority.$invalid && submitted }">Προτεραιότητα*</label>
            <Dropdown
                id="priority"
                v-model="v$.priority.$model"
                :options="priorityOptions"
                optionLabel="label"
                :class="{ 'p-invalid': v$.priority.$invalid && submitted }"
                placeholder="Επιλέξτε προτεραιότητα"
            />
            <small v-if="(v$.priority.$invalid && submitted) || v$.priority.$pending.$response" class="p-error">{{ v$.priority.required.$message }}</small>
        </div>
        <div class="field">
            <label for="type" :class="{ 'p-error': v$.priority.$invalid && submitted }">Τύπος*</label>
            <Dropdown
                id="type"
                v-model="v$.type.$model"
                :options="Utils.TipsTypesOptions"
                optionLabel="label"
                :class="{ 'p-invalid': v$.priority.$invalid && submitted }"
                placeholder="Επιλέξτε τύπο"
            />
            <small v-if="(v$.type.$invalid && submitted) || v$.type.$pending.$response" class="p-error">{{ v$.type.required.$message }}</small>
        </div>
        <div class="field">
            <label style="margin-bottom: 0px; padding-right: 5px" for="active" :class="{ 'p-error': v$.active.$invalid && submitted }">Ενεργό</label>
            <Checkbox id="active" v-model="v$.active.$model" :class="{ 'p-invalid': v$.active.$invalid && submitted }" :binary="true" />
            <small v-if="(v$.active.$invalid && submitted) || v$.active.$pending.$response" class="p-error">{{ v$.active.required.$message }}</small>
        </div>
        <template #footer>
            <div class="flex flex-row w-full justify-content-between pt-2">
                <Button label="Διαγραφή" icon="pi pi-trash" class="p-button-danger" @click="onDeleteTipDialog(state.id)" />
                <div class="flex flex-row gap-2">
                    <Button label="Προεπισκόπηση" icon="pi pi-eye" class="p-button-info" @click="onPreview" />
                    <Button label="Αποθήκευση" icon="pi pi-check" class="p-button-primary w-10rem" :loading="loading" @click="handleSubmit(!v$.$invalid)" />
                </div>
            </div>
        </template>
    </Dialog>
</template>

<script setup>
import Utils from '@/utils/Utils';
import { ref, reactive, computed } from 'vue';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { useToast } from 'primevue/usetoast';
import useRPC from '@/composables/useRPC';
import { usePopupStore } from '@/stores/PopupStore';
import SyncfusionRTE from '@/components/SyncfusionRTE.vue';
import { useConfirm } from 'primevue/useconfirm';

const confirm = useConfirm();
const { RPCApi, RPCMethods } = useRPC();
const dialogShown = ref(false);
const headerTitle = ref('Create/Edit Todo');
const audienceOptions = [
    { label: 'Μαθητές', value: 1 },
    { label: 'Καθηγητές', value: 2 },
    { label: 'Office', value: 4 },
];
const priorityOptions = [
    { label: 'Χαμηλή', value: 1 },
    { label: 'Μεσαία', value: 2 },
    { label: 'Υψηλή', value: 3 },
];
const loading = ref(false);
const toast = useToast();
const popupStore = usePopupStore();
const state = reactive({
    id: null,
    title: null,
    body: null,
    audience: null,
    priority: null,
    active: true,
    type: null,
    description: null,
});
const rules = {
    title: { required },
    body: { required },
    audience: { required },
    priority: { required },
    active: { required },
    type: { required },
};
const closeCB = ref(null);
const submitted = ref(false);
const editMode = ref(false);
const v$ = useVuelidate(rules, state);

const show = async (data, onCloseCB) => {
    const { id } = data || {};
    console.log(data);
    closeCB.value = onCloseCB;
    resetForm();
    dialogShown.value = true;
    submitted.value = false;
    editMode.value = !!id;
    headerTitle.value = editMode.value ? 'Τροποποίηση Tip' : 'Νέο Tip';

    if (editMode.value) {
        let tip = await RPCApi(RPCMethods.Tips.FetchById, { id });
        console.log(tip);
        if (tip.status === 'ok') {
            const { title, body, audience, priority, active, type, description } = tip.data.result.data;
            state.id = id;
            state.title = title;
            state.body = JSON.parse(body).text;
            state.audience = audienceOptions.filter((aud) => audience & aud.value);
            state.priority = priorityOptions.find((p) => p.value === priority);
            state.active = active === 1;
            state.type = Utils.TipsTypesOptions.find((t) => t.value === type);
            state.description = description;
        }
    }
};

const onHide = (action = false) => {
    dialogShown.value = false;
    resetForm();
    closeCB.value(action);
};

const handleSubmit = async (isFormValid) => {
    submitted.value = true;

    console.warn(state);

    if (!isFormValid) return;

    loading.value = true;
    let res;
    console.log(state);

    let tip = {
        title: state.title,
        body: JSON.stringify({ text: state.body }),
        audience: state.audience.reduce((acc, aud) => acc | aud.value, 0),
        priority: state.priority.value,
        active: state.active ? 1 : 0,
        type: state.type.value,
        description: state.description,
    };

    console.log(tip);

    if (editMode.value) {
        tip.id = state.id;
        res = await RPCApi(RPCMethods.Tips.Update, tip);
    } else {
        res = await RPCApi(RPCMethods.Tips.Add, tip);
    }

    if (res.status !== 'ok') {
        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Παρακαλώ προσπαθήστε αργότερα', life: 3000 });
        loading.value = false;
        return;
    }
    toast.add({ severity: 'success', summary: 'Η διαδικασία ολοκληρώθηκε', detail: 'Επιτυχής διαδικασία!', life: 3000 });

    loading.value = false;
    onHide(true);
};

const resetForm = () => {
    for (let field in state) state[field] = null;
    state.active = true;
    submitted.value = false;
};

const onPreview = () => {
    console.log('preview');
    popupStore.show(Utils.Popups.TipsAnnouncementDialog, { preview: { title: state.title, body: state.body, type: state.type.value } }, (action) => {});
};

const onDeleteTipDialog = (id) => {
    console.log('delete tip', id);
    confirm.require({
        message: 'Είστε σίγουροι ότι θέλετε να προχωρήσετε σε διαγραφή;',
        header: 'Επιβεβαίωση',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Διαγραφή',
        rejectLabel: 'Άκυρο',
        acceptClass: 'p-button-danger',
        rejectClass: 'p-button-secondary',
        accept: async () => {
            console.log('delete!');
            await RPCApi(RPCMethods.Tips.Delete, { id });
            onHide(true);
        },
        reject: () => {
            console.log('do nothing');
        },
    });
};

defineExpose({
    show,
});
</script>
