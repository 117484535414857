<!-- eslint-disable vue/no-v-html -->
<template>
    <Dialog
        v-model:visible="dialogShown"
        :close-on-escape="true"
        :dismissable-mask="true"
        :modal="true"
        :style="{ width: '60vw', height: '60vh' }"
        :closable="true"
        class="p-fluid flex flex-column"
        :pt="{
            root: {
                style: 'border: 0px',
            },
            content: {
                style: 'height: 100%',
            },
            header: headerStyle,
            closeButton: {
                style: {
                    position: 'absolute',
                    top: '0.5rem',
                    right: '0.5rem',
                    color: 'white',
                    'background-color': 'transparent',
                    outline: 'none',
                },
            },
            closeButtonIcon: {
                style: {
                    color: 'white',
                    width: '2rem',
                    height: '2rem',
                },
            },
        }"
    >
        <template #header>
            <div class="flex flex-row w-full gap-4" style="height: 100%">
                <div v-if="pickTitleIcon != null">
                    <img :src="pickTitleIcon" style="height: 100%" />
                </div>
                <span style="text-align: center" class="headerCls" v-html="tipTitle"></span>
            </div>
        </template>
        <div class="flex flex-column" style="height: 100%; padding-top: 1rem">
            <div v-if="isWelcome" style="padding-right: 80px">
                <img style="width: 60%; margin-left: auto; margin-right: auto; display: block" :src="welcome_illustration" alt="Welcome illustration" />
            </div>

            <div v-if="isWelcome" style="padding-bottom: 2rem"><b>&#10071;Πριν προχωρήσετε θα θέλαμε να σας ενημερώσουμε για τα ακόλουθα θέματα :</b></div>
            <div class="bodyCls" v-html="tipBodyFormat"></div>
        </div>

        <template #footer>
            <div class="flex flex-row w-full justify-content-center">
                <Button :label="nextBtnLbl" class="p-button-primary" size="large" :style="{ backgroundColor: headerStyle.style.split(';')[1].split(':')[1].trim() }" @click="onContinue" />
            </div>
        </template>
    </Dialog>
</template>

<script setup>
import welcome_illustration from '@/assets/images/welcome.svg';
import { onMounted, reactive, ref, computed, watch, toRefs } from 'vue';
import useRPC from '@/composables/useRPC';
import useStorage from '@/composables/useStorage';
import questionIcon from '@/assets/images/questionIcon.png';
import warningIcon from '@/assets/images/warningIcon.png';
import infoIcon from '@/assets/images/informationIcon.png';
import Utils from '@/utils/Utils';

const { SetStorage, GetStorage, StorageKeys } = useStorage();
const { RPCApi, RPCMethods } = useRPC();
const dialogShown = ref(false);
const submitted = ref(false);
const closeCB = ref(null);
const Signature = () => {
    return `
    <br />
    <p style="margin: 0px">--</p>
    <p style="margin: 0px">Ευχαριστούμε πολύ</p>
    <img src="https://vitali.gr/wp-content/uploads/2021/04/LOGO_237.png" style="max-width: 220px" />
    <br/><p style="margin: 0px"><b>Πανεπιστημιακά Φροντιστήρια - Διαγωνισμοί Δημοσίου</b></p>
    <p style="margin: 0px"><b>Τηλ. Επικοινωνίας</b> : 210-300.12.99</p>
    <p style="margin: 0px"><b>URL</b> : https://vitali.gr/</p>
    <p style="margin: 0px"><b>Facebook</b>: https://www.facebook.com/vitali.edu.gr</p>
    <p style="margin: 0px"><b>Instagram</b>: https://www.instagram.com/vitaliedugr/</p>
    <p style="margin: 0px"><b>Κεντρικό</b> : Ξενοφώντος 9, 2ος όροφος, Πλατεία Συντάγματος, Αθήνα (νέα διεύθυνση)</p>
    <p style="margin: 0px"><b>Παράρτημα Πειραιά</b> : Δεληγιώργη 106Α, Έναντι Πανεπιστημίου, Πειραιά</p>
   `;
};

const WelcomeBody = `<b>&#128110;Προσωπικά Δεδομένα και προστασία τους</b><br/>Τα προσωπικά σας δεδομένα τα οποία υπάρχουν στην καρτέλα σας είναι αυτά που μας έχετε δώσει και χρησιμοποιούνται αποκλειστικά και μόνο για την λειτουργία του συστήματος διαχείρισης ενώ είναι εμφανή μόνο στο διαβαθμισμένο προσωπικό του φροντιστηρίου.
    <br/><b>Σε καμία περίπτωση δεν δίνονται τα στοιχεία σας σε τρίτα πρόσωπα!</b>&#128586;&#9940;
<br/>
<br/>
<b>&#127910;Βιντεοσκόπηση των μαθημάτων (όπου αυτή εφαρμόζεται)</b><br/><b>Η βιντεοσκόπηση των μαθημάτων γίνεται μόνο για να χρησιμοποιηθεί από εσάς ως εργαλείο εκπαίδευσης&#128588; (παρέχεται η δυνατότητα επανάληψης ή αναπλήρωσης των χαμένων μαθημάτων).</b>.
<br/>Συγκεκριμένα η βιντεοσκόπηση των μαθημάτων γίνεται μόνο στην share screen του διδάσκοντα ενώ αντίθετα δεν καταγράφονται οι κάμερες των υπολογιστών&#128187; του διδάσκοντα ή των εκπαιδευόμενων. Οι ρυθμίσεις της βιντεοσκόπησης έχουν γίνει σύμφωνα με τις υποδείξεις της εταιρείας <b>Zoom</b> ώστε σε καμία περίπτωση να μην υπάρχει διαρροή προσωπικών δεδομένων και να είναι σύμφωνες με οποιαδήποτε νόμο&#128220; περί προστασίας προσωπικών δεδομένων.
<br/>Άρα ακόμα και στην περίπτωση που οι συμμετέχοντες σε ένα μάθημα έχουν ανοίξει τις κάμερες των υπολογιστών τους, το σύστημα καταγράφει μόνο το περιεχόμενο της share screen του διδάσκοντα και τον ήχο του μαθήματος ή στην περίπτωση που ο διδάσκων δεν έχει ενεργοποιήσει share screen καταγράφεται μόνο &#127911;ήχος&#127911;. Φυσικά αν κάποιος κάνει μια ερώτηση θα καταγραφεί ηχητικά.
<br/>Επίσης δεν καταγράφονται ούτε ονόματα συμμετεχόντων και ούτε το chat του τμήματος. Οι βιντεοσκοπήσεις των μαθημάτων βρίσκονται αποθηκευμένα σε server της εταιρείας <b>Zoom</b> και δεν μπορεί κανείς να τις "κατεβάσει"&#9940;&#128190;&#9940; στον υπολογιστή του. Η μόνη δυνατότητα είναι η προβολή των βιντεοσκοπήσεων μέσω της εφαρμογής Vitali WebApp και αυτή η δυνατότητα δίνεται αποκλειστικά και μόνο στους συμμετέχοντες σε ένα τμήμα και σε κανέναν άλλο.&#128175;
<b><br/>Τέλος μετά το πέρας των μαθημάτων και σε εύλογο χρονικό διάστημα (εφόσον παρέλθει και η εξέταση) τα βίντεο αυτά διαγράφονται οριστικά από τον server της εταιρείας <b>Zoom</b>.</b>&#128077;
<br/>
<br/>
<b>Επισημαίνεται η παράνομη χρήση του υλικού των μαθημάτων πέρα από τους χρήστες του συστήματος και η προσπάθεια παράνομης διάδοσής τους δεν είναι σύννομη και διώκεται ποινικώς&#128110;.</b>
<br/>
<br/>Για οποιαδήποτε διευκρίνιση σχετικά με την λειτουργία του συστήματος μην διστάσετε να επικοινωνήσετε μαζί μας.&#128129
<br/>
<br/>Επειδή το σύστημα αναπτύσσεται συνεχώς με μοναδικό σκοπό να βελτιώσει τις προσφερόμενες εκπαιδευτικές υπηρεσίες θα θέλαμε να μας στείλετε και δικές σας προτάσεις.&#128512;
<br/>
<br/>
<b>Και μην ξεχνάτε προσπαθούμε να εξασφαλίσουμε με τον καλύτερο τρόπο την δική σας επιτυχία!&#9989;</b>
<br/>
<br/>
${Signature()}
Εκπαιδευτικός Όμιλος ΒΙΤΑΛΗ`;

const WelcomeTitle = `&#127880;Καλώς ήρθατε στο νέο μας ψηφιακό εκπαιδευτήριο Vitali WebApp!&#127880;&#127881;&#127891;&#128218;`;

const tipTitle = ref('');
const tipBody = ref('');
const tipType = ref(null);
const isWelcome = ref(false);
const tips = ref([]);
const preview = ref(null);
const infoColor = '#6366f1';
const warningColor = '#EF4B4B';
const questionColor = '#528159';
const headerStyle = ref({ style: `height: 8rem; background-color: ${infoColor}; color: #fff` });

const tipBodyFormat = computed(() => {
    if (tipBody.value == null) return '';
    //replace all img tags with max-width: 100%
    let s = tipBody.value.replace(/<img/g, '<img style="max-width: 100%"');
    return s;
});

const pickTitleIcon = computed(() => {
    console.warn('tipType.value:' + tipType.value);
    if (tipType.value === Utils.TipsTypes.Warning) {
        return warningIcon;
    } else if (tipType.value === Utils.TipsTypes.Question) {
        return questionIcon;
    } else if (tipType.value === Utils.TipsTypes.Info) {
        return infoIcon;
    }

    return null;
});

watch(
    () => tipType.value,
    (newValue, oldValue) => {
        if (newValue === Utils.TipsTypes.Warning) {
            headerStyle.value.style = `height: 8rem; background-color: ${warningColor}; color: #fff`;
        } else if (newValue === Utils.TipsTypes.Question) {
            headerStyle.value.style = `height: 8rem; background-color: ${questionColor}; color: #fff`;
        } else if (newValue === Utils.TipsTypes.Info) {
            headerStyle.value.style = `height: 8rem; background-color: ${infoColor}; color: #fff`;
        } else {
            headerStyle.value.style = `height: 8rem; background-color: ${infoColor}; color: #fff`;
        }
    },
    { immediate: true }
);

const show = async (data, onCloseCB) => {
    console.log('TipsAnnouncementDialog', data);
    isWelcome.value = data && data.showWelcome;
    preview.value = data && data.preview;
    tips.value = data.tips || [];
    if (isWelcome.value) {
        tips.value = [{ title: WelcomeTitle, body: WelcomeBody, type: Utils.TipsTypes.Welcome }, ...tips.value];
    }

    closeCB.value = onCloseCB;
    dialogShown.value = true;
    submitted.value = false;

    if (preview.value) {
        tipTitle.value = preview.value.title;
        tipBody.value = preview.value.body;
        tipType.value = preview.value.type;
        return;
    }

    getNextTip();
};

const getNextTip = () => {
    console.log(tips.value.length);
    if (tips.value.length > 0) {
        let tip = tips.value.shift();
        tipTitle.value = tip.title;
        tipBody.value = isWelcome.value ? tip.body : JSON.parse(tip.body).text;
        tipType.value = tip.type;
    } else {
        onHide();
    }
};

const nextBtnLbl = computed(() => {
    return tips.value.length > 0 ? 'Επόμενο' : 'Κλείσιμο';
});

const onContinue = async () => {
    if (isWelcome.value) {
        isWelcome.value = false;
        SetStorage(StorageKeys.WelcomeShown, true);
    }
    getNextTip();
};

const onHide = (action = false) => {
    dialogShown.value = false;
    closeCB.value(action);
};

defineExpose({
    show,
});
</script>

<style scoped>
.headerCls {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 2.5rem;
}

.bodyCls {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.5rem;
    padding: 1rem;
}
</style>
