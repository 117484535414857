<template>
    <Dialog
        v-model:visible="dialogShown"
        class="p-fluid"
        :close-on-escape="false"
        :dismissable-mask="false"
        :modal="true"
        :breakpoints="{ '960px': '75vw', '640px': '95vw' }"
        :style="{ width: '50vw' }"
    >
        <template #header>
            <h4>{{ headerTitle }}</h4>
        </template>

        <div class="field">
            <label for="name" :class="{ 'p-error': v$.name.$invalid && submitted }">Κωδικός*</label>
            <InputText id="name" v-model="v$.name.$model" autocomplete="off" spellcheck="off" :class="{ 'p-invalid': v$.name.$invalid && submitted }" />
            <small v-if="(v$.name.$invalid && submitted) || v$.name.$pending.$response" class="p-error">{{ v$.name.required.$message }}</small>
        </div>
        <div class="field">
            <label for="area" :class="{ 'p-error': v$.area.$invalid && submitted }">Περιοχή*</label>
            <Dropdown id="area" v-model="v$.area.$model" :options="areas" option-label="area" :class="{ 'p-invalid': v$.area.$invalid && submitted }" />

            <small v-if="(v$.area.$invalid && submitted) || v$.area.$pending.$response" class="p-error">{{ v$.area.required.$message }}</small>
        </div>
        <div class="field">
            <label for="capacity" :class="{ 'p-error': v$.capacity.$invalid && submitted }">Χωρητικότητα*</label>
            <InputNumber id="capacity" v-model="v$.capacity.$model" locale="el-GR" :min="1" autocomplete="off" spellcheck="off" :class="{ 'p-invalid': v$.capacity.$invalid && submitted }" />
            <small v-if="(v$.capacity.$invalid && submitted) || v$.capacity.$pending.$response" class="p-error">{{ v$.capacity.required.$message }}</small>
        </div>
        <template #footer>
            <Button label="Άκυρο" icon="pi pi-times" class="p-button-outlined w-8rem mr-3" @click="onHide(false)" />
            <Button label="Αποθήκευση" icon="pi pi-check" class="p-button-primary w-10rem" :loading="loading" @click="handleSubmit(!v$.$invalid)" />
        </template>
    </Dialog>
</template>

<script setup>
import { useToast } from 'primevue/usetoast';
import { onMounted, reactive, ref, computed } from 'vue';
import { required, integer } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import useRPC from '@/composables/useRPC';
const { RPCApi, RPCMethods } = useRPC();
const dialogShown = ref(false);
const submitted = ref(false);
const headerTitle = ref('');
const state = reactive({
    id: null,
    name: '',
    area: '',
    capacity: 1,
});
const rules = {
    name: { required },
    area: { required },
    capacity: { required, integer },
};
const v$ = useVuelidate(rules, state);
const loading = ref(false);
const toast = useToast();
const areas = ref([]);
const editMode = ref(false);
const closeCB = ref(null);

const show = async (data, onCloseCB) => {
    const { classroom, classroomAreas } = data;
    closeCB.value = onCloseCB;
    console.log(classroom);
    console.log('classroomAreas:' + classroomAreas);
    areas.value = classroomAreas;
    editMode.value = !!classroom;
    state.id = classroom ? classroom.id : null;
    state.name = classroom ? classroom.name : '';
    state.area = classroom ? areas.value.find((area) => area.id === classroom.AreaID) : areas.value[0];
    state.capacity = classroom ? classroom.capacity : 1;
    headerTitle.value = editMode.value ? 'Τροποποίηση Αίθουσας' : 'Νέα Αίθουσα';
    dialogShown.value = true;
    submitted.value = false;
};

const onHide = (action = false) => {
    dialogShown.value = false;
    resetForm();
    closeCB.value(action);
};

const handleSubmit = async (isFormValid) => {
    submitted.value = true;

    if (!isFormValid) return;

    console.log('ok submit');
    loading.value = true;
    let res;
    if (editMode.value) {
        res = await RPCApi(RPCMethods.Classrooms.Update, {
            id: state.id,
            name: state.name,
            area: state.area.id,
            capacity: state.capacity,
        });
    } else {
        res = await RPCApi(RPCMethods.Classrooms.Add, {
            name: state.name,
            area: state.area.id,
            capacity: state.capacity,
        });
    }

    if (res.status !== 'ok') {
        toast.add({ severity: 'error', summary: res.statusText, detail: res.data, life: 3000 });
        loading.value = false;
        return;
    }
    toast.add({ severity: 'success', summary: 'Η διαδικασία ολοκληρώθηκε', detail: 'Επιτυχής διαδικασία!', life: 3000 });

    loading.value = false;
    onHide(true);
};
const resetForm = () => {
    state.id = null;
    state.name = '';
    state.area = '';
    state.capacity = 1;
    submitted.value = false;
    loading.value = false;
};

defineExpose({
    show,
});
</script>
