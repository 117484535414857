<template>
    <Dialog v-model:visible="dialogShown" :close-on-escape="false" :dismissable-mask="false" :modal="true" :breakpoints="{ '960px': '75vw', '640px': '95vw' }" :style="{ width: '95vw' }">
        <template #header>
            <div class="flex align-items-center">
                <Avatar :image="Utils.GetGenericAvatar(state.id)" class="flex align-items-center border-1 border-300 mt-0" size="medium" shape="circle" />
                <h4 class="flex align-items-center ml-2 mt-3">{{ headerTitle }}</h4>
            </div>
        </template>

        <TabMenu v-if="showTab" :model="tabItems" class="mb-4 fixed-tab-menu" />
        <div v-if="tabIndex === 0" class="formgrid grid p-fluid">
            <div class="col-6">
                <div class="field">
                    <label>Τελευταίο Login:</label>
                    <span style="padding-left: 10px">{{ lastLogin || '--' }}</span>
                </div>
                <div v-if="isAdmin" class="field-checkbox w-full">
                    <Checkbox id="active" v-model="state.active" :binary="true" />
                    <label for="active">Ενεργός</label>
                </div>
                <h5>Υποχρεωτικά πεδία</h5>
                <div class="field">
                    <label for="fname" :class="{ 'p-error': v$.fname.$invalid && submitted }">Όνομα*</label>
                    <InputText
                        id="fname"
                        v-model="v$.fname.$model"
                        :disabled="!isAdminOrOffice"
                        autocomplete="off"
                        spellcheck="off"
                        :class="{ 'p-invalid': v$.fname.$invalid && submitted }"
                        placeholder="Όνομα"
                    />
                    <small v-if="(v$.fname.$invalid && submitted) || v$.fname.$pending.$response" class="p-error">{{ v$.fname.required.$message }}</small>
                </div>

                <div class="field">
                    <label for="lname" :class="{ 'p-error': v$.lname.$invalid && submitted }">Επώνυμο*</label>
                    <InputText
                        id="lname"
                        v-model="v$.lname.$model"
                        :disabled="!isAdminOrOffice"
                        autocomplete="off"
                        spellcheck="off"
                        :class="{ 'p-invalid': v$.lname.$invalid && submitted }"
                        placeholder="Επώνυμο"
                    />
                    <small v-if="(v$.lname.$invalid && submitted) || v$.lname.$pending.$response" class="p-error">{{ v$.lname.required.$message }}</small>
                </div>

                <div class="field">
                    <label for="email" :class="{ 'p-error': v$.email.$invalid && submitted }">Email*</label>
                    <InputText
                        id="email"
                        v-model="v$.email.$model"
                        :disabled="!isAdminOrOffice"
                        autocomplete="off"
                        spellcheck="off"
                        :class="{ 'p-invalid': v$.email.$invalid && submitted }"
                        placeholder="Email"
                    />
                    <small v-if="(v$.email.$invalid && submitted) || v$.email.$pending.$response" class="p-error">{{ v$.email.required.$message }}</small>
                </div>

                <div class="field">
                    <label for="mobile" :class="{ 'p-error': v$.mobile.$invalid && submitted }">Κινητό*</label>
                    <InputText
                        id="mobile"
                        v-model="v$.mobile.$model"
                        :disabled="!isAdminOrOffice"
                        autocomplete="off"
                        spellcheck="off"
                        :class="{ 'p-invalid': v$.mobile.$invalid && submitted }"
                        placeholder="Κινητό"
                    />
                    <small v-if="(v$.mobile.$invalid && submitted) || v$.mobile.$pending.$response" class="p-error">{{ v$.mobile.required.$message }}</small>
                </div>
            </div>
            <div class="col-6">
                <h5>Προαιρετικά πεδία</h5>
                <div class="field">
                    <label for="phone">Σταθερό</label>
                    <InputText id="phone" v-model="state.phone" :disabled="!isAdminOrOffice" autocomplete="off" spellcheck="off" placeholder="Σταθερό" />
                </div>
                <div class="field">
                    <label for="afm">ΑΦΜ</label>
                    <InputText id="afm" v-model="state.afm" :disabled="!isAdminOrOffice" autocomplete="off" spellcheck="off" placeholder="ΑΦΜ" />
                </div>
                <div class="field">
                    <label for="birthday">Ημ/νία γέννησης</label>
                    <Calendar id="birthday" v-model="state.birthDate" :disabled="!isAdminOrOffice" date-format="dd/mm/yy" placeholder="dd/mm/yyyy" />
                </div>
                <div class="field">
                    <label for="gender">Φύλο</label>
                    <Dropdown id="gender" v-model="state.gender" :disabled="!isAdminOrOffice" :options="Utils.Genders" option-label="label" placeholder="Φύλο"></Dropdown>
                </div>
                <div class="field">
                    <label for="city">Πόλη</label>
                    <InputText id="city" v-model="state.city" :disabled="!isAdminOrOffice" autocomplete="off" spellcheck="off" placeholder="Πόλη" />
                </div>
                <div class="field">
                    <label for="address">Διεύθυνση</label>
                    <InputText id="address" v-model="state.address" :disabled="!isAdminOrOffice" autocomplete="off" spellcheck="off" placeholder="Διεύθυνση" />
                </div>
                <div class="field">
                    <label for="zipcode">Τ.Κ.</label>
                    <InputText id="zipcode" v-model="state.zipcode" :disabled="!isAdminOrOffice" autocomplete="off" spellcheck="off" placeholder="Τ.Κ." />
                </div>
            </div>
        </div>
        <div v-if="tabIndex === 1">
            <div class="field">
                <label>Σχόλια</label>
                <SyncfusionRTE v-model="state.comments" height="520" />
            </div>
            <div v-if="isAdmin" class="field">
                <label>Σχόλια admin</label>
                <SyncfusionRTE v-model="state.adminComments" height="300" />
            </div>
        </div>
        <template v-if="(tabIndex === 0 || tabIndex === 1) && isAdminOrOffice" #footer>
            <Button label="Άκυρο" icon="pi pi-times" class="p-button-outlined w-8rem mr-3" @click="onHide(false)" />
            <Button label="Αποθήκευση" icon="pi pi-check" class="p-button-primary w-10rem" :loading="loading" @click="handleSubmit(!v$.$invalid)" />
        </template>
    </Dialog>
</template>

<script setup>
import { useToast } from 'primevue/usetoast';
import { onMounted, reactive, ref, computed, watchEffect, toRefs, nextTick } from 'vue';
import { required, integer, email } from '@vuelidate/validators';
import { useConfirm } from 'primevue/useconfirm';
import { useVuelidate } from '@vuelidate/core';
import moment from 'moment-timezone';
import { usePopupStore } from '@/stores/PopupStore';
import useRPC from '@/composables/useRPC';
import Utils from '@/utils/Utils';
import { FilterMatchMode } from 'primevue/api';
import { useDebounceFn } from '@vueuse/core';
import QuillEditor from '@/components/QuillEditor.vue';
import SyncfusionRTE from '@/components/SyncfusionRTE.vue';

const { RPCApi, RPCMethods } = useRPC();
const dialogShown = ref(false);
const confirm = useConfirm();
const submitted = ref(false);
const headerTitle = ref('');
const categories = ref([]);
const closeCB = ref(null);
const popupStore = usePopupStore();
const lastLogin = ref(null);

const isAdmin = computed(() => {
    return Utils.UserInfo?.role === Utils.Roles.Admin;
});

const isOffice = computed(() => {
    return Utils.UserInfo?.role === Utils.Roles.Office;
});

const isStudent = computed(() => {
    return Utils.UserInfo && Utils.UserInfo.role === Utils.Roles.Student;
});

const isTeacher = computed(() => {
    return Utils.UserInfo && Utils.UserInfo.role === Utils.Roles.Teacher;
});

const isAdminOrOffice = computed(() => {
    return isAdmin.value || isOffice.value;
});

const state = reactive({
    id: null,
    fname: '',
    lname: '',
    email: '',
    mobile: '',
    phone: '',
    afm: '',
    birthDate: null,
    gender: null,
    city: '',
    address: '',
    zipcode: '',
    comments: '',
    adminComments: '',
    active: 0,
});
const rules = {
    fname: { required },
    lname: { required },
    email: { required, email },
    mobile: { required },
};
const v$ = useVuelidate(rules, state, { $stopPropagation: true });
const loading = ref(false);
const emit = defineEmits(['onClose']);
const toast = useToast();
const schools = ref([]);
const editMode = ref(false);
const schoolsLoading = ref(false);
const tabItems = ref([]);
const tabIndex = ref(0);
const classes = ref([]);
const requests = ref([]);

const showTab = ref(false);

const setTab = async (index) => {
    console.log('setTab:' + index);
    tabIndex.value = index;
    await nextTick();
};

const show = async (data, onCloseCB) => {
    const { userID } = data;
    tabItems.value = [
        {
            label: 'Στοιχεία',
            icon: 'pi pi-id-card',
            command: () => {
                setTab(0);
            },
        },
    ];
    if (isAdminOrOffice.value) {
        tabItems.value = [
            ...tabItems.value,
            {
                label: 'Σχόλια',
                icon: 'pi pi-fw pi-comments',
                command: () => {
                    setTab(1);
                },
            },
        ];
    }
    console.log(userID);
    closeCB.value = onCloseCB;
    lastLogin.value = null;
    classes.value = [];
    requests.value = [];
    tabIndex.value = 0;
    resetForm();
    dialogShown.value = true;
    submitted.value = false;
    editMode.value = !!userID;
    headerTitle.value = editMode.value ? 'Καρτέλα Υπάλληλου' : 'Νέος Υπάλληλος';
    showTab.value = true;
    if (userID != null) {
        state.id = userID;
        let res = await RPCApi(RPCMethods.User.GetUserInfoById, { id: userID });
        if (res.status == 'ok') {
            const { fname, lname, email, mobile, school_id, ban, checked, phone, afm, birthDate, gender, city, address, zipCode, comments, adminComments, last_login_datetime } =
                res.data.result.data.userInfo;
            state.fname = fname;
            state.lname = lname;
            state.email = email;
            state.mobile = mobile;
            state.phone = phone;
            state.afm = afm;
            state.birthDate = Utils.datetimeToDate(birthDate);
            state.gender = Utils.Genders.find((x) => x.value === gender);
            state.city = city;
            state.address = address;
            state.zipcode = zipCode;
            state.comments = comments;
            state.active = ban === 1 ? false : true;
            state.adminComments = adminComments;
            lastLogin.value = last_login_datetime
                ? moment(Utils.datetimeToDate(last_login_datetime)).format('DD/MM/YYYY') + ' - ' + moment(Utils.datetimeToDate(last_login_datetime)).format('HH:mm')
                : null;

            headerTitle.value = lname + ' ' + fname;
        }
    }
};

const onHide = (action = false) => {
    dialogShown.value = false;
    resetForm();
    closeCB.value(action);
};

const handleSubmit = async (isFormValid) => {
    submitted.value = true;

    if (!isFormValid) return;

    console.log('ok submit');
    loading.value = true;
    let schoolID = null;
    if (state.category != null) {
        let school = schools.value.find((school) => school.category === state.category.name && school.subCateg1 === state.subCateg1 && school.subCateg2 === state.subCateg2);
        if (school) schoolID = school.id;
    }

    let res;
    let genderValue = state.gender == null ? Utils.GendersEnum.Male : state.gender.value;
    let payload = {
        id: state.id,
        fname: state.fname,
        lname: state.lname,
        email: state.email,
        mobile: state.mobile,
        phone: state.phone,
        afm: state.afm,
        birthDate: state.birthDate,
        gender: genderValue,
        role: Utils.Roles.Office,
        city: state.city,
        address: state.address,
        zipCode: state.zipcode,
        comments: state.comments,
        adminComments: state.adminComments,
        ban: Utils.FromBoolToTinyInt(!state.active),
    };

    console.log(payload);
    if (editMode.value) {
        res = await RPCApi(RPCMethods.User.Update, payload);
    } else {
        res = await RPCApi(RPCMethods.User.Add, payload);
    }

    if (res.status !== 'ok') {
        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Παρακαλώ προσπαθήστε αργότερα', life: 3000 });
        loading.value = false;
        return;
    }
    toast.add({ severity: 'success', summary: 'Η διαδικασία ολοκληρώθηκε', detail: 'Επιτυχής διαδικασία!', life: 3000 });

    loading.value = false;
    onHide(true);
};
const resetForm = () => {
    for (let field in state) {
        if (field === 'ban') state[field] = false;
        else state[field] = null;
    }

    submitted.value = false;
    loading.value = false;
};

defineExpose({
    show,
});
</script>

<style lang="scss">
.p-avatar {
    img {
        width: auto;
    }
}
.fixed-tab-menu {
    position: sticky;
    top: 0;
    z-index: 1;
}
</style>
