<template>
    <Dialog
        v-model:visible="dialogShown"
        class="p-fluid"
        :close-on-escape="false"
        :dismissable-mask="false"
        :modal="true"
        :breakpoints="{ '960px': '75vw', '640px': '95vw' }"
        :style="{ width: '50vw' }"
    >
        <template #header>
            <h4>{{ headerTitle }}</h4>
        </template>

        <div class="col-12">
            <div class="field">
                <label for="title" :class="{ 'p-error': v$.title.$invalid && submitted }">Τίτλος*</label>
                <InputText id="title" v-model="v$.title.$model" autocomplete="off" spellcheck="off" :class="{ 'p-invalid': v$.title.$invalid && submitted }" placeholder="Τίτλος εξεταστικής" />
                <small v-if="(v$.title.$invalid && submitted) || v$.title.$pending.$response" class="p-error">{{ v$.title.required.$message }}</small>
            </div>
            <div class="field">
                <label for="category" :class="{ 'p-error': v$.category.$invalid && submitted }">Κατηγορία*</label>
                <AutoComplete
                    id="category"
                    v-model="v$.category.$model"
                    :class="{ 'p-invalid': v$.category.$invalid && submitted }"
                    :suggestions="filteredCategories"
                    force-selection
                    field="name"
                    placeholder="Επιλέξτε κατηγορία"
                    :dropdown="true"
                    @complete="searchCategory($event)"
                >
                </AutoComplete>
                <small v-if="(v$.category.$invalid && submitted) || v$.category.$pending.$response" class="p-error">{{ v$.category.required.$message }}</small>
            </div>
            <div class="field-checkbox">
                <Checkbox id="active" v-model="state.active" :binary="true" />
                <label for="active">Ενεργή</label>
            </div>
        </div>

        <template #footer>
            <Button label="Άκυρο" icon="pi pi-times" class="p-button-outlined w-8rem mr-3" @click="onHide(false)" />
            <Button label="Αποθήκευση" icon="pi pi-check" class="p-button-primary w-10rem" :loading="loading" @click="handleSubmit(!v$.$invalid)" />
        </template>
    </Dialog>
</template>

<script setup>
import { useToast } from 'primevue/usetoast';
import { onMounted, reactive, ref, computed, watchEffect, toRefs } from 'vue';
import { required, integer } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import useRPC from '@/composables/useRPC';
import Utils from '@/utils/Utils';
const { RPCApi, RPCMethods } = useRPC();
const dialogShown = ref(false);
const submitted = ref(false);
const headerTitle = ref('');
const state = reactive({
    id: null,
    title: '',
    category: null,
    active: true,
});
const rules = {
    title: { required },
    category: { required },
    active: { required },
};
const v$ = useVuelidate(rules, state);
const loading = ref(false);
const toast = useToast();
const editMode = ref(false);
const filteredCategories = ref([]);
const categories = ref([]);
const closeCB = ref(null);

const show = async (data, onCloseCB) => {
    const { period } = data || {};
    closeCB.value = onCloseCB;
    console.log(period);
    resetForm();
    dialogShown.value = true;
    submitted.value = false;
    editMode.value = !!period;
    headerTitle.value = editMode.value ? 'Τροποποίηση Εξεταστικής' : 'Νέα Εξεταστική';
    if (period != null) {
        state.id = period.id;
        state.title = period.title;
        state.category = { id: period.categoryID, name: period.categoryName };
        state.active = period.active;
    }

    RPCApi(RPCMethods.Categories.Fetch).then((res) => {
        if (res.status == 'ok') {
            categories.value = res.data.result.data.categories;
        }
    });
};

const onHide = (action = false) => {
    dialogShown.value = false;
    resetForm();
    closeCB.value(action);
};

const handleSubmit = async (isFormValid) => {
    submitted.value = true;

    if (!isFormValid) return;

    console.log('ok submit');
    loading.value = true;
    let res;
    let payload = {
        id: state.id,
        title: state.title,
        category_id: state.category.id,
        active: state.active ? 1 : 0,
    };

    if (editMode.value) {
        res = await RPCApi(RPCMethods.Periods.Update, payload);
    } else {
        res = await RPCApi(RPCMethods.Periods.Add, payload);
    }

    if (res.status !== 'ok') {
        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Προσπαθήστε ξανά!', life: 3000 });
        loading.value = false;
        return;
    }
    toast.add({ severity: 'success', summary: 'Η διαδικασία ολοκληρώθηκε', detail: 'Επιτυχής διαδικασία!', life: 3000 });

    loading.value = false;
    onHide(true);
};
const resetForm = () => {
    state.id = null;
    state.title = '';
    state.category = null;
    state.active = true;

    submitted.value = false;
    loading.value = false;
};

const searchCategory = async (event) => {
    console.log('searchCategory: ' + event.query);

    if (!event.query.trim().length) {
        filteredCategories.value = [...categories.value];
    } else {
        filteredCategories.value = categories.value.filter((d) => {
            return d.name.toLowerCase().startsWith(event.query.toLowerCase());
        });
    }
};

defineExpose({
    show,
});
</script>
