<template>
    <Dialog
        v-model:visible="dialogShown"
        class="p-fluid"
        :close-on-escape="false"
        :dismissable-mask="false"
        :modal="true"
        :breakpoints="{ '960px': '75vw', '640px': '95vw' }"
        :style="{ width: '50vw' }"
    >
        <template #header>
            <h4>{{ headerTitle }}</h4>
        </template>
        <div class="field">
            <label for="title" :class="{ 'p-error': v$.title.$invalid && submitted }">Τίτλος*</label>
            <InputText id="title" v-model="v$.title.$model" autocomplete="off" spellcheck="off" :class="{ 'p-invalid': v$.title.$invalid && submitted }" placeholder="Τίτλος" />
            <small v-if="(v$.title.$invalid && submitted) || v$.title.$pending.$response" class="p-error">{{ v$.title.required.$message }}</small>
        </div>

        <div class="field">
            <label for="body" :class="{ 'p-error': v$.body.$invalid && submitted }">Περιγραφή*</label>
            <Textarea id="body" v-model="v$.body.$model" rows="5" :class="{ 'p-invalid': v$.body.$invalid && submitted }" placeholder="Περιγραφή" />
            <small v-if="(v$.body.$invalid && submitted) || v$.body.$pending.$response" class="p-error">{{ v$.body.required.$message }}</small>
        </div>

        <div class="field">
            <label for="deadlineAt" :class="{ 'p-error': v$.deadlineAt.$invalid && submitted }">Deadline*</label>
            <Calendar id="deadlineAt" v-model="v$.deadlineAt.$model" :class="{ 'p-invalid': v$.deadlineAt.$invalid && submitted }" date-format="dd/mm/yy" placeholder="dd/mm/yyyy" />
            <small v-if="(v$.deadlineAt.$invalid && submitted) || v$.deadlineAt.$pending.$response" class="p-error">{{ v$.deadlineAt.required.$message }}</small>
        </div>

        <div class="field">
            <label for="type" :class="{ 'p-error': v$.type.$invalid && submitted }">Τύπος*</label>
            <Dropdown id="type" v-model="v$.type.$model" :options="Utils.TodoTypes" optionLabel="label" :class="{ 'p-invalid': v$.type.$invalid && submitted }" placeholder="Επιλέξτε τύπο" disabled />
            <small v-if="(v$.type.$invalid && submitted) || v$.type.$pending.$response" class="p-error">{{ v$.type.required.$message }}</small>
        </div>

        <div v-if="allowRefererLink" class="field">
            <label for="refererLink">Αναφορικότητα σε: </label>
            <a class="font-medium text-right cursor-pointer no-underline pl-2" style="color: blue" @click="onOpenRefererDialog(state.type_referer_id)">{{ state.type_referer_id }}</a>
        </div>

        <div class="field">
            <label for="createdBy" :class="{ 'p-error': v$.createdBy.$invalid && submitted }">Δημιουργήθηκε από*</label>
            <Dropdown
                id="createdBy"
                v-model="v$.createdBy.$model"
                :options="accountablesOptions"
                optionLabel="label"
                :class="{ 'p-invalid': v$.createdBy.$invalid && submitted }"
                placeholder="Επιλέξτε"
            />
            <small v-if="(v$.createdBy.$invalid && submitted) || v$.createdBy.$pending.$response" class="p-error">{{ v$.createdBy.required.$message }}</small>
        </div>

        <div class="field">
            <label for="accountables" :class="{ 'p-error': v$.accountables.$invalid && submitted }">Υπεύθυνοι*</label>
            <MultiSelect
                id="accountables"
                v-model="v$.accountables.$model"
                :options="accountablesOptions"
                optionLabel="label"
                :class="{ 'p-invalid': v$.accountables.$invalid && submitted }"
                placeholder="Επιλέξτε υπεύθυνους"
            />
            <small v-if="(v$.accountables.$invalid && submitted) || v$.accountables.$pending.$response" class="p-error">{{ v$.accountables.required.$message }}</small>
        </div>
        <template #footer>
            <Button label="Άκυρο" icon="pi pi-times" class="p-button-outlined w-8rem mr-3" @click="onHide(false)" />
            <Button label="Αποθήκευση" icon="pi pi-check" class="p-button-primary w-10rem" :loading="loading" @click="handleSubmit(!v$.$invalid)" />
        </template>
    </Dialog>
</template>

<script setup>
import Utils from '@/utils/Utils';
import { ref, reactive, computed } from 'vue';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { useToast } from 'primevue/usetoast';
import useRPC from '@/composables/useRPC';
import { usePopupStore } from '@/stores/PopupStore';
const { RPCApi, RPCMethods } = useRPC();
const dialogShown = ref(false);
const headerTitle = ref('Create/Edit Todo');
const accountablesOptions = ref([]);
const loading = ref(false);
const toast = useToast();
const popupStore = usePopupStore();
const state = reactive({
    id: null,
    title: null,
    body: null,
    deadlineAt: null,
    type: null,
    accountables: [],
    type_referer_id: null,
    createdBy: null,
});
const rules = {
    title: { required },
    body: { required },
    deadlineAt: { required },
    type: { required },
    accountables: { required },
    createdBy: { required },
};
const closeCB = ref(null);
const submitted = ref(false);
const editMode = ref(false);
const v$ = useVuelidate(rules, state);

const show = async (data, onCloseCB) => {
    const { id, type, type_referer_id } = data || {};
    closeCB.value = onCloseCB;
    resetForm();
    dialogShown.value = true;
    submitted.value = false;
    editMode.value = !!id;
    headerTitle.value = editMode.value ? 'Τροποποίηση Todo' : 'Νέο Todo';
    state.type = type == null ? Utils.TodoTypes.find((type) => type.value === Utils.TodoType.Other) : Utils.TodoTypes.find((t) => t.value === type);
    state.type_referer_id = type_referer_id;

    let officeAndAdminUsers = await RPCApi(RPCMethods.User.FetchOfficeAndAdmin);
    if (officeAndAdminUsers.status === 'ok') {
        const { users } = officeAndAdminUsers.data.result.data;
        let d = [];
        for (let user of users) {
            if (user.ban === 1) continue;
            d.push({
                label: user.fname + ' ' + user.lname,
                value: user.id,
            });
        }
        accountablesOptions.value = d;
    }

    if (editMode.value) {
        let todo = await RPCApi(RPCMethods.Todos.FetchById, { id });
        console.warn(todo);
        if (todo.status === 'ok') {
            const { title, body, deadlineAt, type, accountables, type_referer_id, created_by } = todo.data.result.data;
            state.id = id;
            state.title = title;
            state.body = body;
            state.deadlineAt = new Date(deadlineAt);
            state.type = Utils.TodoTypes.find((t) => t.value === type);
            state.type_referer_id = type_referer_id;
            state.createdBy = accountablesOptions.value.find((accountable) => accountable.value === created_by) || null;
            state.accountables = accountables.map((accountable) => {
                return {
                    label: accountable.fname + ' ' + accountable.lname,
                    value: accountable.id,
                };
            });
        }
    } else {
        state.createdBy = accountablesOptions.value.find((accountable) => accountable.value === Utils.UserInfo.id);
    }
};

const allowRefererLink = computed(() => {
    return state.type.value != Utils.TodoType.Other;
});

const onHide = (action = false) => {
    dialogShown.value = false;
    resetForm();
    closeCB.value(action);
};

const handleSubmit = async (isFormValid) => {
    submitted.value = true;

    console.warn(state);

    if (!isFormValid) return;

    loading.value = true;
    let res;
    console.log(state);

    let todo = {
        title: state.title,
        body: state.body,
        deadlineAt: state.deadlineAt,
        type: state.type.value,
        accountables: state.accountables.map((accountable) => accountable.value),
        type_referer_id: state.type_referer_id,
        created_by: state.createdBy.value,
    };

    if (editMode.value) {
        todo.id = state.id;
        res = await RPCApi(RPCMethods.Todos.Update, todo);
    } else {
        res = await RPCApi(RPCMethods.Todos.Add, todo);
    }

    if (res.status !== 'ok') {
        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Παρακαλώ προσπαθήστε αργότερα', life: 3000 });
        loading.value = false;
        return;
    }
    toast.add({ severity: 'success', summary: 'Η διαδικασία ολοκληρώθηκε', detail: 'Επιτυχής διαδικασία!', life: 3000 });

    loading.value = false;
    onHide(true);
};

const resetForm = () => {
    for (let field in state) state[field] = null;

    submitted.value = false;
};

const onOpenRefererDialog = (refererID) => {
    console.warn('onOpenRefererDialog');

    if (state.type.value === Utils.TodoType.Student) {
        console.log('open student dialog: ' + refererID);
        popupStore.show(Utils.Popups.StudentDialog, { studentID: refererID }, () => {});
        return;
    }

    if (state.type.value === Utils.TodoType.Lesson) {
        console.log('open lesson dialog: ' + refererID);
        popupStore.show(Utils.Popups.LessonDialog, { lessonID: refererID }, () => {});
        return;
    }

    if (state.type.value === Utils.TodoType.Class) {
        console.log('open class dialog: ' + refererID);
        popupStore.show(Utils.Popups.ClassDialog, { classID: refererID }, () => {});
        return;
    }

    if (state.type.value === Utils.TodoType.Teacher) {
        console.log('open teacher dialog: ' + refererID);
        popupStore.show(Utils.Popups.TeacherDialog, { teacherID: refererID }, () => {});
        return;
    }
};

defineExpose({
    show,
});
</script>
