export default () => {
    const StorageKeys = Object.freeze({
        RememberMe: 'rememberMe',
        AccessToken: 'accessToken',
        LastEmailUsed: 'lastEmailUsed',
        UnreadInboxMessages: 'unreadInboxMessages',
        TermsAccepted: 'termsAccepted',
        FcmToken: 'fcmToken',
    });

    const SetStorage = (key, value) => {
        try {
            localStorage.setItem(key, JSON.stringify(value));
        } catch (err) {
            console.error(err);
        }
    };

    const GetStorage = (key) => {
        try {
            let value = localStorage.getItem(key);
            if (value == null) return null;

            const serializedState = JSON.parse(value);
            return serializedState;
        } catch (err) {
            console.error(err);
        }
    };

    const ClearStorage = () => {
        try {
            localStorage.clear();
        } catch (err) {
            console.error(err);
        }
    };

    return { SetStorage, GetStorage, ClearStorage, StorageKeys };
};
