<template>
    <div>
        <ejs-richtexteditor
            ref="rteInstance"
            v-model="txt"
            :value="txt"
            :height="props.height"
            :toolbarSettings="toolbarSettings"
            :insertSettings="insertImageSettings"
            @action-complete="onActionComplete"
        ></ejs-richtexteditor>
    </div>
</template>

<script setup>
import { onMounted, ref, watch, provide, toRef } from 'vue';
import {
    RichTextEditorComponent as EjsRichtexteditor,
    Toolbar,
    Link,
    Image,
    HtmlEditor,
    Table,
    QuickToolbar,
    Resize,
    PasteCleanup,
    FormatPainter,
    EmojiPicker,
} from '@syncfusion/ej2-vue-richtexteditor';

const toolbarSettings = {
    items: [
        'Bold',
        'Italic',
        'Underline',
        'StrikeThrough',
        'SuperScript',
        'SubScript',
        '|',
        'FontName',
        'FontSize',
        'FontColor',
        'BackgroundColor',
        'CreateTable',
        '|',
        'Formats',
        'Alignments',
        'OrderedList',
        'UnorderedList',
        'Outdent',
        'Indent',
        '|',
        'CreateLink',
        'Image',
        '|',
        'ClearFormat',
        '|',
        'Undo',
        'Redo',
    ],
};

const rteInstance = ref(null);
const richtexteditor = [Toolbar, Link, Image, HtmlEditor, Table, QuickToolbar, Resize, PasteCleanup, FormatPainter, Table];
provide('richtexteditor', richtexteditor);

const txt = ref('');
const emit = defineEmits(['update:modelValue']);

const insertImageSettings = {
    saveFormat: 'Base64',
};

const props = defineProps({
    modelValue: {
        type: String,
        default: '',
    },
    height: {
        type: String,
        default: '300',
    },
    readonly: {
        type: Boolean,
        default: false,
    },
});

watch(
    () => props.modelValue,
    (newValue, oldValue) => {
        if (newValue !== txt.value) {
            txt.value = newValue;
        }
    },
    { immediate: true }
);

watch(
    () => txt.value,
    (newValue, oldValue) => {
        if (newValue !== oldValue) {
            emit('update:modelValue', newValue);
        }
    },
    { immediate: true }
);

onMounted(() => {
    console.log('Syncfusion editor mounted.');
});

const onActionComplete = async (e) => {
    console.log('onActionComplete!!!', e);
    if (e.requestType === 'Images') {
        // Assuming the image is inserted as an <img> element
        const images = e.elements;
        images.forEach(async (img) => {
            if (img.src.startsWith('blob:')) {
                // Convert blob URL to Base64
                const blob = await fetch(img.src).then((res) => res.blob());
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    const base64data = reader.result;
                    img.src = base64data;
                    // console.log('Image converted to Base64:', base64data);
                    // Handle the image loaded event here
                };
            } else if (img.complete) {
                // If the image is already loaded (cached), manually trigger onload
                img.onload();
            }
        });
    }
};
</script>

<style lang="scss" scoped></style>
