<template>
    <div>
        <div class="card">
            <h5>Εκκρεμούν (Σύνολο:{{ pendingTodosTotalRecords }})</h5>
            <DataTable
                ref="dt"
                v-model:filters="pendingTodosFilters"
                :lazy="true"
                :total-records="pendingTodosTotalRecords"
                :loading="loadingPending"
                :row-hover="true"
                :value="pendingTodos"
                filter-display="row"
                data-key="id"
                responsive-layout="scroll"
                :rows="pendingTodosRowsPerPage"
                :paginator="true"
                @page="onPendingTodoPage($event)"
                @sort="onPendingTodoSort($event)"
                @filter="onPendingTodoFilter($event)"
            >
                <template #header>
                    <Button class="p-button-outlined font-semibold" icon="pi pi-plus" label="Προσθήκη" @click="onCreateTodo()"></Button>
                </template>
                <template #empty> Δεν βρέθηκαν εκκρεμότητες. </template>
                <Column header="Τίτλος" header-style="text-align: left">
                    <template #body="{ data }">
                        <div class="flex flex-row gap-2 block">
                            <Checkbox v-model="data.completed" :binary="true" @change="onCompleteTodo(data.id)" />
                            <div class="expand-width">
                                <a class="font-medium no-underline text-right cursor-pointer expand-width-content" style="color: blue" @click="onEditTodo(data.id)">{{ data.title }}</a>
                            </div>
                        </div>
                    </template>
                </Column>
                <Column v-if="props.type == null" header="Τύπος" field="type" filter-field="type" style="min-width: 1rem" :show-filter-menu="false">
                    <template #body="{ data }">
                        <span style="padding-left: 0.7rem">{{ Utils.TodoTypeToString(data.type) }}</span>
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <Dropdown v-model="filterModel.value" option-label="label" :options="Utils.TodoTypes" placeholder="Όλες" class="p-column-filter" :show-clear="true" @change="filterCallback">
                            <template #value="slotProps">
                                <span v-if="slotProps.value != null">{{ slotProps.value.label }}</span>
                                <span v-else>Όλοι</span>
                            </template>
                            <template #option="slotProps">
                                <span>{{ slotProps.option.label }}</span>
                            </template>
                        </Dropdown>
                    </template>
                </Column>
                <Column header="Δημιουργήθηκε από" field="createdBy" style="min-width: 2rem" :show-filter-menu="false">
                    <template #body="{ data }">
                        <div v-if="data.createdBy?.id" class="expand-width">
                            <a class="font-medium no-underline text-right cursor-pointer expand-width-content" style="color: blue" @click="onShowUser(data.createdBy.id)">{{ data.createdBy.name }} </a>
                        </div>
                        <div v-else>--</div>
                    </template>
                </Column>
                <Column header="Υπεύθυνοι" field="accountables" filter-field="accountables" style="min-width: 1rem" :show-filter-menu="false">
                    <template #body="{ data }">
                        <div v-for="item of data.accountables" :key="item.id" class="expand-width">
                            <a class="font-medium no-underline text-right cursor-pointer expand-width-content" style="color: blue" @click="onShowUser(item.id)">{{ item.fname }} {{ item.lname }}</a>
                        </div>
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <Dropdown
                            v-model="filterModel.value"
                            option-label="label"
                            :options="accountablesOptions"
                            placeholder="Όλες"
                            class="p-column-filter"
                            :show-clear="true"
                            @change="filterCallback"
                        >
                            <template #value="slotProps">
                                <span v-if="slotProps.value != null">{{ slotProps.value.label }}</span>
                                <span v-else>Όλοι</span>
                            </template>
                            <template #option="slotProps">
                                <span>{{ slotProps.option.label }}</span>
                            </template>
                        </Dropdown>
                    </template>
                </Column>
                <Column header="Ημ/νια Δημιουργίας" field="createdAt" style="min-width: 2rem" :show-filter-menu="false">
                    <template #body="{ data }">
                        <span>{{ moment(Utils.datetimeToDate(data.createdAt)).format('DD/MM/YYYY') }}</span>
                    </template>
                </Column>
                <Column header="Deadline" field="deadlineAt" style="min-width: 2rem" :show-filter-menu="false">
                    <template #body="{ data }">
                        <span>{{ moment(Utils.datetimeToDate(data.deadlineAt)).format('DD/MM/YYYY') }}</span>
                    </template>
                </Column>
                <Column header="" style="min-width: 1rem" :show-filter-menu="false">
                    <template #body="{ data }">
                        <Button icon="pi pi-trash" class="p-button-danger p-button-text" @click="onDeleteTodoDialog(data.id)" />
                    </template>
                </Column>
            </DataTable>
        </div>
        <div class="card">
            <h5>Ολοκληρώθηκαν (Σύνολο:{{ completedTodosTotalRecords }})</h5>
            <DataTable
                ref="dt"
                v-model:filters="completedTodosFilters"
                :lazy="true"
                :total-records="completedTodosTotalRecords"
                :loading="loadingCompleted"
                :row-hover="true"
                :value="completedTodos"
                filter-display="row"
                data-key="id"
                responsive-layout="scroll"
                :rows="completedTodosRowsPerPage"
                :paginator="true"
                @page="onCompletedTodoPage($event)"
                @sort="onCompletedTodoSort($event)"
                @filter="onCompletedTodoFilter($event)"
            >
                <template #empty> Δεν βρέθηκαν ολοκληρωμένα todos. </template>
                <Column header="Τίτλος" header-style="text-align: left">
                    <template #body="{ data }">
                        <div class="flex flex-row gap-2 block">
                            <Checkbox v-model="data.completed" :binary="true" @change="onPendingTodo(data.id)" />
                            <div class="expand-width">
                                <a class="font-medium no-underline text-right cursor-pointer expand-width-content" style="color: blue" @click="onEditTodo(data.id)">{{ data.title }}</a>
                            </div>
                        </div>
                    </template>
                </Column>
                <Column v-if="props.type == null" header="Τύπος" field="type" filter-field="type" style="min-width: 1rem" :show-filter-menu="false">
                    <template #body="{ data }">
                        <span style="padding-left: 0.7rem">{{ Utils.TodoTypeToString(data.type) }}</span>
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <Dropdown v-model="filterModel.value" option-label="label" :options="Utils.TodoTypes" placeholder="Όλες" class="p-column-filter" :show-clear="true" @change="filterCallback">
                            <template #value="slotProps">
                                <span v-if="slotProps.value != null">{{ slotProps.value.label }}</span>
                                <span v-else>Όλοι</span>
                            </template>
                            <template #option="slotProps">
                                <span>{{ slotProps.option.label }}</span>
                            </template>
                        </Dropdown>
                    </template>
                </Column>
                <Column header="Δημιουργήθηκε από" field="createdBy" style="min-width: 2rem" :show-filter-menu="false">
                    <template #body="{ data }">
                        <div v-if="data.createdBy?.id" class="expand-width">
                            <a class="font-medium no-underline text-right cursor-pointer expand-width-content" style="color: blue" @click="onShowUser(data.createdBy.id)">{{ data.createdBy.name }} </a>
                        </div>
                        <div v-else>--</div>
                    </template>
                </Column>
                <Column header="Υπεύθυνοι" field="accountables" filter-field="accountables" style="min-width: 1rem" :show-filter-menu="false">
                    <template #body="{ data }">
                        <div v-for="item of data.accountables" :key="item.id" class="expand-width">
                            <a class="font-medium no-underline text-right cursor-pointer expand-width-content" style="color: blue" @click="onShowUser(item.id)">{{ item.fname }} {{ item.lname }}</a>
                        </div>
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <Dropdown
                            v-model="filterModel.value"
                            option-label="label"
                            :options="accountablesOptions"
                            placeholder="Όλες"
                            class="p-column-filter"
                            :show-clear="true"
                            @change="filterCallback"
                        >
                            <template #value="slotProps">
                                <span v-if="slotProps.value != null">{{ slotProps.value.label }}</span>
                                <span v-else>Όλοι</span>
                            </template>
                            <template #option="slotProps">
                                <span>{{ slotProps.option.label }}</span>
                            </template>
                        </Dropdown>
                    </template>
                </Column>
                <Column header="Ημ/νια Δημιουργίας" field="createdAt" style="min-width: 2rem" :show-filter-menu="false">
                    <template #body="{ data }">
                        <span>{{ moment(Utils.datetimeToDate(data.createdAt)).format('DD/MM/YYYY') }}</span>
                    </template>
                </Column>
                <Column header="Ημ/νια Ολοκλήρωσης" field="completedAt" style="min-width: 2rem" :show-filter-menu="false">
                    <template #body="{ data }">
                        <span>{{ moment(Utils.datetimeToDate(data.completedAt)).format('DD/MM/YYYY') }}</span>
                    </template>
                </Column>
                <Column header="Ολοκληρώθηκε από" field="completedBy" style="min-width: 2rem" :show-filter-menu="false">
                    <template #body="{ data }">
                        <a class="font-medium text-right cursor-pointer no-underline" style="color: blue" @click="onOpenUserProfile(data.completedBy.id)"
                            >{{ data.completedBy?.fname }} {{ data.completedBy?.lname }}</a
                        >
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { useToast } from 'primevue/usetoast';
import Utils from '@/utils/Utils';
import { FilterMatchMode } from 'primevue/api';
import { useDebounceFn } from '@vueuse/core';
import useRPC from '@/composables/useRPC';
import { useConfirm } from 'primevue/useconfirm';
import { usePopupStore } from '@/stores/PopupStore';
import moment from 'moment-timezone';

const popupStore = usePopupStore();
const confirm = useConfirm();
const dt = ref(null);
const { RPCApi, RPCMethods } = useRPC();
const toast = useToast();
const pendingTodosFilters = ref({
    accountables: { value: null, matchMode: FilterMatchMode.EQUALS },
    type: { value: null, matchMode: FilterMatchMode.EQUALS },
});
const pendingTodosTotalRecords = ref(0);
const loadingPending = ref(false);
const pendingTodos = ref([]);
const pendingTodosRowsPerPage = ref(10);
const fetchPendingTodosParams = ref({});

const completedTodosFilters = ref({
    accountables: { value: null, matchMode: FilterMatchMode.EQUALS },
    type: { value: null, matchMode: FilterMatchMode.EQUALS },
});
const completedTodosTotalRecords = ref(0);
const loadingCompleted = ref(false);
const completedTodos = ref([]);
const completedTodosRowsPerPage = ref(10);
const fetchCompletedTodosParams = ref({});
const accountablesOptions = ref([]);

let props = defineProps({
    type: {
        type: String,
        default: null,
    },
    typeRefererId: {
        type: Number,
        default: null,
    },
});
onMounted(async () => {
    console.log('props.type', props.type);
    console.log('props.typeRefererId', props.typeRefererId);
    pendingTodosFilters.value.type = { value: props.type, matchMode: FilterMatchMode.EQUALS };
    if (props.typeRefererId != null) pendingTodosFilters.value.typeRefererId = { value: props.typeRefererId, matchMode: FilterMatchMode.EQUALS };

    fetchOfficeAndAdmin();

    completedTodosFilters.value.type = { value: props.type, matchMode: FilterMatchMode.EQUALS };
    if (props.typeRefererId != null) completedTodosFilters.value.typeRefererId = { value: props.typeRefererId, matchMode: FilterMatchMode.EQUALS };
    fetchPendingTodosParams.value = {
        paging: { offset: 0, limit: dt.value.rows },
        sort: null,
        filters: pendingTodosFilters.value,
    };
    fetchCompletedTodosParams.value = {
        paging: { offset: 0, limit: dt.value.rows },
        sort: null,
        filters: completedTodosFilters.value,
    };
    fetchPending();
    fetchCompleted();
});

const fetchOfficeAndAdmin = async () => {
    let res = await RPCApi(RPCMethods.User.FetchOfficeAndAdmin);
    if (res.status === 'ok') {
        const { users } = res.data.result.data;
        let d = [];
        for (let user of users) {
            if (user.ban === 1) continue;
            d.push({
                label: user.fname + ' ' + user.lname,
                value: user.id,
            });
        }
        accountablesOptions.value = d;
    }
};

const fetchPending = async () => {
    console.log('fetchPending');
    loadingPending.value = true;
    let d = [];
    try {
        fetchPendingTodosParams.value.filters.pending = { value: 1, matchMode: FilterMatchMode.EQUALS };
        let res = await RPCApi(RPCMethods.Todos.Fetch, fetchPendingTodosParams.value);
        console.log(res);
        if (res.status == 'ok') {
            const { total, todos: todosData } = res.data.result.data;
            pendingTodosTotalRecords.value = total;

            for (let obj of todosData) {
                d.push({
                    id: obj.id,
                    completed: obj.pending === 0,
                    type: obj.type,
                    title: obj.title,
                    body: obj.body,
                    deadlineAt: obj.deadlineAt,
                    createdAt: obj.createdAt,
                    createdBy: {
                        id: obj.created_by,
                        name: accountablesOptions.value.find((a) => a.value == obj.created_by)?.label,
                    },
                    accountables: obj.accountables.map((accountable) => {
                        return {
                            id: accountable.accountable,
                            fname: accountable.fname,
                            lname: accountable.lname,
                        };
                    }),
                });
            }
        }
    } catch (e) {
        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Προσπαθήστε αργότερα', life: 3000 });
    }

    pendingTodos.value = d;
    loadingPending.value = false;
};

const fetchCompleted = async () => {
    console.log('fetchCompleted');
    loadingCompleted.value = true;
    let d = [];
    try {
        fetchCompletedTodosParams.value.filters.pending = { value: 0, matchMode: FilterMatchMode.EQUALS };
        let res = await RPCApi(RPCMethods.Todos.Fetch, fetchCompletedTodosParams.value);
        console.log(res);
        if (res.status == 'ok') {
            const { total, todos: todosData } = res.data.result.data;
            completedTodosTotalRecords.value = total;

            for (let obj of todosData) {
                d.push({
                    id: obj.id,
                    completed: obj.pending === 0,
                    type: obj.type,
                    title: obj.title,
                    body: obj.body,
                    deadlineAt: obj.deadlineAt,
                    createdAt: obj.createdAt,
                    completedAt: obj.completedAt,
                    completedBy: obj.completed_by,
                    createdBy: {
                        id: obj.created_by,
                        name: accountablesOptions.value.find((a) => a.value == obj.created_by)?.label,
                    },
                    accountables: obj.accountables.map((accountable) => {
                        return {
                            id: accountable.accountable,
                            fname: accountable.fname,
                            lname: accountable.lname,
                        };
                    }),
                });
            }
        }
    } catch (e) {
        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Προσπαθήστε αργότερα', life: 3000 });
    }

    completedTodos.value = d;
    loadingCompleted.value = false;
};

const onCompleteTodo = async (id) => {
    console.log('onCompleteTodo', id);
    let res = await RPCApi(RPCMethods.Todos.Update, { id, pending: 0 });

    fetchPending();
    fetchCompleted();
};

const onPendingTodo = async (id) => {
    console.log('onPendingTodo', id);
    let res = await RPCApi(RPCMethods.Todos.Update, { id, pending: 1 });

    fetchPending();
    fetchCompleted();
};

const onCreateTodo = async () => {
    console.log('onCreateTodo');
    popupStore.show(Utils.Popups.TodoDialog, { type: props.type, type_referer_id: props.typeRefererId }, (action) => {
        if (action) fetchPending();
    });
};

const onEditTodo = async (id) => {
    console.log('onEditTodo', id);
    popupStore.show(Utils.Popups.TodoDialog, { id }, (action) => {
        if (action) {
            fetchPending();
            fetchCompleted();
        }
    });
};

const onPendingTodoPage = useDebounceFn((event) => {
    fetchPendingTodosParams.value.paging = { offset: event.first, limit: pendingTodosRowsPerPage };
    fetchPending();
}, 300);

const onCompletedTodoPage = useDebounceFn((event) => {
    fetchCompletedTodosParams.value.paging = { offset: event.first, limit: completedTodosRowsPerPage };
    fetchCompleted();
}, 300);

const onPendingTodoSort = useDebounceFn((event) => {
    console.log('onTaskSort', event);
    fetchPendingTodosParams.value.paging = { offset: 0, limit: pendingTodosRowsPerPage };
    fetchPendingTodosParams.value.sort = { sortField: event.sortField, sortOrder: event.sortOrder };
    fetchPending();
}, 300);

const onCompletedTodoSort = useDebounceFn((event) => {
    console.log('onTaskSort', event);
    fetchCompletedTodosParams.value.paging = { offset: 0, limit: completedTodosRowsPerPage };
    fetchCompletedTodosParams.value.sort = { sortField: event.sortField, sortOrder: event.sortOrder };
    fetchCompleted();
}, 300);

const onPendingTodoFilter = useDebounceFn(() => {
    console.log('onFilter', pendingTodosFilters.value);
    fetchPendingTodosParams.value.paging = { offset: 0, limit: pendingTodosRowsPerPage };
    fetchPendingTodosParams.value.filters = {
        accountables: { value: pendingTodosFilters.value.accountables?.value?.value, matchMode: FilterMatchMode.EQUALS },
        type: { value: pendingTodosFilters.value.type?.value?.value, matchMode: FilterMatchMode.EQUALS },
    };
    fetchPending();
}, 1000);

const onCompletedTodoFilter = useDebounceFn(() => {
    fetchCompletedTodosParams.value.paging = { offset: 0, limit: completedTodosRowsPerPage };
    fetchCompletedTodosParams.value.filters = {
        accountables: { value: completedTodosFilters.value.accountables?.value?.value, matchMode: FilterMatchMode.EQUALS },
        type: { value: completedTodosFilters.value.type?.value?.value, matchMode: FilterMatchMode.EQUALS },
    };
    fetchCompleted();
}, 1000);

const onDeleteTodoDialog = async (id) => {
    console.log('onDeleteTodoDialog', id);
    confirm.require({
        message: 'Είστε σίγουροι ότι θέλετε να προχωρήσετε σε διαγραφή;',
        header: 'Επιβεβαίωση',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Διαγραφή',
        rejectLabel: 'Άκυρο',
        acceptClass: 'p-button-danger',
        rejectClass: 'p-button-secondary',
        accept: async () => {
            console.log('delete!');
            await RPCApi(RPCMethods.Todos.Delete, { id });
            fetchPending();
            fetchCompleted();
        },
        reject: () => {
            console.log('do nothing');
        },
    });
};

const onOpenUserProfile = (id) => {
    console.log('onOpenUserProfile', id);
    popupStore.show(Utils.Popups.OfficeDialog, { userID: id }, () => {});
};

const onShowUser = (id) => {
    console.log('onShowUser', id);
    popupStore.show(Utils.Popups.OfficeDialog, { userID: id }, () => {});
};
</script>

<style scoped>
.expand-width {
    width: 100%;
    min-width: 100px; /* Set the minimum width of the column, you can adjust this value based on your needs */
}

.expand-width-content {
    display: inline-block;
    white-space: nowrap;
}
</style>
