import { defineStore } from 'pinia';
import useStorage from '@/composables/useStorage';
import { ref, computed, watch } from 'vue';
import useFirebase from '@/composables/useFirebase';
import Utils from '@/utils/Utils';

const { ChatRooms } = useFirebase();

export const useChatStore = defineStore('ChatStore', () => {
    const unreadChatMessages = ref({});
    const unreadClassChatMessages = ref({});

    const refresh = async () => {
        unreadChatMessages.value = {};
        unreadClassChatMessages.value = {};
        let rooms = ChatRooms().where('users', 'array-contains', Utils.UserInfo.id).where('adminPrive', '==', false);
        if (Utils.UserInfo.role === Utils.Roles.Admin) {
            rooms = ChatRooms().where('users', 'array-contains', Utils.UserInfo.id).where('adminPrive', '==', true);
        }
        rooms.onSnapshot((roomSnapshot) => {
            roomSnapshot.forEach((room) => {
                const messages = ChatRooms().doc(room.id).collection('messages');
                messages.onSnapshot((messageSnapshot) => {
                    let docs = messageSnapshot.docs;
                    for (let i = 0; i < docs.length; i++) {
                        const message = docs[i].data();
                        const seen = message.seen;
                        unreadChatMessages.value[docs[i].id] = seen != null && seen[Utils.UserInfo.id] != null;
                    }
                });
            });
        });
    };

    const totalUnreadMessages = computed(() => {
        let count = 0;
        for (let key in unreadChatMessages.value) {
            if (!unreadChatMessages.value[key]) count++;
        }
        return count;
    });

    return { refresh, totalUnreadMessages };
});
