<template>
    <Dialog v-model:visible="dialogShown" class="p-fluid" :close-on-escape="false" :dismissable-mask="false" :modal="true" :style="{ width: '95vw' }">
        <template #header>
            <div class="flex flex-row gap-2">
                <span style="font-size: large">{{ headerTitle }}</span>
                <span v-if="selectedCourses.length > 0" style="font-size: large; align-self: center">({{ selectedCourses.map((c) => c.title).join(', ') }})</span>
            </div>
        </template>
        <div v-if="editMode || step === 0" class="card">
            <div class="flex flex-row gap-2 pb-2">
                <span style="font-size: medium">Επιλέξτε μαθήματα</span>
            </div>
            <DataTable
                v-model:selection="selectedCourses"
                v-model:filters="tableFilters"
                sort-mode="multiple"
                :loading="loading"
                :row-hover="true"
                :value="courses"
                data-key="id"
                responsive-layout="scroll"
                :rows="10"
                :paginator="true"
                filter-display="row"
                @row-select="onCourseRowSelected"
                @row-unselect="onCourseRowUnselected"
            >
                <template #empty> Δεν βρέθηκαν μαθήματα. </template>
                <Column selection-mode="multiple" header-style="width: 3rem"></Column>
                <Column header="Κατηγορία" filter-field="categoryName" field="categoryName" :show-filter-menu="false" style="min-width: 10rem" :sortable="true">
                    <template #body="{ data }">
                        {{ data.categoryName }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Εύρεση βάση κατηγορίας" @input="filterCallback()" />
                    </template>
                </Column>
                <Column header="Υπ/ρία Ι" filter-field="subCateg1" field="subCateg1" :show-filter-menu="false" style="min-width: 10rem" :sortable="true">
                    <template #body="{ data }">
                        {{ data.subCateg1 }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Εύρεση βάση Υπ/ρία/Ι" @input="filterCallback()" />
                    </template>
                </Column>
                <Column header="Υπ/ρία ΙΙ" filter-field="subCateg2" field="subCateg2" :show-filter-menu="false" style="min-width: 10rem" :sortable="true">
                    <template #body="{ data }">
                        {{ data.subCateg2 }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Εύρεση βάση Υπ/ρία ΙΙ" @input="filterCallback()" />
                    </template>
                </Column>
                <Column header="Μάθημα" field="title" filter-field="title" :show-filter-menu="false" style="min-width: 10rem" :sortable="true">
                    <template #body="{ data }">
                        <a class="font-medium no-underline text-left cursor-pointer" style="color: blue" @click="editCourseDialog(data)"> {{ data.title }}</a>
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Εύρεση βάση ερώτησης" @input="filterCallback()" />
                    </template>
                </Column>
                <Column header="Εξάμηνο" filter-field="semester" field="semester" :show-filter-menu="false" style="min-width: 10rem" :sortable="true">
                    <template #body="{ data }">
                        {{ data.semester }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Εύρεση βάση εξαμήνου" @input="filterCallback()" />
                    </template>
                </Column>
                <!-- <Column header="Ώρες" field="hours" style="min-width: 10rem" :sortable="true">
                    <template #body="{ data }">
                        {{ data.hours }}
                    </template>
                </Column> -->
                <!-- <Column header="Δίδακτρα" field="price" style="min-width: 10rem" :sortable="true">
                    <template #body="{ data }">
                        {{ Utils.toEUR(data.price) }}
                    </template>
                </Column> -->
                <Column field="active" filter-field="active" header="Ενεργό" data-type="boolean" :show-filter-menu="false" body-class="text-left" style="min-width: 8rem">
                    <template #body="{ data }">
                        <i class="pi" :class="{ 'true-icon pi-check-circle': data.active, 'false-icon pi-times-circle': !data.active }"></i>
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <TriStateCheckbox v-model="filterModel.value" @change="filterCallback()" />
                    </template>
                </Column>
            </DataTable>
        </div>
        <div v-if="editMode || step === 1" class="card">
            <div class="formgrid grid p-fluid">
                <div class="flex flex-column w-full">
                    <div class="field">
                        <label style="font-weight: bolder">Α/α: {{ state.order_num }}</label>
                    </div>
                    <div class="field">
                        <label style="font-weight: bolder">Ερώτηση*</label>
                        <SyncfusionRTE v-model="state.question" height="250" />
                    </div>

                    <div class="flex flex-row gap-4 align-items-start">
                        <div class="flex flex-column flex-1 gap-2">
                            <div class="flex flex-column gap-2 w-full">
                                <div class="flex flex-column gap-2">
                                    <label style="font-weight: bolder">Επιλογή Α*</label>
                                    <SyncfusionRTE v-model="state.choice1" height="150" />
                                </div>
                            </div>

                            <div class="flex flex-column gap-2 w-full">
                                <div class="flex flex-column gap-2">
                                    <label style="font-weight: bolder">Επιλογή Β*</label>
                                    <SyncfusionRTE v-model="state.choice2" height="150" />
                                </div>
                            </div>

                            <div v-if="choices.includes(3)" class="flex flex-column gap-2 w-full">
                                <div class="flex flex-column gap-2">
                                    <div class="flex flex-row gap-4 align-items-center relative">
                                        <Button
                                            v-if="choices.length === 3"
                                            icon="pi pi-minus"
                                            rounded
                                            severity="danger"
                                            style="position: absolute; top: -0.3rem; left: 0rem; width: 2rem; height: 2rem"
                                            @click="removeChoice('choice3')"
                                        ></Button>
                                        <label style="font-weight: bolder" :style="{ 'padding-left': choices.length === 3 ? '3rem' : '0rem' }">Επιλογή Γ</label>
                                    </div>
                                    <SyncfusionRTE v-model="state.choice3" height="150" />
                                </div>
                            </div>

                            <div v-if="choices.includes(4)" class="flex flex-column gap-2 w-full">
                                <div class="flex flex-column gap-2">
                                    <div class="flex flex-row gap-4 align-items-center relative">
                                        <Button
                                            v-if="choices.length === 4"
                                            style="position: absolute; top: -0.3rem; left: 0rem; width: 2rem; height: 2rem"
                                            icon="pi pi-minus"
                                            rounded
                                            severity="danger"
                                            @click="removeChoice('choice4')"
                                        ></Button>
                                        <label style="font-weight: bolder" :style="{ 'padding-left': choices.length === 4 ? '3rem' : '0rem' }">Επιλογή Δ</label>
                                    </div>

                                    <SyncfusionRTE v-model="state.choice4" height="150" />
                                </div>
                            </div>

                            <div v-if="choices.includes(5)" class="flex flex-column gap-2 w-full">
                                <div class="flex flex-column gap-2">
                                    <div class="flex flex-row gap-4 align-items-center relative">
                                        <Button
                                            v-if="choices.length === 5"
                                            style="position: absolute; top: -0.3rem; left: 0rem; width: 2rem; height: 2rem"
                                            icon="pi pi-minus"
                                            rounded
                                            severity="danger"
                                            @click="removeChoice('choice5')"
                                        ></Button>
                                        <label style="font-weight: bolder; padding-left: 3rem">Επιλογή Ε</label>
                                    </div>
                                    <SyncfusionRTE v-model="state.choice5" height="150" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="choices.length < 5" class="h-6rem pt-5 w-16rem">
                        <Button icon="pi pi-plus" label="Προσθήκη Επιλογής" severity="success" @click="addChoice"></Button>
                    </div>

                    <div class="flex flex-row gap-4">
                        <div class="flex flex-row gap-4 align-items-start pt-4">
                            <div class="flex flex-column gap-2 w-12rem">
                                <label style="font-weight: bolder">Σωστή απάντηση*</label>
                                <Dropdown v-model="state.answer" :options="availableAnswers" optionLabel="label" optionValue="value" placeholder="Επιλέξτε" />
                            </div>
                        </div>

                        <div class="flex flex-row gap-4 align-items-start pt-4">
                            <div class="flex flex-column gap-2 w-16rem">
                                <label style="font-weight: bolder">Κεφάλαιο*</label>
                                <!-- <InputText v-model="state.chapter" autocomplete="off" spellcheck="off" placeholder="Κεφάλαιο μαθήματος" /> -->
                                <AutoComplete
                                    v-model="state.chapter"
                                    field="label"
                                    :suggestions="availableChapters"
                                    placeholder="Επιλέξτε"
                                    :dropdown="true"
                                    :multiple="false"
                                    dataKey="value"
                                    @complete="searchChapters($event)"
                                >
                                </AutoComplete>
                            </div>
                        </div>

                        <div class="flex flex-row gap-4 align-items-start pt-4">
                            <div class="flex flex-column gap-2 w-12rem">
                                <label style="font-weight: bolder">Δικαιώματα*</label>
                                <Dropdown v-model="state.rights" :options="quizRights" optionLabel="label" optionValue="value" placeholder="Επιλέξτε" />
                            </div>
                        </div>

                        <div class="flex flex-row gap-4 align-items-start pt-4">
                            <div class="flex flex-column gap-2 w-12rem">
                                <label style="font-weight: bolder">Βαθμός δυσκολίας*</label>
                                <Dropdown v-model="state.difficulty" :options="quizDifficulties" optionLabel="label" optionValue="value" placeholder="Επιλέξτε" />
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-column gap-2 w-full pt-4">
                        <div class="flex flex-column gap-2">
                            <label style="font-weight: bolder">Επεξήγηση σωστής απάντησης</label>
                            <!-- <Textarea v-model="state.description" :rows="3" /> -->
                            <SyncfusionRTE v-model="state.description" height="150" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <div class="flex flex-row w-full justify-content-between" style="padding-top: 1.5rem">
                <Button
                    v-if="editMode"
                    :label="quizActive ? 'Απενεργοποίηση' : 'Ενεργοποίηση'"
                    :icon="quizActive ? 'pi pi-ban' : 'pi pi-check'"
                    :class="['w-14rem', quizActive ? 'p-button-warning' : 'p-button-success']"
                    :loading="loading"
                    @click="onActivateQuiz"
                />
                <Button v-if="editMode" label="Preview" icon="pi pi-eye" class="p-button-info w-10rem ml-4" @click="onPreview" />
                <div v-if="editMode" class="flex flex-row gap-2 w-full justify-content-end">
                    <Button label="Άκυρο" icon="pi pi-times" class="p-button-outlined w-8rem mr-3" @click="onHide(false)" />
                    <Button label="Αποθήκευση" icon="pi pi-check" class="p-button-primary w-10rem" :loading="loading" @click="handleSubmit" />
                </div>
                <div v-else-if="step === 0" class="flex flex-row gap-2 w-full justify-content-end">
                    <Button label="Άκυρο" icon="pi pi-times" class="p-button-outlined w-8rem mr-3" @click="onHide(false)" />
                    <Button label="Επόμενο" icon="pi pi-arrow-right" class="p-button-primary w-10rem" :disabled="!selectedCourses.length" @click="nextStep" />
                </div>
                <div v-else-if="step === 1" class="flex flex-row gap-2 w-full justify-content-end">
                    <Button label="Κλείσιμο" icon="pi pi-times" class="p-button-outlined w-8rem mr-3" @click="onHide(true)" />
                    <Button label="Αποθήκευση" icon="pi pi-check" class="p-button-primary w-10rem" :loading="loading" @click="handleSubmit" />
                </div>
            </div>
        </template>
    </Dialog>
</template>

<script setup>
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import { useToast } from 'primevue/usetoast';
import moment from 'moment-timezone';
import { onMounted, reactive, ref, computed, watchEffect, toRefs, nextTick } from 'vue';
import { required, integer, helpers } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import useRPC from '@/composables/useRPC';
import Utils from '@/utils/Utils';
import { usePopupStore } from '@/stores/PopupStore';
import QuillEditor from '@/components/QuillEditor.vue';
import SyncfusionRTE from '@/components/SyncfusionRTE.vue';

const step = ref(0);
const { FetchFilesInFolder, DeleteFileInFolder, UploadFileInFolder, EditFileMetadata, User } = useRPC();
const choices = ref([1, 2, 3, 4]);
const selectedCourses = ref([]);
const popupStore = usePopupStore();
const { RPCApi, RPCMethods } = useRPC();
const dialogShown = ref(false);
const submitted = ref(false);
const headerTitle = ref('');
const state = reactive({
    id: null,
    question: null,
    choice1: null,
    choice2: null,
    choice3: null,
    choice4: null,
    choice5: null,
    answer: null,
    courses: [],
    chapter: null,
    difficulty: 1,
    rights: null,
    description: null,
    active: 1,
    order_num: 0,
});

const quizDifficulties = ref([
    { label: 'Εύκολο', value: 1 },
    { label: 'Μέτριο', value: 2 },
    { label: 'Δύσκολο', value: 3 },
]);

const tableFilters = ref({
    title: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    categoryName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    subCateg1: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    subCateg2: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    semester: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    examDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
    active: { value: true, matchMode: FilterMatchMode.EQUALS },
});
const loading = ref(false);
const toast = useToast();
const editMode = ref(false);
const closeCB = ref(null);
const courses = ref([]);
const totalCourses = ref(0);
const StoragePath = 'quizzes';

const isTeacher = computed(() => {
    return Utils.UserInfo.role === Utils.Roles.Teacher;
});

const quizActive = computed(() => {
    return state.active === 1;
});

const availableChapters = ref([
    {
        label: 'Κεφάλαιο 1',
        value: 'Κεφάλαιο 1',
    },
]);

const availableAnswers = computed(() => {
    let arr = [];
    for (let choice of choices.value) {
        switch (choice) {
            case 1:
                arr.push({ label: 'Επιλογή Α', value: 1 });
                break;
            case 2:
                arr.push({ label: 'Επιλογή Β', value: 2 });
                break;
            case 3:
                arr.push({ label: 'Επιλογή Γ', value: 3 });
                break;
            case 4:
                arr.push({ label: 'Επιλογή Δ', value: 4 });
                break;
            case 5:
                arr.push({ label: 'Επιλογή Ε', value: 5 });
                break;
            default:
                break;
        }
    }
    return arr;
});

const quizRights = computed(() => {
    return [
        // { label: 'Σε Προεγγραφή', value: 1 },
        { label: 'Σε τμήμα', value: 2 },
        { label: 'Όλα', value: 3 },
    ];
});

const nextStep = async () => {
    if (step.value === 0) {
        step.value = 1;
    }
    await nextTick();
    scrollTop();
};

const previousStep = async () => {
    if (step.value === 1) {
        step.value = 0;
    }
    await nextTick();
    scrollTop();
};

const show = async (data, onCloseCB) => {
    console.warn('data:', data);
    step.value = 0;
    await fetchCourses();
    resetForm();

    availableChapters.value = [];
    if (localStorage.getItem('chapters')) {
        availableChapters.value = JSON.parse(localStorage.getItem('chapters'));
    } else {
        availableChapters.value = [
            {
                label: 'Κεφάλαιο 1',
                value: 'Κεφάλαιο 1',
            },
        ];
    }

    closeCB.value = onCloseCB;
    dialogShown.value = true;
    submitted.value = false;
    editMode.value = data?.quizID != null;
    state.id = data?.quizID || null;
    selectedCourses.value = [];

    headerTitle.value = editMode.value ? `Τροποποίηση Ερώτησης (${state.id})` : 'Νέα ερώτηση';

    if (editMode.value) {
        let res = await RPCApi(RPCMethods.Quizzes.FetchQuizById, { id: state.id });
        console.warn('res:', res);
        if (res.status === 'ok') {
            let { quiz, courses: quizCourses } = res.data.result.data;
            state.question = JSON.parse(quiz.question).text;
            // console.warn('state.question:', state.question);

            state.choice1 = JSON.parse(quiz.choice1 || null)?.text;

            state.choice2 = JSON.parse(quiz.choice2 || null)?.text;

            if (quiz.choice3) choices.value.push(3);
            await nextTick();
            state.choice3 = JSON.parse(quiz.choice3 || null)?.text;

            if (quiz.choice4) choices.value.push(4);
            await nextTick();
            state.choice4 = JSON.parse(quiz.choice4 || null)?.text;

            if (quiz.choice5) choices.value.push(5);
            await nextTick();
            state.choice5 = JSON.parse(quiz.choice5 || null)?.text;

            state.courses = quizCourses;
            state.answer = availableAnswers.value.find((a) => `choice${a.value}` === quiz.answer)?.value;
            state.chapter = quiz.chapter;
            state.rights = quizRights.value.find((d) => d.value === quiz.rights)?.value;
            state.difficulty = quiz.difficulty;
            state.active = quiz.active;
            state.description = JSON.parse(quiz.description || null)?.text;
            state.order_num = quiz.order_num;

            selectedCourses.value = courses.value.filter((c) => quizCourses.find((qc) => qc === c.id));
        } else {
            toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Προσπαθήστε ξανά!', life: 3000 });
            onHide(false);
        }
    } else {
        let preselectedCourse = courses.value.find((c) => data?.courseID === c.id);
        if (preselectedCourse) selectedCourses.value = [preselectedCourse];

        //sort courses so that selected ones are first
        let selected = selectedCourses.value;
        let unselected = courses.value.filter((c) => !selectedCourses.value.find((sc) => sc.id === c.id));
        courses.value = selected.concat(unselected);
    }
    loading.value = false;
};

const fetchCourses = async () => {
    let res = await RPCApi(RPCMethods.Courses.Fetch, { withQuizOnly: isTeacher.value });
    console.log(res);
    let d = [];
    if (res.status == 'ok') {
        const { total, coursesData } = res.data.result.data;
        totalCourses.value = total;

        for (let obj of coursesData) {
            d.push({
                id: obj.id,
                title: obj.title,
                categoryName: obj.categoryName,
                categoryID: obj.categoryID,
                subCateg1: obj.subCateg1,
                subCateg2: obj.subCateg2,
                semester: obj.semester,
                hours: obj.hours,
                price: obj.price,
                hours_private: obj.hours_private,
                price_private: obj.price_private,
                teacherID: obj.teacher_id,
                examDate: Utils.datetimeToDate(obj.examination_date),
                details: obj.details,
                description: obj.description,
                site: obj.site,
                active: obj.active === 1 ? true : false,
            });
        }
        courses.value = d;
        loading.value = false;
    }
};

const onHide = (action = false, payload = null) => {
    console.log('onHide', action, payload);
    dialogShown.value = false;
    if (!action) selectedCourses.value = [];
    resetForm();
    closeCB.value(action, payload);
};

const handleSubmit = async () => {
    submitted.value = true;

    //check if form is valid
    if (selectedCourses.value.length === 0) {
        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Το πεδίο "Σχετιζόμενα μαθήματα" είναι υποχρεωτικό!', life: 3000 });
        return;
    }

    if (!state.question) {
        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Το πεδίο "Ερώτηση" είναι υποχρεωτικό!', life: 3000 });
        return;
    }

    if (!state.choice1) {
        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Το πεδίο "Επιλογή Α" είναι υποχρεωτικό!', life: 3000 });
        return;
    }

    if (!state.choice2) {
        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Το πεδίο "Επιλογή Β" είναι υποχρεωτικό!', life: 3000 });
        return;
    }

    if (!state.answer) {
        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Το πεδίο "Σωστή απάντηση" είναι υποχρεωτικό!', life: 3000 });
        return;
    }

    if (!state.chapter) {
        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Το πεδίο "Κεφάλαιο" είναι υποχρεωτικό!', life: 3000 });
        return;
    }

    if (!state.rights) {
        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Το πεδίο "Δικαιώματα" είναι υποχρεωτικό!', life: 3000 });
        return;
    }

    loading.value = true;

    console.log('all files uploaded, go on...');

    let res;
    let payload = {
        question: null,
        choice1: null,
        choice2: null,
        choice3: null,
        choice4: null,
        choice5: null,
        description: null,
    };

    for (let key in payload) {
        if (state[key]) {
            if (editMode.value) {
                if (key === 'description') {
                    payload[key] = JSON.stringify({ text: state[key] });
                } else if (key === 'question' || key === 'choice1' || key === 'choice2' || key === 'choice3' || key === 'choice4' || key === 'choice5') {
                    payload[key] = JSON.stringify({ text: state[key] });
                }
            } else {
                payload[key] = JSON.stringify({ text: state[key] });
            }
        }
    }

    payload.answer = state.answer;
    payload.courses = selectedCourses.value.map((c) => c.id);
    payload.chapter = state.chapter.value || state.chapter;
    payload.rights = state.rights;
    payload.difficulty = state.difficulty;

    console.log('payload:', payload);

    if (payload.chapter) {
        let alreadyStoredChapters = localStorage.getItem('chapters');
        if (alreadyStoredChapters) {
            let storedChapters = JSON.parse(alreadyStoredChapters);
            if (!storedChapters.find((c) => c.value === payload.chapter)) {
                storedChapters.push({ label: payload.chapter.value || payload.chapter, value: payload.chapter.value || payload.chapter });
                availableChapters.value = storedChapters;
            }
        } else {
            availableChapters.value.push({ label: payload.chapter.value || payload.chapter, value: payload.chapter.value || payload.chapter });
        }
        localStorage.setItem('chapters', JSON.stringify(availableChapters.value));
    }

    if (editMode.value) {
        payload.id = state.id;
        res = await RPCApi(RPCMethods.Quizzes.UpdateQuiz, payload);
    } else {
        res = await RPCApi(RPCMethods.Quizzes.AddQuiz, payload);
    }

    if (res.status === 'ok') {
        toast.add({ severity: 'success', summary: 'Η διαδικασία ολοκληρώθηκε', detail: 'Επιτυχής διαδικασία!', life: 3000 });
        if (editMode.value) {
            onHide(true);
            return;
        }
        loading.value = false;
        resetForm();
        scrollTop();

        return;
    }

    toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Προσπαθήστε ξανά!', life: 3000 });
    loading.value = false;
};

const scrollTop = () => {
    const dialogContent = document.querySelector('.p-dialog .p-dialog-content');
    if (dialogContent) {
        dialogContent.scrollTop = 0;
    }
};
const resetForm = () => {
    for (let field in state) {
        state[field] = null;
    }
    state.chapter = availableChapters.value[0].value;
    choices.value = [1, 2, 3, 4];
    submitted.value = false;
    loading.value = false;
};

const editCourseDialog = (course) => {
    console.log('editCourseDialog', course);
    popupStore.show(Utils.Popups.CourseDialog, course.id, (action) => {});
};

const onCourseRowSelected = (event) => {
    console.log('onCourseRowSelected: ', event.data);
};

const onCourseRowUnselected = (event) => {
    console.log('onCourseRowUnselected: ', event.data);
};

const handleClipChange = (e) => {
    let files = e.target.files;
    console.log('handleClipChange:', files);
    state.descriptionClip = files[0];
};

const removeChoice = (target) => {
    console.log('removeChoice: ' + target);
    switch (target) {
        case 'choice5':
            state.choice5 = null;
            choices.value = choices.value.filter((c) => c !== 5);
            break;
        case 'choice4':
            state.choice4 = null;
            choices.value = choices.value.filter((c) => c !== 4);
            break;
        case 'choice3':
            state.choice3 = null;
            choices.value = choices.value.filter((c) => c !== 3);
            break;
        default:
            break;
    }
};

const addChoice = () => {
    console.log('addChoice');
    if (choices.value.length < 5) {
        choices.value.push(choices.value.length + 1);
    }
};

const onActivateQuiz = async () => {
    console.log('onActivateQuiz');
    let res = await RPCApi(RPCMethods.Quizzes.ActivateQuiz, { id: state.id, active: state.active === 0 ? 1 : 0 });
    if (res.status === 'ok') {
        toast.add({ severity: 'success', summary: 'Η διαδικασία ολοκληρώθηκε', detail: 'Επιτυχής διαδικασία!', life: 3000 });
        loading.value = false;
        onHide(true);
        return;
    }

    toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Προσπαθήστε ξανά!', life: 3000 });
    loading.value = false;
};

const searchChapters = (e) => {
    console.log('searchChapters');

    availableChapters.value = [];
    if (localStorage.getItem('chapters')) {
        availableChapters.value = JSON.parse(localStorage.getItem('chapters'));
    } else {
        availableChapters.value = [
            {
                label: 'Κεφάλαιο 1',
                value: 'Κεφάλαιο 1',
            },
        ];
    }
};

const onPreview = () => {
    console.log('onPreview!');
    let payload = {
        quizzes: [
            {
                id: state.id,
                question: state.question,
                choice1: state.choice1,
                choice2: state.choice2,
                choice3: state.choice3,
                choice4: state.choice4,
                choice5: state.choice5,
                answer: state.answer,
                chapter: state.chapter,
                rights: state.rights,
                difficulty: state.difficulty,
                description: state.description,
            },
        ],
        previewMode: true,
    };
    popupStore.show(Utils.Popups.QuizPlayDialog, payload, (action) => {});
};

defineExpose({
    show,
});
</script>
