<template>
    <Dialog
        v-model:visible="dialogShown"
        class="p-fluid"
        :close-on-escape="false"
        :dismissable-mask="false"
        :modal="true"
        :breakpoints="{ '960px': '75vw', '640px': '95vw' }"
        :style="{ width: '95vw' }"
        @hide="onHide"
    >
        <template #header>
            <h4>{{ headerTitle }}</h4>
        </template>
        <div class="formgrid grid p-fluid">
            <div class="col-6" :class="{ 'col-12': !showRecipientsDatatable }">
                <div v-if="forcedRecipients == null || forcedRecipients == 'categories'" class="field-checkbox">
                    <Checkbox id="noReply" v-model="noReply" :binary="true" :disabled="!userSpecific || replyMode || outboxMsg" />
                    <label for="noReply">Χωρίς Απάντηση</label>
                </div>
                <div v-if="forcedRecipients == null || forcedRecipients == 'categories'" class="field-checkbox">
                    <Checkbox id="sendgridActive" v-model="sendgridActive" :binary="true" :disabled="replyMode || outboxMsg" />
                    <label for="sendgridActive">Αποστολή Email</label>
                </div>
                <div v-if="userSpecific && editMode && !outboxMsg" class="field-checkbox">
                    <Checkbox id="markRead" v-model="markRead" :binary="true" @change="onMarkReadChanged" />
                    <label for="markRead">Το διάβασα</label>
                </div>
                <div v-if="forcedRecipients == 'support'" class="field">
                    <!-- <label>Πρός:</label> -->
                    <!-- <span style="padding-left: 10px; font-weight: 800">Vitali Support</span> -->
                    <span style="font-weight: 800">Vitali Support</span>
                </div>
                <div v-else-if="forcedRecipients == 'adminAndOffice'" class="field">
                    <label v-if="editMode">Από:</label>
                    <label v-else>Προς:</label>
                    <div>
                        <span style="font-weight: 800">Vitali Support</span>
                        <!-- <div class="field-checkbox">
                            <Checkbox v-model="toAdmin" :binary="true" :disabled="editMode" @change="toAdminChanged" />
                            <label>Διεύθυνση φροντιστηρίου</label>
                        </div>
                        <div>
                            <div class="field-checkbox">
                                <Checkbox v-model="toOffice" :disabled="editMode" :binary="true" @change="toOfficeChanged" />
                                <label>Vitali Support</label>
                            </div>
                        </div> -->
                    </div>
                </div>
                <div v-if="!lessonNotificationID && !forcedRecipients" class="field">
                    <label :class="{ 'p-error': v$.sendDate.$invalid && submitted }">Ημερομηνία*</label>
                    <Calendar
                        v-model="v$.sendDate.$model"
                        date-format="dd/mm/yy"
                        placeholder="Επιλογή Ημ/νίας"
                        :class="{ 'p-invalid': v$.sendDate.$invalid && submitted }"
                        :show-time="true"
                        :stepMinute="30"
                        show-icon
                        @date-select="onSendDateSelected"
                    />
                    <small v-if="(v$.sendDate.$invalid && submitted) || v$.sendDate.$pending.$response" class="p-error">{{ v$.sendDate.required.$message }}</small>
                </div>
                <!-- <div v-if="isAdmin" class="field">
                    <label>Αποστολή ώς</label>
                    <Dropdown v-model="selectedSenderRole" option-label="label" :options="senderRoles" placeholder="Επιλογή">
                        <template #value="slotProps">
                            <span v-if="slotProps.value != null">{{ slotProps.value.label }}</span>
                            <span v-else>Επιλογή</span>
                        </template>
                        <template #option="slotProps">
                            <span>{{ slotProps.option.label }}</span>
                        </template>
                    </Dropdown>
                </div> -->
                <div v-if="isAdminOrOffice && !editMode" class="field">
                    <label>Template</label>
                    <Dropdown v-model="selectedTemplate" option-label="label" :options="notificationTemplates" placeholder="Επιλογή" @change="onTemplateChanged">
                        <template #value="slotProps">
                            <span v-if="slotProps.value != null">{{ slotProps.value.label }}</span>
                            <span v-else>Επιλογή</span>
                        </template>
                        <template #option="slotProps">
                            <span>{{ slotProps.option.label }}</span>
                        </template>
                    </Dropdown>
                </div>
                <div class="field">
                    <label :class="{ 'p-error': v$.title.$invalid && submitted }">Τίτλος*</label>
                    <InputText v-model="v$.title.$model" :disabled="replyMode" autocomplete="off" spellcheck="off" placeholder="Τίτλος" :class="{ 'p-invalid': v$.title.$invalid && submitted }" />
                    <small v-if="(v$.title.$invalid && submitted) || v$.title.$pending.$response" class="p-error">{{ v$.title.required.$message }}</small>
                </div>
                <div v-if="replyMode" class="field">
                    <SyncfusionRTE v-model="v$.body.$model" />
                </div>
                <div v-else class="field">
                    <label :class="{ 'p-error': v$.body.$invalid && submitted }">Κείμενο*</label>
                    <SyncfusionRTE v-model="v$.body.$model" />
                    <small v-if="(v$.body.$invalid && submitted) || v$.body.$pending.$response" class="p-error">{{ v$.body.required.$message }}</small>
                </div>
                <div v-if="replyMode && !outboxMsg" class="field">
                    <label>Απάντηση</label>
                    <SyncfusionRTE v-model="state.bodyAnswer" />
                    <small v-if="(v$.body.$invalid && submitted) || v$.body.$pending.$response" class="p-error">{{ v$.body.required.$message }}</small>
                </div>
                <div v-if="isAdminOrOffice" class="field">
                    <label>Επισυνάψεις</label>
                    <NotificationsUploader ref="uploader"></NotificationsUploader>
                </div>
            </div>
            <div v-if="!userSpecific && !replyMode" class="col-6">
                <div class="field">
                    <label>Παραλήπτες</label>
                    <DataTable
                        ref="recipientsTable"
                        v-model:selection="v$.recipients.$model"
                        :total-records="totalRecipients"
                        :loading="loadingRecipients"
                        :row-hover="true"
                        :value="recipients"
                        data-key="id"
                        responsive-layout="scroll"
                        :rows="recipientsRowsPerPage"
                        :paginator="true"
                    >
                        <template #empty> Δεν βρέθηκαν παραλήπτες. </template>
                        <Column selection-mode="multiple" header-style="width: 3rem"> </Column>
                        <Column header="Ονοματεπώνυμο" field="fullname" filter-field="lname" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                            <template #body="{ data }">
                                <div class="flex">
                                    <Avatar :image="Utils.GetGenericAvatar(data.id)" class="flex align-items-center border-1 border-300" size="medium" shape="circle" />
                                    <a
                                        class="flex-initial flex align-items-center justify-content-center ml-2 font-medium no-underline text-left cursor-pointer"
                                        style="color: blue"
                                        @click="showRecipientDialog(data.id)"
                                        >{{ data.fullname }}</a
                                    >
                                </div>
                            </template>
                        </Column>
                        <Column header="Email" filter-field="email" field="email" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                            <template #body="{ data }">
                                {{ data.email }}
                            </template>
                        </Column>
                        <Column field="ban" filter-field="ban" header="Ενεργός" data-type="boolean" body-class="text-left" :sortable="true">
                            <template #body="{ data }">
                                <i class="pi" :class="{ 'true-icon pi-check-circle': !data.ban, 'false-icon pi-times-circle': data.ban }"></i>
                            </template>
                        </Column>
                        <Column field="requestStatus" filter-field="requestStatus" header="Αίτηση" body-class="text-left" style="min-width: 10rem">
                            <template #body="{ data }">
                                <span class="status-badge" :class="`status-${data.requestStatus}`">{{ Utils.RequestStatusToString(data.requestStatus) }}</span>
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>
            <div v-else-if="forcedRecipients == 'categories' && !replyMode && !outboxMsg" class="col-6">
                <div class="field">
                    <label>Παραλήπτες</label>
                    <Dropdown v-model="recipientsSelectedRole" :options="availableRecipientsRoles" option-label="label" @change="onRecipientsSelectedRoleChanged">
                        <template #value="slotProps">
                            <span v-if="slotProps.value != null">{{ slotProps.value.label }}</span>
                            <span v-else>Επιλογή</span>
                        </template>
                        <template #option="slotProps">
                            <span>{{ slotProps.option.label }}</span>
                        </template>
                    </Dropdown>
                    <DataTable
                        v-if="showStudentsRecipientsTable"
                        ref="dt"
                        v-model:selection="v$.recipients.$model"
                        v-model:filters="tableFilters"
                        :lazy="true"
                        :total-records="totalRequests"
                        :loading="loadingRecipients"
                        :row-hover="true"
                        :value="requests"
                        filter-display="row"
                        data-key="id"
                        responsive-layout="scroll"
                        :rows="rowsPerPage"
                        :paginator="true"
                        :selectAll="selectAllStudents"
                        @select-all-change="onSelectAllStudentsChange"
                        @row-select="onRowStudentsSelect"
                        @row-unselect="onRowStudentsUnselect"
                        @page="onPage($event)"
                        @sort="onSort($event)"
                        @filter="onFilter($event)"
                    >
                        <template #header>
                            <div class="flex flex-row justify-content-between">
                                <div v-if="isAdminOrOffice" class="flex flex-row">
                                    <div class="h-3 pr-2">
                                        <AutoComplete
                                            id="category"
                                            v-model="tableFilters['category'].value"
                                            :suggestions="filteredCategories"
                                            force-selection
                                            field="name"
                                            placeholder="Κατηγορία"
                                            :dropdown="true"
                                            @item-select="onCategorySelect"
                                            @complete="searchCategory($event)"
                                        >
                                        </AutoComplete>
                                    </div>
                                    <div class="h-3 pr-2">
                                        <AutoComplete
                                            id="subCateg1"
                                            v-model="tableFilters['subCateg1'].value"
                                            force-selection
                                            :suggestions="filteredSubCategories1"
                                            placeholder="Υπ/ρία Ι"
                                            :dropdown="true"
                                            @item-select="onSubCateg1Select"
                                            @complete="searchSubCategory1($event)"
                                        >
                                        </AutoComplete>
                                    </div>
                                    <div class="h-3 pr-2">
                                        <AutoComplete
                                            id="subCateg2"
                                            v-model="tableFilters['subCateg2'].value"
                                            force-selection
                                            :suggestions="filteredSubCategories2"
                                            placeholder="Υπ/ρία ΙI"
                                            :dropdown="true"
                                            @item-select="onSubCateg2Select"
                                            @complete="searchSubCategory2($event)"
                                        >
                                        </AutoComplete>
                                    </div>
                                    <div class="h-3 pr-2">
                                        <AutoComplete
                                            id="title"
                                            v-model="tableFilters['courseTitle'].value"
                                            :suggestions="filteredCourses"
                                            force-selection
                                            :field="courseTitleLblFunction"
                                            placeholder="Mάθημα"
                                            :dropdown="true"
                                            @item-select="onCourseTitleSelect"
                                            @complete="searchCourses($event)"
                                        >
                                        </AutoComplete>
                                    </div>
                                    <Button icon="pi pi-filter-slash" class="mr-2 mb-2 p-button-text" @click="resetGlobalFilters" />
                                </div>
                            </div>
                        </template>
                        <template #empty> Δεν βρέθηκαν αιτήσεις. </template>
                        <Column selection-mode="multiple" header-style="width: 3rem"> </Column>
                        <Column v-if="isAdminOrOffice" header="Μαθητής" field="studentName" filter-field="studentName" :sortable="true" :show-filter-menu="false">
                            <template #body="{ data }">
                                <div class="flex">
                                    <Avatar :image="Utils.GetGenericAvatar(data.id)" class="flex align-items-center border-1 border-300" size="medium" shape="circle" />
                                    <a
                                        class="flex-initial flex align-items-center justify-content-center ml-2 font-medium no-underline text-left cursor-pointer"
                                        style="color: blue"
                                        @click="showStudentDialog(data.recipientID)"
                                        >{{ data.studentName }}</a
                                    >
                                </div>
                            </template>
                        </Column>
                        <Column header="Μάθημα" field="title" filter-field="title" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                            <template #body="{ data }">
                                {{ data.title }}
                            </template>
                        </Column>
                        <Column header="Σχολή" field="school" filter-field="school" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                            <template #body="{ data }">
                                {{ data.school }}
                            </template>
                        </Column>
                        <Column field="isPrivate" filter-field="isPrivate" header="Τύπος" data-type="boolean" body-class="text-left" style="min-width: 8rem">
                            <template #body="{ data }">
                                <span class="private-badge" :class="`private-${data.isPrivate}`">{{ Utils.Types[Utils.FromBoolToTinyInt(data.isPrivate)].label }}</span>
                            </template>
                            <template #filter="{ filterModel, filterCallback }">
                                <Dropdown
                                    v-model="filterModel.value"
                                    option-label="label"
                                    :options="Utils.Types"
                                    placeholder="Όλοι"
                                    class="p-column-filter"
                                    :show-clear="true"
                                    @change="filterCallback"
                                >
                                    <template #value="slotProps">
                                        <span v-if="slotProps.value != null" class="private-badge" :class="`private-${slotProps.value.value === 1}`">{{ slotProps.value.label }}</span>
                                        <span v-else>Όλοι</span>
                                    </template>
                                    <template #option="slotProps">
                                        <span class="private-badge" :class="`private-${slotProps.option.value === 1}`">{{ slotProps.option.label }}</span>
                                    </template>
                                </Dropdown>
                            </template>
                        </Column>
                        <Column field="status" filter-field="status" header="Κατάσταση" body-class="text-left" style="min-width: 10rem" :show-filter-menu="false">
                            <template #body="{ data }">
                                <span class="status-badge" :class="`status-${data.status}`">{{ Utils.RequestStatusToString(data.status) }}</span>
                            </template>
                            <template #filter="{ filterModel, filterCallback }">
                                <Dropdown
                                    v-model="filterModel.value"
                                    option-label="label"
                                    :options="Utils.RequestStatuses"
                                    placeholder="Όλες"
                                    class="p-column-filter"
                                    :show-clear="true"
                                    @change="filterCallback"
                                >
                                    <template #value="slotProps">
                                        <span v-if="slotProps.value != null" :class="`status-badge status-${slotProps.value.value}`">{{ slotProps.value.label }}</span>
                                        <span v-else>Όλες</span>
                                    </template>
                                    <template #option="slotProps">
                                        <span class="status-badge" :class="`status-${slotProps.option.value}`">{{ slotProps.option.label }}</span>
                                    </template>
                                </Dropdown>
                            </template>
                        </Column>
                        <Column header="Εξεταστική" field="examPeriod" filter-field="examPeriod" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                            <template #body="{ data }">
                                {{ data.examsPeriodTitle }}
                            </template>
                            <template #filter="{ filterModel, filterCallback }">
                                <Dropdown
                                    v-model="filterModel.value"
                                    option-label="label"
                                    :options="examPeriods"
                                    placeholder="Όλες"
                                    class="p-column-filter"
                                    :show-clear="true"
                                    @change="filterCallback"
                                >
                                    <template #value="slotProps">
                                        <span v-if="slotProps.value != null">{{ slotProps.value.label }}</span>
                                        <span v-else>Όλες</span>
                                    </template>
                                    <template #option="slotProps">
                                        <span>{{ slotProps.option.label }}</span>
                                    </template>
                                </Dropdown>
                            </template>
                        </Column>
                    </DataTable>
                    <DataTable
                        v-else-if="showTeachersRecipientsTable"
                        ref="dt"
                        v-model:selection="v$.recipients.$model"
                        v-model:filters="tableTeachersFilters"
                        :total-records="totalTeachers"
                        :lazy="true"
                        :loading="loadingRecipients"
                        :row-hover="true"
                        :value="teachers"
                        :selectAll="selectAllTeachers"
                        filter-display="row"
                        data-key="id"
                        responsive-layout="scroll"
                        @select-all-change="onSelectAllTeachersChange"
                        @row-select="onRowTeachersSelect"
                        @row-unselect="onRowTeachersUnselect"
                        @sort="onTeachersSort($event)"
                        @filter="onTeachersFilter($event)"
                    >
                        <template #header> </template>
                        <template #empty> Δεν βρέθηκαν καθηγητές. </template>
                        <Column selection-mode="multiple" header-style="width: 3rem"> </Column>
                        <Column header="Ονοματεπώνυμο" field="fullname" filter-field="lname" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                            <template #body="{ data }">
                                <div class="flex">
                                    <Avatar :image="Utils.GetGenericAvatar(data.id)" class="flex align-items-center border-1 border-300" size="medium" shape="circle" />
                                    <a
                                        class="flex-initial flex align-items-center justify-content-center ml-2 font-medium no-underline text-left cursor-pointer"
                                        style="color: blue"
                                        @click="showTeacherDialog(data.recipientID)"
                                        >{{ data.fullname }}</a
                                    >
                                </div>
                            </template>
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Εύρεση βάση ονόματος" @keyup="filterCallback()" />
                            </template>
                        </Column>
                        <Column header="Email" filter-field="email" field="email" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                            <template #body="{ data }">
                                {{ data.email }}
                            </template>
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Εύρεση βάση email" @keyup="filterCallback()" />
                            </template>
                        </Column>
                        <Column header="Τηλέφωνο" field="mobile" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                            <template #body="{ data }">
                                {{ data.mobile }}
                            </template>
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Εύρεση βάση τηλ" @keyup="filterCallback()" />
                            </template>
                        </Column>
                        <Column header="Ειδικότητα" field="departments" filter-field="departments" :sortable="true" :show-filter-menu="false" style="max-width: 22rem">
                            <template #body="{ data }">
                                <span v-for="(item, index) in data.departments" :key="item.departmentID">{{ item.departmentName }} <span v-if="index < data.departments.length - 1">, </span></span>
                            </template>
                            <template #filter="{ filterModel, filterCallback }">
                                <MultiSelect v-model="filterModel.value" :options="departments" placeholder="Όλα" class="p-column-filter" :showClear="true" @change="filterCallback()">
                                    <template #value="slotProps">
                                        <span v-if="slotProps.value && slotProps.value.length">
                                            {{ slotProps.value.map((option) => option.label).join(', ') }}
                                        </span>
                                        <span v-else>Όλα</span>
                                    </template>
                                    <template #option="slotProps">
                                        <span>{{ slotProps.option?.label }}</span>
                                    </template>
                                </MultiSelect>
                            </template>
                        </Column>
                        <Column field="ban" filter-field="ban" header="Ενεργός" data-type="boolean" body-class="text-left">
                            <template #body="{ data }">
                                <i class="pi" :class="{ 'true-icon pi-check-circle': !data.ban, 'false-icon pi-times-circle': data.ban }"></i>
                            </template>
                            <template #filter="{ filterModel, filterCallback }">
                                <TriStateCheckbox v-model="filterModel.value" @change="filterCallback" />
                            </template>
                        </Column>
                        <Column field="lastLoginDatetime" filter-field="lastLoginDatetime" header="Login" body-class="text-left" data-type="boolean">
                            <template #body="slotProps">
                                <i
                                    class="pi"
                                    :class="{ 'true-icon pi-check-circle': slotProps.data.lastLoginDatetime != null, 'false-icon pi-times-circle': slotProps.data.lastLoginDatetime == null }"
                                ></i>
                            </template>
                            <template #filter="{ filterModel, filterCallback }">
                                <TriStateCheckbox v-model="filterModel.value" class="p-column-filter" @change="filterCallback" />
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>
        </div>
        <template v-if="showFooter" #footer>
            <Button label="Αποστολή" icon="pi pi-send" class="p-button-primary w-10rem" :loading="loading" @click="handleSubmit(!v$.$invalid)" />
            <Button label="Άκυρο" icon="pi pi-times" class="p-button-outlined w-10rem mr-3" @click="onHide(false)" />
        </template>
    </Dialog>
</template>

<script setup>
import { useToast } from 'primevue/usetoast';
import moment from 'moment-timezone';
import { onMounted, reactive, ref, computed, watchEffect, toRefs, nextTick } from 'vue';
import { required, integer, helpers } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import useRPC from '@/composables/useRPC';
import useTemplates from '@/composables/useTemplates';
import Utils from '@/utils/Utils';
import { useConfirm } from 'primevue/useconfirm';
import NotificationsUploader from '@/components/NotificationsUploader.vue';
import { useDebounceFn } from '@vueuse/core';
import { nanoid } from 'nanoid';
import { usePopupStore } from '@/stores/PopupStore.js';
import { FilterMatchMode } from 'primevue/api';
import QuillEditor from '@/components/QuillEditor.vue';
import SyncfusionRTE from '@/components/SyncfusionRTE.vue';

const departments = ref([]);
const teachers = ref([]);
const totalTeachers = ref(0);
const dt = ref();
const examPeriods = ref([]);
const selectedTemplate = ref(null);
const notificationTemplates = ref([]);
const recipientsTable = ref();
const filteredCategories = ref([]);
const filteredSubCategories1 = ref([]);
const filteredSubCategories2 = ref([]);
const filteredExamPeriods = ref([]);
const filteredCourses = ref([]);
const filteredPeriods = ref([]);
const categories = ref([]);
const schools = ref([]);
const { RPCApi, RPCMethods } = useRPC();
const {
    LessonReminder,
    FirstLessonReminder,
    WelcomeInvitation,
    EconomicsNotification,
    ClassRegistration,
    SendNotes,
    BankAccount,
    PayClassHoursHalf,
    PayClassHours,
    PayPrivateClassHoursOnePerson,
    PayPrivateClassHoursTwoPersons,
    PayPrivateClassHoursThreePersons,
    PayClassPostGraduate,
    PrepareEsdda,
    NotesEsdda,
    JobPostings,
    ZoomLink,
    AvailableTemplates,
} = useTemplates();
const dialogShown = ref(false);
const submitted = ref(false);
const headerTitle = ref('');
const confirm = useConfirm();
const popupStore = usePopupStore();
const noReply = ref(true);
const sendgridActive = ref(true);
const markRead = ref(true);
const attachmentsBucketID = ref(null);
const uploader = ref(null);
const toAdmin = ref(false);
const toOffice = ref(false);
const requests = ref([]);
const totalRequests = ref(0);
const rowsPerPage = ref(10);
const fetchParams = ref({});
const selectAllStudents = ref(false);
const selectAllTeachers = ref(false);
const fetchTeachersParams = ref({});
const showRecipientsDatatable = computed(() => {
    if (outboxMsg.value) {
        return false;
    }
    if (replyMode.value) return false;
    return !userSpecific.value || forcedRecipients.value == 'categories';
});

const recipientsSelectedRole = ref(null);

const showStudentsRecipientsTable = computed(() => {
    console.log(recipientsSelectedRole.value);
    return recipientsSelectedRole.value?.value == Utils.Roles.Student;
});

const showTeachersRecipientsTable = computed(() => {
    return recipientsSelectedRole.value?.value == Utils.Roles.Teacher;
});

const availableRecipientsRoles = [
    { label: 'Καθηγητές', value: Utils.Roles.Teacher },
    { label: 'Μαθητές', value: Utils.Roles.Student },
    { label: 'Vitali Support', value: Utils.Roles.Οffice },
];
// const selectedSenderRole = ref(null);
const senderRoles = ref([]);
const state = reactive({
    id: null,
    title: null,
    body: null,
    bodyAnswer: null,
    sendDate: new Date(),
    recipients: null,
    classID: null,
    classTitle: null,
    lessonStartAt: null,
    zoomMeetingURL: null,
    classPrice: null,
});
const loadingRecipients = ref(false);
const rules = {
    title: { required },
    body: { required },
    sendDate: {
        required,
    },
    recipients: { required },
};

const tableFilters = ref({
    title: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    school: { value: null, matchMode: FilterMatchMode.CONTAINS },
    examPeriod: { value: null, matchMode: FilterMatchMode.CONTAINS },
    studentName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    accountableID: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    isPrivate: { value: null, matchMode: FilterMatchMode.EQUALS },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
    category: { value: null, matchMode: FilterMatchMode.EQUALS },
    subCateg1: { value: null, matchMode: FilterMatchMode.EQUALS },
    subCateg2: { value: null, matchMode: FilterMatchMode.EQUALS },
    courseTitle: { value: null, matchMode: FilterMatchMode.EQUALS },
});
const tableTeachersFilters = ref({
    lname: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    mobile: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    ban: { value: true, matchMode: FilterMatchMode.EQUALS },
    departments: { value: null, matchMode: FilterMatchMode.IN },
    lastLoginDatetime: { value: null, matchMode: FilterMatchMode.EQUALS },
});
const totalRecipients = ref(0);

const v$ = useVuelidate(rules, state, { $stopPropagation: true });
const loading = ref(false);
const toast = useToast();
const editMode = ref(false);
const recipientsRowsPerPage = ref(10);
const recipients = ref([]);
const userSpecific = ref(false);
const closeCB = ref(null);
const firstLessonNotification = ref(false);
const lessonNotificationID = ref(null);
const showOnly = ref(false);
const outboxMsg = ref(false);
const hoursInSecs = 3600000;
const cloneMode = ref(false);
const forcedRecipients = ref(null);

const adminRecipients = ref([]);
const officeRecipients = ref([]);

const replyMode = ref(false);

const isAdmin = computed(() => {
    return Utils.UserInfo?.role === Utils.Roles.Admin;
});

const isOffice = computed(() => {
    return Utils.UserInfo?.role === Utils.Roles.Office;
});

const isStudent = computed(() => {
    return Utils.UserInfo && Utils.UserInfo.role === Utils.Roles.Student;
});

const isTeacher = computed(() => {
    return Utils.UserInfo && Utils.UserInfo.role === Utils.Roles.Teacher;
});

const isAdminOrOffice = computed(() => {
    return isAdmin.value || isOffice.value;
});

const onPage = useDebounceFn((event) => {
    // console.log('onPage', event);
    fetchParams.value.paging = { offset: event.first, limit: rowsPerPage };
    fetchRecipients();
}, 300);

const onSort = useDebounceFn((event) => {
    // console.log('onSort', event);
    if (event.sortField === 'fullname') event.sortField = 'lname';
    fetchParams.value.paging = { offset: 0, limit: rowsPerPage };
    fetchParams.value.sort = { sortField: event.sortField, sortOrder: event.sortOrder };
    fetchRecipients();
}, 300);

const onFilter = useDebounceFn(() => {
    // console.log('onFilter', tableFilters.value);
    fetchParams.value.paging = { offset: 0, limit: rowsPerPage };
    fetchParams.value.filters = {
        title: tableFilters.value.title,
        examsPeriodTitle: { value: tableFilters.value.examPeriod.value?.label, matchMode: FilterMatchMode.EQUALS },
        accountableID: { value: tableFilters.value.accountableID.value?.value, matchMode: FilterMatchMode.EQUALS },
        isPrivate: { value: tableFilters.value.isPrivate.value?.value, matchMode: FilterMatchMode.EQUALS },
        status: { value: tableFilters.value.status.value?.value, matchMode: FilterMatchMode.EQUALS },
        category: { value: tableFilters.value.category.value?.id, matchMode: FilterMatchMode.EQUALS },
        subCateg1: { value: tableFilters.value.subCateg1.value, matchMode: FilterMatchMode.EQUALS },
        subCateg2: { value: tableFilters.value.subCateg2.value, matchMode: FilterMatchMode.EQUALS },
        courseID: { value: tableFilters.value.courseTitle.value?.id, matchMode: FilterMatchMode.EQUALS },
    };
    onSelectAllStudentsChange({ checked: false });
    fetchRecipients();
}, 1000);

const showFooter = computed(() => {
    // console.log('showOnly:', showOnly.value);
    // console.log('cloneMode:', cloneMode.value);
    // console.log('editMode:', editMode.value);
    // console.log('noReply:', noReply.value);
    if (outboxMsg.value) {
        return false;
    }
    if (isTeacher.value && !editMode.value) {
        return true;
    }
    if (showOnly.value) return false;
    if (isAdminOrOffice.value) return true;
    if (noReply.value && !cloneMode.value) return false;

    return true;
});

const onTemplateChanged = async (e) => {
    console.log(notificationTemplates.value);
    console.log(selectedTemplate.value);

    const templateIns = notificationTemplates.value.find((t) => t.label === selectedTemplate.value.label);
    if (templateIns && templateIns.value) {
        let { title, body } = templateIns.value();

        let isStudent = false;
        if (selectedTemplate.value.id === 3) {
            console.log("create temp password for user if he hasn't one");
            if (state.recipients.length === 1) {
                loading.value = true;
                let userRes = await RPCApi(RPCMethods.User.GetUserInfoById, { id: state.recipients[0] });
                if (userRes.status === 'ok') {
                    let notLoggedIn = userRes.data.result.data.userInfo.last_login_datetime == null;
                    isStudent = userRes.data.result.data.userInfo.role === Utils.Roles.Student;
                    let templateUpdated = templateIns.value(isStudent, !notLoggedIn, userRes.data.result.data.userInfo.email);
                    title = templateUpdated.title;
                    body = templateUpdated.body;
                    if (notLoggedIn) {
                        let createTempPass = await RPCApi(RPCMethods.User.CreateTempPassword, { id: state.recipients[0] });
                        if (createTempPass.status === 'ok') {
                            body = body.replace(
                                '__temp_credentials__',
                                `
                            <p>Για να συνδεθείτε, πατήστε εδώ: <a href='${Utils.FrontendDomainURL}/login?email=${userRes.data.result.data.userInfo.email}'>WebApp</a></p>
                            <p>Το όνομα χρήστη είναι το email που μας έχετε δηλώσει (User name): <span style="color: rgb(230, 0, 0)"><b>${userRes.data.result.data.userInfo.email}</b></span></p>
                            <p>Ο κωδικός σας για να εισέλθετε στην εφαρμογή είναι : <span style="color: rgb(230, 0, 0)"><b>${createTempPass.data.result.data.tempPassword}</b></span></p>
                            <p>Ο κωδικός έχει δημιουργηθεί αυτόματα και σας προτείνουμε να τον αλλάξετε κατά την πρώτη σας είσοδο στην πλατφόρμα.</p>
                            `
                            );
                        }
                    } else {
                        body = body.replace('__temp_credentials__', '');
                    }
                }
                loading.value = false;
            } else {
                body = body.replace('__temp_credentials__', '');
            }
        }

        state.title = title;
        state.body = body;

        updateTitleAndBodyTexts();
    } else {
        state.title = null;
        state.body = null;
    }
};

const show = async (data, onCloseCB) => {
    console.warn(data);
    attachmentsBucketID.value = nanoid();
    resetForm();
    senderRoles.value = [
        { label: 'Διεύθυνση φροντιστηρίου', value: 1 },
        { label: 'Support', value: 2 },
    ];
    fetchParams.value = {
        paging: { offset: 0, limit: 10 },
        sort: null,
        filters: tableFilters.value,
    };
    fetchTeachersParams.value = {
        paging: { offset: 0, limit: 10 },
        sort: null,
        filters: tableTeachersFilters.value,
    };
    adminRecipients.value = [];
    officeRecipients.value = [];
    toOffice.value = false;
    toAdmin.value = false;
    sendgridActive.value = true;
    markRead.value = false;
    closeCB.value = onCloseCB;
    showOnly.value = data.showOnly;
    outboxMsg.value = data.outboxMsg;
    dialogShown.value = true;
    submitted.value = false;
    cloneMode.value = !!data.clone;
    editMode.value = !!data.notificationID;
    headerTitle.value = editMode.value ? `Εμφάνιση μηνύματος` : 'Νέο μήνυμα';
    notificationTemplates.value = AvailableTemplates.map((x) => ({ id: x.id, value: x.value, label: x.label }));
    if (cloneMode.value) {
        headerTitle.value = 'Κλώνος μηνύματος';
    }
    userSpecific.value = !!data.userID;
    console.warn('userSpecific????' + userSpecific.value);
    lessonNotificationID.value = data.lessonID;
    forcedRecipients.value = data.forcedRecipients;

    await fetchExamPeriods();
    if (forcedRecipients.value) {
        let departmentsRes = await RPCApi(RPCMethods.Departments.Fetch);
        let arr = [];
        if (departmentsRes.status == 'ok') {
            for (let s of departmentsRes.data.result.data) {
                if (!s.title || !s.title.trim()) continue;
                s.label = s.title;
                s.value = s.id;
                arr.push(s);
            }
        }
        departments.value = arr;

        let schoolsRes = await RPCApi(RPCMethods.Schools.Fetch);
        if (schoolsRes.status == 'ok') {
            schools.value = schoolsRes.data.result.data.schools;
            categories.value = schoolsRes.data.result.data.categories;
        }
    }
    if (!userSpecific.value) {
        console.warn('data::::', data);
        state.classTitle = data.classTitle;
        state.lessonStartAt = data.lessonStartAt;
        state.zoomMeetingURL = data.zoomMeetingURL;
        state.classID = data.classID;
        state.lessonTitle = data.lessonTitle;
        state.teacherName = data.teacherName;
        state.classPrice = data.classPrice;
        noReply.value = true;
    }

    let recipientsArr = [];
    let fetchByIdResult;
    if (editMode.value) {
        state.id = data.notificationID;
        fetchByIdResult = await RPCApi(RPCMethods.Notifications.FetchById, {
            id: data.notificationID,
        });
        console.log(fetchByIdResult);
        if (fetchByIdResult.status === 'ok') {
            const { title, body, type, runAt, status, attachmentsBucketID: bucketID, sendgrid, no_reply, sender_name, createdAt, sender_role } = fetchByIdResult.data.result.data.notification;
            recipientsArr = fetchByIdResult.data.result.data.recipients.map((r) => r.user_id);
            state.title = title;
            if (body.indexOf('---') === -1) {
                let contactDetails = null;
                if (sender_role == null || sender_name == null) {
                    contactDetails = 'Σύστημα';
                } else {
                    contactDetails = sender_role === Utils.Roles.Admin ? 'Διεύθυνση φροντιστηρίου' : sender_role === Utils.Roles.Office ? 'Γραμματεία' : `${sender_name}`;
                }

                state.body = `--- Από: ${contactDetails}, Ημ/νία: ${
                    moment(Utils.datetimeToDate(createdAt)).format('DD/MM/YYYY') + ' - ' + moment(Utils.datetimeToDate(createdAt)).format('HH:mm')
                } ---<br/><br/> ${body}`;
            } else {
                state.body = `${body}`;
            }

            state.sendDate = Utils.datetimeToDate(runAt);
            attachmentsBucketID.value = bucketID || nanoid();
            sendgridActive.value = sendgrid === 1;
            noReply.value = no_reply === 1;
            if (!userSpecific.value) state.lessonStartAt = Utils.datetimeToDate(runAt);

            let isRead = await RPCApi(RPCMethods.Notifications.MessageIsReadByUser, {
                id: data.notificationID,
            });
            // markRead.value = isRead.status === 'ok' && isRead.data.result.data.isRead;
            markRead.value = true;
            await onMarkReadChanged();
        }
    } else {
        if (isTeacher.value || isStudent.value) {
            noReply.value = false;
        }
    }

    if (userSpecific.value) {
        if (forcedRecipients.value) {
            await fetchRecipients();
        } else {
            state.recipients = [data.userID];
        }
    } else {
        if (!editMode.value && state.lessonStartAt) {
            firstLessonNotification.value = data.isFirstLesson;
            updateTitleAndBodyTexts();
            if (firstLessonNotification.value) {
                state.sendDate = new Date();
            } else {
                // 1 hour before the lesson start time
                state.sendDate = new Date(state.lessonStartAt.getTime() - hoursInSecs);
            }
        }

        await loadStudentsForClass(data.classID);
        if (!editMode.value) {
            state.recipients = [...recipients.value].filter(
                (s) =>
                    !s.ban &&
                    (s.requestStatus === Utils.RequestStatus.Posted ||
                        s.requestStatus === Utils.RequestStatus.PreRegistered ||
                        s.requestStatus === Utils.RequestStatus.OnClass ||
                        s.requestStatus === Utils.RequestStatus.Completed)
            );
        } else {
            state.recipients = recipients.value.filter((s) => recipientsArr.includes(s.id));
        }
    }

    await nextTick();
    if (isAdminOrOffice.value) {
        uploader.value.fetchFiles(attachmentsBucketID.value);
    }

    if (!editMode.value) {
        replyMode.value = false;
    } else {
        replyMode.value = !cloneMode.value && !noReply.value;
    }

    if (userSpecific.value && forcedRecipients.value === 'adminAndOffice') {
        sendgridActive.value = false;
    }

    console.log(' replyMode.value :' + replyMode.value);

    //if replyMode then the state.recipients will be the sender_id of the message (if sender_role is admin or office then the recipients will be the admin and office users)
    if (replyMode.value) {
        const { sender_id, sender_role } = fetchByIdResult.data.result.data.notification;
        console.warn('sender_id:' + sender_id);
        console.warn('sender_role:' + sender_role);
        if (sender_role === Utils.Roles.Office || sender_role === Utils.Roles.Admin) {
            let officeAndAdminUsers = await RPCApi(RPCMethods.User.FetchOfficeAndAdmin);
            if (officeAndAdminUsers.status === 'ok') {
                const { users } = officeAndAdminUsers.data.result.data;
                state.recipients = [
                    ...users
                        .filter((u) => u.ban === 0)
                        .map((u) => {
                            return { recipientID: u.id };
                        }),
                ];
                console.log('state.recipients:', state.recipients);
            }
        } else {
            state.recipients = [{ recipientID: sender_id }];
        }
    }

    loading.value = false;
};

const fetchExamPeriods = async () => {
    let examPeriodsRes = await RPCApi(RPCMethods.Periods.Fetch);
    if (examPeriodsRes.status === 'ok') {
        const { periods } = examPeriodsRes.data.result.data;
        let r = [];
        for (let period of periods) {
            if (period.active === 1)
                r.push({
                    label: `${period.title}`,
                    value: period.id,
                });
        }
        examPeriods.value = r;
    }
};

const updateTitleAndBodyTexts = async () => {
    console.warn('updateTitleAndBodyTexts!!!', state, state.recipients);
    if (userSpecific.value) return;

    let data = {};
    if (firstLessonNotification.value) {
        data = FirstLessonReminder(state.classTitle, state.teacherName, state.classTitle, state.lessonStartAt);
    } else {
        if (!selectedTemplate.value) {
            data = LessonReminder(state.classTitle, state.lessonStartAt, state.zoomMeetingURL);
        } else {
            switch (selectedTemplate.value.id) {
                case 1:
                    data = LessonReminder(state.classTitle, state.lessonStartAt, state.zoomMeetingURL);
                    break;
                case 2:
                    data = FirstLessonReminder(state.classTitle, state.teacherName, state.classTitle, state.lessonStartAt);
                    break;
                case 3:
                    data = WelcomeInvitation();
                    break;
                case 4:
                    data = EconomicsNotification();
                    break;
                case 5:
                    data = ClassRegistration(state.classTitle);
                    break;
                case 6:
                    data = SendNotes();
                    break;
                case 7:
                    data = BankAccount();
                    break;
                case 8:
                    data = PayClassHoursHalf(state.classTitle);
                    break;
                case 9:
                    data = PayClassHours(state.classTitle, state.classPrice);
                    break;
                case 10:
                    data = PayPrivateClassHoursOnePerson(state.classTitle, state.classPrice);
                    break;
                case 11:
                    data = PayPrivateClassHoursTwoPersons(state.classTitle, state.classPrice);
                    break;
                case 12:
                    data = PayPrivateClassHoursThreePersons(state.classTitle, state.classPrice);
                    break;
                case 13:
                    data = PayClassPostGraduate(state.classTitle, state.classPrice);
                    break;
                case 14:
                    data = PrepareEsdda();
                    break;
                case 15:
                    data = NotesEsdda();
                    break;
                case 16:
                    data = JobPostings();
                    break;
                case 17:
                    data = ZoomLink(state.zoomMeetingURL);
                    break;
                case 18:
                    data = {};
                    break;
            }
        }
    }
    state.title = data.title;
    state.body = data.body;
};

const fetchRecipients = async () => {
    loadingRecipients.value = true;
    let res = null;
    let d = [];
    if (forcedRecipients.value === 'categories') {
        if (recipientsSelectedRole.value?.value === Utils.Roles.Student) {
            let requestsRes = await RPCApi(RPCMethods.Request.FetchRequests, fetchParams.value);
            console.log(requestsRes);
            let d = [];
            if (requestsRes.status == 'ok') {
                const { total, requests: requestsData } = requestsRes.data.result.data;
                totalRequests.value = total;

                for (let obj of requestsData) {
                    d.push({
                        id: obj.requestID,
                        title: obj.title,
                        school: obj.school,
                        studentName: obj.studentName,
                        recipientID: obj.studentID,
                        examsPeriodTitle: obj.examsPeriodTitle,
                        isPrivate: obj.isPrivate === 1 ? true : false,
                        status: obj.status,
                    });
                }
                requests.value = d;
            } else {
                toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Προσπαθήστε αργότερα!', life: 3000 });
            }
        } else {
            let resTeachers = await RPCApi(RPCMethods.User.FetchTeachersWithParams, { ...fetchTeachersParams.value, active: true });
            console.log(resTeachers);
            let d = [];
            if (resTeachers.status == 'ok') {
                const { teachersData, total } = resTeachers.data.result.data;
                totalTeachers.value = total;
                console.log('total:' + total);

                for (let obj of teachersData) {
                    d.push({
                        id: obj.id,
                        recipientID: obj.id,
                        fullname: obj.lname + ' ' + obj.fname,
                        lname: obj.lname,
                        email: obj.email,
                        mobile: obj.mobile,
                        school: obj.school,
                        ban: obj.ban === 1 ? true : false,
                        departments: obj.departments,
                        lastLoginDatetime: obj.lastLoginDatetime
                            ? moment(Utils.datetimeToDate(obj.lastLoginDatetime)).format('DD/MM/YYYY') + ' - ' + moment(Utils.datetimeToDate(obj.lastLoginDatetime)).format('HH:mm')
                            : null,
                    });
                }
                teachers.value = d;
            }
        }
    } else if (forcedRecipients.value === 'support') {
        res = await RPCApi(RPCMethods.User.FetchOfficeAndAdmin);
        if (res && res.status == 'ok') {
            const { users } = res.data.result.data;
            totalRecipients.value = users.length;

            for (let obj of users) {
                if (obj.ban === 1) continue;
                d.push({
                    id: obj.id,
                    recipientID: obj.id,
                    fullname: obj.lname + ' ' + obj.fname,
                    role: obj.role,
                });
            }
            recipients.value = d;
            state.recipients = d;
        } else {
            toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Προσπαθήστε αργότερα!', life: 3000 });
        }
    } else if (forcedRecipients.value === 'adminAndOffice') {
        res = await RPCApi(RPCMethods.User.FetchOfficeAndAdmin);
        if (res && res.status == 'ok') {
            const { users } = res.data.result.data;
            totalRecipients.value = users.length;

            for (let obj of users) {
                if (obj.ban === 1) continue;
                d.push({
                    id: obj.id,
                    recipientID: obj.id,
                    fullname: obj.lname + ' ' + obj.fname,
                    role: obj.role === 1 ? 'Διεύθυνση φροντιστηρίου' : 'Support',
                });
            }
            recipients.value = d;
            state.recipients = d;

            console.warn(state.recipients);
        } else {
            toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Προσπαθήστε αργότερα!', life: 3000 });
        }
    }

    console.log(res);

    loadingRecipients.value = false;
};

const loadStudentsForClass = async (classID) => {
    loadingRecipients.value = true;
    recipients.value = [];
    let res = await RPCApi(RPCMethods.Class.FetchStudents, {
        classID,
    });

    let d = [];
    if (res.status == 'ok') {
        const { students: studentsData } = res.data.result.data;
        if (studentsData != null) {
            for (let obj of studentsData) {
                if (obj.requestStatus !== Utils.RequestStatus.Canceled && obj.requestStatus !== Utils.RequestStatus.Stopped) {
                    d.push({
                        id: obj.id,
                        fullname: obj.studentName,
                        email: obj.email,
                        ban: obj.ban === 1 ? true : false,
                        requestStatus: obj.requestStatus,
                    });
                }
            }
        }

        recipients.value = d;
        totalRecipients.value = d.length;
    } else {
        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Προσπαθήστε αργότερα!', life: 3000 });
    }

    loadingRecipients.value = false;
};

const onHide = (action = false) => {
    dialogShown.value = false;
    resetForm();
    closeCB.value(action);
};

const handleSubmit = async (isFormValid) => {
    submitted.value = true;

    console.warn('recipients::');
    console.warn(state.recipients);

    if (!userSpecific.value && state.recipients.find((x) => x.ban)) {
        confirm.require({
            message: 'Έχετε επιλέξει παραλήπτες με ban!',
            header: 'Προσοχή!',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'OK',
            rejectLabel: 'Άκυρο',
        });
        return;
    }

    if (!state.recipients || state.recipients.length === 0) {
        confirm.require({
            message: 'Δεν έχετε επιλέξει παραλήπτες!',
            header: 'Προσοχή!',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'OK',
            rejectLabel: 'Άκυρο',
        });
        return;
    }

    if (
        !userSpecific.value &&
        state.recipients.find(
            (x) =>
                x.requestStatus !== Utils.RequestStatus.Posted &&
                x.requestStatus !== Utils.RequestStatus.PreRegistered &&
                x.requestStatus !== Utils.RequestStatus.OnClass &&
                x.requestStatus !== Utils.RequestStatus.Completed
        )
    ) {
        confirm.require({
            message: `Έχετε επιλέξει παραλήπτες χωρίς δικαίωμα μηνυμάτων για το τμήμα!`,
            header: 'Προσοχή!',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'OK',
            rejectLabel: 'Άκυρο',
        });
        return;
    }

    if (replyMode.value && !outboxMsg.value && (state.bodyAnswer == null || state.bodyAnswer.trim() === '')) {
        confirm.require({
            message: `Δεν έχετε επιλέξει μήνυμα για απάντηση!`,
            header: 'Προσοχή!',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'OK',
            rejectLabel: 'Άκυρο',
        });
        return;
    }

    if (!isFormValid) return;

    console.log('ok submit');
    loading.value = true;
    let res;
    let payloadExtra = {
        noReply: noReply.value,
        sendgrid: sendgridActive.value,
    };
    let payload = {
        type: Utils.NotificationType.Email,
        createdAt: new Date(),
        runAt: state.sendDate,
        title: state.title,
        body: state.body,
        attachmentsBucketID: attachmentsBucketID.value,
        classID: userSpecific.value ? null : state.classID,
        // sender_role: selectedSenderRole.value?.value || null,
        ...payloadExtra,
    };

    let distinctNotifications = false;

    if (userSpecific.value) {
        if (forcedRecipients.value) {
            if (editMode.value && replyMode.value) {
                let datenow = moment(new Date()).format('DD/MM/YYYY') + ' - ' + moment(new Date()).format('HH:mm');
                let contactDetails =
                    Utils.UserInfo.role === Utils.Roles.Admin
                        ? 'Διεύθυνση φροντιστηρίου'
                        : Utils.UserInfo.role === Utils.Roles.Office
                        ? 'Γραμματεία'
                        : `${Utils.UserInfo.lname} ${Utils.UserInfo.fname}`;
                payload = {
                    ...payload,
                    title: 'RE: ' + payload.title,
                    body: `${payload.body}<br/><br/>--- Από: ${contactDetails}, Ημ/νία: ${datenow} ---<br/><b>Απάντηση:</b><br/> ${state.bodyAnswer}`,
                    recipients: state.recipients.map((x) => x.recipientID),
                };
            } else {
                distinctNotifications =
                    !noReply.value && isAdminOrOffice.value && (recipientsSelectedRole.value?.value === Utils.Roles.Teacher || recipientsSelectedRole.value?.value === Utils.Roles.Student);
                payload = {
                    ...payload,
                    recipients: state.recipients.map((x) => x.recipientID),
                };
            }
        } else {
            payload = {
                ...payload,
                recipients: [state.recipients[0]],
            };
        }
    } else {
        payload = {
            ...payload,
            recipients: state.recipients.map((x) => x.id),
        };
    }
    console.warn(payload);
    if (noReply.value) {
        //remove office recipients and admin recipients
        payload.recipients = payload.recipients.filter((x) => x.role !== Utils.Roles.Office && x.role !== Utils.Roles.Admin);
    }

    console.log(payload.recipients);

    if (!editMode.value && state.lessonStartAt && !firstLessonNotification.value) {
        payload.hidden = 1;
    }

    if (editMode.value && !cloneMode.value) {
        payload.id = state.id;
        res = await RPCApi(RPCMethods.Notifications.Update, payload);
    } else {
        if (lessonNotificationID.value) payload.lessonID = lessonNotificationID.value;
        if (distinctNotifications) {
            res = await RPCApi(RPCMethods.Notifications.AddMultiple, payload);
        } else {
            res = await RPCApi(RPCMethods.Notifications.Add, payload);
        }
    }

    if (res && res.status !== 'ok') {
        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Προσπαθήστε ξανά!', life: 3000 });
        loading.value = false;
        return;
    }

    toast.add({ severity: 'success', summary: 'Η διαδικασία ολοκληρώθηκε', detail: 'Επιτυχής διαδικασία!', life: 3000 });

    if (firstLessonNotification.value) {
        let { title, body } = LessonReminder(state.classTitle, state.lessonStartAt, state.zoomMeetingURL);
        let payload = {
            type: Utils.NotificationType.Email,
            createdAt: new Date(),
            runAt: new Date(state.lessonStartAt.getTime() - hoursInSecs),
            hidden: 1,
            recipients: userSpecific.value ? [state.recipients[0]] : state.recipients.map((x) => x.id),
            title: title,
            body: body,
            attachmentsBucketID: attachmentsBucketID.value,
            classID: userSpecific.value ? null : state.classID,
            ...payloadExtra,
        };
        payload.lessonID = lessonNotificationID.value;
        await RPCApi(RPCMethods.Notifications.Add, payload);
    }

    loading.value = false;
    onHide(true);
};

const resetForm = () => {
    for (let field in state) {
        if (field === 'sendDate') {
            state[field] = new Date();
        } else state[field] = null;
    }

    replyMode.value = false;
    submitted.value = false;
    loading.value = false;
    firstLessonNotification.value = false;
    lessonNotificationID.value = null;
    showOnly.value = false;
    outboxMsg.value = false;
    noReply.value = true;
    sendgridActive.value = false;
    forcedRecipients.value = null;
    filteredCategories.value = [];
    filteredSubCategories1.value = [];
    filteredSubCategories2.value = [];
    filteredExamPeriods.value = [];

    tableFilters.value = {
        title: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        school: { value: null, matchMode: FilterMatchMode.CONTAINS },
        examPeriod: { value: null, matchMode: FilterMatchMode.CONTAINS },
        studentName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        accountableID: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        isPrivate: { value: null, matchMode: FilterMatchMode.EQUALS },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        category: { value: null, matchMode: FilterMatchMode.EQUALS },
        subCateg1: { value: null, matchMode: FilterMatchMode.EQUALS },
        subCateg2: { value: null, matchMode: FilterMatchMode.EQUALS },
        courseTitle: { value: null, matchMode: FilterMatchMode.EQUALS },
    };

    tableTeachersFilters.value = {
        lname: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        mobile: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        ban: { value: true, matchMode: FilterMatchMode.EQUALS },
        departments: { value: null, matchMode: FilterMatchMode.IN },
        lastLoginDatetime: { value: null, matchMode: FilterMatchMode.EQUALS },
    };

    recipientsSelectedRole.value = null;
    fetchParams.value = null;
    fetchTeachersParams.value = null;

    selectedTemplate.value = null;
};

const showRecipientDialog = (studentID) => {
    popupStore.show(Utils.Popups.StudentDialog, { studentID }, (action) => {});
};

const onMarkReadChanged = async () => {
    await RPCApi(RPCMethods.Notifications.MarkMessageRead, { id: state.id, read: markRead.value });
};

const onSendDateSelected = useDebounceFn(async (date) => {
    console.log(date);
    state.lessonStartAt = date;
}, 300);

const searchCategory = async (event) => {
    console.log('searchCategory: ' + event.query);

    if (!event.query.trim().length) {
        filteredCategories.value = [...categories.value];
    } else {
        filteredCategories.value = categories.value.filter((d) => {
            return d.name.toLowerCase().startsWith(event.query.toLowerCase());
        });
    }
};

const searchSubCategory1 = async (event) => {
    console.log('searchSubCategory1: ' + event.query);
    if (tableFilters.value.category.value == null) {
        filteredSubCategories1.value = [];
        return;
    }

    filteredSubCategories1.value = schools.value.filter((d) => {
        return d.category === tableFilters.value.category.value.name;
    });

    if (!event.query.trim().length) {
        filteredSubCategories1.value = [...filteredSubCategories1.value];
    } else {
        filteredSubCategories1.value = filteredSubCategories1.value.filter((d) => {
            return d.subCateg1.toLowerCase().startsWith(event.query.toLowerCase());
        });
    }

    filteredSubCategories1.value = [...new Set(filteredSubCategories1.value.map((d) => d.subCateg1))];
};

const searchSubCategory2 = async (event) => {
    console.log('searchSubCategory2: ' + event.query);

    if (tableFilters.value.subCateg1.value == null) {
        filteredSubCategories2.value = [];
        return;
    }

    filteredSubCategories2.value = schools.value.filter((d) => {
        return d.subCateg1 === tableFilters.value.subCateg1.value;
    });

    if (!event.query.trim().length) {
        filteredSubCategories2.value = [...filteredSubCategories2.value];
    } else {
        filteredSubCategories2.value = filteredSubCategories2.value.filter((d) => {
            return d.subCateg2.toLowerCase().startsWith(event.query.toLowerCase());
        });
    }

    filteredSubCategories2.value = [...new Set(filteredSubCategories2.value.map((d) => d.subCateg2))];
};

const searchCourses = useDebounceFn(async (event) => {
    console.log('searchCourses: ' + event.query);
    if (tableFilters.value.category.value == null || tableFilters.value.subCateg1.value == null || tableFilters.value.subCateg2.value == null) {
        filteredCourses.value = [];
        return;
    }

    let school = schools.value.find(
        (s) => s.category === tableFilters.value.category.value.name && s.subCateg1 === tableFilters.value.subCateg1.value && s.subCateg2 === tableFilters.value.subCateg2.value
    );
    if (school == null) {
        filteredCourses.value = [];
        return;
    }
    let res = await RPCApi(RPCMethods.Courses.FetchCoursesForSchool, { title: event.query.trim(), schoolID: school.id });
    if (res.status !== 'ok') {
        filteredCourses.value = [];
        return;
    }

    filteredCourses.value = res.data.result.data;
}, 300);

const onCategorySelect = (event) => {
    console.log('onCategorySelect: ', event.value);
    // tableFilters.value.category.value = event.value.id;
    tableFilters.value.subCateg1.value = null;
    tableFilters.value.subCateg2.value = null;
    filteredSubCategories1.value = [];
    filteredSubCategories2.value = [];
    filteredCourses.value = [];

    onFilter();
};

const onSubCateg1Select = (event) => {
    console.log('onSubCateg1Select: ', event.value);
    tableFilters.value.subCateg1.value = event.value;
    tableFilters.value.subCateg2.value = null;
    filteredSubCategories2.value = [];
    filteredCourses.value = [];

    onFilter();
};

const onSubCateg2Select = (event) => {
    console.log('onSubCateg2Select: ', event.value);
    tableFilters.value.subCateg2.value = event.value;
    filteredCourses.value = [];

    onFilter();
};

const onCourseTitleSelect = (event) => {
    console.log('onCourseTitleSelect: ', event.value);
    tableFilters.value.courseTitle.value = event.value;
    onFilter();
};

const courseTitleLblFunction = (course) => {
    if (course == null || course.title == null) return '';
    if (course.semester == null) return course.title;
    return course.title + ' (' + course.semester + ')';
};

const resetGlobalFilters = () => {
    tableFilters.value.category.value = null;
    tableFilters.value.subCateg1.value = null;
    tableFilters.value.subCateg2.value = null;
    tableFilters.value.courseTitle.value = null;
    filteredSubCategories1.value = [];
    filteredSubCategories2.value = [];
    filteredCourses.value = [];
    onFilter();
};

const showStudentDialog = (studentID) => {
    console.log('showStudentDialog:' + studentID);
    popupStore.show(Utils.Popups.StudentDialog, { studentID }, (action) => {
        fetchRecipients();
    });
};

const onTeachersPage = useDebounceFn((event) => {
    console.log('onPage', event);
    fetchTeachersParams.value.paging = { offset: event.first, limit: rowsPerPage };
    fetchRecipients();
}, 300);

const onTeachersSort = useDebounceFn((event) => {
    console.log('onSort', event);
    if (event.sortField === 'fullname') event.sortField = 'lname';
    fetchTeachersParams.value.paging = { offset: 0, limit: rowsPerPage };
    fetchTeachersParams.value.sort = { sortField: event.sortField, sortOrder: event.sortOrder };
    fetchRecipients();
}, 300);

const onTeachersFilter = useDebounceFn(() => {
    console.log('onFilter', tableTeachersFilters.value);
    fetchTeachersParams.value.paging = { offset: 0, limit: rowsPerPage };
    fetchTeachersParams.value.filters = {
        ...tableTeachersFilters.value,
        departments: { value: tableTeachersFilters.value.departments.value?.map((d) => d.value), matchMode: FilterMatchMode.IN },
        ban:
            tableTeachersFilters.value.ban.value == null
                ? { value: null, matchMode: FilterMatchMode.EQUALS }
                : tableTeachersFilters.value.ban.value === true
                ? { value: 0, matchMode: FilterMatchMode.EQUALS }
                : { value: 1, matchMode: FilterMatchMode.EQUALS },
        lastLoginDatetime:
            tableTeachersFilters.value.lastLoginDatetime.value == null
                ? { value: null, matchMode: FilterMatchMode.EQUALS }
                : tableTeachersFilters.value.lastLoginDatetime.value === true
                ? { value: 1, matchMode: FilterMatchMode.EQUALS }
                : { value: 0, matchMode: FilterMatchMode.EQUALS },
    };
    onSelectAllTeachersChange({ checked: false });
    fetchRecipients();
}, 1000);

const showTeacherDialog = (teacherID) => {
    console.log('showTeacherDialog:' + teacherID);
    popupStore.show(Utils.Popups.TeacherDialog, { teacherID }, () => {
        fetchRecipients();
    });
};

const onSelectAllStudentsChange = async (event) => {
    if (event.checked) {
        loadingRecipients.value = true;
        let selectionRequestsRes = await RPCApi(RPCMethods.Request.FetchRequests, { ...fetchParams.value, paging: null });
        loadingRecipients.value = false;
        console.log(selectionRequestsRes);
        let d = [];
        if (selectionRequestsRes.status == 'ok') {
            const { total, requests: requestsData } = selectionRequestsRes.data.result.data;
            totalRequests.value = total;

            for (let obj of requestsData) {
                d.push({
                    id: obj.requestID,
                    title: obj.title,
                    school: obj.school,
                    studentName: obj.studentName,
                    recipientID: obj.studentID,
                    examsPeriodTitle: obj.examsPeriodTitle,
                    isPrivate: obj.isPrivate === 1 ? true : false,
                    status: obj.status,
                });
            }
            selectAllStudents.value = true;
            state.recipients = d;
        } else {
            toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Προσπαθήστε αργότερα!', life: 3000 });
        }
    } else {
        selectAllStudents.value = false;
        state.recipients = [];
    }
};
const onRowTeachersSelect = () => {
    selectAllTeachers.value = state.recipients.length === totalTeachers.value;
};
const onRowTeachersUnselect = () => {
    selectAllTeachers.value = false;
};

const onSelectAllTeachersChange = async (event) => {
    console.log('onSelectAllTeachersChange:' + event.checked);
    if (event.checked) {
        loadingRecipients.value = true;
        let resTeachers = await RPCApi(RPCMethods.User.FetchTeachersWithParams, { ...fetchTeachersParams.value, paging: null });
        loadingRecipients.value = false;
        console.log(resTeachers);
        let d = [];
        if (resTeachers.status == 'ok') {
            const { teachersData, total } = resTeachers.data.result.data;
            totalTeachers.value = total;
            console.log('total:' + total);

            for (let obj of teachersData) {
                d.push({
                    id: obj.id,
                    recipientID: obj.id,
                    fullname: obj.lname + ' ' + obj.fname,
                    lname: obj.lname,
                    email: obj.email,
                    mobile: obj.mobile,
                    school: obj.school,
                    ban: obj.ban === 1 ? true : false,
                    departments: obj.departments,
                    lastLoginDatetime: obj.lastLoginDatetime
                        ? moment(Utils.datetimeToDate(obj.lastLoginDatetime)).format('DD/MM/YYYY') + ' - ' + moment(Utils.datetimeToDate(obj.lastLoginDatetime)).format('HH:mm')
                        : null,
                });
            }
            selectAllTeachers.value = true;
            state.recipients = d;
        }
    } else {
        selectAllTeachers.value = false;
        state.recipients = [];
    }
};
const onRowStudentsSelect = () => {
    selectAllStudents.value = state.recipients.length === totalRequests.value;
};
const onRowStudentsUnselect = () => {
    selectAllStudents.value = false;
};

const onRecipientsSelectedRoleChanged = async (event) => {
    console.log('onRecipientsSelectedRoleChanged', event);

    tableFilters.value.category.value = null;
    tableFilters.value.subCateg1.value = null;
    tableFilters.value.subCateg2.value = null;
    tableFilters.value.courseTitle.value = null;
    tableFilters.value.title.value = null;
    tableFilters.value.school.value = null;
    tableFilters.value.examPeriod.value = null;
    tableFilters.value.studentName.value = null;
    tableFilters.value.accountableID.value = null;
    tableFilters.value.isPrivate.value = null;
    tableFilters.value.status.value = null;
    tableFilters.value.status.value = null;

    tableTeachersFilters.value.lname.value = null;
    tableTeachersFilters.value.email.value = null;
    tableTeachersFilters.value.mobile.value = null;
    tableTeachersFilters.value.ban.value = true;
    tableTeachersFilters.value.departments.value = null;
    tableTeachersFilters.value.lastLoginDatetime.value = null;

    state.recipients = [];

    if (recipientsSelectedRole.value) {
        if (recipientsSelectedRole.value.value === Utils.Roles.Student) {
            onFilter();
        } else if (recipientsSelectedRole.value.value === Utils.Roles.Teacher) {
            onTeachersFilter();
        } else {
            noReply.value = false;

            let res = await RPCApi(RPCMethods.User.FetchOfficeAndAdmin);
            let d = [];
            if (res && res.status == 'ok') {
                const { users } = res.data.result.data;
                totalRecipients.value = users.length;

                for (let obj of users) {
                    if (obj.ban === 1) continue;
                    if (obj.id !== Utils.UserInfo.id) {
                        d.push({
                            id: obj.id,
                            recipientID: obj.id,
                            fullname: obj.lname + ' ' + obj.fname,
                            role: obj.role === 1 ? 'Διεύθυνση φροντιστηρίου' : 'Support',
                        });
                    }
                }
                recipients.value = d;
                state.recipients = d;
            }
        }
    }
};

defineExpose({
    show,
});
</script>
