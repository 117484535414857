import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import mdiVue from 'mdi-vue/v3';
import * as mdijs from '@mdi/js';

import { createPinia } from 'pinia';
import { createApp } from 'vue';

import router from '@/router';
import App from '@/App.vue';
import PrimeVue from 'primevue/config';
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import Tree from 'primevue/tree';
import Checkbox from 'primevue/checkbox';
import Button from 'primevue/button';
import Tooltip from 'primevue/tooltip';
import OverlayPanel from 'primevue/overlaypanel';
import Ripple from 'primevue/ripple';
import BadgeDirective from 'primevue/badgedirective';
import StyleClass from 'primevue/styleclass';
import Badge from 'primevue/badge';
import Chart from 'primevue/chart';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import FullCalendar from '@fullcalendar/vue3';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';
import Menu from 'primevue/menu';
import Avatar from 'primevue/avatar';
import Dialog from 'primevue/dialog';
import InputNumber from 'primevue/inputnumber';
import Dropdown from 'primevue/dropdown';
import Skeleton from 'primevue/skeleton';
import AutoComplete from 'primevue/autocomplete';
import Calendar from 'primevue/calendar';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import Textarea from 'primevue/textarea';
import TabMenu from 'primevue/tabmenu';
import ConfirmationService from 'primevue/confirmationservice';
import ConfirmDialog from 'primevue/confirmdialog';
import Editor from 'primevue/editor';
import MultiSelect from 'primevue/multiselect';
import Divider from 'primevue/divider';
import Sidebar from 'primevue/sidebar';
import Message from 'primevue/message';
import AvatarGroup from 'primevue/avatargroup';
import Timeline from 'primevue/timeline';
import ProgressSpinner from 'primevue/progressspinner';
import Vue3Lottie from 'vue3-lottie';
import Image from 'primevue/image';
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';

import '@/assets/styles.scss';

const pinia = createPinia();
const app = createApp(App);
app.config.globalProperties.window = window;

app.use(pinia);
app.use(PrimeVue, { ripple: true, inputStyle: 'outlined' });
app.use(ToastService);
app.use(router);
app.use(mdiVue, {
    icons: mdijs,
});
app.use(ConfirmationService);

app.directive('tooltip', Tooltip);
app.directive('ripple', Ripple);
app.directive('badge', BadgeDirective);
app.directive('styleclass', StyleClass);

app.use(Vue3Lottie);
app.component('InputText', InputText);
app.component('Password', Password);
app.component('Checkbox', Checkbox);
app.component('Button', Button);
app.component('Badge', Badge);
app.component('Chart', Chart);
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('Toast', Toast);
app.component('FullCalendar', FullCalendar);
app.component('Avatar', Avatar);
app.component('Menu', Menu);
app.component('Dialog', Dialog);
app.component('InputNumber', InputNumber);
app.component('Dropdown', Dropdown);
app.component('Skeleton', Skeleton);
app.component('AutoComplete', AutoComplete);
app.component('Calendar', Calendar);
app.component('TriStateCheckbox', TriStateCheckbox);
app.component('Textarea', Textarea);
app.component('TabMenu', TabMenu);
app.component('ConfirmDialog', ConfirmDialog);
app.component('Editor', Editor);
app.component('MultiSelect', MultiSelect);
app.component('Divider', Divider);
app.component('Sidebar', Sidebar);
app.component('Message', Message);
app.component('AvatarGroup', AvatarGroup);
app.component('Timeline', Timeline);
app.component('ProgressSpinner', ProgressSpinner);
app.component('Tree', Tree);
app.component('OverlayPanel', OverlayPanel);
app.component('Image', Image);
app.component('InputGroup', InputGroup);
app.component('InputGroupAddon', InputGroupAddon);

app.mount('#app');
