<template>
    <Dialog v-model:visible="dialogShown" class="p-fluid" :close-on-escape="false" :dismissable-mask="false" :modal="true" :style="{ width: '80vw' }" :closable="false">
        <template #header>
            <div>
                <img src="https://vitali.gr/wp-content/uploads/2021/04/LOGO_237.png" style="max-width: 220px" />
            </div>
        </template>
        <div class="flex flex-column w-full h-full">
            <div class="flex flex-row gap-2 w-full">
                <img style="height: 30rem" :src="welcome_illustration" alt="Welcome illustration" />
                <span style="font-size: x-large; align-self: center; font-weight: 700">Ακολουθείστε τις οδηγίες για να συνδεθείτε στη νέα έκδοση {{ version }}</span>
            </div>
            <div>
                <img style="height: 100%; width: 100%" :src="guideImage" />
            </div>
        </div>
    </Dialog>
</template>

<script setup>
import welcome_illustration from '@/assets/images/new_version.jpg';
import guideImage from '@/assets/images/guide.png';
import { onMounted, reactive, ref, computed, watchEffect, toRefs } from 'vue';
import useRPC from '@/composables/useRPC';
import useStorage from '@/composables/useStorage';
const { SetStorage, GetStorage, StorageKeys } = useStorage();
const { RPCApi, RPCMethods } = useRPC();
const dialogShown = ref(false);
const submitted = ref(false);
const loading = ref(false);
const closeCB = ref(null);
const body = ref('');
const version = ref('');

const show = async (onCloseCB, versionNum) => {
    version.value = versionNum;
    body.value = `
${Signature()}
Εκπαιδευτικός Όμιλος ΒΙΤΑΛΗ
    `;
    closeCB.value = onCloseCB;
    dialogShown.value = true;
    submitted.value = false;
};

const onHide = (action = false) => {
    dialogShown.value = false;
    closeCB.value(action);
};

const Signature = () => {
    return `
    <br />
    <img src="https://vitali.gr/wp-content/uploads/2021/04/LOGO_237.png" style="max-width: 220px" />
    <br/><p style="margin: 0px"><b>Πανεπιστημιακά Φροντιστήρια - Διαγωνισμοί Δημοσίου</b></p>
    <p style="margin: 0px"><b>Τηλ. Επικοινωνίας</b> : 210-300.12.99</p>
    <p style="margin: 0px"><b>URL</b> : https://vitali.gr/</p>
    <p style="margin: 0px"><b>Facebook</b>: https://www.facebook.com/vitali.edu.gr</p>
    <p style="margin: 0px"><b>Instagram</b>: https://www.instagram.com/vitaliedugr/</p>
    <p style="margin: 0px"><b>Κεντρικό</b> : Ξενοφώντος 9, 2ος όροφος, Πλατεία Συντάγματος, Αθήνα (νέα διεύθυνση)</p>
    <p style="margin: 0px"><b>Παράρτημα Πειραιά</b> : Δεληγιώργη 106Α, Έναντι Πανεπιστημίου, Πειραιά</p>
   `;
};

defineExpose({
    show,
});
</script>

<style>
.headerCls {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
</style>
