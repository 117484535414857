<template>
    <Dialog
        v-model:visible="dialogShown"
        class="p-fluid"
        :close-on-escape="false"
        :dismissable-mask="false"
        :modal="true"
        :breakpoints="{ '960px': '75vw', '640px': '95vw' }"
        :style="{ width: '50vw' }"
    >
        <template #header>
            <h4>{{ headerTitle }}</h4>
        </template>

        <div class="field">
            <label for="category" :class="{ 'p-error': v$.category.$invalid && submitted }">Κατηγορία*</label>
            <AutoComplete
                id="category"
                v-model="v$.category.$model"
                :class="{ 'p-invalid': v$.category.$invalid && submitted }"
                :suggestions="filteredCategories"
                placeholder="Επιλέξτε / Πληκτρολογήστε Νέα κατηγορία"
                :dropdown="true"
                field="name"
                @complete="searchCategory($event)"
            >
            </AutoComplete>
            <small v-if="(v$.category.$invalid && submitted) || v$.category.$pending.$response" class="p-error">{{ v$.category.required.$message }}</small>
        </div>
        <div class="field">
            <label for="subCateg1" :class="{ 'p-error': v$.subCateg1.$invalid && submitted }">Υποκατηγορία Ι*</label>
            <AutoComplete
                id="subCateg1"
                v-model="v$.subCateg1.$model"
                :class="{ 'p-invalid': v$.subCateg1.$invalid && submitted }"
                :suggestions="filteredSubCategories1"
                placeholder="Επιλέξτε / Πληκτρολογήστε Νέα Υποκατηγορία Ι"
                :dropdown="true"
                @complete="searchSubCategory1($event)"
            >
            </AutoComplete>
            <small v-if="(v$.subCateg1.$invalid && submitted) || v$.subCateg1.$pending.$response" class="p-error">{{ v$.subCateg1.required.$message }}</small>
        </div>
        <div class="field">
            <label for="subCateg2" :class="{ 'p-error': v$.subCateg2.$invalid && submitted }">Υποκατηγορία ΙΙ*</label>
            <AutoComplete
                id="subCateg2"
                v-model="v$.subCateg2.$model"
                :class="{ 'p-invalid': v$.subCateg2.$invalid && submitted }"
                :suggestions="filteredSubCategories2"
                placeholder="Επιλέξτε / Πληκτρολογήστε Νέα Υποκατηγορία ΙΙ"
                :dropdown="true"
                @complete="searchSubCategory2($event)"
            >
            </AutoComplete>
            <small v-if="(v$.subCateg2.$invalid && submitted) || v$.subCateg2.$pending.$response" class="p-error">{{ v$.subCateg2.required.$message }}</small>
        </div>
        <template #footer>
            <Button label="Άκυρο" icon="pi pi-times" class="p-button-outlined w-8rem mr-3" @click="onHide(false)" />
            <Button label="Αποθήκευση" icon="pi pi-check" class="p-button-primary w-10rem" :loading="loading" @click="handleSubmit(!v$.$invalid)" />
        </template>
    </Dialog>
</template>

<script setup>
import { useToast } from 'primevue/usetoast';
import { onMounted, reactive, ref, computed, watchEffect, toRefs } from 'vue';
import { required, integer } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import useRPC from '@/composables/useRPC';
const { RPCApi, RPCMethods } = useRPC();
const dialogShown = ref(false);
const submitted = ref(false);
const headerTitle = ref('');
const state = reactive({
    id: null,
    category: '',
    subCateg1: '',
    subCateg2: '',
});
const rules = {
    category: { required },
    subCateg1: { required },
    subCateg2: { required },
};
const v$ = useVuelidate(rules, state);
const loading = ref(false);
const toast = useToast();
const categories = ref([]);
const schools = ref([]);
const editMode = ref(false);
const filteredCategories = ref([]);
const filteredSubCategories1 = ref([]);
const filteredSubCategories2 = ref([]);
const closeCB = ref(null);

const show = async (data, onCloseCB) => {
    const { school, categData, schoolsData } = data;
    console.log(school);
    console.log('categData:', categData);
    closeCB.value = onCloseCB;
    categories.value = categData;
    schools.value = schoolsData;
    editMode.value = !!school;
    state.id = school ? school.id : null;
    state.category = school ? categData.find((x) => x.name === school.category) : '';
    state.subCateg1 = school ? school.subCateg1 : '';
    state.subCateg2 = school ? school.subCateg2 : '';
    headerTitle.value = editMode.value ? 'Τροποποίηση Σχολής' : 'Νέα Σχολή';
    dialogShown.value = true;
    submitted.value = false;
};

const onHide = (action = false) => {
    dialogShown.value = false;
    resetForm();
    closeCB.value(action);
};

const handleSubmit = async (isFormValid) => {
    submitted.value = true;

    if (!isFormValid) return;

    console.log('ok submit');
    loading.value = true;
    let res;
    if (editMode.value) {
        res = await RPCApi(RPCMethods.Schools.Update, {
            category_name: state.category.name,
            category_id: state.category.id,
            id: state.id,
            subCateg1: state.subCateg1,
            subCateg2: state.subCateg2,
        });
    } else {
        res = await RPCApi(RPCMethods.Schools.Add, {
            category_name: state.category.name,
            subCateg1: state.subCateg1,
            subCateg2: state.subCateg2,
        });
    }

    if (res.status !== 'ok') {
        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Η σχολή υπάρχει ήδη!', life: 3000 });
        loading.value = false;
        return;
    }
    toast.add({ severity: 'success', summary: 'Η διαδικασία ολοκληρώθηκε', detail: 'Επιτυχής διαδικασία!', life: 3000 });

    loading.value = false;
    onHide(true);
};
const resetForm = () => {
    state.id = null;
    state.category = null;
    state.subCateg1 = '';
    state.subCateg2 = '';
    submitted.value = false;
    loading.value = false;
};

const searchCategory = async (event) => {
    console.log('searchCategory: ' + event.query);

    if (!event.query.trim().length) {
        filteredCategories.value = [...categories.value];
    } else {
        filteredCategories.value = categories.value.filter((d) => {
            return d.name.toLowerCase().startsWith(event.query.toLowerCase());
        });
    }
};

const searchSubCategory1 = async (event) => {
    console.log('searchSubCategory1: ' + event.query);
    console.log(state.category);
    if (state.category == null) {
        filteredSubCategories1.value = [];
        return;
    }

    filteredSubCategories1.value = schools.value.filter((d) => {
        return d.category === state.category.name;
    });

    if (!event.query.trim().length) {
        filteredSubCategories1.value = [...filteredSubCategories1.value];
    } else {
        filteredSubCategories1.value = filteredSubCategories1.value.filter((d) => {
            return d.subCateg1.toLowerCase().startsWith(event.query.toLowerCase());
        });
    }

    filteredSubCategories1.value = [...new Set(filteredSubCategories1.value.map((d) => d.subCateg1))];
};

const searchSubCategory2 = async (event) => {
    console.log('searchSubCategory2: ' + event.query);

    if (state.subCateg1 == null) {
        filteredSubCategories2.value = [];
        return;
    }

    filteredSubCategories2.value = schools.value.filter((d) => {
        return d.subCateg1 === state.subCateg1;
    });

    if (!event.query.trim().length) {
        filteredSubCategories2.value = [...filteredSubCategories2.value];
    } else {
        filteredSubCategories2.value = filteredSubCategories2.value.filter((d) => {
            return d.subCateg2.toLowerCase().startsWith(event.query.toLowerCase());
        });
    }

    filteredSubCategories2.value = [...new Set(filteredSubCategories2.value.map((d) => d.subCateg2))];
};

defineExpose({
    show,
});
</script>
