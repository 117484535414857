<template>
    <DataTable
        ref="dt"
        v-model:filters="tableFilters"
        :total-records="totalFiles"
        :loading="loading"
        :row-hover="true"
        :value="files"
        filter-display="row"
        data-key="id"
        responsive-layout="scroll"
        :rows="rowsPerPage"
        :paginator="true"
    >
        <template #header>
            <div class="flex flex-row justify-content-between">
                <div class="flex-none">
                    <Button v-if="isAdminOrOffice || isTeacher" icon="pi pi-plus" label="Προσθήκη" class="mr-2 mb-2" @click="addFiles" />
                </div>
            </div>
        </template>
        <template #empty> Δεν βρέθηκαν αρχεία. </template>
        <Column header="Όνομα" field="filename" filter-field="filename" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
            <template #body="{ data }">
                <a class="font-medium no-underline text-left cursor-pointer" style="color: blue" @click="downloadFile(data.url, data.filename)">{{ data.filename }}</a>
            </template>
        </Column>
        <Column header="Ημερομηνία" field="date" filter-field="date" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
            <template #body="{ data }">
                {{ Utils.formatDate(Utils.datetimeToDate(data.date)) }}
            </template>
        </Column>
        <Column header="Μέγεθος" field="size" filter-field="size" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
            <template #body="{ data }">
                {{ Utils.formatBytes(data.size) }}
            </template>
        </Column>
        <Column v-if="isAdminOrOffice || isTeacher" field="active" filter-field="active" header="Ενεργό" data-type="boolean" body-class="text-left">
            <template #body="{ data }">
                <Checkbox :model-value="data.active" :binary="true" @click="toggleActive(data.id, data.active)" />
            </template>
        </Column>
        <Column v-if="isAdminOrOffice || isTeacher" header-style="width: 4rem; text-align: center" body-style="text-align: center; overflow: visible">
            <template #body="{ data }">
                <Button type="button" icon="pi pi-trash" class="p-button-text p-button-danger" @click="deleteFile(data.id)"></Button>
            </template>
        </Column>
    </DataTable>
</template>
<script setup>
import { useConfirm } from 'primevue/useconfirm';
import useRPC from '@/composables/useRPC';
import { ref, onMounted, computed, toRef } from 'vue';
import useFirebase from '@/composables/useFirebase';
import Utils from '@/utils/Utils';
import { usePopupStore } from '@/stores/PopupStore';
import useStorage from '@/composables/useStorage';
import { FilterMatchMode } from 'primevue/api';
const rowsPerPage = ref(10);
const files = ref([]);
const totalFiles = ref(0);
const loading = ref(false);
const confirm = useConfirm();
const { ClearStorage } = useStorage();
const { FetchFilesInFolder, DeleteFileInFolder, UploadFileInFolder, EditFileMetadata } = useRPC();
const fileInput = ref(null);

const tableFilters = ref({
    active: { value: null, matchMode: FilterMatchMode.EQUALS },
});

const isAdmin = computed(() => {
    return Utils.UserInfo.role === Utils.Roles.Admin;
});

const isOffice = computed(() => {
    return Utils.UserInfo.role === Utils.Roles.Office;
});

const isStudent = computed(() => {
    return Utils.UserInfo.role === Utils.Roles.Student;
});

const isTeacher = computed(() => {
    return Utils.UserInfo.role === Utils.Roles.Teacher;
});

const isAdminOrOffice = computed(() => {
    return isAdmin.value || isOffice.value;
});

let folderName = ref(null);

const fetchFiles = async (folder) => {
    loading.value = true;
    console.log('fetchFiles for folder: ' + folder);
    try {
        folderName.value = folder;

        let res = await FetchFilesInFolder(folderName.value.toString());

        let items = [];
        for (let file of res.data.data) {
            items.push({
                id: file.filename,
                filename: file.filename,
                size: file.size,
                url: file.url,
                date: file.date,
                active: file.active === 1,
            });
        }

        files.value = items;
        totalFiles.value = items.length;

        loading.value = false;
    } catch (err) {
        console.error(err);
    } finally {
        loading.value = false;
    }
};

const uploadFiles = async (files) => {
    try {
        loading.value = true;
        let promises = [];
        console.log('uploadFiles - files: ', files);
        for (let file of files) {
            let type = file.extension || file.type;
            if (type === 'svg' || type === 'pdf') {
                type = file.type;
            }

            await UploadFileInFolder(folderName.value.toString(), file);
        }

        loading.value = false;
    } catch (err) {
        console.log(err);
    }
};

const deleteFile = async (fileID) => {
    confirm.require({
        message: `Είστε σίγουροι για την διαγραφή του αρχείου;`,
        header: 'Επιβεβαίωση',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ναι',
        rejectLabel: 'Άκυρο',
        acceptClass: 'p-button-danger',
        rejectClass: 'p-button-secondary',
        accept: async () => {
            try {
                await DeleteFileInFolder(folderName.value.toString(), fileID);
                await fetchFiles(folderName.value);
            } catch (err) {
                console.log(err);
            }
        },
        reject: () => {
            console.log('do nothing');
        },
    });
};

const downloadFile = async (url, filename) => {
    window.open(url, '_blank');
};

const handleFilesChange = async (e) => {
    const files = e.target.files;
    await uploadFiles(files);
    await fetchFiles(folderName.value);
};

const addFiles = () => {
    if (!fileInput.value) {
        fileInput.value = document.createElement('input');
        fileInput.value.setAttribute('type', 'file');
        fileInput.value.setAttribute('id', Utils.generateUUID());
        fileInput.value.setAttribute('accept', '*');
        fileInput.value.setAttribute('style', 'display: none');
        fileInput.value.setAttribute('multiple', '');
        document.body.appendChild(fileInput.value);

        fileInput.value.removeEventListener('change', handleFilesChange);
        fileInput.value.addEventListener('change', handleFilesChange);
    }
    fileInput.value.click();
};

const toggleActive = async (fileID, active) => {
    console.log('toggleActive: ' + fileID + ' ' + active);
    try {
        await EditFileMetadata(folderName.value.toString(), fileID, { active: active ? 0 : 1 });
        await fetchFiles(folderName.value);
    } catch (err) {
        console.log(err);
    }
};

defineExpose({
    fetchFiles,
});
</script>
