<template>
    <Dialog v-model:visible="dialogShown" :close-on-escape="false" :dismissable-mask="false" :modal="true" @hide="onHide">
        <template #header>
            <div class="flex flex-row justify-content-between align-content-center">
                <h4>Ανάθεση Μαθήματος</h4>
            </div>
        </template>

        <div class="flex flex-column" style="gap: 1rem">
            <div class="flex flex-row w-full" style="gap: 1.81rem">
                <div class="box w-6 flex flex-row" style="height: 12.125rem">
                    <div class="flex flex-column" style="padding-left: 1rem; padding-top: 1.6rem">
                        <h5>Σχολή</h5>
                        <div class="flex flex-row gap-8 pt-1">
                            <div class="flex flex-column gap-3">
                                <span style="font-weight: 800">Κατηγορία:</span>
                                <span style="font-weight: 800">Υποκατηγορία Ι:</span>
                                <span style="font-weight: 800">Υποκατηγορία ΙΙ:</span>
                            </div>
                            <div class="flex flex-column gap-3">
                                <span>{{ state.category }}</span>
                                <span>{{ state.subCateg1 }}</span>
                                <span>{{ state.subCateg2 }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box flex-1" style="height: 12.125rem">
                    <div class="flex flex-column" style="padding-left: 1rem; padding-top: 1.6rem">
                        <h5>Μάθημα</h5>
                        <div class="flex flex-row gap-8 pt-1">
                            <div class="flex flex-column gap-3">
                                <span style="font-weight: 800">Τίτλος:</span>
                                <span style="font-weight: 800">Εξεταστική:</span>
                                <span style="font-weight: 800">Αρχεία:</span>
                            </div>
                            <div class="flex flex-column gap-3">
                                <span>{{ state.course?.title }}</span>
                                <span>{{ state.examPeriod?.title }}</span>
                                <a class="font-medium no-underline text-left cursor-pointer" style="color: blue" @click="showLessonFiles(state.course)">Αρχεία Μαθήματος</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-row w-full">
                <div class="box w-full">
                    <div class="flex flex-column" style="padding-left: 1rem; padding-top: 1.6rem; padding-bottom: 1.6rem">
                        <div class="flex flex-row pt-1 pr-2 justify-content-between w-full">
                            <div class="flex flex-column gap-3">
                                <div class="flex flex-row">
                                    <span style="font-weight: 800; width: 18rem">Μαθητές σε αναμονή:</span>
                                    <div style="width: 100%">
                                        <span>{{ state.totalRequests?.length }}</span>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <span style="font-weight: 800; width: 18rem">Απαντηση μέχρι:</span>
                                    <div style="width: 100%">
                                        <span>{{ moment(state.deadline).format('DD/MM/YYYY') }}</span>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <span style="font-weight: 800; width: 18rem; align-self: center">Ανάληψη μαθήματος:</span>
                                    <div style="width: 100%">
                                        <Dropdown
                                            v-model="v$.answer.$model"
                                            :options="Utils.PostingsChoices"
                                            optionLabel="label"
                                            placeholder="Επιλέξτε"
                                            style="width: 19rem"
                                            :class="{ 'p-invalid': v$.answer.$invalid && v$.answer.$dirty }"
                                        />
                                    </div>
                                </div>

                                <div v-if="positiveAnswer" class="flex flex-row">
                                    <span style="font-weight: 800; width: 18rem; align-self: center">Πρόταση ωρών (min):</span>
                                    <div style="width: 100%">
                                        <InputNumber v-model="state.hoursFrom" :min="0" :max="999" :showButtons="false" suffix=" ώρες" style="width: 8rem" />
                                    </div>
                                </div>

                                <div v-if="positiveAnswer" class="flex flex-row">
                                    <span style="font-weight: 800; width: 18rem; align-self: center">Πρόταση ωρών (max):</span>
                                    <div style="width: 100%">
                                        <InputNumber v-model="state.hoursTo" :min="0" :max="999" :showButtons="false" suffix=" ώρες" style="width: 8rem" />
                                    </div>
                                </div>

                                <div v-if="positiveAnswer" class="flex flex-row">
                                    <span style="font-weight: 800; width: 18rem; align-self: center">Προτεινόμενη έναρξη A:</span>
                                    <div style="width: 100%">
                                        <Calendar v-model="state.startDateA" :showIcon="true" style="width: 19rem" date-format="D dd/mm/yy" :show-time="true" :stepMinute="30" />
                                    </div>
                                </div>
                                <div v-if="positiveAnswer" class="flex flex-row">
                                    <span style="font-weight: 800; width: 18rem; align-self: center">Προτεινόμενη έναρξη B:</span>
                                    <div style="width: 100%">
                                        <Calendar v-model="state.startDateB" :showIcon="true" style="width: 19rem" date-format="D dd/mm/yy" :show-time="true" :stepMinute="30" />
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-row gap-3">
                                <span style="font-weight: 800">Μηνύματα:</span>
                                <div class="flex flex-column gap-0">
                                    <div ref="chatContainer" class="chat-container">
                                        <div class="virtual-scroller-content">
                                            <div class="messages-container">
                                                <div
                                                    v-for="message in messages"
                                                    :key="message.id"
                                                    class="message"
                                                    :data-value="isMyMsg(message.text) ? 'mine' : 'other'"
                                                    :style="{ display: message.text.trim().length === 0 ? 'none' : 'flex' }"
                                                >
                                                    <div class="flex flex-row gap-2">
                                                        <span>{{ pureMsg(message.text) }}</span>
                                                        <span v-if="message.date" style="font-size: 0.7rem; color: #999999; text-align: end; align-self: center">
                                                            ({{ moment(Utils.datetimeToDate(message.date)).format('DD/MM/YYYY HH:mm') }})
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="input-container">
                                        <input v-model="newMessage" type="text" placeholder="Πληκτρολόγησε ένα μήνυμα" @keyup.enter="appendAndSendMsg" />
                                        <Button label="" icon="pi pi-send" @click="appendAndSendMsg" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <template #footer>
            <Button label="Αποθήκευση" icon="pi pi-save" class="p-button-primary w-10rem" :disabled="!allFieldsValid" @click="onSendAnswers" />
        </template>

        <div v-if="loading" class="flex flex-row justify-content-between align-content-center w-full h-full absolute top-0 left-0 z-5 bg-black-alpha-20">
            <Vue3Lottie :animationData="loaderCircle" :loop="true" :autoPlay="true" style="width: 100%" />
        </div>
    </Dialog>

    <Dialog v-model:visible="showUploaderDialog" modal :style="{ width: '80vw' }">
        <template #header>
            <h3>{{ uploaderTitle }}</h3>
        </template>
        <Uploader ref="uploader" :viewMode="true" />
    </Dialog>
</template>

<script setup>
import { useToast } from 'primevue/usetoast';
import { required, integer, email } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { useDebounceFn } from '@vueuse/core';
import { usePopupStore } from '@/stores/PopupStore.js';
import useRPC from '@/composables/useRPC';
import Utils from '@/utils/Utils';
import moment from 'moment-timezone';
import { onMounted, onUnmounted, reactive, ref, computed, watchEffect, toRefs, nextTick, watch } from 'vue';
import useTemplates from '@/composables/useTemplates';
import { useConfirm } from 'primevue/useconfirm';
import { FilterMatchMode, FilterOperator, FilterService } from 'primevue/api';
import loaderCircle from '@/assets/lottie/loaderCircle.json';
import Uploader from '@/components/Uploader.vue';

const chatContainer = ref(null);
const messages = ref([]);
const newMessage = ref('');
const showUploaderDialog = ref(false);
const uploader = ref(null);
const uploaderTitle = ref('');
const submitted = ref(false);
const state = reactive({
    id: null,
    category: null,
    subCateg1: null,
    subCateg2: null,
    course: null,
    examPeriod: null,
    totalRequests: null,
    deadline: null,
    answer: null,
    hoursFrom: null,
    hoursTo: null,
    startDateA: null,
    startDateB: null,
});
const rules = {
    answer: {
        required,
    },
};
const v$ = useVuelidate(rules, state);
const { RPCApi, RPCMethods } = useRPC();
const headerTitle = ref('');
const loading = ref(false);
const popupStore = usePopupStore();
const confirm = useConfirm();
const toast = useToast();
const dialogShown = ref(false);
const closeCB = ref(null);

watch(
    () => state.answer,
    (val) => {
        if (val?.value !== 1) {
            state.hoursFrom = null;
            state.hoursTo = null;
            state.startDateA = null;
            state.startDateB = null;
        }
    }
);

const positiveAnswer = computed(() => {
    return state.answer?.value === 1;
});

const show = async (data, onCloseCB) => {
    closeCB.value = onCloseCB;
    dialogShown.value = true;
    let { id } = data;
    state.id = id;

    let res = await fetchPostingInfo(id);
};

const allFieldsValid = computed(() => {
    return !v$.value.$invalid;
});

const fetchPostingInfo = async (id) => {
    loading.value = true;
    try {
        let res = await RPCApi(RPCMethods.JobPostings.ReadJobPosting, { id: id, teacher: Utils.UserInfo.id });
        console.warn('res', res);
        if (res.status != 'ok') {
            toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Σφάλμα κατά την ανάκτηση των στοιχείων της ανάθεσης' });
            return;
        }
        let { category, subCateg1, subCateg2, courseTitle, courseId, examPeriodId, examPeriodTitle, totalRequests, deadline, title, body, application } = res.data.result.data;
        state.category = category;
        state.subCateg1 = subCateg1;
        state.subCateg2 = subCateg2;
        state.course = {
            title: courseTitle,
            id: courseId,
        };
        state.examPeriod = {
            title: examPeriodTitle,
            id: examPeriodId,
        };
        state.totalRequests = totalRequests;
        state.deadline = Utils.datetimeToDate(deadline);
        state.title = title;
        state.body = body;

        if (application) {
            state.answer = Utils.PostingsChoices.find((x) => x.value === application.apply);
            await nextTick();
            state.hoursFrom = application.hours_from;
            state.hoursTo = application.hours_to;
            state.startDateA = Utils.datetimeToDate(application.start_date_a);
            state.startDateB = Utils.datetimeToDate(application.start_date_b);
            breakCommentsToChatMessages(application.comments);
        }
    } catch (e) {
        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Σφάλμα κατά την ανάκτηση των στοιχείων της ανάθεσης' });
    }

    await nextTick();
    scrollToBottom();

    loading.value = false;
};

const breakCommentsToChatMessages = (comments) => {
    if (!comments) return;
    messages.value = [];
    let msgs = comments.split('\n');
    msgs.forEach((msg) => {
        messages.value.push({
            id: Utils.generateUUID(),
            text: msg.includes('_DATE_:') ? msg.substring(0, msg.indexOf('_DATE_:')) : msg,
            date: msg.includes('_DATE_:') ? msg.substring(msg.indexOf('_DATE_:') + 7) : null,
        });
    });
};

const isMyMsg = (msg) => {
    return msg.startsWith('_' + Utils.UserInfo.id + '_:');
};

const pureMsg = (msg) => {
    //replace using reg exp the '_xxxx_:' where xxxx is the user id as int, also remove '\n', alson remove _ADMIN_: case
    return msg
        .replace(/_([0-9]+)_:/g, '')
        .replace(/\n/g, '')
        .replace(/_ADMIN_:/g, '');
};

const appendAndSendMsg = async () => {
    loading.value = true;
    if (newMessage.value == null || newMessage.value.trim() === '') return;
    let tempMsg = newMessage.value;
    let msg = {
        id: Utils.generateUUID(),
        text: '_' + Utils.UserInfo.id + '_:' + newMessage.value,
    };
    newMessage.value = '';

    let sendRes = await RPCApi(RPCMethods.JobPostings.UpdateJobPostingApplicationComments, { id: state.id, msg: tempMsg, teacherId: Utils.UserInfo.id });
    if (sendRes.status != 'ok') {
        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Σφάλμα κατά την αποστολή του μηνύματος' });
        return;
    }
    breakCommentsToChatMessages(sendRes.data.result.data.comments);
    console.warn('sendRes', sendRes);

    await nextTick();
    scrollToBottom();
    loading.value = false;
};

const scrollToBottom = () => {
    console.warn('scrollToBottom', chatContainer.value.scrollHeight);
    chatContainer.value.scrollTop = chatContainer.value.scrollHeight;
};

const onSendAnswers = () => {
    let isFormValid = !v$.value.$invalid;
    v$.value.$touch();

    console.log('isFormValid?', isFormValid);
    if (!isFormValid) return;

    loading.value = true;

    let data = {
        id: state.id,
        answer: state.answer.value,
        hoursFrom: state.hoursFrom,
        hoursTo: state.hoursTo,
        startDateA: state.startDateA,
        startDateB: state.startDateB,
        teacherId: Utils.UserInfo.id,
    };

    console.log('data', data);

    RPCApi(RPCMethods.JobPostings.UpdateJobPostingApplication, data)
        .then((res) => {
            console.log('res', res);
            if (res.status == 'ok') {
                toast.add({ severity: 'success', summary: 'Επιτυχία', detail: 'Η απάντηση στην ανάθεση αποστάλθηκε με επιτυχία!', life: 5000 });
                onHide(true);
            } else {
                switch (res.status) {
                    case 'job_posting_already_picked':
                        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Η ανάθεση έχει ήδη αναληφθεί από άλλον καθηγητή!', life: 5000 });
                        break;
                    case 'job_posting_application_not_found':
                        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Η αίτηση για την ανάθεση δεν βρέθηκε!', life: 5000 });
                        break;
                    case 'invalid_parameters':
                        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Τα πεδία είναι υποχρεωτικά!', life: 5000 });
                        break;
                    default:
                        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Σφάλμα κατά την αποστολή της απάντησης!', life: 5000 });
                        break;
                }
            }
        })
        .catch((e) => {
            console.warn(e);
            toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Σφάλμα κατά την αποστολή της απάντησης!', life: 5000 });
        })
        .finally(() => {
            loading.value = false;
        });
};

const onHide = (action = false) => {
    console.log('onHide');
    dialogShown.value = false;
    resetForm();
    closeCB.value(action);
};

const showLessonFiles = async (course) => {
    console.log('showLessonFiles for course: ' + course.id + ' and title: ' + course.title);
    showUploaderDialog.value = true;
    uploaderTitle.value = 'Αρχεία μαθήματος: ' + course.title;
    await nextTick();
    uploader.value.fetchFiles(`courses/${course.id}/files`);
};

const resetForm = () => {
    console.warn('resetForm');
    v$.value.$reset();
    messages.value = [];
    newMessage.value = '';
    state.answer = null;
    state.hoursFrom = null;
    state.hoursTo = null;
    state.startDateA = null;
    state.startDateB = null;
    state.comments = null;
};

defineExpose({
    show,
});
</script>

<style lang="scss" scoped>
.box {
    border-radius: 0.75rem;
    border: 2px solid #e3e8ef;
    background: #fff;
    overflow: hidden;
}

.chat-container {
    display: flex;
    flex-direction: column;
    width: 600px;
    height: 330px;
    margin: auto;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    overflow-y: scroll;
    position: relative;

    .virtual-scroller-content {
        position: absolute;
        top: 0;
        left: 0;
        min-height: 100%;
        min-width: 100%;
        will-change: transform;
    }
}

.messages-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 15px;
    overflow-y: auto;
}

.message {
    max-width: 70%;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 10px;
    background-color: #e0f7fa;
    word-break: break-word;

    &[data-value='mine'] {
        align-self: flex-end;
        background-color: #b2ebf2;
    }

    &[data-value='other'] {
        align-self: flex-start;
        background-color: #eceff1;
    }
}

.input-container {
    display: flex;
    align-items: center;
    padding-top: 5px;

    input {
        flex-grow: 1;
        padding: 10px;
        border: 1px solid #e0e0e0;
        border-radius: 4px;
        margin-right: 10px;
    }

    button {
        padding: 10px 15px;
        background-color: #26a69a;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }

    button:hover {
        background-color: #00796b;
    }
}
</style>
