<template>
    <div class="col-12">
        <div class="card">
            <h5>Διαχείριση Μαθημάτων (Σύνολο: {{ totalCourses }})</h5>
            <DataTable
                v-model:filters="tableFilters"
                sort-mode="multiple"
                :loading="loading"
                :row-hover="true"
                :value="courses"
                data-key="id"
                responsive-layout="scroll"
                :rows="10"
                :paginator="true"
                filter-display="row"
            >
                <template #header>
                    <div v-if="!props.isSelectionMode" class="flex justify-content-between flex-column sm:flex-row">
                        <Button icon="pi pi-plus" label="Νέο Μάθημα" class="mr-2 mb-2" @click="addCourse" />
                    </div>
                </template>
                <template #empty> Δεν βρέθηκαν μαθήματα. </template>
                <Column v-if="props.isSelectionMode" header="Επιλογή" header-style="text-align: left">
                    <template #body="{ data }">
                        <div class="flex flex-row gap-2 block">
                            <Checkbox v-model="data.selected" :binary="true" @change="onSelectedCourseChanged" />
                        </div>
                    </template>
                </Column>
                <Column header="Κατηγορία" filter-field="categoryName" field="categoryName" :show-filter-menu="false" style="min-width: 10rem" :sortable="true">
                    <template #body="{ data }">
                        {{ data.categoryName }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Εύρεση βάση κατηγορίας" @input="filterCallback()" />
                    </template>
                </Column>
                <Column header="Υπ/ρία Ι" filter-field="subCateg1" field="subCateg1" :show-filter-menu="false" style="min-width: 10rem" :sortable="true">
                    <template #body="{ data }">
                        {{ data.subCateg1 }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Εύρεση βάση Υπ/ρία/Ι" @input="filterCallback()" />
                    </template>
                </Column>
                <Column header="Υπ/ρία ΙΙ" filter-field="subCateg2" field="subCateg2" :show-filter-menu="false" style="min-width: 10rem" :sortable="true">
                    <template #body="{ data }">
                        {{ data.subCateg2 }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Εύρεση βάση Υπ/ρία ΙΙ" @input="filterCallback()" />
                    </template>
                </Column>
                <Column header="Μάθημα" field="title" filter-field="title" :show-filter-menu="false" style="min-width: 10rem" :sortable="true">
                    <template #body="{ data }">
                        <a class="font-medium no-underline text-left cursor-pointer" style="color: blue" @click="editCourseDialog(data)"> {{ data.title }}</a>
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Εύρεση βάση τίτλου" @input="filterCallback()" />
                    </template>
                </Column>
                <Column header="Εξάμηνο" filter-field="semester" field="semester" :show-filter-menu="false" style="min-width: 10rem" :sortable="true">
                    <template #body="{ data }">
                        {{ data.semester }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Εύρεση βάση εξαμήνου" @input="filterCallback()" />
                    </template>
                </Column>
                <Column header="Ώρες" field="hours" style="min-width: 10rem" :sortable="true">
                    <template #body="{ data }">
                        {{ data.hours }}
                    </template>
                </Column>
                <Column header="Δίδακτρα" field="price" style="min-width: 10rem" :sortable="true">
                    <template #body="{ data }">
                        {{ Utils.toEUR(data.price) }}
                    </template>
                </Column>
                <Column field="active" filter-field="active" header="Ενεργό" data-type="boolean" :show-filter-menu="false" body-class="text-left" style="min-width: 8rem">
                    <template #body="{ data }">
                        <i class="pi" :class="{ 'true-icon pi-check-circle': data.active, 'false-icon pi-times-circle': !data.active }"></i>
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <TriStateCheckbox v-model="filterModel.value" @change="filterCallback()" />
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>
</template>
<script setup>
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import { ref, onMounted, watchEffect } from 'vue';
import useRPC from '@/composables/useRPC';
import Utils from '@/utils/Utils';
import { usePopupStore } from '@/stores/PopupStore';

const { RPCApi, RPCMethods } = useRPC();
const loading = ref(true);
const courses = ref([]);
const totalCourses = ref(0);
const popupStore = usePopupStore();

const tableFilters = ref({
    title: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    categoryName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    subCateg1: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    subCateg2: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    semester: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    examDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
    active: { value: true, matchMode: FilterMatchMode.EQUALS },
});

const props = defineProps({
    isSelectionMode: {
        type: Boolean,
        default: false,
    },
    selectedCourses: {
        type: Array,
        default: () => [],
    },
});

const emit = defineEmits(['selectedCoursesChanged']);

onMounted(async () => {
    loading.value = true;
    fetch();
});

const fetch = async () => {
    RPCApi(RPCMethods.Courses.Fetch).then((res) => {
        console.log(res);
        let d = [];
        if (res.status == 'ok') {
            const { total, coursesData } = res.data.result.data;
            totalCourses.value = total;

            for (let obj of coursesData) {
                d.push({
                    id: obj.id,
                    title: obj.title,
                    categoryName: obj.categoryName,
                    categoryID: obj.categoryID,
                    subCateg1: obj.subCateg1,
                    subCateg2: obj.subCateg2,
                    semester: obj.semester,
                    hours: obj.hours,
                    price: obj.price,
                    hours_private: obj.hours_private,
                    price_private: obj.price_private,
                    teacherID: obj.teacher_id,
                    examDate: Utils.datetimeToDate(obj.examination_date),
                    details: obj.details,
                    description: obj.description,
                    site: obj.site,
                    active: obj.active === 1 ? true : false,
                });
            }
            courses.value = d;
            loading.value = false;

            if (props.selectedCourses.length > 0) {
                for (let c of courses.value) {
                    c.selected = false;
                    for (let sc of props.selectedCourses) {
                        if (c.id == sc.id) {
                            c.selected = true;
                        }
                    }
                }
                //sort selected courses to the top
                courses.value.sort((a, b) => (a.selected === b.selected ? 0 : a.selected ? -1 : 1));
            }
        }
    });
};

const onDialogClosed = (action) => {
    console.log('onDialogClosed:' + action);
    if (action) fetch();
};

const addCourse = () => {
    popupStore.show(Utils.Popups.CourseDialog, null, (action) => {
        onDialogClosed(action);
    });
};

const editCourseDialog = (course) => {
    console.log('editCourseDialog', course);
    popupStore.show(Utils.Popups.CourseDialog, course.id, (action) => {
        onDialogClosed(action);
    });
};

const onSelectedCourseChanged = (e) => {
    let selectedArr = [];
    for (let c of courses.value) {
        if (c.selected) {
            selectedArr.push(c);
        }
    }
    console.log('selectedArr:', selectedArr);
    emit('selectedCoursesChanged', selectedArr);
};
</script>
