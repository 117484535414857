<template>
    <Dialog
        v-model:visible="dialogShown"
        class="p-fluid"
        :close-on-escape="false"
        :dismissable-mask="false"
        :modal="true"
        :breakpoints="{ '960px': '75vw', '640px': '95vw' }"
        :style="{ width: '40vw' }"
    >
        <template #header>
            <h4>{{ headerTitle }}</h4>
        </template>

        <div class="col-12">
            <div class="field">
                <label :class="{ 'p-error': v$.invoiceType.$invalid && submitted }">Τύπος Παραστατικού*</label>
                <Dropdown
                    v-model="v$.invoiceType.$model"
                    option-label="label"
                    :class="{ 'p-invalid': v$.invoiceType.$invalid && submitted }"
                    :options="Utils.InvoiceTypesOptions"
                    placeholder="Επιλογή"
                    class="p-column-filter"
                >
                    <template #value="slotProps">
                        <span v-if="slotProps.value != null">{{ slotProps.value.label }}</span>
                        <span v-else>Επιλογή</span>
                    </template>
                    <template #option="slotProps">
                        <span>{{ slotProps.option.label }}</span>
                    </template>
                </Dropdown>
                <small v-if="(v$.invoiceType.$invalid && submitted) || v$.invoiceType.$pending.$response" class="p-error">{{ v$.invoiceType.required.$message }}</small>
            </div>
            <div class="field-checkbox">
                <Checkbox id="skipMydata" v-model="state.skipMydata" :binary="true" />
                <label for="skipMydata">Χωρίς myData</label>
            </div>
            <div class="field">
                <label :class="{ 'p-error': v$.code.$invalid && submitted }">Τρόπος Πληρωμής*</label>
                <Dropdown
                    v-model="v$.code.$model"
                    option-label="label"
                    :options="Utils.ElorusPayments"
                    :class="{ 'p-invalid': v$.code.$invalid && submitted }"
                    placeholder="Επιλογή"
                    class="p-column-filter"
                >
                    <template #value="slotProps">
                        <span v-if="slotProps.value != null">{{ slotProps.value.label }}</span>
                        <span v-else>Επιλογή</span>
                    </template>
                    <template #option="slotProps">
                        <span>{{ slotProps.option.label }}</span>
                    </template>
                </Dropdown>
                <small v-if="(v$.code.$invalid && submitted) || v$.code.$pending.$response" class="p-error">{{ v$.code.required.$message }}</small>
            </div>
            <div class="field">
                <div class="flex flex-row flex-wrap gap-2 justify-content-between align-content-center align-items-center">
                    <div class="flex w-12rem">
                        <div class="field">
                            <label v-if="!editMode" :class="{ 'p-error': v$.amountΑ.$invalid && submitted }">Ποσό (σε ευρώ)*</label>
                            <label v-else :class="{ 'p-error': v$.amountΑ.$invalid && submitted }"> Ποσό*</label>
                            <InputNumber
                                v-model="v$.amountΑ.$model"
                                mode="decimal"
                                :min-fraction-digits="2"
                                locale="el-GR"
                                autocomplete="off"
                                spellcheck="off"
                                placeholder="0"
                                :class="{ 'p-invalid': v$.amountΑ.$invalid && submitted }"
                            />
                            <small v-if="(v$.amountΑ.$invalid && submitted) || v$.amountΑ.$pending.$response" class="p-error">{{ v$.amountΑ.required.$message }}</small>
                        </div>
                    </div>
                    <div class="flex w-12rem">
                        <div class="field">
                            <label v-if="!editMode" :class="{ 'p-error': v$.dateΑ.$invalid && submitted }">Ημερομηνία *</label>
                            <label v-else :class="{ 'p-error': v$.dateΑ.$invalid && submitted }">Ημερομηνία*</label>
                            <Calendar
                                v-model="v$.dateΑ.$model"
                                :manualInput="false"
                                date-format="dd/mm/yy"
                                placeholder="dd/mm/yyyy"
                                :class="{ 'p-invalid': v$.dateΑ.$invalid && submitted }"
                                :minDate="new Date()"
                            />
                            <small v-if="(v$.dateΑ.$invalid && submitted) || v$.dateΑ.$pending.$response" class="p-error">{{ v$.dateΑ.required.$message }}</small>
                        </div>
                    </div>
                </div>
            </div>

            <Divider align="left"> </Divider>

            <div class="field">
                <div class="flex flex-row gap-2 justify-content-between align-content-center align-items-center">
                    <div class="flex w-full">
                        <div class="field w-full">
                            <label :class="{ 'p-error': v$.fname.$invalid && submitted }">Όνομα*</label>
                            <InputText v-model="v$.fname.$model" :class="{ 'p-error': v$.fname.$invalid && submitted }" autocomplete="off" spellcheck="off" placeholder="" :disabled="true" />
                            <small v-if="(v$.fname.$invalid && submitted) || v$.fname.$pending.$response" class="p-error">{{ v$.fname.required.$message }}</small>
                        </div>
                    </div>
                    <div class="flex w-full">
                        <div class="field w-full">
                            <label :class="{ 'p-error': v$.lname.$invalid && submitted }">Επώνυμο*</label>
                            <InputText v-model="v$.lname.$model" :class="{ 'p-error': v$.lname.$invalid && submitted }" autocomplete="off" spellcheck="off" placeholder="" :disabled="true" />
                            <small v-if="(v$.lname.$invalid && submitted) || v$.lname.$pending.$response" class="p-error">{{ v$.lname.required.$message }}</small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="field">
                <div class="flex flex-row gap-2 justify-content-between align-content-center align-items-center">
                    <div class="flex w-full">
                        <div class="field w-full">
                            <label :class="{ 'p-error': v$.mobile.$invalid && submitted }">Τηλέφωνο*</label>
                            <InputText v-model="v$.mobile.$model" autocomplete="off" spellcheck="off" :class="{ 'p-invalid': v$.mobile.$invalid && submitted }" :disabled="true" />
                            <small v-if="(v$.mobile.$invalid && submitted) || v$.mobile.$pending.$response" class="p-error">{{ v$.mobile.required.$message }}</small>
                        </div>
                    </div>
                    <div class="flex w-full">
                        <div class="field w-full">
                            <label :class="{ 'p-error': v$.email.$invalid && submitted }">Email*</label>
                            <InputText v-model="v$.email.$model" autocomplete="off" spellcheck="off" :class="{ 'p-invalid': v$.email.$invalid && submitted }" :disabled="true" />
                            <small v-if="(v$.email.$invalid && submitted) || v$.email.$pending.$response" class="p-error">{{ v$.email.required.$message }}</small>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="isInvoice" class="field">
                <div class="flex flex-row gap-2 justify-content-between align-content-center align-items-center">
                    <div class="flex w-full">
                        <div class="field w-full">
                            <label>Εταιρεία</label>
                            <InputText v-model="state.companyName" autocomplete="off" spellcheck="off" maxlength="100" />
                        </div>
                    </div>
                    <div class="flex w-full">
                        <div class="field w-full">
                            <label>ΑΦΜ</label>
                            <InputText v-model="state.afm" autocomplete="off" spellcheck="off" placeholder="ΑΦΜ" maxlength="20" />
                        </div>
                    </div>
                    <div class="flex w-full">
                        <div class="field w-full">
                            <label>ΔΟΥ</label>
                            <Dropdown v-model="state.doy" option-label="label" :options="Utils.DoyOptions" placeholder="Επιλογή" class="p-column-filter">
                                <template #value="slotProps">
                                    <span v-if="slotProps.value != null">{{ slotProps.value.label }}</span>
                                    <span v-else>Επιλογή</span>
                                </template>
                                <template #option="slotProps">
                                    <span>{{ slotProps.option.label }}</span>
                                </template>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="isInvoice" class="field">
                <div class="flex flex-row gap-1 justify-content-between align-content-center align-items-center">
                    <div class="field">
                        <label>Διεύθυνση</label>
                        <InputText v-model="state.address" autocomplete="off" spellcheck="off" maxlength="200" />
                    </div>
                    <div class="field">
                        <label>Πόλη</label>
                        <InputText v-model="state.city" autocomplete="off" spellcheck="off" maxlength="100" />
                    </div>
                    <div class="field">
                        <label>Περιοχή</label>
                        <InputText v-model="state.area" autocomplete="off" spellcheck="off" />
                    </div>
                    <div class="field">
                        <label>Τ.Κ.</label>
                        <InputText v-model="state.zipCode" autocomplete="off" spellcheck="off" maxlength="20" />
                    </div>
                </div>
            </div>

            <Divider align="left"> </Divider>
            <div class="field">
                <label>Σχόλια</label>
                <Textarea v-model="state.comment" autocomplete="off" spellcheck="off" placeholder="Σχόλια" rows="3" cols="30" />
            </div>
        </div>

        <template #footer>
            <Button label="Άκυρο" icon="pi pi-times" class="p-button-outlined w-8rem mr-3" @click="onHide(false)" />
            <Button
                :label="saveButtonLbl"
                icon="pi pi-check"
                class="p-button-primary w-14rem"
                :class="{ 'p-button-warning': state.dateΑ < new Date() }"
                :loading="loading"
                @click="handleSubmit(!v$.$invalid)"
            />
        </template>
    </Dialog>
</template>

<script setup>
import { useToast } from 'primevue/usetoast';
import { onMounted, reactive, ref, computed, watchEffect, toRefs } from 'vue';
import { required, email } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import moment from 'moment-timezone';
import useRPC from '@/composables/useRPC';
import Utils from '@/utils/Utils';
const { RPCApi, RPCMethods } = useRPC();
const dialogShown = ref(false);
const submitted = ref(false);
const headerTitle = ref('');
const state = reactive({
    id: null,
    code: null,
    amountΑ: null,
    dateΑ: new Date(),
    fname: null,
    lname: null,
    mobile: null,
    phone: null,
    afm: null,
    city: null,
    address: null,
    zipCode: null,
    companyName: null,
    email: null,
    comment: null,
    invoiceType: null,
    skipMydata: false,
    doy: null,
    area: null,
});
const rules = {
    code: { required },
    invoiceType: { required },
    amountΑ: { required },
    dateΑ: { required },
    email: { required, email },
    fname: { required },
    lname: { required },
    mobile: { required },
};
const v$ = useVuelidate(rules, state);
const toast = useToast();
const editMode = ref(false);
const userId = ref(null);
const closeCB = ref(null);
const loading = ref(false);
const calledAfterPayment = ref(false);
const typeRef = ref('receipt');

const isReceipt = computed(() => {
    return typeRef.value === 'receipt';
});

const isInvoice = computed(() => {
    return typeRef.value === 'invoice';
});

const scheduled = computed(() => {
    let dateAMidnight = moment(state.dateΑ).startOf('day').toDate();
    let nowDateMidnight = moment(new Date()).startOf('day').toDate();
    console.log('dateAMidnight:', dateAMidnight);
    console.log('nowDateMidnight:', nowDateMidnight);
    return dateAMidnight > nowDateMidnight;
});

const saveButtonLbl = computed(() => {
    let dateAMidnight = moment(state.dateΑ).startOf('day').toDate();
    let nowDateMidnight = moment(new Date()).startOf('day').toDate();
    console.log('dateAMidnight:', dateAMidnight);
    console.log('nowDateMidnight:', nowDateMidnight);
    let type = typeRef.value === 'receipt' ? 'ΑΠΥ' : 'ΤΠΥ';
    return dateAMidnight > nowDateMidnight ? `Αποθήκευση ${type}` : `Έκδοση ${type}`;
});

const show = async (data, onCloseCB) => {
    let { invoiceData, userID, code, amount, date, type } = data;
    calledAfterPayment.value = !!amount;
    closeCB.value = onCloseCB;
    console.log('invoice:', invoiceData);
    resetForm();
    typeRef.value = type || 'receipt';
    dialogShown.value = true;
    submitted.value = false;
    editMode.value = !!invoiceData;
    userId.value = userID;

    console.log('Utils.ElorusPayments::', Utils.ElorusPayments);
    console.log('code:', code);
    console.log('Utils.getMyDATAPaymentMethodID(code):', Utils.getMyDATAPaymentMethodID(code));

    state.code = Utils.ElorusPayments.find((x) => x.value == Utils.getMyDATAPaymentMethodID(code));
    console.warn('state.code::' + state.code);
    state.amountΑ = amount || null;
    state.dateΑ = date < Date.now() ? new Date() : date;

    headerTitle.value = typeRef.value === 'receipt' ? (!scheduled.value ? 'ΑΠΥ' : 'ΑΠΥ (Προγραμματισμένη)') : 'ΤΠΥ';

    //fetch user data
    let res = await RPCApi(RPCMethods.User.GetUserInfoById, { id: userId.value });
    console.log(res);
    if (res.status == 'ok') {
        const { fname, lname, email, mobile, phone, afm, city, address, zipCode } = res.data.result.data.userInfo;
        state.fname = fname;
        state.lname = lname;
        state.email = email;
        state.mobile = mobile;
        state.phone = phone;
        state.afm = afm;
        state.city = city;
        state.address = address;
        state.zipCode = zipCode;
    }

    let elorusContact = await RPCApi(RPCMethods.User.GetElorusContact, { userID: userId.value });
    console.warn('elorusContact:', elorusContact);
    if (elorusContact.status === 'ok') {
        const { company, tax_office, vat_number, addresses } = elorusContact.data.result.data;
        state.companyName = company;
        state.doy = Utils.DoyOptions.find((x) => x.value === tax_office);
        state.afm = vat_number;
        if (addresses.length > 0) {
            state.city = addresses[0].city;
            state.address = addresses[0].address;
            state.zipCode = addresses[0].zip;
            state.area = addresses[0].state;
        }
    }

    if (editMode.value) {
        state.id = invoiceData.id;
        state.amountΑ = invoiceData.amount;
        state.dateΑ = Utils.datetimeToDate(invoiceData.scheduledAtDate);
        state.comment = invoiceData.comment;
        state.invoiceType = Utils.InvoiceTypesOptions.find((x) => x.value === invoiceData.type);
        state.skipMydata = invoiceData.skipMydata;
        state.code = Utils.ElorusPayments.find((x) => x.value == invoiceData.paymentMethod) || null;
    } else {
        state.invoiceType = Utils.InvoiceTypesOptions.find((x) => x.value === (typeRef.value === 'receipt' ? Utils.InvoiceTypes.Receipt : Utils.InvoiceTypes.Invoice));
    }
};

const onHide = (action = false) => {
    dialogShown.value = false;
    resetForm();
    closeCB.value(action);
};

const simpleSave = async (isFormValid) => {
    console.log('simpleSave');
};

const handleSubmit = async (isFormValid) => {
    submitted.value = true;

    if (!isFormValid) return;

    //check if state.dateΑ is a past day
    let dateAMidnight = moment(state.dateΑ).startOf('day').toDate();
    let nowDateMidnight = moment(new Date()).startOf('day').toDate();
    console.log('dateAMidnight:', dateAMidnight);
    console.log('nowDateMidnight:', nowDateMidnight);
    if (dateAMidnight < nowDateMidnight) {
        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Η ημερομηνία έκδοσης είναι λάθος!', life: 3000 });
        return;
    }

    console.log('ok submit');
    loading.value = true;
    let res;
    let payload = {
        paymentMethod: state.code.value,
        skipMydata: state.skipMydata === true ? 1 : 0,
        invoiceType: state.invoiceType.value,
        amountA: state.amountΑ,
        dateA: state.dateΑ,
        fname: state.fname,
        lname: state.lname,
        companyName: state.companyName,
        job: '',
        clientType: Utils.CustomerTypes.Private_individual,
        email: state.email,
        phone: state.mobile,
        address: state.address,
        city: state.city,
        state: state.area,
        zip: state.zipCode,
        country: 'GR',
        language: 'el',
        afm: state.afm,
        doy: state.doy?.value,
        comment: state.comment,
        userID: userId.value,
    };

    console.log(payload);

    if (editMode.value) {
        payload.id = state.id;
        res = await RPCApi(RPCMethods.Invoice.Update, payload);
    } else {
        res = await RPCApi(RPCMethods.Invoice.Add, payload);
    }

    console.warn(res);

    if (res.status !== 'ok') {
        switch (res.status) {
            case 'address_required_for_invoices':
                toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Η διεύθυνση είναι υποχρεωτικό πεδίο στα τιμολόγια!', life: 3000 });
                break;
            case 'city_required_for_invoices':
                toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Η πόλη είναι υποχρεωτικό πεδίο στα τιμολόγια!', life: 3000 });
                break;
            case 'zip_required_for_invoices':
                toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Ο Τ.Κ. είναι υποχρεωτικό πεδίο στα τιμολόγια!', life: 3000 });
                break;
            case 'country_required_for_invoices':
                toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Η χώρα είναι υποχρεωτικό πεδίο στα τιμολόγια!', life: 3000 });
                break;
            case 'afm_required_for_invoices':
                toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'To ΑΦΜ είναι υποχρεωτικό πεδίο στα τιμολόγια!', life: 3000 });
                break;
            case 'doy_required_for_invoices':
                toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Η ΔΟΥ είναι υποχρεωτικό πεδίο στα τιμολόγια!', life: 3000 });
                break;
            case 'company_required_for_invoices':
                toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Η εταιρία είναι υποχρεωτικό πεδίο στα τιμολόγια!', life: 3000 });
                break;
            case 'invoice_not_found':
                toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Δεν βρέθηκε το παραστατικό!', life: 3000 });
                break;
            case 'invoice_not_editable':
                toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Το πιστοποιητικό έχει ήδη εκδοθεί!', life: 3000 });
                break;
            default:
                if (res.data && res.data.result && res.data.result.data && res.data.result.data.type)
                    toast.add({ severity: 'error', summary: 'Σφάλμα', detail: res.data.result.data.type, life: 3000 });
                else toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Παρουσιάστηκε κάποιο σφάλμα!', life: 3000 });

                break;
        }

        loading.value = false;
        return;
    }
    toast.add({ severity: 'success', summary: 'Η διαδικασία ολοκληρώθηκε', detail: 'Επιτυχής διαδικασία!', life: 3000 });

    loading.value = false;
    onHide(true);
};
const resetForm = () => {
    for (let k in state) state[k] = null;

    state.dateΑ = new Date();
    state.skipMydata = false;

    submitted.value = false;
    loading.value = false;

    typeRef.value = 'receipt';
};

defineExpose({
    show,
});
</script>
