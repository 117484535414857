import { defineStore } from 'pinia';
import { ref, computed, watch } from 'vue';
import useRPC from '@/composables/useRPC';
import useFirebase from '@/composables/useFirebase';
import Utils from '@/utils/Utils';

const { realtimeDB, realtimeDBInstance } = useFirebase();

export const useGlobalStore = defineStore('Globalstore', () => {
    const panicMode = ref(false);
    const sqlDBInfo = ref(null);

    const isAdmin = computed(() => {
        return Utils.UserInfo?.role === Utils.Roles.Admin;
    });
    const isOffice = computed(() => {
        return Utils.UserInfo?.role === Utils.Roles.Office;
    });
    const isAdminOrOffice = computed(() => {
        return isAdmin.value || isOffice.value;
    });

    const init = async () => {
        if (!isAdminOrOffice.value) return;
        const userStatusRef = realtimeDBInstance().ref('/panic');
        userStatusRef.on('value', (snapshot) => {
            panicMode.value = snapshot.val();
        });
    };

    const togglePanicMode = async () => {
        if (!isAdminOrOffice.value) return;
        panicMode.value = !panicMode.value;
        realtimeDBInstance().ref('/panic').set(panicMode.value);
    };

    const hasPanicMode = computed(() => {
        if (!isAdminOrOffice.value) return false;
        return panicMode.value;
    });

    const setDBInfo = (info) => {
        sqlDBInfo.value = info;
    };

    const dbIsDev = computed(() => {
        return sqlDBInfo.value?.dev;
    });

    return { init, togglePanicMode, hasPanicMode, panicMode, setDBInfo, dbIsDev };
});
