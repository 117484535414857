<template>
    <div class="flex flex-column w-full">
        <TabMenu v-if="coursesMode" :model="tabMenuItems" />
        <div v-if="!props.viewMode" style="padding: 1rem; display: flex; flex-direction: row; align-items: center; justify-content: space-between">
            <div class="flex flex-row w-full" style="gap: 1rem">
                <Button v-if="isAdminOrOffice || isTeacher" label="Δημιουργία Φακέλου" icon="pi pi-plus" class="w-20rem" @click="addFolder" />
                <Button v-if="isAdminOrOffice || isTeacher" label="Upload Φακέλου" class="p-button-secondary w-20rem" icon="pi pi-upload" @click="uploadFolder" />
            </div>
            <div class="flex flex-row w-full justify-content-end" style="gap: 1rem">
                <Button
                    v-if="(isAdminOrOffice || isTeacher) && treeData.length > 0"
                    label="Απενεργοποίηση επιλεγμένων"
                    icon="pi pi-times"
                    class="p-button-warning"
                    :disabled="controlButtonsDisabled"
                    @click="toggleActive(false)"
                />
                <Button
                    v-if="(isAdminOrOffice || isTeacher) && treeData.length > 0"
                    label="Ενεργοποίηση επιλεγμένων"
                    icon="pi pi-check"
                    class="p-button-success"
                    :disabled="controlButtonsDisabled"
                    @click="toggleActive(true)"
                />
                <Button
                    v-if="(isAdminOrOffice || isTeacher) && treeData.length > 0"
                    label="Διαγραφή επιλεγμένων"
                    icon="pi pi-trash"
                    class="p-button-danger"
                    :disabled="controlButtonsDisabled"
                    @click="deleteSelectedFiles"
                />
            </div>
        </div>
        <div class="flex flex-column w-full justify-content-start align-items-start">
            <Tree
                v-if="treeData.length > 0 || loading"
                v-model:selectionKeys="selectedKey"
                :selectionMode="!props.viewMode ? 'checkbox' : null"
                :value="treeData"
                :filter="true"
                filterMode="lenient"
                :loading="loading"
                scrollHeight="50rem"
            >
                <template #default="slotProps">
                    <div class="flex flex-row align-items-center gap-4">
                        <b>{{ slotProps.node.label }}</b>
                        <Button v-if="!isStudent && !props.viewMode" icon="pi pi-plus" severity="help" text raised rounded aria-label="Upload" @click.stop="addFiles(slotProps.node)" />
                    </div>
                </template>
                <template #url="slotProps">
                    <a href="#" target="_blank" @click.prevent="openFileLink(slotProps.node.data.url)">
                        <span :style="{ color: !slotProps.node.data.active ? '#808080' : 'inherit' }">{{ slotProps.node.label }}</span>
                    </a>
                </template>
            </Tree>
            <Vue3Lottie v-else-if="!loading" :animationData="NoEntries" :loop="true" :autoPlay="true" style="width: 30rem" />
        </div>
    </div>
    <Dialog v-model:visible="createFolderDialogShown" header="Δημιουργία φακέλου" :modal="true" :closeOnEscape="false" closable>
        <div class="p-fluid">
            <div class="p-field">
                <label for="folderName">Όνομα φακέλου</label>
                <InputText id="folderName" v-model="v$.createdFolderName.$model" autocomplete="off" spellcheck="off" :class="{ 'p-invalid': v$.createdFolderName.$invalid }" />
            </div>
        </div>
        <template #footer>
            <Button label="Αποθήκευση" icon="pi pi-check" class="p-button-primary w-10rem" :disabled="v$.createdFolderName.$invalid" @click="saveFolderName" />
        </template>
    </Dialog>

    <div v-if="uploadInProgress" class="loader-container">
        <Vue3Lottie :animationData="loaderCircle" :loop="true" :autoPlay="true" style="width: 100%" />
    </div>
</template>
<script setup>
import { useConfirm } from 'primevue/useconfirm';
import useRPC from '@/composables/useRPC';
import { ref, onMounted, computed, toRef, watch, reactive } from 'vue';
import useFirebase from '@/composables/useFirebase';
import Utils from '@/utils/Utils';
import { usePopupStore } from '@/stores/PopupStore';
import useStorage from '@/composables/useStorage';
import { FilterMatchMode } from 'primevue/api';
import Dropdown from 'primevue/dropdown';
import { required, integer } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import NoEntries from '@/assets/lottie/empty_animation.json';
import loaderCircle from '@/assets/lottie/loaderCircle.json';

const coursesMode = ref(false);
const uploadInProgress = ref(false);
const { RPCApi, RPCMethods } = useRPC();
const selectedKey = ref(null);
const treeData = ref([]);
const loading = ref(true);
const confirm = useConfirm();
const { ClearStorage } = useStorage();
const { FetchFilesInFolder, DeleteFileInFolder, UploadFileInFolder, EditFileMetadata, User } = useRPC();
const createFolderDialogShown = ref(false);
const extraData = ref(null);
const folderName = ref(null);
const selectedFolder = ref(null);
const fileInput = ref(null);
const uploadProgress = ref(0);
const props = defineProps({
    viewMode: {
        type: Boolean,
        default: false,
    },
});

const state = reactive({
    createdFolderName: '',
});

const rules = {
    createdFolderName: { required },
};
const v$ = useVuelidate(rules, state);

const isAdmin = computed(() => {
    return Utils.UserInfo.role === Utils.Roles.Admin;
});

const isOffice = computed(() => {
    return Utils.UserInfo.role === Utils.Roles.Office;
});

const isStudent = computed(() => {
    return Utils.UserInfo.role === Utils.Roles.Student;
});

const isTeacher = computed(() => {
    return Utils.UserInfo.role === Utils.Roles.Teacher;
});

const isAdminOrOffice = computed(() => {
    return isAdmin.value || isOffice.value;
});

const controlButtonsDisabled = computed(() => {
    return !selectedKey.value || Object.keys(selectedKey.value).length === 0;
});

const tabMenuItems = computed(() => {
    return extraData.value.courses.map((course) => ({
        label: course.title,
        command: () => {
            fetchFiles(`courses/${course.id}/files`, extraData.value);
        },
    }));
});

onMounted(async () => {
    coursesMode.value = false;
});

const storagePath = computed(() => {
    return folderName.value;
});

const openFileLink = async (url) => {
    console.log('openFileLink: ', url);
    window.open(url, '_blank');
};

const deleteSelectedFiles = async () => {
    console.log('deleteSelectedFiles: ', selectedKey.value);
    if (!selectedKey.value || Object.keys(selectedKey.value).length === 0) {
        console.log('no files selected');
        return;
    }
    confirm.require({
        message: `Είστε σίγουροι για την διαγραφή των επιλεγμένων αρχείων;`,
        header: 'Επιβεβαίωση',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ναι',
        rejectLabel: 'Άκυρο',
        acceptClass: 'p-button-danger',
        rejectClass: 'p-button-secondary',
        accept: async () => {
            try {
                for (let key in selectedKey.value) {
                    let name = key.split('/')[key.split('/').length - 1];
                    console.log('name:', name);
                    let isDir = key.includes('/');
                    if (!isDir) continue;
                    let dirName = storagePath.value + '/' + key.substring(0, key.lastIndexOf('/'));
                    console.log('directory:' + dirName + ', file: ' + name);

                    //if last chars in dirName is 'files//' then it is the root folder
                    if (dirName.substring(dirName.length - 7) === 'files//') {
                        dirName = dirName.substring(0, dirName.length - 8) + '/files';
                        console.warn('dirName normalized is:' + dirName);
                    }

                    await DeleteFileInFolder(dirName, name);
                    if (isTeacher.value) {
                        console.warn(extraData.value);
                        await await RPCApi(RPCMethods.User.SendDeleteFileNotification, { dirName: dirName, name: name, extraData: extraData.value });
                    }
                }
                await fetchFiles();
            } catch (err) {
                console.log(err);
            }
        },
        reject: () => {
            console.log('do nothing');
        },
    });
};

const toggleActive = async (activate) => {
    console.log('toggleActive: ', activate, selectedKey.value);
    if (!selectedKey.value || Object.keys(selectedKey.value).length === 0) {
        console.log('no files selected');
        return;
    }
    confirm.require({
        message: `Είστε σίγουροι για την ενεργοποίηση των επιλεγμένων αρχείων;`,
        header: 'Επιβεβαίωση',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ναι',
        rejectLabel: 'Άκυρο',
        acceptClass: 'p-button-danger',
        rejectClass: 'p-button-secondary',
        accept: async () => {
            try {
                for (let key in selectedKey.value) {
                    let name = key.split('/')[key.split('/').length - 1];
                    console.log('name:', name);
                    let isDir = key.includes('/');
                    if (!isDir) continue;
                    let dirName = storagePath.value + '/' + key.substring(0, key.lastIndexOf('/'));
                    console.log('directory:' + dirName + ', file: ' + name + ' - key: ' + key);

                    let treeNode = findChildInTreeWithKey(treeData.value, key);
                    let alreadyActivatedTargets = treeNode ? treeNode.data.activatedTargets : [];
                    let activatedTargetIds = [...alreadyActivatedTargets];
                    if (extraData.value?.classID) {
                        if (activate && activatedTargetIds.indexOf(extraData.value.classID) === -1) {
                            activatedTargetIds.push(extraData.value.classID);
                        } else if (!activate) {
                            activatedTargetIds = activatedTargetIds.filter((item) => item !== extraData.value.classID);
                        }

                        await EditFileMetadata(dirName, name, { activated_targets: activatedTargetIds });
                    } else {
                        await EditFileMetadata(dirName, name, { active: activate ? 1 : 0 });
                    }
                }

                await fetchFiles();
            } catch (err) {
                console.log(err);
            }
        },
        reject: () => {
            console.log('do nothing');
        },
    });
};

const findChildInTreeWithKey = (data, key) => {
    for (let item of data) {
        if (item.key === key) return item;
        if (item.children && item.children.length > 0) {
            let found = findChildInTreeWithKey(item.children, key);
            if (found) return found;
        }
    }
};

const addToTree = (tree, subfolders, file) => {
    let currentLevel = tree;
    for (let folder of subfolders) {
        if (folder === '') continue;

        // Check if folder already exists at this level
        let existingFolder = currentLevel.find((node) => node.key === folder);

        if (!existingFolder) {
            // If not, create a new folder and add it to the current level
            existingFolder = {
                key: folder,
                label: folder,
                data: { isFolder: true, fullPathDir: file.url.substring(file.url.indexOf('files/') + 6, file.url.lastIndexOf('/')) },
                children: [],
            };
            currentLevel.push(existingFolder);
        }

        // Move to the next level (i.e., the children of the existing/new folder)
        currentLevel = existingFolder.children;
    }

    if (file.size > 0) {
        if (extraData.value && extraData.value.classID) {
            file.active = file.activated_targets.includes(extraData.value.classID);
        }
        if (isStudent.value && !file.active) return;
        currentLevel.push({
            key: `${file.url.substring(file.url.indexOf('files/') + 6, file.url.lastIndexOf('/'))}/${file.filename}`,
            label: file.filename,
            type: 'url',
            data: { url: file.url, active: file.active, isFolder: false, activatedTargets: file.activated_targets },
        });
    }
};

const fetchFiles = async (folder, data) => {
    state.createdFolderName = '';
    console.warn('fetchFiles: ', folder, data);
    if (folder != null) {
        coursesMode.value = data && data.courses != null;
    }
    selectedKey.value = {};
    loading.value = true;
    if (data) extraData.value = data;
    try {
        if (folder != null) folderName.value = folder.toString();

        let res = await FetchFilesInFolder(storagePath.value);
        console.log('FetchFilesInFolder res: ', res);
        let tree = [];
        for (let file of res.data.data) {
            let subfolders = file.url.substring(file.url.indexOf('files/') + 6, file.url.lastIndexOf('/')).split('/');
            addToTree(tree, subfolders, file);
        }

        if (isStudent.value) {
            tree = tree.filter((item) => item.children.length > 0);
            checkEachFolderHasFile(tree);
        }
        console.warn(tree);
        treeData.value = tree;

        loading.value = false;
    } catch (err) {
        console.error(err);
    } finally {
        loading.value = false;
    }
};

const checkEachFolderHasFile = (tree) => {
    let i = tree.length;
    while (i--) {
        const node = tree[i];
        // If this is a folder
        if (node.data.isFolder) {
            // If this folder has no children, remove this node
            if (!node.children) {
                console.warn('Folder ' + node.key + ' has no files');
                tree.splice(i, 1);
            } else {
                // Recursively check this folder's children
                const hasFileInChildren = checkEachFolderHasFile(node.children);
                if (!hasFileInChildren) {
                    console.warn('Folder ' + node.key + ' has no files');
                    tree.splice(i, 1);
                }
            }
        }
    }
    // If we've checked all nodes and haven't removed any, then every folder must contain at least one file
    return tree.some((node) => !node.data.isFolder) || tree.some((node) => node.children && checkEachFolderHasFile(node.children));
};

const uploadFiles = async (files) => {
    console.log('uploadFiles::', files);
    try {
        uploadInProgress.value = true;
        for (let file of files) {
            let webkitRelativePath = file.webkitRelativePath;
            let pathExceptLast = webkitRelativePath.substring(0, webkitRelativePath.lastIndexOf('/'));
            let foldersPath = selectedFolder.value ? storagePath.value + '/' + selectedFolder.value : storagePath.value;
            if (pathExceptLast !== '' && pathExceptLast != null) foldersPath = foldersPath + '/' + pathExceptLast;

            console.warn('foldersPath:' + foldersPath + ', file: ' + file.name);

            await UploadFileInFolder(foldersPath, file);
        }
        uploadInProgress.value = false;
    } catch (err) {
        console.log(err);
    }
};

const handleFilesChange = async (e) => {
    const files = e.target.files;
    await uploadFiles(files);
    await fetchFiles();
};

const addFiles = (node) => {
    if (node.data.fullPathDir) selectedFolder.value = node.data.fullPathDir;
    else if (node.key) selectedFolder.value = node.key;
    if (!fileInput.value) {
        fileInput.value = document.createElement('input');
        fileInput.value.setAttribute('type', 'file');
        fileInput.value.setAttribute('id', Utils.generateUUID());
        fileInput.value.setAttribute('accept', '*');
        fileInput.value.setAttribute('style', 'display: none');
        fileInput.value.setAttribute('multiple', '');
        document.body.appendChild(fileInput.value);

        fileInput.value.removeEventListener('change', handleFilesChange);
        fileInput.value.addEventListener('change', handleFilesChange);
    }
    fileInput.value.click();
};

const addFolder = async () => {
    console.log('addFolder');
    state.createdFolderName = '';
    createFolderDialogShown.value = true;
};

const uploadFolder = async () => {
    console.log('uploadFolder');
    selectedFolder.value = '';
    if (!fileInput.value) {
        fileInput.value = document.createElement('input');
        fileInput.value.setAttribute('type', 'file');
        fileInput.value.setAttribute('id', Utils.generateUUID());
        fileInput.value.setAttribute('accept', '*');
        fileInput.value.setAttribute('style', 'display: none');
        fileInput.value.setAttribute('multiple', '');
        fileInput.value.setAttribute('directory', '');
        fileInput.value.setAttribute('webkitdirectory', '');
        fileInput.value.setAttribute('moxdirectory', '');
        document.body.appendChild(fileInput.value);

        fileInput.value.removeEventListener('change', handleFilesChange);
        fileInput.value.addEventListener('change', handleFilesChange);
    }
    fileInput.value.click();
};

const saveFolderName = async () => {
    console.log('saveFolderName: ' + state.createdFolderName);
    treeData.value = [
        {
            key: state.createdFolderName,
            label: state.createdFolderName,
            data: { isFolder: true },
            children: [],
        },
        ...treeData.value,
    ];

    createFolderDialogShown.value = false;
};

defineExpose({
    fetchFiles,
});
</script>
<style lang="scss" scoped>
.container {
    display: flex;
    flex-direction: row;
    gap: 3rem;
    width: 100%;
    height: 600px;
    overflow-y: scroll;
    flex-wrap: wrap;
    padding: 1rem 0.5rem;
    min-width: 800px;

    .file-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
        width: 150px;

        .file {
            cursor: pointer;
            background: #eee;
            border: 1px solid #ccc;
            -moz-border-radius: 3px 15px 3px 3px;
            -webkit-border-radius: 3px 15px 3px 3px;
            border-radius: 3px 15px 3px 3px;
            -moz-box-shadow: inset rgba(255, 255, 255, 0.8) 0 1px 1px;
            -webkit-box-shadow: inset rgba(255, 255, 255, 0.8) 0 1px 1px;
            box-shadow: inset rgba(255, 255, 255, 0.8) 0 1px 1px;
            width: 80px;
            height: 90px;
        }

        .file-title {
            width: 100%;
            max-width: 100px;
            text-align: center;
            font-size: 1em;
            white-space: normal;
            overflow-wrap: break-word;
            word-wrap: break-word;
        }
    }

    .folder-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
        width: 150px;

        .folder {
            cursor: pointer;
            width: 130px;
            height: 90px;
            position: relative;
            background-color: #5a8bbc;
            border-radius: 0 3px 3px 6px;
            box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.5);
        }

        .folder:before {
            content: '';
            width: 50%;
            height: 0.5em;
            border-radius: 0 20px 0 0;
            background-color: #5a8bbc;
            position: absolute;
            top: -0.5em;
            left: 0px;
        }

        .folder-title {
            width: 100%;
            text-align: center;
            color: #000;
            font-weight: bold;
            font-size: 1em;
        }
    }
}

.loader-container {
    position: fixed; /* or absolute */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7); /* semi-transparent white */
    z-index: 1000; /* high z-index to cover the whole screen */
}
</style>
