<template>
    <Dialog v-model:visible="dialogShown" :close-on-escape="false" :dismissable-mask="false" :modal="true" :breakpoints="{ '960px': '75vw', '640px': '95vw' }" :style="{ width: '85vw' }">
        <template #header>
            <h4>Εμφάνιση Αιτήσεων</h4>
        </template>
        <DataTable
            ref="dt"
            v-model:selection="selectedRequests"
            :total-records="totalRequests"
            :loading="loading"
            :row-hover="true"
            :value="requests"
            data-key="id"
            responsive-layout="scroll"
            :rows="rowsPerPage"
            :paginator="true"
            @row-select="onCourseRowSelected"
            @row-unselect="onCourseRowUnselected"
        >
            <template #header>
                <h5>Σύνολο: {{ totalRequests }}</h5>
                <h5>Επιλεγμένες: {{ selectedRequests.length }}</h5>
            </template>
            <template #empty> Δεν βρέθηκαν αιτήσεις. </template>
            <Column selection-mode="multiple" header-style="width: 3rem"></Column>
            <Column header="Αίτηση" field="id" style="min-width: 2rem">
                <template #body="{ data }">
                    <a class="font-medium no-underline text-left cursor-pointer" style="color: blue" @click="onShowRequest(data.id)">
                        {{ data.id }}
                    </a>
                </template>
            </Column>
            <Column header="Αριθ. Ανάθεσης" field="title" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                <template #body="{ data }">
                    <a v-if="data.jobPostingID != null" class="font-medium no-underline text-left cursor-pointer" style="color: blue" @click="onShowJobPostingEdit(data.jobPostingID)">
                        {{ data.jobPostingID }}
                    </a>
                    <span v-else> -- </span>
                </template>
            </Column>
            <Column header="Μάθημα" field="title" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                <template #body="{ data }">
                    <a class="font-medium no-underline text-left cursor-pointer" style="color: blue" @click="onShowCourse()">
                        {{ data.title }} {{ data.semester != null ? `(${data.semester})` : '' }}
                    </a>
                </template>
            </Column>
            <Column header="Σχολή" field="school" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                <template #body="{ data }">
                    {{ data.school }}
                </template>
            </Column>
            <Column header="Εξεταστική" field="examPeriod" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                <template #body="{ data }">
                    {{ data.examPeriod }}
                </template>
            </Column>
            <Column field="isPrivate" header="Τύπος" data-type="boolean" body-class="text-left" style="min-width: 8rem">
                <template #body="{ data }">
                    <span class="private-badge" :class="`private-${data.isPrivate}`">{{ Utils.Types[Utils.FromBoolToTinyInt(data.isPrivate)].label }}</span>
                </template>
            </Column>
            <Column field="status" header="Κατάσταση" body-class="text-left" style="min-width: 10rem" :show-filter-menu="false">
                <template #body="{ data }">
                    <span class="status-badge" :class="`status-${data.status}`">{{ Utils.RequestStatusToString(data.status) }}</span>
                </template>
            </Column>
            <Column header="Μαθητής" field="studentName" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                <template #body="{ data }">
                    <a class="font-medium no-underline text-left cursor-pointer" style="color: blue" @click="showStudentDialog(data.studentID)">{{ data.studentName }}</a>
                </template>
            </Column>
            <!-- <Column header="Υπεύθυνος" field="accountableID" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                <template #body="{ data }">
                    <a class="font-medium no-underline text-left cursor-pointer" style="color: blue" @click="showAccountableDialog(data.accountableID)">{{ data.accountableName }}</a>
                </template>
            </Column> -->
        </DataTable>

        <template #footer>
            <Button label="Άκυρο" icon="pi pi-times" class="p-button-outlined w-8rem mr-3" @click="onHide(false)" />
            <Button label="Αποθήκευση" icon="pi pi-check" class="p-button-primary w-10rem" @click="onHide(true)" />
        </template>
    </Dialog>
</template>

<script setup>
import { useToast } from 'primevue/usetoast';
import { onMounted, reactive, ref, computed, watch } from 'vue';
import { required, integer, email } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { useDebounceFn } from '@vueuse/core';
import { usePopupStore } from '@/stores/PopupStore.js';
import useRPC from '@/composables/useRPC';
import Utils from '@/utils/Utils';
import moment from 'moment-timezone';
const { RPCApi, RPCMethods } = useRPC();
const dialogShown = ref(false);
const courseId = ref(null);
const dt = ref();
const loading = ref(true);
const selectedRequests = ref([]);
const totalRequests = ref(0);
const requests = ref([]);
const rowsPerPage = ref(10);
const closeCB = ref(null);
const popupStore = usePopupStore();

const show = async (data, onCloseCB) => {
    const { courseID, totalSelectedRequests, examPeriod } = data;
    closeCB.value = onCloseCB;
    courseId.value = courseID;
    dialogShown.value = true;
    console.log('show', data);
    loading.value = true;
    selectedRequests.value = [];

    let params = { id: courseID };
    if (data.examPeriod) {
        params.examPeriod = data.examPeriod;
    }
    const requestsForCourse = await RPCApi(RPCMethods.Request.FetchPendingRequestsForCourse, params);
    console.log('requestsForCourse', requestsForCourse);
    if (requestsForCourse.status == 'ok') {
        let arr = [];
        for (let obj of requestsForCourse.data.result.data) {
            arr.push({
                id: obj.id,
                title: obj.courseTitle,
                school: obj.schoolTitle,
                isPrivate: obj.private === 1,
                status: obj.status,
                semester: obj.semester,
                examPeriod: obj.examPeriod,
                studentID: obj.studentID,
                studentName: obj.studentName,
                accountableID: obj.accountableID,
                accountableName: obj.accountableName,
                jobPostingID: obj.jobPostingID,
            });
        }
        requests.value = arr;
        totalRequests.value = arr.length;
        if (totalSelectedRequests) selectedRequests.value = arr.filter((x) => totalSelectedRequests.includes(x.id));
    }
    loading.value = false;
};

const onHide = (action = false) => {
    dialogShown.value = false;
    if (!action) selectedRequests.value = [];
    closeCB.value(
        action,
        selectedRequests.value.map((x) => x.id),
        courseId.value,
        selectedRequests.value[0]?.examPeriod || null,
        selectedRequests.value.filter((x) => x.jobPostingID != null).length > 0
    );
};

const showStudentDialog = (studentID) => {
    popupStore.show(Utils.Popups.StudentDialog, { studentID }, () => {
        console.log('student dialog closed');
    });
};

const onCourseRowSelected = (event) => {
    console.log('onCourseRowSelected: ', event.data);
};

const onCourseRowUnselected = (event) => {
    console.log('onCourseRowUnselected: ', event.data);
};

const onShowRequest = (id) => {
    popupStore.show(Utils.Popups.RequestDialog, { requestID: id }, () => {});
};

const onShowCourse = () => {
    popupStore.show(Utils.Popups.CourseDialog, courseId.value, () => {});
};

const onShowJobPostingEdit = (id) => {
    popupStore.show(Utils.Popups.JobPostingsEditDialog, { id }, () => {});
};

defineExpose({
    show,
});
</script>
