<!-- eslint-disable no-case-declarations -->
<template>
    <AppSubMenu :model="model" />
</template>

<script setup>
import Utils from '@/utils/Utils';
import { useNotificationsStore } from '@/stores/NotificationsStore';
import { ref, onMounted, watch, onUnmounted, onBeforeUpdate } from 'vue';
import AppSubMenu from './AppSubMenu.vue';
import { useChatStore } from '@/stores/ChatStore';
import { useRouter } from 'vue-router';
import useFirebase from '@/composables/useFirebase';
import { useToast } from 'primevue/usetoast';
import { useLayout } from '@/layout/composables/layout';

const notificationsStore = useNotificationsStore();
const router = useRouter();
const chatStore = useChatStore();
const toast = useToast();
const { layoutState } = useLayout();

const model = ref([
    {
        label: 'ΑΡΧΙΚΗ',
        items: [
            {
                label: 'Dashboard',
                icon: 'home-outline',
                iconSize: 18,
                to: Utils.BASE,
            },
        ],
    },
]);

watch(
    () => notificationsStore.totalUnread,
    () => {
        setNotificationsIndication(notificationsStore.totalUnread);
    }
);

watch(
    () => notificationsStore.totalPostingsExpired,
    () => {
        setPostingsIndication(notificationsStore.totalPostingsExpired);
    }
);

watch(
    () => chatStore.totalUnreadMessages,
    () => {
        setChatIndication(chatStore.totalUnreadMessages);
    }
);

onUnmounted(() => {
    notificationsStore.clear();
});

onMounted(async () => {
    setupMenu();
    notificationsStore.init();
    setNotificationsIndication(notificationsStore.totalUnread);
    setPostingsIndication(notificationsStore.totalPostingsExpired);

    chatStore.refresh();
});

const setNotificationsIndication = (value) => {
    for (let obj of model.value) {
        for (let item of obj.items) {
            if (item.to === Utils.getRoute('notifications')) {
                item.indication = value;
            }
        }
    }
};

const setPostingsIndication = (value) => {
    for (let obj of model.value) {
        for (let item of obj.items) {
            if (item.to === Utils.getRoute('job_postings')) {
                item.indication = value;
            }
        }
    }
};

const setChatIndication = (value) => {
    for (let obj of model.value) {
        for (let item of obj.items) {
            if (item.to === Utils.getRoute('chat')) {
                item.indication = value;
            }
        }
    }
};

const setupMenu = () => {
    let prevData = [...model.value];
    switch (Utils.UserInfo.role) {
        case Utils.Roles.Admin:
            model.value = [
                ...model.value,
                {
                    label: 'ΓΕΝΙΚΑ',
                    items: [
                        {
                            label: 'Ημερήσιο Πρόγραμμα',
                            icon: 'calendar-month-outline',
                            iconSize: 16,
                            to: Utils.getRoute('dailycalendar'),
                        },
                        {
                            label: 'Παρουσιολόγιο',
                            icon: 'hand-back-right-outline',
                            iconSize: 16,
                            to: Utils.getRoute('attendances'),
                        },
                        {
                            label: 'Mailbox',
                            icon: 'email-outline',
                            iconSize: 16,
                            to: Utils.getRoute('notifications'),
                        },
                        {
                            label: 'Chat',
                            icon: 'chat-outline',
                            iconSize: 16,
                            to: Utils.getRoute('chat'),
                            indication: 0,
                        },
                        {
                            label: 'Διαχείριση Προσωπικού',
                            icon: 'account-supervisor-outline',
                            iconSize: 16,
                            to: Utils.getRoute('stuff'),
                        },
                        {
                            label: 'Tips',
                            icon: 'lightbulb-on-outline',
                            iconSize: 16,
                            to: Utils.getRoute('tips'),
                            indication: 0,
                        },
                        {
                            label: 'Todo',
                            icon: 'checkbox-marked-outline',
                            iconSize: 16,
                            to: Utils.getRoute('todos'),
                        },
                        {
                            label: 'Ρυθμίσεις',
                            icon: 'cog-outline',
                            iconSize: 16,
                            to: Utils.getRoute('app_settings'),
                        },
                    ],
                },
                {
                    label: 'Αιτησεις',
                    items: [
                        { label: 'Διαχείριση Αιτήσεων', icon: 'file-outline', iconSize: 16, to: Utils.getRoute('requests') },
                        { label: 'Προσχέδια Αιτήσεων', icon: 'file-clock-outline', iconSize: 16, to: Utils.getRoute('temp_requests') },
                    ],
                },
                {
                    label: 'Τμηματα',
                    items: [
                        { label: 'Διαχείριση Τμημάτων', icon: 'account-group-outline', iconSize: 16, to: Utils.getRoute('classes') },
                        {
                            label: 'Αναθέσεις',
                            icon: 'newspaper-variant-outline',
                            iconSize: 16,
                            to: Utils.getRoute('job_postings'),
                        },
                    ],
                },
                {
                    label: 'Μαθητες',
                    items: [{ label: 'Διαχείριση Μαθητών', icon: 'account-school-outline', iconSize: 16, to: Utils.getRoute('students') }],
                },
                {
                    label: 'Καθηγητες',
                    items: [
                        { label: 'Διαχείριση Καθηγητών', icon: 'human-male-board', iconSize: 16, to: Utils.getRoute('teachers') },
                        { label: 'Ώρες', icon: 'timetable', iconSize: 16, to: Utils.getRoute('teachers-hours') },
                        { label: 'Αξιολογήσεις', icon: 'message-star-outline', iconSize: 16, to: Utils.getRoute('teachers_rating') },
                        { label: 'Ειδικότητες', icon: 'school-outline', iconSize: 16, to: Utils.getRoute('departments') },
                    ],
                },
                {
                    label: 'Μαθηματα',
                    items: [
                        { label: 'Σχολές', icon: 'shape-outline', iconSize: 16, to: Utils.getRoute('schools') },
                        { label: 'Διαχείριση Μαθημάτων', icon: 'format-list-bulleted-type', iconSize: 16, to: Utils.getRoute('courses') },
                        { label: 'Εξεταστικές', icon: 'timeline-clock-outline', iconSize: 16, to: Utils.getRoute('periods') },
                        { label: 'Ερωτήσεις (Quiz)', icon: 'file-question-outline', iconSize: 16, to: Utils.getRoute('quizzes') },
                    ],
                },
                {
                    label: 'Αιθουσες',
                    items: [{ label: 'Διαχείριση Αιθουσών', icon: 'google-classroom', iconSize: 16, to: Utils.getRoute('classrooms') }],
                },
                {
                    label: 'Εργασιες',
                    items: [{ label: 'Διαχείριση Εργασιών', icon: 'note-text-outline', iconSize: 16, to: Utils.getRoute('assignments') }],
                },
                {
                    label: 'Εκτυπωσεις',
                    items: [
                        {
                            label: 'Πληρωμές',
                            icon: 'cash-clock',
                            iconSize: 16,
                            to: Utils.getRoute('treasury'),
                        },
                        { label: 'Αιτήσεις', icon: 'file-export-outline', iconSize: 16, to: Utils.getRoute('requests-printer') },
                        { label: 'Παραστατικά', icon: 'receipt', iconSize: 16, to: Utils.getRoute('invoices-printer') },
                    ],
                },
            ];
            break;
        case Utils.Roles.Office:
            model.value = [
                ...model.value,
                {
                    label: 'ΓΕΝΙΚΑ',
                    items: [
                        {
                            label: 'Ημερήσιο Πρόγραμμα',
                            icon: 'calendar-month-outline',
                            iconSize: 16,
                            to: Utils.getRoute('dailycalendar'),
                        },
                        {
                            label: 'Παρουσιολόγιο',
                            icon: 'hand-back-right-outline',
                            iconSize: 16,
                            to: Utils.getRoute('attendances'),
                        },
                        {
                            label: 'Mailbox',
                            icon: 'email-outline',
                            iconSize: 16,
                            to: Utils.getRoute('notifications'),
                        },
                        {
                            label: 'Chat',
                            icon: 'chat-outline',
                            iconSize: 16,
                            to: Utils.getRoute('chat'),
                        },
                        {
                            label: 'Todo',
                            icon: 'checkbox-marked-outline',
                            iconSize: 16,
                            to: Utils.getRoute('todos'),
                        },
                    ],
                },
                {
                    label: 'Αιτησεις',
                    items: [
                        { label: 'Διαχείριση Αιτήσεων', icon: 'file-outline', iconSize: 16, to: Utils.getRoute('requests') },
                        { label: 'Προσχέδια Αιτήσεων', icon: 'file-clock-outline', iconSize: 16, to: Utils.getRoute('temp_requests') },
                    ],
                },
                {
                    label: 'Τμηματα',
                    items: [
                        { label: 'Διαχείριση Τμημάτων', icon: 'account-group-outline', iconSize: 16, to: Utils.getRoute('classes') },
                        {
                            label: 'Αναθέσεις',
                            icon: 'newspaper-variant-outline',
                            iconSize: 16,
                            to: Utils.getRoute('job_postings'),
                        },
                    ],
                },
                {
                    label: 'Μαθητες',
                    items: [{ label: 'Διαχείριση Μαθητών', icon: 'account-school-outline', iconSize: 16, to: Utils.getRoute('students') }],
                },

                {
                    label: 'Καθηγητες',
                    items: [
                        { label: 'Διαχείριση Καθηγητών', icon: 'human-male-board', iconSize: 16, to: Utils.getRoute('teachers') },
                        { label: 'Ώρες', icon: 'timetable', iconSize: 16, to: Utils.getRoute('teachers-hours') },
                        { label: 'Αξιολογήσεις', icon: 'message-star-outline', iconSize: 16, to: Utils.getRoute('teachers_rating') },
                        { label: 'Ειδικότητες', icon: 'school-outline', iconSize: 16, to: Utils.getRoute('departments') },
                    ],
                },
                {
                    label: 'Μαθηματα',
                    items: [
                        { label: 'Σχολές', icon: 'shape-outline', iconSize: 16, to: Utils.getRoute('schools') },
                        { label: 'Διαχείριση Μαθημάτων', icon: 'format-list-bulleted-type', iconSize: 16, to: Utils.getRoute('courses') },
                        { label: 'Εξεταστικές', icon: 'timeline-clock-outline', iconSize: 16, to: Utils.getRoute('periods') },
                        { label: 'Ερωτήσεις (Quiz)', icon: 'file-question-outline', iconSize: 16, to: Utils.getRoute('quizzes') },
                    ],
                },
                {
                    label: 'Αιθουσες',
                    items: [{ label: 'Διαχείριση Αιθουσών', icon: 'google-classroom', iconSize: 16, to: Utils.getRoute('classrooms') }],
                },
                {
                    label: 'Εργασιες',
                    items: [{ label: 'Διαχείριση Εργασιών', icon: 'note-text-outline', iconSize: 16, to: Utils.getRoute('assignments') }],
                },
                {
                    label: 'Εκτυπωσεις',
                    items: [
                        {
                            label: 'Πληρωμές',
                            icon: 'cash-clock',
                            iconSize: 16,
                            to: Utils.getRoute('treasury'),
                        },
                        { label: 'Αιτήσεις', icon: 'file-export-outline', iconSize: 16, to: Utils.getRoute('requests-printer') },
                    ],
                },
            ];
            break;
        case Utils.Roles.Student:
            model.value = [
                {
                    label: '',
                    items: [
                        {
                            label: 'Αρχική',
                            icon: 'home-outline',
                            iconSize: 18,
                            to: Utils.BASE,
                        },
                        {
                            label: 'Chat',
                            icon: 'chat-outline',
                            iconSize: 16,
                            to: Utils.getRoute('chat'),
                        },
                        {
                            label: 'Mailbox',
                            icon: 'email-outline',
                            iconSize: 16,
                            to: Utils.getRoute('notifications'),
                        },
                        { label: 'Αιτήσεις', icon: 'file-outline', iconSize: 16, to: Utils.getRoute('requests') },
                        { label: 'Τμήματα', icon: 'account-group-outline', iconSize: 16, to: Utils.getRoute('classes') },
                        // { label: 'Οι Εργασίες μου', icon: 'note-text-outline', iconSize: 16, to: Utils.getRoute('assignments') },
                        { label: 'Οικονομικά', icon: 'cash-clock', iconSize: 16, to: Utils.getRoute('economics') },
                        { label: 'Quiz', icon: 'file-question-outline', iconSize: 16, to: Utils.getRoute('quizzes_student') },
                    ],
                },
            ];

            break;
        case Utils.Roles.Teacher:
            model.value = [
                {
                    label: '',
                    items: [
                        {
                            label: 'Αρχική',
                            icon: 'home-outline',
                            iconSize: 18,
                            to: Utils.BASE,
                        },
                        {
                            label: 'Chat',
                            icon: 'chat-outline',
                            iconSize: 16,
                            to: Utils.getRoute('chat'),
                        },
                        {
                            label: 'Aναθέσεις',
                            icon: 'newspaper-variant-outline',
                            iconSize: 16,
                            to: Utils.getRoute('job_postings'),
                        },
                        {
                            label: 'Mailbox',
                            icon: 'email-outline',
                            iconSize: 16,
                            to: Utils.getRoute('notifications'),
                        },
                        { label: 'Τμήματα', icon: 'account-group-outline', iconSize: 16, to: Utils.getRoute('classes') },
                        { label: 'Παρουσιολόγιο', icon: 'hand-back-right-outline', iconSize: 16, to: Utils.getRoute('attendances') },
                        { label: 'Ώρες μαθημάτων', icon: 'timetable', iconSize: 16, to: Utils.getRoute('teachers-hours') },
                        { label: 'Ερωτήσεις (Quiz)', icon: 'file-question-outline', iconSize: 16, to: Utils.getRoute('quizzes') },
                        { label: 'Quiz', icon: 'note-text-outline', iconSize: 16, to: Utils.getRoute('quizzes_teacher') },
                    ],
                },
            ];
            break;
        default:
            console.warn('No user role found!');
            break;
    }
};
</script>
