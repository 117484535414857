<template>
    <div
        class="user-container"
        :style="{
            '--totalSize': props.size,
            '--levelBorderColor': user.star ? '#fbe898' : '#c5f6fa',
        }"
    >
        <div class="bg">
            <div v-if="props.user.role === Utils.Roles.Admin" class="badge">
                <img :src="adminIcon" />
            </div>
            <div v-if="props.user.role === Utils.Roles.Office" class="badge">
                <img :src="officeIcon" />
            </div>
            <div v-if="props.user.role === Utils.Roles.Student" class="badge">
                <img :src="studentIcon" />
            </div>
            <div v-if="props.user.role === Utils.Roles.Teacher" class="badge">
                <img :src="teacherIcon" />
            </div>
            <div v-if="online" class="online"></div>
            <div class="photo-container">
                <img :src="avatar" />
            </div>
        </div>
    </div>
</template>

<script setup>
import Utils from '@/utils/Utils';
import { ref, onMounted, reactive, watch, toRef, computed } from 'vue';
import adminIcon from '@/assets/images/badgeAdmin.svg';
import officeIcon from '@/assets/images/badgeOffice.svg';
import studentIcon from '@/assets/images/badgeStudent.svg';
import teacherIcon from '@/assets/images/badgeProfessor.svg';

const props = defineProps({
    user: {
        type: Object,
        required: true,
        default: () => ({}),
    },
    size: {
        type: String,
        required: false,
        default: '10rem',
    },
});

const online = computed(() => {
    return props.user.status === 'online';
});

const avatar = computed(() => {
    let userID = props.user._id || props.user.id;
    return Utils.GetGenericAvatar(userID);
});
</script>

<style lang="scss" scoped>
.user-container {
    // Fallback values
    --totalSize: 10rem;
    --borderRadius: 50%;
    --badgeSize: calc(var(--totalSize) * 0.2);
    --photoContainerBGColor: #8d3c00;
    --gradientColorA: #f5f5f5;
    --gradientColorB: #f5f5f5;
    --levelBGColor: #b96f09;
    --opacity: 1;
    z-index: 0;

    .bg {
        position: relative;
        border-radius: var(--borderRadius);
        width: calc(var(--totalSize) * 0.92);
        height: calc(var(--totalSize) * 0.92);
        background-image: linear-gradient(180deg, var(--gradientColorB) 0%, var(--gradientColorA) 100%);
        font-size: var(--badgeSize);

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: var(--totalSize);
            height: var(--totalSize);
            transform: translate(-50%, -50%);
            border-radius: 50%;
            background-image: linear-gradient(180deg, var(--gradientColorA) 0%, var(--gradientColorB) 100%);
            z-index: -1;
        }

        .badge {
            position: absolute;
            width: 2.2em;
            height: 2.2em;
            top: -0.6em;
            right: -0.6em;
            // border-style: solid;
            // border-width: 0.1em;
            // border-color: rgb(251, 232, 152);
            // border-radius: 50%;
            // background-color: #b96f09;
            z-index: 4;

            img {
                width: 80%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: #ffffff;
            }
        }

        .online {
            position: absolute;
            width: 1.4em;
            height: 1.4em;
            bottom: -0.1em;
            right: -0.1em;
            border-style: solid;
            border-width: 0.1em;
            border-color: #ffffff;
            border-radius: 50%;
            background-color: #00ca72;
            z-index: 4;
        }

        .photo-container {
            position: absolute;
            width: 90%;
            height: 90%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            // border-radius: 1rem;
            // background-color: var(--photoContainerBGColor);
            // border-radius: var(--borderRadius);
            z-index: 3;

            img {
                width: 100%;
                height: 100%;
                border-radius: var(--borderRadius);
                object-fit: cover;
            }
        }
    }
}
</style>
