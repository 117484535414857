<template>
    <Dialog
        v-model:visible="dialogShown"
        class="p-fluid"
        :close-on-escape="false"
        :dismissable-mask="false"
        :modal="true"
        :breakpoints="{ '960px': '75vw', '640px': '95vw' }"
        :style="{ width: '35vw' }"
    >
        <template #header>
            <h4>{{ headerTitle }}</h4>
        </template>
        <div class="col-12">
            <div class="field">
                <label :class="{ 'p-error': v$.reason.$invalid && submitted }">Αιτιολογία*</label>
                <Textarea
                    id="reason"
                    v-model="v$.reason.$model"
                    autocomplete="off"
                    spellcheck="off"
                    rows="3"
                    cols="30"
                    placeholder="Αιτιολογία"
                    :class="{ 'p-invalid': v$.reason.$invalid && submitted }"
                />
                <small v-if="(v$.reason.$invalid && submitted) || v$.reason.$pending.$response" class="p-error">{{ v$.reason.required.$message }}</small>
            </div>
            <div class="field">
                <label :class="{ 'p-error': v$.amount.$invalid && submitted }">Ποσό*</label>
                <InputNumber
                    v-model="v$.amount.$model"
                    mode="decimal"
                    :min-fraction-digits="2"
                    currency="EUR"
                    autocomplete="off"
                    spellcheck="off"
                    placeholder="σε Ευρώ"
                    locale="el-GR"
                    :class="{ 'p-invalid': v$.amount.$invalid && submitted }"
                />
                <small v-if="(v$.amount.$invalid && submitted) || v$.amount.$pending.$response" class="p-error">{{ v$.amount.required.$message }}</small>
            </div>
        </div>
        <template #footer>
            <Button label="Άκυρο" icon="pi pi-times" class="p-button-outlined w-8rem mr-3" @click="onHide(false)" />
            <Button label="Αποθήκευση" icon="pi pi-check" class="p-button-primary w-10rem" :loading="loading" @click="handleSubmit(!v$.$invalid)" />
        </template>
    </Dialog>
</template>

<script setup>
import { useToast } from 'primevue/usetoast';
import moment from 'moment-timezone';
import { onMounted, reactive, ref, computed, watchEffect, toRefs } from 'vue';
import { required, integer, helpers } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import useRPC from '@/composables/useRPC';
import Utils from '@/utils/Utils';
const { RPCApi, RPCMethods } = useRPC();
const dialogShown = ref(false);
const submitted = ref(false);
const headerTitle = ref('');
const state = reactive({
    userId: null,
    dueId: null,
    reason: null,
    amount: null,
});
const rules = {
    reason: { required },
    amount: { required },
};
const v$ = useVuelidate(rules, state, { $stopPropagation: true });
const toast = useToast();
const editMode = ref(false);
const loading = ref(false);
const closeCB = ref(null);

const show = async (data, onCloseCB) => {
    resetForm();
    closeCB.value = onCloseCB;
    dialogShown.value = true;
    submitted.value = false;
    editMode.value = data.dueId != null;
    state.userId = data.userId;
    state.dueId = data.dueId;
    headerTitle.value = editMode.value ? `Τροποποίηση Χρέωσης (${state.dueId})` : 'Νέα Χρέωση';

    if (editMode.value) {
        state.reason = data.reason;
        state.amount = data.amount;
    }
};

const onHide = (action = false) => {
    dialogShown.value = false;
    resetForm();
    closeCB.value(action);
};

const handleSubmit = async (isFormValid) => {
    submitted.value = true;

    if (!isFormValid) return;

    console.log('ok submit');
    let res;
    let payload = {
        userId: state.userId,
        reason: state.reason,
        amount: state.amount,
    };

    loading.value = true;
    if (editMode.value) {
        payload.dueId = state.dueId;
        res = await RPCApi(RPCMethods.User.UpdateGeneralDue, payload);
    } else {
        res = await RPCApi(RPCMethods.User.AddGeneralDue, payload);
    }

    if (res.status === 'ok') {
        toast.add({ severity: 'success', summary: 'Η διαδικασία ολοκληρώθηκε', detail: 'Επιτυχής διαδικασία!', life: 3000 });
        loading.value = false;
        onHide(true);
        return;
    }

    loading.value = false;
    toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Προσπαθήστε ξανά!', life: 3000 });
};
const resetForm = () => {
    for (let field in state) {
        state[field] = null;
    }

    submitted.value = false;
};

defineExpose({
    show,
});
</script>
