<template>
    <Dialog
        v-model:visible="dialogShown"
        :close-on-escape="false"
        :dismissable-mask="false"
        :modal="true"
        :breakpoints="{ '960px': '75vw', '640px': '95vw' }"
        :style="{ width: isAdminOrOffice ? '65vw' : '65vw' }"
    >
        <template #header>
            <div class="flex flex-row justify-items-center align-items-center">
                <img v-if="isDraft && isAdminOrOffice" src="@/assets/images/draft.png" style="height: 1.8rem" />
                <h5 v-if="isDraft && isAdminOrOffice" style="margin: 0; color: blueviolet">ΠΡΟΣΧΕΔΙΟ</h5>
                <img v-if="isDraft && isAdminOrOffice" src="@/assets/images/draft.png" style="height: 1.8rem" />
                <h4 style="margin: 0">{{ headerTitle }}</h4>
            </div>
        </template>
        <div v-if="schoolsLoading" class="flex flex-row justify-content-between align-content-center w-full h-full absolute top-0 left-0 z-5 bg-black-alpha-20">
            <Vue3Lottie :animationData="loaderCircle" :loop="true" :autoPlay="true" style="width: 100%" />
        </div>
        <div class="formgrid grid p-fluid">
            <div class="col-12">
                <div v-if="isAdminOrOffice">
                    <h5>Στοιχεία Μαθητή</h5>
                    <div class="field">
                        <label :class="{ 'p-error': v$.student.$invalid && submitted }" for="student">Μαθητής*</label>
                        <AutoComplete
                            id="student"
                            v-model="v$.student.$model"
                            :suggestions="filteredStudents"
                            force-selection
                            :field="studentLblFunction"
                            placeholder="Ονοματεπώνυμο"
                            :delay="600"
                            :class="{ 'p-invalid': v$.student.$invalid && submitted }"
                            :disabled="editMode && !cloneMode"
                            @item-select="onStudentSelect"
                            @complete="searchStudents($event)"
                            @clear="clearSchool"
                        >
                        </AutoComplete>
                        <small v-if="(v$.student.$invalid && submitted) || v$.student.$pending.$response" class="p-error">{{ v$.student.required.$message }}</small>
                    </div>
                    <div class="field">
                        <label>Υπόλοιπο:</label>
                        <span class="dues-badge ml-2" :class="`status-${dueType}`">{{ studentDues }}</span>
                    </div>
                    <div class="field-checkbox">
                        <Checkbox id="ban" :model-value="state.student?.ban" :binary="true" :disabled="true" />
                        <label for="ban">Ban</label>
                    </div>
                    <div class="field">
                        <label for="mobile">Κινητό</label>
                        <InputText id="mobile" :value="state.student?.mobile" disabled autocomplete="off" spellcheck="off" />
                    </div>
                    <div v-if="state.student && state.student.comments" class="field">
                        <SyncfusionRTE v-model="state.student.comments" height="320" />
                    </div>
                    <div v-if="studentRequestComments" class="field">
                        <SyncfusionRTE v-model="studentRequestComments" height="320" />
                    </div>
                </div>

                <h5 v-if="isAdminOrOffice">Σχολή</h5>
                <div v-if="isAdminOrOffice">
                    <div class="field">
                        <label :class="{ 'p-error': v$.category.$invalid && submitted && !isDraft }" for="category">Κατηγορία*</label>
                        <AutoComplete
                            id="category"
                            v-model="v$.category.$model"
                            :suggestions="filteredCategories"
                            force-selection
                            field="name"
                            placeholder="Επιλέξτε κατηγορία"
                            :class="{ 'p-invalid': v$.category.$invalid && submitted && !isDraft }"
                            :dropdown="true"
                            :disabled="!isAdminOrOffice"
                            @complete="searchCategory($event)"
                        >
                        </AutoComplete>
                        <small v-if="(v$.category.$invalid && submitted && !isDraft) || v$.category.$pending.$response" class="p-error">{{ v$.category.required.$message }}</small>
                    </div>
                </div>
                <div v-else-if="isDraft">
                    <div class="box flex flex-column mt-2 mb-4 p-3">
                        <h5 for="category" style="align-self: center; width: 100%">Κείμενο</h5>
                        <span>{{ studentRequestComments }}</span>
                    </div>
                </div>
                <div v-if="isAdminOrOffice">
                    <div class="field">
                        <label :class="{ 'p-error': v$.subCateg1.$invalid && submitted && !isDraft }" for="subCateg1">Υποκατηγορία Ι*</label>
                        <AutoComplete
                            id="subCateg1"
                            v-model="v$.subCateg1.$model"
                            force-selection
                            :suggestions="filteredSubCategories1"
                            placeholder="Επιλέξτε Υποκατηγορία Ι"
                            :class="{ 'p-invalid': v$.subCateg1.$invalid && submitted && !isDraft }"
                            :dropdown="true"
                            :disabled="!isAdminOrOffice"
                            @complete="searchSubCategory1($event)"
                        >
                        </AutoComplete>
                        <small v-if="(v$.category.$invalid && submitted && !isDraft) || v$.category.$pending.$response" class="p-error">{{ v$.category.required.$message }}</small>
                    </div>
                </div>
                <div v-if="isAdminOrOffice">
                    <div class="field">
                        <label :class="{ 'p-error': v$.subCateg2.$invalid && submitted && !isDraft }" for="subCateg2">Υποκατηγορία ΙΙ*</label>
                        <AutoComplete
                            id="subCateg2"
                            v-model="v$.subCateg2.$model"
                            force-selection
                            :suggestions="filteredSubCategories2"
                            placeholder="Επιλέξτε Υποκατηγορία ΙΙ"
                            :class="{ 'p-invalid': v$.subCateg2.$invalid && submitted && !isDraft }"
                            :dropdown="true"
                            :disabled="!isAdminOrOffice"
                            @complete="searchSubCategory2($event)"
                        >
                        </AutoComplete>
                        <small v-if="(v$.category.$invalid && submitted && !isDraft) || v$.category.$pending.$response" class="p-error">{{ v$.category.required.$message }}</small>
                    </div>
                </div>

                <h5 v-if="isAdminOrOffice">Μάθημα</h5>
                <div v-if="isAdminOrOffice">
                    <div class="field">
                        <label :class="{ 'p-error': v$.course.$invalid && submitted && !isDraft }" for="title">Τίτλος*</label>
                        <AutoComplete
                            id="title"
                            v-model="v$.course.$model"
                            :suggestions="filteredCourses"
                            force-selection
                            :field="courseTitleLblFunction"
                            :class="{ 'p-invalid': v$.course.$invalid && submitted && !isDraft }"
                            placeholder="Επιλέξτε μάθημα"
                            :dropdown="true"
                            :disabled="!isAdminOrOffice"
                            @complete="searchCourses($event)"
                        >
                        </AutoComplete>
                        <small v-if="(v$.course.$invalid && submitted && !isDraft) || v$.course.$pending.$response" class="p-error">{{ v$.course.required.$message }}</small>
                    </div>
                </div>
                <div v-if="isAdminOrOffice">
                    <div class="field">
                        <label :class="{ 'p-error': v$.examPeriod.$invalid && submitted && !isDraft }" for="examPeriod">Εξεταστική*</label>
                        <AutoComplete
                            id="examPeriod"
                            v-model="v$.examPeriod.$model"
                            :suggestions="filteredExamPeriods"
                            :class="{ 'p-invalid': v$.examPeriod.$invalid && submitted && !isDraft }"
                            force-selection
                            field="label"
                            placeholder="Επιλέξτε εξεταστική"
                            :dropdown="true"
                            :disabled="!isAdminOrOffice"
                            @complete="searchExamPeriod($event)"
                        >
                        </AutoComplete>
                        <small v-if="(v$.examPeriod.$invalid && submitted && !isDraft) || v$.examPeriod.$pending.$response" class="p-error">{{ v$.examPeriod.required.$message }}</small>
                    </div>
                </div>
                <div v-if="isAdminOrOffice" class="flex flex-row justify-content-between align-items-center">
                    <div v-if="v$.course.$model != null && v$.examPeriod.$model != null">
                        <Button label="Αιτήσεις για το μάθημα" icon="pi pi-file" severity="warning" class="w-18rem" @click="onShowRequestsForCoursePeriod" />
                    </div>
                    <div v-if="v$.course.$model != null && v$.examPeriod.$model != null">
                        <Button label="Τμήματα για το μάθημα" icon="pi pi-sitemap" class="w-18rem" severity="help" @click="onShowClassesForCoursePeriod" />
                    </div>
                    <div v-if="state.status?.value === Utils.RequestStatus.Waiting && editMode">
                        <Button label="Ανάθεση σε καθηγητές" icon="pi pi-users" class="w-18rem" severity="info" @click="onShowJobPostingsDialog" />
                    </div>
                </div>

                <h5 v-if="isAdminOrOffice">Στοιχεία Αίτησης</h5>
                <div v-if="isAdminOrOffice" class="field">
                    <label>Δικαιώματα Αίτησης</label>
                    <AutoComplete
                        v-model="rights"
                        field="label"
                        force-selection
                        :suggestions="requestRightsSuggestions"
                        placeholder="Επιλέξτε δικαιώματα"
                        :dropdown="true"
                        :multiple="true"
                        dataKey="value"
                        disabled
                        @complete="searchRights($event)"
                        @change="onRightsChange"
                    >
                    </AutoComplete>
                </div>
                <div v-if="isAdminOrOffice">
                    <div class="field">
                        <label for="createTime">Ημερομηνία δημιουργίας</label>
                        <Calendar id="createTime" v-model="state.dateCreated" date-format="dd/mm/yy" placeholder="Επιλογή Ημ/νίας" :disabled="editMode" />
                    </div>
                </div>
                <div v-if="isAdminOrOffice">
                    <div class="field">
                        <label for="answerTime">Απάντηση μέχρι</label>
                        <Calendar id="answerTime" v-model="state.answerTime" :disabled="!isAdminOrOffice" date-format="dd/mm/yy" placeholder="Επιλογή Ημ/νίας" />
                    </div>
                </div>
                <div v-if="isAdminOrOffice">
                    <div class="field-checkbox">
                        <label for="isPrivate" class="ml-0">Ιδιαίτερο</label>
                        <Checkbox id="isPrivate" v-model="state.isPrivate" :binary="true" class="ml-2" />
                    </div>
                </div>
                <div v-if="isAdminOrOffice" class="field">
                    <label for="status" class="ml-0" :class="{ 'p-error': v$.status.$invalid && submitted }">Κατάσταση*</label>
                    <Dropdown
                        id="status"
                        v-model="v$.status.$model"
                        option-label="label"
                        :options="availableStatuses"
                        placeholder="Επιλογή"
                        :disabled="!editMode || cloneMode"
                        :class="{ 'p-invalid': v$.status.$invalid && submitted }"
                    >
                        <template #value="slotProps">
                            <span v-if="slotProps.value != null" :class="`status-badge status-${slotProps.value.value}`">{{ slotProps.value.label }}</span>
                            <span v-else>Επιλογή</span>
                        </template>
                        <template #option="slotProps">
                            <span class="status-badge" :class="`status-${slotProps.option.value}`">{{ slotProps.option.label }}</span>
                        </template>
                    </Dropdown>
                    <small v-if="(v$.status.$invalid && submitted) || v$.status.$pending.$response" class="p-error">{{ v$.status.required.$message }}</small>
                </div>
                <div v-if="isAdminOrOffice">
                    <div v-if="state.requestClassID != null" class="field">
                        <label for="requestClassID">Τμήμα Αίτησης:</label>
                        <a class="font-medium no-underline text-left pl-1 cursor-pointer" style="color: blue" @click="onShowClass(state.requestClassID)">
                            {{ state.requestClassTitle }}
                        </a>
                    </div>
                </div>
                <div v-if="isAdminOrOffice">
                    <div class="field">
                        <label :class="{ 'p-error': v$.offer.$invalid && submitted && !isDraft }" for="offer">Προσφορά*</label>
                        <InputNumber
                            id="offer"
                            v-model="v$.offer.$model"
                            mode="currency"
                            currency="EUR"
                            autocomplete="off"
                            spellcheck="off"
                            placeholder="σε Ευρώ"
                            locale="el-GR"
                            :class="{ 'p-invalid': v$.offer.$invalid && submitted && !isDraft }"
                        />
                        <small v-if="(v$.offer.$invalid && submitted && !isDraft) || v$.offer.$pending.$response" class="p-error">{{ v$.offer.required.$message }}</small>
                    </div>
                </div>
                <div v-if="isAdminOrOffice" class="field">
                    <label for="comments_request">Σχόλια Αίτησης (από γραμματεία)</label>
                    <Textarea id="comments_request" v-model="state.comments" autocomplete="off" spellcheck="off" rows="6" cols="30" />
                </div>
                <div v-if="requestLogs?.length > 0 && isAdminOrOffice" class="field">
                    <label>Ιστορικό</label>
                    <Timeline :value="requestLogs">
                        <template #opposite="slotProps">
                            <div class="flex flex-column">
                                <div>
                                    <a class="font-medium no-underline text-left cursor-pointer" style="color: blue" @click="showUser(slotProps.item.userID, slotProps.item.role)">{{
                                        slotProps.item.fullname
                                    }}</a>
                                </div>
                                <small class="p-text-secondary">{{ slotProps.item.date }}</small>
                            </div>
                        </template>
                        <template #content="slotProps">
                            <div class="flex flex-row align-content-between justify-content-start align-items-center gap-1 w-full">
                                Από
                                <span :class="`status-badge status-${slotProps.item.from_status}`">{{ Utils.RequestStatusToString(slotProps.item.from_status) }}</span> σε
                                <span :class="`status-badge status-${slotProps.item.to_status}`"> {{ Utils.RequestStatusToString(slotProps.item.to_status) }}</span>
                            </div>
                        </template>
                    </Timeline>
                </div>

                <div v-if="!isAdminOrOffice" class="flex flex-column" style="gap: 1.81rem">
                    <div v-if="!isDraft" class="flex flex-row w-full" style="gap: 1.81rem">
                        <div class="box w-6 flex flex-row" style="height: 12.125rem">
                            <div class="flex flex-column" style="padding-left: 1rem; padding-top: 1.6rem">
                                <h5>Σχολή</h5>
                                <div class="flex flex-row gap-8 pt-1">
                                    <div class="flex flex-column gap-3">
                                        <span style="font-weight: 800">Κατηγορία:</span>
                                        <span style="font-weight: 800">Υποκατηγορία Ι:</span>
                                        <span style="font-weight: 800">Υποκατηγορία ΙΙ:</span>
                                    </div>
                                    <div class="flex flex-column gap-3">
                                        <span>{{ state.category?.name }}</span>
                                        <span>{{ state.subCateg1 }}</span>
                                        <span>{{ state.subCateg2 }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="box flex-1" style="height: 12.125rem">
                            <div class="flex flex-column" style="padding-left: 1rem; padding-top: 1.6rem">
                                <h5>Μάθημα</h5>
                                <div class="flex flex-row gap-8 pt-1">
                                    <div class="flex flex-column gap-3">
                                        <span style="font-weight: 800">Τίτλος:</span>
                                        <span style="font-weight: 800">Εξεταστική:</span>
                                    </div>
                                    <div class="flex flex-column gap-3">
                                        <span v-if="state.course">{{ state.course?.title }}</span>
                                        <span v-if="state.examPeriod">{{ state.examPeriod?.label }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-row w-full">
                        <div class="box" style="width: 100%; height: 12.125rem">
                            <div class="flex flex-column" style="padding-left: 1rem; padding-top: 1.6rem">
                                <h5>Στοιχεία Αίτησης</h5>
                                <div class="flex flex-row gap-8 pt-1">
                                    <div class="flex flex-column gap-3">
                                        <span style="font-weight: 800">Ημερομηνια δημιουργιας:</span>
                                        <span style="font-weight: 800">Απαντηση μέχρι:</span>
                                    </div>
                                    <div class="flex flex-column gap-3">
                                        <span v-if="state.dateCreated">{{ moment(state.dateCreated).format('DD/MM/YYYY') }}</span>
                                        <span v-if="state.answerTime">{{ moment(state.answerTime).format('DD/MM/YYYY') }}</span>
                                    </div>
                                    <div class="flex flex-column gap-3">
                                        <span style="font-weight: 800">Τύπος:</span>
                                        <span style="font-weight: 800">Κατάσταση:</span>
                                    </div>
                                    <div class="flex flex-column gap-3">
                                        <span class="private-badge" :class="`private-${state.isPrivate}`">{{ Utils.Types[Utils.FromBoolToTinyInt(state.isPrivate)].label }}</span>
                                        <span :class="`status-badge status-${state.status?.value}`">{{ state.status?.label }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <template #footer>
            <Button v-if="isAdminOrOffice" label="Αποθήκευση" icon="pi pi-check" class="p-button-primary w-10rem" :loading="loading" @click="handleSubmit(!v$.$invalid)" />
            <Button v-if="canDeleteRequest" label="Διαγραφή" icon="pi pi-bin" class="p-button-danger w-10rem" :loading="loading" @click="deleteRequest" />
        </template>
    </Dialog>
</template>

<script setup>
import loaderCircle from '@/assets/lottie/loaderCircle.json';
import { useToast } from 'primevue/usetoast';
import { onMounted, reactive, ref, computed, watch } from 'vue';
import { required, integer, email } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import { useDebounceFn } from '@vueuse/core';
import useRPC from '@/composables/useRPC';
import Utils from '@/utils/Utils';
import moment from 'moment-timezone';
import { usePopupStore } from '@/stores/PopupStore';
import QuillEditor from '@/components/QuillEditor.vue';
import SyncfusionRTE from '@/components/SyncfusionRTE.vue';

const { RPCApi, RPCMethods } = useRPC();
const dialogShown = ref(false);
const submitted = ref(false);
const headerTitle = ref('');
const categories = ref([]);
const filteredStudents = ref([]);
const filteredCategories = ref([]);
const filteredCourses = ref([]);
const filteredExamPeriods = ref([]);
const filteredSubCategories1 = ref([]);
const filteredSubCategories2 = ref([]);
const fetchReqParams = ref({});
const dueType = ref('zero');
const classroomAreas = ref([]);
const loadingAreas = ref(true);
const dt = ref();
const selectedTeachers = ref([]);
const availableStatuses = ref([]);
const popupStore = usePopupStore();
const studentRequestComments = ref(null);
const tableFilters = ref({
    lname: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    mobile: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    school: { value: null, matchMode: FilterMatchMode.CONTAINS },
    ban: { value: null, matchMode: FilterMatchMode.EQUALS },
    checked: { value: null, matchMode: FilterMatchMode.EQUALS },
});
const closeCB = ref(null);
const totalTeachers = ref(0);
const loadingTeachers = ref(true);
const teachers = ref([]);
const rowsPerPage = ref(10);
const rights = ref([]);
const requestRightsSuggestions = ref([]);
const requestLogs = ref([]);

const rules = {
    student: { required },
    category: { required },
    subCateg1: { required },
    subCateg2: { required },
    course: { required },
    status: { required },
    offer: { required },
    examPeriod: { required },
};

const state = reactive({
    id: null,
    category: null,
    subCateg1: null,
    subCateg2: null,
    course: null,
    examPeriod: null,
    answerTime: null,
    dateCreated: null,
    isPrivate: null,
    status: null,
    offer: null,
    comments: null,
    accountable: null,
    student: null,
    requestClassID: null,
    requestClassTitle: null,
    jobPostingID: null,
});

const isAdmin = computed(() => {
    return Utils.UserInfo?.role === Utils.Roles.Admin;
});

const isOffice = computed(() => {
    return Utils.UserInfo?.role === Utils.Roles.Office;
});

const isStudent = computed(() => {
    return Utils.UserInfo?.role === Utils.Roles.Student;
});

const isTeacher = computed(() => {
    return Utils.UserInfo?.role === Utils.Roles.Teacher;
});

const isAdminOrOffice = computed(() => {
    return isAdmin.value || isOffice.value;
});

const canDeleteRequest = computed(() => {
    return state.status?.value === Utils.RequestStatus.Waiting && state.course?.id == null;
});

const v$ = useVuelidate(rules, state, { $stopPropagation: true });
const loading = ref(false);
const editMode = ref(false);
const toast = useToast();
const schools = ref([]);
const schoolsLoading = ref(false);
const cloneMode = ref(false);
const classID = ref(null);
const initialStatus = ref(null);
const courseId = ref(null);
const examPeriods = ref([]);
const isDraft = ref(false);

watch(state, (newState, oldState) => {
    if (newState.student == null) {
        dueType.value = 'zero';
        return;
    }
    dueType.value = newState.student.dues < 0 ? 'negative' : newState.student.dues > 0 ? 'positive' : 'zero';
});

const studentDues = computed(() => {
    if (state.student == null) {
        return Utils.toEUR(0);
    }
    return Utils.toEUR(state.student.dues);
});

const studentLblFunction = (student) => {
    if (student == null || student.email == null) return '';
    return `${student.name} (${student.email})`;
};

const courseTitleLblFunction = (course) => {
    if (course == null || course.title == null) return '';
    if (course.semester == null) return course.title;
    return course.title + ' (' + course.semester + ')';
};

const totalSelectedTeachers = computed(() => {
    return selectedTeachers.value.length;
});

const fetchStudentDues = async (studentId) => {
    const duesRes = await RPCApi(RPCMethods.User.FetchDuesForStudent, { userId: state.student.id });
    if (duesRes.status == 'ok') {
        const { dues } = duesRes.data.result.data;
        state.student.dues = dues;
    }
};

const show = async (data, onCloseCB) => {
    const { requestID, clone, studentID, clsID, courseID, canEdit } = data;
    rights.value = [];
    requestLogs.value = [];
    resetForm();
    closeCB.value = onCloseCB;
    courseId.value = courseID;
    initialStatus.value = Utils.RequestStatuses.find((s) => s.value == Utils.RequestStatus.Waiting);
    cloneMode.value = clone;
    loadingAreas.value = true;
    schoolsLoading.value = true;
    dialogShown.value = true;
    submitted.value = false;
    editMode.value = !!requestID;
    classID.value = clsID;
    headerTitle.value = cloneMode.value ? `Κλώνος Αίτησης (${requestID})` : editMode.value ? `Τροποποίηση Αίτησης (${requestID})` : 'Νέα Αίτηση';
    if (!isAdminOrOffice.value) {
        headerTitle.value = `Αίτηση (${requestID})`;
    }
    let schoolID = null;
    let areaID = null;
    if (studentID != null) {
        const studentRes = await RPCApi(RPCMethods.User.GetUserInfoById, { id: studentID });
        if (studentRes.status == 'ok') {
            const { fname, lname, email, mobile, school_id, ban, comments: studentComments } = studentRes.data.result.data.userInfo;
            state.student = { id: studentID, name: `${lname} ${fname}`, email: email, mobile: mobile, ban: ban === 1, comments: studentComments, school: school_id };
            schoolID = school_id;
            fetchStudentDues(studentID);
        }
    }

    let schoolsRes = await RPCApi(RPCMethods.Schools.Fetch);
    if (schoolsRes.status == 'ok') {
        schools.value = schoolsRes.data.result.data.schools;
        categories.value = schoolsRes.data.result.data.categories;
        if ((editMode.value || studentID) && schoolID && !courseID) {
            console.log('change categ:' + schoolID);
            let schoolInfo = schools.value.find((school) => school.id === schoolID);
            let categInfo = categories.value.find((categ) => categ.name === schoolInfo.category);
            state.category = { id: categInfo.id, name: categInfo.name };
            state.subCateg1 = schoolInfo.subCateg1;
            state.subCateg2 = schoolInfo.subCateg2;
        }
    }
    schoolsLoading.value = false;

    let examPeriodsRes = await RPCApi(RPCMethods.Periods.Fetch);
    if (examPeriodsRes.status === 'ok') {
        const { periods } = examPeriodsRes.data.result.data;
        let r = [];
        for (let period of periods) {
            if (period.active === 1)
                r.push({
                    label: `${period.title}`,
                    value: period.id,
                });
        }
        examPeriods.value = r;
    }

    if (requestID != null) {
        state.id = requestID;
        let reqRes = await RPCApi(RPCMethods.Request.GetRequestInfoById, { id: requestID });
        console.log(reqRes);
        if (reqRes.status == 'ok') {
            const {
                studentName,
                studentEmail,
                studentMobile,
                studentBan,
                studentComments,
                examPeriod,
                examPeriodId,
                answerTime,
                isPrivate,
                status,
                dateCreated,
                courseHours,
                coursePrice,
                offer,
                comments,
                courseHoursPrivate,
                coursePricePrivate,
                studentSchool,
                categId,
                categName,
                subCateg1,
                subCateg2,
                requestClassID,
                requestClassTitle,
                jobPostingID,
                rights: requestRights,
            } = reqRes.data.result.data;
            const courseId = reqRes.data.result.data.courseId;
            const preferredClassroomArea = reqRes.data.result.data.preferredClassroomArea;
            const courseTitle = reqRes.data.result.data.courseTitle;
            const courseSemester = reqRes.data.result.data.courseSemester;
            const schoolId = reqRes.data.result.data.schoolId;
            const studentId = reqRes.data.result.data.studentId;
            studentRequestComments.value = reqRes.data.result.data.studentRequestComments;
            state.student = { id: studentId, name: studentName, email: studentEmail, mobile: studentMobile, ban: studentBan === 1, comments: studentComments, school: studentSchool };
            state.category = categId ? { id: categId, name: categName } : null;
            state.subCateg1 = subCateg1;
            state.subCateg2 = subCateg2;
            state.course = {
                id: courseId,
                title: courseTitle,
                semester: courseSemester,
                hours: courseHours,
                price: coursePrice,
                hoursPrivate: courseHoursPrivate,
                pricePrivate: coursePricePrivate,
            };
            state.examPeriod = examPeriods.value.find((p) => p.value === examPeriodId);
            state.dateCreated = cloneMode.value ? new Date() : Utils.datetimeToDate(dateCreated);
            state.answerTime = cloneMode.value ? moment(state.dateCreated).add(3, 'days').toDate() : Utils.datetimeToDate(answerTime);
            state.isPrivate = isPrivate === 1 ? true : false;
            state.status = cloneMode.value ? Utils.RequestStatuses.find((s) => s.value == 0) : Utils.RequestStatuses.find((s) => s.value == status);
            state.offer = offer;
            state.comments = comments;
            state.requestClassID = requestClassID;
            state.requestClassTitle = requestClassTitle;
            state.jobPostingID = jobPostingID;
            schoolID = schoolId;
            areaID = preferredClassroomArea;
            initialStatus.value = state.status;

            isDraft.value = courseId == null;

            if (requestClassID != null) {
                classID.value = requestClassID;
            }

            // if (isAdmin.value) {
            availableStatuses.value = [...Utils.RequestStatuses];
            // } else {
            //     switch (status) {
            //         case Utils.RequestStatus.Waiting:
            //             availableStatuses.value = Utils.RequestStatuses.filter(
            //                 (s) => s.value === Utils.RequestStatus.Posted || s.value === Utils.RequestStatus.Canceled || s.value === Utils.RequestStatus.NotServed
            //             );
            //             break;
            //         case Utils.RequestStatus.Posted:
            //             availableStatuses.value = Utils.RequestStatuses.filter((s) => s.value === Utils.RequestStatus.PreRegistered || s.value === Utils.RequestStatus.Canceled);
            //             break;
            //         case Utils.RequestStatus.PreRegistered:
            //             availableStatuses.value = Utils.RequestStatuses.filter((s) => s.value === Utils.RequestStatus.OnClass || s.value === Utils.RequestStatus.Stopped);
            //             break;
            //         case Utils.RequestStatus.OnClass:
            //             availableStatuses.value = Utils.RequestStatuses.filter((s) => s.value === Utils.RequestStatus.Completed || s.value === Utils.RequestStatus.Stopped);
            //             break;
            //         case Utils.RequestStatus.Stopped:
            //             availableStatuses.value = Utils.RequestStatuses.filter((s) => s.value === Utils.RequestStatus.OnClass);
            //             break;
            //         default:
            //             availableStatuses.value = [];
            //             break;
            //     }
            // }

            rights.value = Utils.RequestRightsTypes.filter((x) => {
                return (x.value & requestRights) === x.value;
            });
            onRightsChange();

            fetchStudentDues(studentId);
        }

        if (isAdminOrOffice.value) {
            let reqLogs = await RPCApi(RPCMethods.Request.FetchLogsForRequest, { id: requestID });
            console.warn('reqLogs', reqLogs);
            if (reqLogs.status == 'ok') {
                const logs = reqLogs.data.result.data;
                let logsArr = [];
                for (let log of logs) {
                    logsArr.push({ ...log, date: moment(Utils.datetimeToDate(log.date)).format('DD/MM/YYYY HH:mm') });
                }
                requestLogs.value = logsArr;
            }
        }
    } else {
        state.dateCreated = new Date();
        state.answerTime = moment(state.dateCreated).add(3, 'days').toDate();
        state.status = initialStatus.value;
        if (courseID) {
            console.log('get info for course: ' + courseID);
            let courseInfoRes = await RPCApi(RPCMethods.Courses.FetchCourseInfoById, { id: courseID });
            if (courseInfoRes.status == 'ok') {
                let { courseInfo } = courseInfoRes.data.result.data;
                let categ = categories.value.find((c) => c.id === courseInfo.categoryID);
                if (categ) state.category = { id: categ.id, name: categ.name };
                state.subCateg1 = courseInfo.subCateg1;
                state.subCateg2 = courseInfo.subCateg2;
                state.course = {
                    id: courseID,
                    title: courseInfo.title,
                    semester: courseInfo.semester,
                    hours: courseInfo.hours,
                    price: courseInfo.price,
                    hoursPrivate: courseInfo.hours_private,
                    pricePrivate: courseInfo.price_private,
                };
            }
        }
    }

    await fetchTeachers();

    let areasRes = await RPCApi(RPCMethods.Areas.Fetch);
    if (areasRes.status == 'ok') {
        console.log(areasRes.data.result.data);
        let areasArr = [];
        for (let s of areasRes.data.result.data.areas) {
            areasArr.push({ label: s.area, value: s.id });
        }
        classroomAreas.value = areasArr;
        state.area = areaID ? classroomAreas.value.find((area) => area.value === areaID) : null;
    }
    loadingAreas.value = false;
};

const onHide = (action = false) => {
    dialogShown.value = false;
    resetForm();
    closeCB.value(action);
};

const priceFormatted = computed(() => {
    if (state.course == null || state.course.price == null) return Utils.toEUR(0);
    return Utils.toEUR(state.course.price);
});

const pricePrivateFormatted = computed(() => {
    if (state.course == null || state.course.pricePrivate == null) return Utils.toEUR(0);
    return Utils.toEUR(state.course.pricePrivate);
});

const handleSubmit = async (isFormValid) => {
    console.log('isFormValid??' + isFormValid);
    submitted.value = true;

    if (!isFormValid && !isDraft.value) return;

    console.log('classID.value:' + classID.value);
    console.log('initialStatus.value:' + initialStatus.value.value);
    console.log('state.status.value:' + state.status.value);

    if (classID.value == null) {
        if (
            initialStatus.value.value === Utils.RequestStatus.Waiting &&
            (state.status.value === Utils.RequestStatus.Posted || state.status.value === Utils.RequestStatus.PreRegistered || state.status.value === Utils.RequestStatus.OnClass)
        ) {
            console.log('ONLY INSIDE A CLASS YOU CAN CHANGE THE REQUEST STATUS!!!');
            toast.add({ severity: 'warn', summary: 'Προσοχή!', detail: 'H κατάσταση της αίτησης πρέπει να αλλάξει μέσα από τμήμα!', life: 3000 });
            return;
        }
    }

    if (initialStatus.value.value === Utils.RequestStatus.Waiting && (state.status.value === Utils.RequestStatus.OnClass || state.status.value === Utils.RequestStatus.PreRegistered)) {
        toast.add({ severity: 'warn', summary: 'Προσοχή!', detail: `H Αίτηση πρέπει να προχωρήσει σε 'Ενημερώθηκε'!`, life: 3000 });
        return;
    }

    console.log('ok submit');

    loading.value = true;
    let schoolID = null;
    if (state.category != null) {
        let school = schools.value.find((school) => school.category === state.category.name && school.subCateg1 === state.subCateg1 && school.subCateg2 === state.subCateg2);
        if (school) schoolID = school.id;
    }

    console.log('state.examPeriod:::', state.examPeriod);

    let res;
    let rightsSum = 0;
    let payload = {
        userId: state.student.id,
        answerTime: state.answerTime,
        dateCreated: state.dateCreated,
        preferredClassroomArea: state.area != null ? state.area.value : 3,
        isPrivate: state.isPrivate,
        status: state.status.value,
        accountable: state.accountable?.id,
        comments: state.comments,
        courseId: state.course?.id,
        examPeriod: state.examPeriod?.value,
        offer: state.offer,
        creatorId: Utils.UserInfo.id,
        rights: rights.value
            ? rights.value.reduce((sum, cur) => {
                  rightsSum += cur.value;
                  return rightsSum;
              }, 0)
            : 0,
    };

    console.log(payload);
    if (cloneMode.value || !editMode.value) {
        res = await RPCApi(RPCMethods.Request.Add, payload);
    } else {
        payload.id = state.id;
        if (classID.value != null && payload.status > Utils.RequestStatus.Waiting) payload.classId = classID.value;
        res = await RPCApi(RPCMethods.Request.Update, payload);
    }

    if (res.status !== 'ok') {
        if (res.status === 'user_has_already_request_in_class') {
            toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Ο χρήστης έχει ήδη αίτηση σε αυτό το τμήμα!', life: 3000 });
        } else {
            toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Παρακαλώ προσπαθήστε αργότερα', life: 3000 });
        }

        loading.value = false;
        return;
    }
    toast.add({ severity: 'success', summary: 'Η διαδικασία ολοκληρώθηκε', detail: 'Επιτυχής διαδικασία!', life: 3000 });

    loading.value = false;
    onHide(true);
};

const deleteRequest = async () => {
    console.log('deleteRequest');
    loading.value = true;
    let res = await RPCApi(RPCMethods.Request.Delete, { id: state.id });
    if (res.status !== 'ok') {
        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Παρακαλώ προσπαθήστε αργότερα', life: 3000 });
        loading.value = false;
        return;
    }
    toast.add({ severity: 'success', summary: 'Η διαδικασία ολοκληρώθηκε', detail: 'Επιτυχής διαδικασία!', life: 3000 });
    loading.value = false;
    onHide(true);
};

const resetForm = () => {
    for (let field in state) state[field] = null;

    submitted.value = false;
    loading.value = false;
};

const onStudentSelect = async (student) => {
    console.log('onStudentSelect', student);
    let school = schools.value.find((c) => c.id == student.value.school);
    if (school && courseId.value == null) {
        state.category = categories.value.find((c) => c.name == school.category);
        state.subCateg1 = school.subCateg1;
        state.subCateg2 = school.subCateg2;
    }

    await fetchStudentDues(student.id);
};

const clearSchool = () => {
    if (courseId.value != null) return;
    state.category = null;
    state.subCateg1 = null;
    state.subCateg2 = null;
};

const searchStudents = useDebounceFn(async (event) => {
    if (event.query.trim().length === 0) {
        filteredStudents.value = [];
        return;
    }

    console.log('searchStudents: ' + event.query);
    let res = await RPCApi(RPCMethods.User.FetchStudentsByName, { name: event.query.trim() });
    if (res.status !== 'ok') {
        filteredStudents.value = [];
        return;
    }

    const { students } = res.data.result.data;
    let r = [];
    for (let student of students) {
        r.push({
            id: student.id,
            name: student.name,
            email: student.email,
            mobile: student.mobile,
            ban: student.ban === 1,
            comments: student.comments,
            school: student.school_id,
            dues: 0,
        });
    }

    filteredStudents.value = r;

    console.log(filteredStudents.value);
}, 300);

const searchCategory = async (event) => {
    console.log('searchCategory: ' + event.query);

    if (event.query == null || event.query.trim().length === 0) {
        filteredCategories.value = [...categories.value];
    } else {
        filteredCategories.value = categories.value.filter((d) => {
            return d.name.toLowerCase().startsWith(event.query.toLowerCase());
        });
    }
};

const searchExamPeriod = async (event) => {
    console.log('searchExamPeriod: ' + event.query);
    if (state.category == null) {
        filteredExamPeriods.value = [];
        return;
    }

    let res = await RPCApi(RPCMethods.Periods.FetchCategoryPeriodsByName, { categoryID: state.category.id, name: event.query.trim() });
    if (res.status !== 'ok') {
        filteredExamPeriods.value = [];
        return;
    }

    filteredExamPeriods.value = res.data.result.data.periods;

    let temp = [];
    for (let obj of res.data.result.data.periods) {
        temp.push({
            value: obj.id,
            label: obj.title,
        });
    }

    filteredExamPeriods.value = temp;
};

const searchCourses = useDebounceFn(async (event) => {
    console.log('searchCourses: ' + event.query);
    if (state.category == null || state.subCateg1 == null || state.subCateg2 == null) {
        filteredCourses.value = [];
        return;
    }

    let school = schools.value.find((s) => s.category === state.category.name && s.subCateg1 === state.subCateg1 && s.subCateg2 === state.subCateg2);
    if (school == null) {
        filteredCourses.value = [];
        return;
    }
    let res = await RPCApi(RPCMethods.Courses.FetchCoursesForSchool, { title: event.query.trim(), schoolID: school.id });
    if (res.status !== 'ok') {
        filteredCourses.value = [];
        return;
    }

    filteredCourses.value = res.data.result.data?.filter((d) => {
        if (d.active === 1) return d;
    });
}, 300);

const searchSubCategory1 = async (event) => {
    console.log('searchSubCategory1: ' + event.query);
    console.log(state.category);
    if (state.category == null) {
        filteredSubCategories1.value = [];
        return;
    }

    filteredSubCategories1.value = schools.value.filter((d) => {
        return d.category === state.category.name;
    });

    if (!event.query.trim().length) {
        filteredSubCategories1.value = [...filteredSubCategories1.value];
    } else {
        filteredSubCategories1.value = filteredSubCategories1.value.filter((d) => {
            return d.subCateg1.toLowerCase().startsWith(event.query.toLowerCase());
        });
    }

    filteredSubCategories1.value = [...new Set(filteredSubCategories1.value.map((d) => d.subCateg1))];
};

const searchSubCategory2 = async (event) => {
    console.log('searchSubCategory2: ' + event.query);

    if (state.subCateg1 == null) {
        filteredSubCategories2.value = [];
        return;
    }

    filteredSubCategories2.value = schools.value.filter((d) => {
        return d.subCateg1 === state.subCateg1;
    });

    if (!event.query.trim().length) {
        filteredSubCategories2.value = [...filteredSubCategories2.value];
    } else {
        filteredSubCategories2.value = filteredSubCategories2.value.filter((d) => {
            return d.subCateg2.toLowerCase().startsWith(event.query.toLowerCase());
        });
    }

    filteredSubCategories2.value = [...new Set(filteredSubCategories2.value.map((d) => d.subCateg2))];
};

const fetchTeachers = async () => {
    loadingTeachers.value = true;
    RPCApi(RPCMethods.User.FetchTeachers).then((res) => {
        console.log(res);
        let d = [];
        if (res.status == 'ok') {
            const teachersData = res.data.result.data;
            totalTeachers.value = teachersData.length;

            for (let obj of teachersData) {
                d.push({
                    id: obj.id,
                    fullname: obj.lname + ' ' + obj.fname,
                    lname: obj.lname,
                    email: obj.email,
                    mobile: obj.mobile,
                    school: obj.school,
                    ban: obj.ban === 1,
                });
            }
            teachers.value = d;
        } else {
            toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Προσπαθήστε αργότερα!', life: 3000 });
        }
        loadingTeachers.value = false;
    });
};

const onShowRequestsForCoursePeriod = () => {
    console.log('open dialog for requests: ');
    popupStore.show(Utils.Popups.CoursePeriodRequestsDialog, {
        courseID: state.course.id,
        courseTitle: state.course.title,
        examPeriodTitle: state.examPeriod.label,
        examPeriodID: state.examPeriod.value,
    });
};

const onShowClassesForCoursePeriod = () => {
    console.log('open dialog for classes: ');
    popupStore.show(Utils.Popups.CoursePeriodClassesDialog, {
        courseID: state.course.id,
        courseTitle: state.course.title,
        examPeriodTitle: state.examPeriod.label,
        examPeriodID: state.examPeriod.value,
    });
};

const onShowClass = (classID) => {
    popupStore.show(
        Utils.Popups.ClassDialog,
        {
            classID: classID,
        },
        (res) => {
            console.log(res);
        }
    );
};

const onShowJobPostingsDialog = () => {
    console.warn('jobPostingID: ' + state.jobPostingID);
    if (state.jobPostingID != null) {
        popupStore.show(
            Utils.Popups.JobPostingsEditDialog,
            {
                id: state.jobPostingID,
            },
            (res) => {
                console.log(res);
            }
        );
    } else {
        popupStore.show(
            Utils.Popups.JobPostingsCreateDialog,
            {
                ...state,
            },
            (res) => {
                console.log(res);
            }
        );
    }
};

const searchRights = async (event) => {
    requestRightsSuggestions.value = [...Utils.RequestRightsTypes];
};

const onRightsChange = (event) => {
    rights.value = [...new Map(rights.value.map((item) => [item['value'], item])).values()];
    let allIncluded = rights.value.find((d) => d.value == Utils.RequestRights.All);
    if (allIncluded) rights.value = [Utils.RequestRightsTypes.find((x) => Utils.RequestRights.All == x.value)];
};
const showUser = (id, role) => {
    if (role == Utils.Roles.Office) {
        popupStore.show(Utils.Popups.OfficeDialog, { userID: id }, () => {});
        return;
    }
    popupStore.show(Utils.Popups.AdminDialog, { userID: id }, () => {});
};

defineExpose({
    show,
});
</script>

<style lang="scss" scoped>
.box {
    border-radius: 0.75rem;
    border: 2px solid #e3e8ef;
    background: #fff;
}
</style>
