<template>
    <Dialog
        v-model:visible="dialogShown"
        class="p-fluid"
        :close-on-escape="false"
        :dismissable-mask="false"
        :modal="true"
        :breakpoints="{ '960px': '75vw', '640px': '95vw' }"
        :style="{ width: '90vw' }"
    >
        <template #header>
            <h4>{{ headerTitle }}</h4>
        </template>

        <DataTable
            ref="dt"
            v-model:filters="tableFilters"
            :lazy="true"
            :total-records="totalClasses"
            :loading="loading"
            :row-hover="true"
            :value="classes"
            filter-display="row"
            data-key="id"
            responsive-layout="scroll"
            :rows="rowsPerPage"
            :paginator="true"
            @page="onPage($event)"
            @sort="onSort($event)"
            @filter="onFilter($event)"
        >
            <template #header>Σύνολο: {{ totalClasses }}</template>
            <template #empty> Δεν βρέθηκαν τμήματα. </template>
            <Column header="ID" field="classID" style="min-width: 2rem">
                <template #body="{ data }">
                    <a class="font-medium no-underline text-left cursor-pointer" style="color: blue" @click="showClassDialog(data.classID)">{{ data.classID }}</a>
                </template>
            </Column>
            <Column header="Τίτλος" field="classTitle" filter-field="classTitle" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                <template #body="{ data }">
                    {{ data.classTitle }}
                </template>
            </Column>
            <Column field="examPeriod" filter-field="examPeriod" header="Εξεταστική" body-class="text-left" style="min-width: 10rem" :show-filter-menu="false">
                <template #body="{ data }">
                    <span>{{ data.examsPeriodTitle }}</span>
                </template>
            </Column>
            <Column field="isPrivate" filter-field="isPrivate" header="Τύπος" data-type="boolean" body-class="text-left" style="min-width: 8rem">
                <template #body="{ data }">
                    <span class="private-badge" :class="`private-${data.isPrivate}`">{{ Utils.Types[Utils.FromBoolToTinyInt(data.isPrivate)].label }}</span>
                </template>
            </Column>
            <Column field="status" filter-field="status" header="Κατάσταση" body-class="text-left" style="min-width: 10rem" :show-filter-menu="false">
                <template #body="{ data }">
                    <span class="cls-status-badge" :class="`cls-status-${data.status}`">{{ Utils.ClassStatusToString(data.status) }}</span>
                </template>
            </Column>
            <Column header="Καθηγητής" field="teacher" filter-field="teacher" sort-field="teacherName" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                <template #body="{ data }">
                    <a class="font-medium no-underline text-left cursor-pointer" style="color: blue" @click="showTeacherDialog(data.teacher.id)">{{ data.teacher.name }}</a>
                </template>
            </Column>
        </DataTable>

        <template #footer> </template>
    </Dialog>
</template>

<script setup>
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import { ref, onMounted, onUnmounted, computed } from 'vue';
import useRPC from '@/composables/useRPC';
import Utils from '@/utils/Utils';
import { useToast } from 'primevue/usetoast';
import { useDebounceFn } from '@vueuse/core';
import { usePopupStore } from '@/stores/PopupStore.js';

const { RPCApi, RPCMethods } = useRPC();
const dt = ref();
const loading = ref(true);
const classes = ref([]);
const totalClasses = ref(0);
const rowsPerPage = ref(10);
const fetchParams = ref({});
const toast = useToast();
const examPeriods = ref([]);
const teachers = ref([]);
const filteredCategories = ref([]);
const filteredSubCategories1 = ref([]);
const filteredSubCategories2 = ref([]);
const filteredCourses = ref([]);
const categories = ref([]);
const schools = ref([]);
const closeCB = ref(null);
const popupStore = usePopupStore();
const dialogShown = ref(false);
const headerTitle = ref('');

const tableFilters = ref({
    examPeriod: { value: null, matchMode: FilterMatchMode.EQUALS },
    courseTitle: { value: null, matchMode: FilterMatchMode.EQUALS },
});

const onPage = useDebounceFn((event) => {
    console.log('onPage', event);
    fetchParams.value.paging = { offset: event.first, limit: rowsPerPage };
    fetch();
}, 300);

const onSort = useDebounceFn((event) => {
    console.log('onSort', event);
    if (event.sortField === 'fullname') event.sortField = 'lname';
    fetchParams.value.paging = { offset: 0, limit: rowsPerPage };
    fetchParams.value.sort = { sortField: event.sortField, sortOrder: event.sortOrder };
    fetch();
}, 300);

const show = async (data, onCloseCB) => {
    fetchParams.value = {
        paging: { offset: 0, limit: rowsPerPage },
        sort: null,
        filters: tableFilters.value,
    };
    let { courseID, courseTitle, examPeriodTitle } = data;
    closeCB.value = onCloseCB;
    dialogShown.value = true;

    headerTitle.value = `Τμήματα για μάθημα: "${courseTitle}" - ${examPeriodTitle}`;

    fetchParams.value.paging = { offset: 0, limit: rowsPerPage };
    fetchParams.value.filters = {
        examsPeriodTitle: { value: examPeriodTitle, matchMode: FilterMatchMode.EQUALS },
        courseID: { value: courseID, matchMode: FilterMatchMode.EQUALS },
    };
    fetch();
};

const fetch = async () => {
    loading.value = true;
    RPCApi(RPCMethods.Class.Fetch, { ...fetchParams.value, forceAllMatch: true }).then((res) => {
        console.log(res);
        let d = [];
        if (res.status == 'ok') {
            const { total, classes: classesData } = res.data.result.data;
            totalClasses.value = total;

            for (let obj of classesData) {
                d.push({
                    classID: obj.classID,
                    classTitle: obj.classTitle,
                    examsPeriodTitle: obj.examsPeriodTitle,
                    teacher: { id: obj.teacherID, name: obj.teacherName },
                    isPrivate: obj.isPrivate === 1 ? true : false,
                    status: obj.status,
                });
            }
            classes.value = d;
        } else {
            toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Προσπαθήστε αργότερα!', life: 3000 });
        }
        loading.value = false;
    });
};

const showTeacherDialog = (teacherID) => {
    console.log('showTeacher:' + teacherID);
    popupStore.show(Utils.Popups.TeacherDialog, { teacherID }, (action) => {});
};

const onHide = (action = false) => {
    dialogShown.value = false;
    closeCB.value(action);
};

const showClassDialog = (classID) => {
    popupStore.show(Utils.Popups.ClassDialog, { classID }, (action) => {
        console.log('onDialogClosed:' + action);
        if (action) fetch();
    });
};

defineExpose({
    show,
});
</script>
