<template>
    <Dialog v-model:visible="dialogShown" :close-on-escape="false" :dismissable-mask="false" :modal="true" :style="{ width: isAdminOrOffice ? '90vw' : '60vw' }">
        <template #header>
            <div class="flex align-items-center">
                <Avatar :image="Utils.GetGenericAvatar(state.id)" class="flex align-items-center border-1 border-300 mt-0" size="medium" shape="circle" />
                <h4 class="flex align-items-center ml-2 mt-3">{{ headerTitle }}</h4>
            </div>
        </template>

        <TabMenu v-if="showTab" :model="tabItems" class="mb-4 fixed-tab-menu" />
        <div v-if="tabIndex === 0" class="formgrid grid p-fluid p-3">
            <div class="flex flex-column w-full">
                <div class="field">
                    <label>Τελευταίο Login:</label>
                    <span style="padding-left: 10px">{{ lastLogin || '--' }}</span>
                </div>

                <div class="box w-full h-full flex flex-row" style="height: 12.125rem">
                    <div class="flex flex-column" style="padding-left: 1rem; padding-right: 1rem; padding-top: 1rem">
                        <h5>Υποχρεωτικά πεδία</h5>
                        <div class="flex flex-row gap-3 flex-wrap w-full justify-content-between items-center mb-4">
                            <div class="flex flex-column gap-1">
                                <label for="fname" :class="{ 'p-error': v$.fname.$invalid && submitted }">Όνομα*</label>
                                <InputText
                                    id="fname"
                                    v-model="v$.fname.$model"
                                    :disabled="!isAdminOrOffice"
                                    autocomplete="off"
                                    spellcheck="off"
                                    :class="{ 'p-invalid': v$.fname.$invalid && submitted }"
                                    placeholder="Όνομα"
                                    style="width: 260px"
                                />
                                <small v-if="(v$.fname.$invalid && submitted) || v$.fname.$pending.$response" class="p-error">{{ v$.fname.required.$message }}</small>
                            </div>

                            <div class="flex flex-column gap-1 ml-1">
                                <label for="lname" :class="{ 'p-error': v$.lname.$invalid && submitted }">Επώνυμο*</label>
                                <InputText
                                    id="lname"
                                    v-model="v$.lname.$model"
                                    :disabled="!isAdminOrOffice"
                                    autocomplete="off"
                                    spellcheck="off"
                                    :class="{ 'p-invalid': v$.lname.$invalid && submitted }"
                                    placeholder="Επώνυμο"
                                    style="width: 260px"
                                />
                                <small v-if="(v$.lname.$invalid && submitted) || v$.lname.$pending.$response" class="p-error">{{ v$.lname.required.$message }}</small>
                            </div>

                            <div class="flex flex-column gap-1">
                                <label for="mobile" :class="{ 'p-error': v$.mobile.$invalid && submitted }">Κινητό*</label>
                                <InputText
                                    id="mobile"
                                    v-model="v$.mobile.$model"
                                    :disabled="!isAdminOrOffice"
                                    autocomplete="off"
                                    spellcheck="off"
                                    :class="{ 'p-invalid': v$.mobile.$invalid && submitted }"
                                    placeholder="Κινητό"
                                    style="width: 260px"
                                />
                                <small v-if="(v$.mobile.$invalid && submitted) || v$.mobile.$pending.$response" class="p-error">{{ v$.mobile.required.$message }}</small>
                            </div>

                            <div class="flex flex-column gap-1">
                                <label for="email" :class="{ 'p-error': v$.email.$invalid && submitted }">Email*</label>
                                <InputText
                                    id="email"
                                    v-model="v$.email.$model"
                                    :disabled="!isAdminOrOffice"
                                    autocomplete="off"
                                    spellcheck="off"
                                    :class="{ 'p-invalid': v$.email.$invalid && submitted }"
                                    placeholder="Email"
                                    style="width: 260px"
                                />
                                <small v-if="(v$.email.$invalid && submitted) || v$.email.$pending.$response" class="p-error">{{ v$.email.required.$message }}</small>
                            </div>

                            <div class="flex flex-column gap-1">
                                <label for="email2">Email 2</label>
                                <InputText id="email2" v-model="state.email2" :disabled="!isAdminOrOffice" autocomplete="off" spellcheck="off" placeholder="Email" style="width: 260px" />
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="isAdminOrOffice" class="box w-full h-full flex flex-column p-3" style="height: 12.125rem; margin-top: 1rem">
                    <h5>Control Access</h5>
                    <div class="flex flex-row w-full align-items-center justify-flex-start gap-8">
                        <div v-if="!isStudent" class="flex flex-row">
                            <label for="active" class="mr-2">Ενεργός</label>
                            <Checkbox id="active" v-model="state.active" :disabled="!isAdminOrOffice" :binary="true" @change="onActiveChanged" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="box w-full h-full flex flex-row" style="height: 12.125rem; margin-top: 1rem">
                <div class="flex flex-column" style="padding-left: 1rem; padding-top: 1rem; padding-right: 1rem">
                    <h5>Προαιρετικά πεδία</h5>
                    <div class="flex flex-row gap-4 flex-wrap mb-4 justify-content-between items-center">
                        <div class="flex flex-column gap-1">
                            <label for="phone">Σταθερό</label>
                            <InputText id="phone" v-model="state.phone" autocomplete="off" spellcheck="off" placeholder="Σταθερό" style="width: 200px" />
                        </div>
                        <div class="flex flex-column gap-1">
                            <label for="afm">ΑΦΜ</label>
                            <InputText id="afm" v-model="state.afm" autocomplete="off" spellcheck="off" placeholder="ΑΦΜ" style="width: 200px" />
                        </div>
                        <div class="flex flex-column gap-1">
                            <label for="birthday">Ημ/νία γέννησης</label>
                            <Calendar id="birthday" v-model="state.birthDate" date-format="dd/mm/yy" placeholder="dd/mm/yyyy" style="width: 200px" />
                        </div>
                        <div class="flex flex-column gap-1">
                            <label for="city">Πόλη</label>
                            <InputText id="city" v-model="state.city" autocomplete="off" spellcheck="off" placeholder="Πόλη" style="width: 200px" />
                        </div>
                        <div class="flex flex-column gap-1">
                            <label for="address">Διεύθυνση</label>
                            <InputText id="address" v-model="state.address" autocomplete="off" spellcheck="off" placeholder="Διεύθυνση" style="width: 260px" />
                        </div>
                        <div class="flex flex-column gap-1">
                            <label for="zipcode">Τ.Κ.</label>
                            <InputText id="zipcode" v-model="state.zipcode" autocomplete="off" spellcheck="off" placeholder="Τ.Κ." style="width: 200px" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="box w-full h-full flex flex-row" style="height: 12.125rem; margin-top: 1rem">
                <div class="flex flex-column w-full" style="padding-left: 1rem; padding-top: 1rem; padding-right: 1rem">
                    <h5>Εκπαιδευτικη κατηγορια</h5>
                    <div v-if="isAdminOrOffice" class="flex flex-row gap-4 flex-wrap mb-4 justify-content-between items-center w-full">
                        <div class="flex flex-column gap-1">
                            <label for="category">Κατηγορία</label>
                            <AutoComplete
                                id="category"
                                v-model="state.category"
                                :suggestions="filteredCategories"
                                force-selection
                                field="name"
                                placeholder="Επιλέξτε κατηγορία"
                                class="w-30rem"
                                :dropdown="true"
                                :disabled="!isAdminOrOffice"
                                @complete="searchCategory($event)"
                            >
                            </AutoComplete>
                        </div>
                        <div class="flex flex-column gap-1">
                            <label for="subCateg1">Υποκατηγορία Ι</label>
                            <AutoComplete
                                id="subCateg1"
                                v-model="state.subCateg1"
                                force-selection
                                :suggestions="filteredSubCategories1"
                                placeholder="Επιλέξτε Υποκατηγορία Ι"
                                class="w-30rem"
                                :dropdown="true"
                                :disabled="!isAdminOrOffice"
                                @complete="searchSubCategory1($event)"
                            >
                            </AutoComplete>
                        </div>
                        <div class="flex flex-column gap-1">
                            <label for="subCateg2">Υποκατηγορία ΙΙ</label>
                            <AutoComplete
                                id="subCateg2"
                                v-model="state.subCateg2"
                                force-selection
                                :suggestions="filteredSubCategories2"
                                placeholder="Επιλέξτε Υποκατηγορία ΙΙ"
                                class="w-30rem"
                                :dropdown="true"
                                :disabled="!isAdminOrOffice"
                                @complete="searchSubCategory2($event)"
                            >
                            </AutoComplete>
                        </div>
                    </div>
                    <div v-else class="flex flex-row gap-4 mb-4 w-full">
                        <div class="flex flex-column gap-2">
                            <label style="font-weight: 800">Κατηγορία</label>
                            <label style="font-weight: 800">Υποκατηγορία Ι</label>
                            <label style="font-weight: 800">Υποκατηγορία ΙΙ</label>
                        </div>
                        <div class="flex flex-column gap-2">
                            <span>{{ state.category.name }}</span>
                            <span>{{ state.subCateg1 }}</span>
                            <span>{{ state.subCateg2 }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="tabIndex === 1">
            <div class="field">
                <label>Σχόλια</label>
                <SyncfusionRTE v-model="state.comments" height="520" />
            </div>
            <div v-if="isAdmin" class="field">
                <label>Σχόλια admin</label>
                <SyncfusionRTE v-model="state.adminComments" height="300" />
            </div>
        </div>
        <!-- <div v-if="tabIndex === 2">
            <DataTable
                ref="rdt"
                v-model:filters="reqTableFilters"
                :lazy="true"
                :total-records="totalRequests"
                :loading="loadingRequests"
                :row-hover="true"
                :value="requests"
                data-key="id"
                responsive-layout="scroll"
                :rows="rowsPerPage"
                :paginator="true"
                @page="onReqPage($event)"
                @sort="onReqSort($event)"
            >
                <template #header>
                    <div class="flex justify-content-between flex-column sm:flex-row">
                        <Button icon="pi pi-plus" label="Νέα Αίτηση" class="mr-2 mb-2" @click="addRequest" />
                    </div>
                </template>
                <template #empty> Δεν βρέθηκαν αιτήσεις για τον μαθητή. </template>
                <Column header="ID" field="id" style="min-width: 2rem">
                    <template #body="{ data }">
                        {{ data.id }}
                    </template>
                </Column>
                <Column header="Τίτλος" field="title" filter-field="title" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                    <template #body="{ data }">
                        {{ data.title }}
                    </template>
                </Column>
                <Column header="Εξεταστική" field="examPeriod" filter-field="examPeriod" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                    <template #body="{ data }">
                        {{ data.examPeriod }}
                    </template>
                </Column>
                <Column header="Σχολή" field="school" filter-field="school" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                    <template #body="{ data }">
                        {{ data.school }}
                    </template>
                </Column>
                <Column field="status" filter-field="status" header="Κατάσταση" body-class="text-left" style="min-width: 10rem">
                    <template #body="{ data }">
                        <span class="status-badge" :class="`status-${data.status}`">{{ Utils.RequestStatusToString(data.status) }}</span>
                    </template>
                </Column>
                <Column field="classTitle" header="Τμήμα" body-class="text-left" style="min-width: 10rem">
                    <template #body="{ data }">
                        <a class="font-medium no-underline text-left cursor-pointer" style="color: blue" @click="showClassDialog(data.classID)"> {{ data.classTitle }}</a>
                    </template>
                </Column>
                <Column field="isPrivate" filter-field="isPrivate" header="Τύπος" data-type="boolean" body-class="text-left" style="min-width: 8rem">
                    <template #body="{ data }">
                        <span class="private-badge" :class="`private-${data.isPrivate}`">{{ Utils.Types[Utils.FromBoolToTinyInt(data.isPrivate)].label }}</span>
                    </template>
                </Column>
                <Column header-style="width: 4rem; text-align: center" body-style="text-align: center; overflow: visible">
                    <template #body="{ data }">
                        <Button type="button" icon="pi pi-pencil" class="p-button-text" @click="editRequest(data.id)"></Button>
                    </template>
                </Column>
            </DataTable>
        </div> -->
        <div v-if="tabIndex === 2"><StudentEconomics ref="studentEconomics" /></div>
        <div v-if="tabIndex === 3">
            <StudentNotifications
                ref="studentNotifications"
                @add-notification="addStudentNotification"
                @show-notification="showStudentNotification"
                @edit-notification="editStudentNotification"
                @clone-notification="cloneStudentNotification"
                @delete-notification="deleteStudentNotification"
            />
        </div>
        <div v-if="tabIndex === 4"><Todos ref="todos" :type="Utils.TodoType.Student" :typeRefererId="state.id" /></div>
        <div v-if="tabIndex === 5"><Chat ref="chat" chatH="60vh" :priveOnlyUser="chatUserInfo" /></div>

        <template v-if="tabIndex === 0 || tabIndex === 1" #footer>
            <Button label="Άκυρο" icon="pi pi-times" class="p-button-outlined w-8rem mr-3" @click="onHide(false)" />
            <Button label="Αποθήκευση" icon="pi pi-check" class="p-button-primary w-10rem" :loading="loading" @click="handleSubmit(!v$.$invalid)" />
        </template>
    </Dialog>
</template>

<script setup>
import StudentEconomics from '@/components/StudentEconomics.vue';
import StudentNotifications from '@/components/StudentNotifications.vue';
import Todos from '@/components/Todos.vue';
import { useToast } from 'primevue/usetoast';
import { onMounted, reactive, ref, computed, watchEffect, toRefs, nextTick } from 'vue';
import { required, integer, email } from '@vuelidate/validators';
import { useConfirm } from 'primevue/useconfirm';
import { useVuelidate } from '@vuelidate/core';
import moment from 'moment-timezone';
import { usePopupStore } from '@/stores/PopupStore';
import useRPC from '@/composables/useRPC';
import Utils from '@/utils/Utils';
import { FilterMatchMode } from 'primevue/api';
import { useDebounceFn } from '@vueuse/core';
import Chat from '@/components/Chat.vue';
import QuillEditor from '@/components/QuillEditor.vue';
import SyncfusionRTE from '@/components/SyncfusionRTE.vue';

const { RPCApi, RPCMethods } = useRPC();
const dialogShown = ref(false);
const confirm = useConfirm();
const submitted = ref(false);
const headerTitle = ref('');
const categories = ref([]);
const filteredCategories = ref([]);
const filteredSubCategories1 = ref([]);
const filteredSubCategories2 = ref([]);
const fetchReqParams = ref({});
const studentEconomics = ref(null);
const closeCB = ref(null);
const studentNotifications = ref(null);
const popupStore = usePopupStore();
const lastLogin = ref(null);
const todos = ref(null);
const chat = ref(null);

const isAdmin = computed(() => {
    return Utils.UserInfo?.role === Utils.Roles.Admin;
});

const isOffice = computed(() => {
    return Utils.UserInfo?.role === Utils.Roles.Office;
});

const isStudent = computed(() => {
    return Utils.UserInfo && Utils.UserInfo.role === Utils.Roles.Student;
});

const isTeacher = computed(() => {
    return Utils.UserInfo && Utils.UserInfo.role === Utils.Roles.Teacher;
});

const isAdminOrOffice = computed(() => {
    return isAdmin.value || isOffice.value;
});

const showClassDialog = (classID) => {
    popupStore.show(Utils.Popups.ClassDialog, { classID }, (action) => {});
};

const state = reactive({
    id: null,
    fname: '',
    lname: '',
    email: '',
    email2: '',
    mobile: '',
    school: '',
    category: '',
    subCateg1: '',
    subCateg2: '',
    phone: '',
    afm: '',
    birthDate: null,
    gender: null,
    city: '',
    address: '',
    zipcode: '',
    comments: '',
    adminComments: '',
    active: 1,
    checked: 0,
});
const rules = {
    fname: { required },
    lname: { required },
    email: { required, email },
    mobile: { required },
};
const v$ = useVuelidate(rules, state, { $stopPropagation: true });
const loading = ref(false);
const emit = defineEmits(['onClose']);
const toast = useToast();
const schools = ref([]);
const editMode = ref(false);
const schoolsLoading = ref(false);
const tabItems = ref([]);
const tabIndex = ref(0);
const classes = ref([]);
const requests = ref([]);

const reqTableFilters = ref({
    title: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    school: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
});

const showTab = ref(false);
const totalRequests = ref(0);
const loadingRequests = ref(false);

const rowsPerPage = ref(10);
const rdt = ref();

const chatUserInfo = computed(() => {
    return {
        _id: state.id,
        username: state.fname + ' ' + state.lname,
        email: state.email,
        role: Utils.Roles.Student,
    };
});

const onReqPage = useDebounceFn((event) => {
    console.log('onReqPage', event);
    fetchReqParams.value.paging = { offset: event.first, limit: rowsPerPage };
    fetchRequests();
}, 300);

const onReqSort = useDebounceFn((event) => {
    console.log('onSort', event);
    fetchReqParams.value.paging = { offset: 0, limit: rowsPerPage };
    fetchReqParams.value.sort = { sortField: event.sortField, sortOrder: event.sortOrder };
    fetchRequests();
}, 300);

const setTab = async (index) => {
    console.log('setTab:' + index);
    tabIndex.value = index;
    await nextTick();
    if (tabIndex.value === 2) {
        //     console.log('fetch requests.');
        //     fetchReqParams.value = {
        //         paging: { offset: 0, limit: rdt.value.rows },
        //         sort: { sortField: 'id', sortOrder: 'DESC' },
        //         filters: reqTableFilters.value,
        //     };
        //     fetchRequests();
        // } else if (tabIndex.value === 3) {
        console.log('fetch economics.');
        studentEconomics.value.fetchEconomics(state.id);
    } else if (tabIndex.value === 3) {
        console.log('fetch notifications.');
        studentNotifications.value.fetchNotifications(state.id);
    } else if (tabIndex.value === 4) {
        console.log('fetch todos.');
    } else if (tabIndex.value === 5) {
        console.log('show chat.');
    }
};

const fetchRequests = async () => {
    loadingRequests.value = true;
    const res = await RPCApi(RPCMethods.User.FetchRequests, { ...fetchReqParams.value, userId: state.id });
    console.log(res);
    let d = [];
    if (res.status == 'ok') {
        const { total, requests: requestsData } = res.data.result.data;
        totalRequests.value = total;
        for (let obj of requestsData) {
            d.push({
                id: obj.id,
                title: obj.title,
                examPeriod: obj.examPeriod,
                school: obj.school,
                status: obj.status,
                isPrivate: obj.private === 1 ? true : false,
                classID: obj.classID,
                classTitle: obj.classTitle,
            });
        }
        requests.value = d;
    } else {
        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Προσπαθήστε αργότερα!', life: 3000 });
    }
    loadingRequests.value = false;
};

const show = async (data, onCloseCB) => {
    const { studentID } = data;
    tabItems.value = [
        {
            label: 'Στοιχεία',
            icon: 'pi pi-id-card',
            command: () => {
                setTab(0);
            },
        },
    ];
    if (Utils.UserInfo.role === Utils.Roles.Admin || Utils.UserInfo.role === Utils.Roles.Office) {
        tabItems.value = [
            ...tabItems.value,
            {
                label: 'Σχόλια',
                icon: 'pi pi-fw pi-comments',
                command: () => {
                    setTab(1);
                },
            },
            {
                label: 'Οικονομικά',
                icon: 'pi pi-fw pi-wallet',
                command: () => {
                    setTab(2);
                },
            },
            {
                label: 'Mailbox',
                icon: 'pi pi-fw pi-envelope',
                command: () => {
                    setTab(3);
                },
            },
            {
                label: 'ToDo',
                icon: 'pi pi-fw pi-check-square',
                command: () => {
                    setTab(4);
                },
            },
            {
                label: 'Chat',
                icon: 'pi pi-fw pi-comments',
                command: () => {
                    setTab(5);
                },
            },
        ];
    }
    console.log(studentID);
    closeCB.value = onCloseCB;
    lastLogin.value = null;
    classes.value = [];
    requests.value = [];
    tabIndex.value = 0;
    resetForm();
    dialogShown.value = true;
    submitted.value = false;
    editMode.value = !!studentID;
    headerTitle.value = editMode.value ? 'Καρτέλα Μαθητή' : 'Νέος Μαθητής';
    showTab.value = studentID != null;
    let schoolID = null;
    if (studentID != null) {
        state.id = studentID;
        let res = await RPCApi(RPCMethods.User.GetUserInfoById, { id: studentID });
        if (res.status == 'ok') {
            const { fname, lname, email, email2, mobile, school_id, ban, checked, phone, afm, birthDate, gender, city, address, zipCode, comments, adminComments, last_login_datetime } =
                res.data.result.data.userInfo;
            state.fname = fname;
            state.lname = lname;
            state.email = email;
            state.email2 = email2;
            state.mobile = mobile;
            state.phone = phone;
            state.afm = afm;
            state.birthDate = Utils.datetimeToDate(birthDate);
            state.gender = Utils.Genders.find((x) => x.value === gender);
            state.city = city;
            state.address = address;
            state.zipcode = zipCode;
            state.comments = comments;
            state.adminComments = adminComments;
            state.active = ban === 0 ? true : false;
            state.checked = checked === 1 ? true : false;
            lastLogin.value = last_login_datetime
                ? moment(Utils.datetimeToDate(last_login_datetime)).format('DD/MM/YYYY') + ' - ' + moment(Utils.datetimeToDate(last_login_datetime)).format('HH:mm')
                : null;
            schoolID = school_id;

            headerTitle.value = lname + ' ' + fname + ' (' + studentID + ')';
        }
    }

    schoolsLoading.value = true;
    RPCApi(RPCMethods.Schools.Fetch).then((res) => {
        if (res.status == 'ok') {
            schools.value = res.data.result.data.schools;
            categories.value = res.data.result.data.categories;
            if (editMode.value && schoolID) {
                let schoolInfo = schools.value.find((school) => school.id === schoolID);
                let categInfo = categories.value.find((categ) => categ.name === schoolInfo.category);
                state.category = { id: categInfo.id, name: categInfo.name };
                state.subCateg1 = schoolInfo.subCateg1;
                state.subCateg2 = schoolInfo.subCateg2;
            }
        }
        schoolsLoading.value = false;
    });
};

const onHide = (action = false, data = null) => {
    dialogShown.value = false;
    resetForm();
    closeCB.value(action, data);
};

const handleSubmit = async (isFormValid) => {
    submitted.value = true;

    if (!isFormValid) return;

    console.log('ok submit');
    loading.value = true;
    let schoolID = null;
    if (state.category != null) {
        let school = schools.value.find((school) => school.category === state.category.name && school.subCateg1 === state.subCateg1 && school.subCateg2 === state.subCateg2);
        if (school) schoolID = school.id;
    }

    let res;
    let genderValue = state.gender == null ? Utils.GendersEnum.Male : state.gender.value;
    let payload = {
        id: state.id,
        fname: state.fname,
        lname: state.lname,
        email: state.email,
        email2: state.email2,
        mobile: state.mobile,
        school_id: schoolID,
        phone: state.phone,
        afm: state.afm,
        birthDate: state.birthDate,
        gender: genderValue,
        role: Utils.Roles.Student,
        city: state.city,
        address: state.address,
        zipCode: state.zipcode,
        comments: state.comments,
        adminComments: state.adminComments,
        ban: Utils.FromBoolToTinyInt(!state.active),
        checked: Utils.FromBoolToTinyInt(state.checked),
    };

    if (editMode.value) {
        res = await RPCApi(RPCMethods.User.Update, payload);
    } else {
        res = await RPCApi(RPCMethods.User.Add, payload);
    }

    if (res.status !== 'ok') {
        if (res.status === 'user_already_added') {
            toast.add({ severity: 'error', summary: 'Προσοχή', detail: 'Ο χρήστης υπάρχει ήδη!', life: 3000 });
        } else {
            toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Παρακαλώ προσπαθήστε αργότερα', life: 3000 });
        }

        loading.value = false;
        return;
    }
    toast.add({ severity: 'success', summary: 'Η διαδικασία ολοκληρώθηκε', detail: 'Επιτυχής διαδικασία!', life: 3000 });

    loading.value = false;

    onHide(true, { newStudentId: res.data.result.data?.insertId });
};
const resetForm = () => {
    for (let field in state) {
        if (field === 'active') state[field] = true;
        else state[field] = null;
    }

    submitted.value = false;
    loading.value = false;
};

const searchCategory = async (event) => {
    console.log('searchCategory: ' + event.query);

    if (!event.query.trim().length) {
        filteredCategories.value = [...categories.value];
    } else {
        filteredCategories.value = categories.value.filter((d) => {
            return d.name.toLowerCase().startsWith(event.query.toLowerCase());
        });
    }
};

const searchSubCategory1 = async (event) => {
    console.log('searchSubCategory1: ' + event.query);
    console.log(state.category);
    if (state.category == null) {
        filteredSubCategories1.value = [];
        return;
    }

    filteredSubCategories1.value = schools.value.filter((d) => {
        return d.category === state.category.name;
    });

    if (!event.query.trim().length) {
        filteredSubCategories1.value = [...filteredSubCategories1.value];
    } else {
        filteredSubCategories1.value = filteredSubCategories1.value.filter((d) => {
            return d.subCateg1.toLowerCase().startsWith(event.query.toLowerCase());
        });
    }

    filteredSubCategories1.value = [...new Set(filteredSubCategories1.value.map((d) => d.subCateg1))];
};

const searchSubCategory2 = async (event) => {
    console.log('searchSubCategory2: ' + event.query);

    if (state.subCateg1 == null) {
        filteredSubCategories2.value = [];
        return;
    }

    filteredSubCategories2.value = schools.value.filter((d) => {
        return d.subCateg1 === state.subCateg1;
    });

    if (!event.query.trim().length) {
        filteredSubCategories2.value = [...filteredSubCategories2.value];
    } else {
        filteredSubCategories2.value = filteredSubCategories2.value.filter((d) => {
            return d.subCateg2.toLowerCase().startsWith(event.query.toLowerCase());
        });
    }

    filteredSubCategories2.value = [...new Set(filteredSubCategories2.value.map((d) => d.subCateg2))];
};

const addRequest = async () => {
    console.log('addRequest');
    popupStore.show(Utils.Popups.RequestDialog, { studentID: state.id }, (action) => {
        console.log('onRequestDialogClosed:' + action);
        if (action) fetchRequests();
    });
};

const editRequest = async (requestID) => {
    console.log('editRequest:' + requestID);
    popupStore.show(Utils.Popups.RequestDialog, { requestID }, (action) => {
        console.log('onRequestDialogClosed:' + action);
        if (action) fetchRequests();
    });
};

const addStudentNotification = async () => {
    console.log('addStudentNotification');
    popupStore.show(Utils.Popups.NotificationDialog, { userID: state.id }, (action) => {
        console.log('onRequestDialogClosed:' + action);
        studentNotifications.value.fetchNotifications(state.id);
    });
};

const editStudentNotification = async (data) => {
    console.log('editStudentNotification:' + data);
    popupStore.show(Utils.Popups.NotificationDialog, { notificationID: data.id, userID: state.id }, (action) => {
        console.log('onRequestDialogClosed:' + action);
        studentNotifications.value.fetchNotifications(state.id);
    });
};

const cloneStudentNotification = (data) => {
    popupStore.show(Utils.Popups.NotificationDialog, { notificationID: data.id, userID: state.id, clone: true }, (action) => {
        console.log('onNotificationDialogClosed:' + action);
        studentNotifications.value.fetchNotifications(state.id);
    });
};

const showStudentNotification = async (data) => {
    console.log('showStudentNotification:' + data);
    popupStore.show(Utils.Popups.NotificationDialog, { notificationID: data.id, userID: state.id, showOnly: true }, (action) => {});
};

const deleteStudentNotification = async (id) => {
    console.log('deleteStudentNotification:' + id);
    confirm.require({
        message: 'Είστε σίγουροι ότι θέλετε να διαγράψετε το mail;',
        header: 'Επιβεβαίωση',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Διαγραφή',
        rejectLabel: 'Άκυρο',
        acceptClass: 'p-button-danger',
        rejectClass: 'p-button-secondary',
        accept: async () => {
            console.log('delete!');
            await RPCApi(RPCMethods.Notifications.Delete, { id });
            studentNotifications.value.fetchNotifications(state.id);
        },
        reject: () => {
            console.log('do nothing');
        },
    });
};

const onActiveChanged = (event) => {
    console.log('onActiveChanged', state.active);
    if (!isAdmin.value && !state.active) {
        toast.add({ severity: 'error', summary: 'Προσοχή', detail: 'Δεν έχετε δικαίωμα ban!', life: 3000 });
        state.active = true;
    }
};

defineExpose({
    show,
});
</script>

<style lang="scss">
.p-avatar {
    img {
        width: auto;
    }
}

.fixed-tab-menu {
    position: sticky;
    top: 0;
    z-index: 1;
}

.box {
    border-radius: 0.75rem;
    border: 2px solid #e3e8ef;
    background: #fff;
}
</style>
