<template>
    <DataTable ref="dt" :total-records="totalVideos" :loading="loading" :row-hover="true" :value="videos" data-key="id" responsive-layout="scroll" :rows="rowsPerPage" :paginator="true">
        <template #empty> Δεν βρέθηκαν video. </template>
        <Column header="" :sortable="false" :show-filter-menu="false">
            <template #body="{ data }">
                <div class="flex align-items-center">
                    <div style="position: relative">
                        <img
                            class="cursor-pointer mt-1"
                            src="@/assets/images/play.svg"
                            style="position: absolute; top: 50%; transform: translateY(-50%); width: 3.5rem; height: 3.5rem"
                            @click="openVideo(data)"
                        />
                    </div>
                </div>
            </template>
        </Column>
        <Column header="Ημερομηνία" field="date" filter-field="date" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
            <template #body="{ data }">
                {{ moment(Utils.datetimeToDate(data.date)).format('DD/MM/YYYY') + ' - ' + moment(Utils.datetimeToDate(data.date)).format('HH:mm') }}
            </template>
        </Column>
        <Column header="Διάρκεια" field="duration" filter-field="duration" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
            <template #body="{ data }">
                {{ Utils.toHoursAndMinutes(data.duration / 60) }}
            </template>
        </Column>
        <Column v-if="isAdminOrOffice" header="Στατιστικά" field="stats">
            <template #body="{ data }">
                <div v-for="(stat, index) in data.stats" :key="index" class="flex flex-col">
                    <div class="flex flex-row gap-1">
                        <div>{{ stat.studentName }}:</div>
                        <div>{{ stat.views }} θεάσεις</div>
                    </div>
                </div>
            </template>
        </Column>
    </DataTable>
</template>
<script setup>
import { useConfirm } from 'primevue/useconfirm';
import { ref, onMounted, computed } from 'vue';
import useFirebase from '@/composables/useFirebase';
import logo from '@/assets/images/logo.png';
import Utils from '@/utils/Utils';
import useRPC from '@/composables/useRPC';
import moment from 'moment-timezone';
import { usePopupStore } from '@/stores/PopupStore';
import playIcon from '@/assets/images/play.svg';
import { useToast } from 'primevue/usetoast';

const toast = useToast();
const classId = ref(null);
const rowsPerPage = ref(10);
const videos = ref([]);
const totalVideos = ref(0);
const loading = ref(false);
const confirm = useConfirm();
const { RPCApi, RPCMethods } = useRPC();
const popupStore = usePopupStore();
const showFirstVideoOnly = ref(false);
const isAdmin = computed(() => {
    return Utils.UserInfo.role === Utils.Roles.Admin;
});

const isOffice = computed(() => {
    return Utils.UserInfo.role === Utils.Roles.Office;
});

const isStudent = computed(() => {
    return Utils.UserInfo.role === Utils.Roles.Student;
});

const isTeacher = computed(() => {
    return Utils.UserInfo.role === Utils.Roles.Teacher;
});

const isAdminOrOffice = computed(() => {
    return isAdmin.value || isOffice.value;
});

const fetchVideos = async (classID, onlyFirstVideo) => {
    console.log('fetchVideos::' + classID);
    classId.value = classID;
    showFirstVideoOnly.value = onlyFirstVideo;
    loading.value = true;
    try {
        let res = await RPCApi(RPCMethods.Lessons.FetchRecordedVideosForClass, { classID });
        console.log(res);
        if (res.status === 'ok') {
            let recordings = res.data.result.data;
            let arr = [];
            for (let rec of recordings) {
                let { id, topic, start_time, duration, url, stats } = rec;
                let filename = topic;
                let date = start_time;
                arr.push({ id, filename, url, date, duration, stats });
            }

            if (showFirstVideoOnly.value && arr.length > 0) {
                videos.value = arr
                    .sort((a, b) => {
                        return a.date > b.date ? 1 : -1;
                    })
                    .slice(0, 1);
            } else {
                videos.value = arr;
            }
        }

        loading.value = false;
    } catch (err) {
        console.error(err);
    } finally {
        loading.value = false;
    }
};

const openVideo = async (data) => {
    const { id, url } = data;
    if (Utils.UserInfo.role === Utils.Roles.Student) {
        try {
            let res = await RPCApi(RPCMethods.User.WatchedRecordedVideo, { fileId: id, classId: classId.value });
            console.warn('res', res);
            if (res.status === 'video_views_exceeded') {
                // toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Έχετε υπερβεί το μέγιστο όριο προβολών βίντεο!', life: 3000 });
                return;
            }

            if (res.status === 'ok') {
                openVideoPopup(url);
            }
        } catch (err) {
            console.error(err);
            toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Προέκυψε σφάλμα κατά την προσπάθεια προβολής του βίντεο!', life: 3000 });
        }
    } else {
        openVideoPopup(url);
    }
};

const openVideoPopup = async (url) => {
    // window.open(url + '?user=' + Utils.UserInfo.lname + '_' + Utils.UserInfo.fname + '&email=' + Utils.UserInfo.email + '&mobile=' + Utils.UserInfo.mobile, '_blank');
    const newWindow = window.open('', '_blank', 'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=800,height=600');
    newWindow.document.write(`
        <html>
        <head>
            <style>
                body {
                    user-select: none;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                }
            </style>
        </head>
        <body oncontextmenu="return false;">
            <iframe src="${
                url + '?user=' + Utils.UserInfo.lname + '_' + Utils.UserInfo.fname + '&email=' + Utils.UserInfo.email + '&mobile=' + Utils.UserInfo.mobile
            }" style="width: 100%; height: 100%; border: none;" allowfullscreen></iframe>
        </body>
        </html>
    `);
    newWindow.document.close();
};

defineExpose({
    fetchVideos,
});
</script>
