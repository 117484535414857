import { createRouter, createWebHistory } from 'vue-router';
import App from '@/App.vue';
import Utils from '@/utils/Utils';
import AppLayout from '@/layout/AppLayout.vue';

const routes = [
    {
        path: Utils.BASE,
        name: 'app',
        component: AppLayout,
        children: [
            {
                path: '',
                name: 'dashboard',
                component: () => import('@/components/Dashboard.vue'),
                meta: {
                    requiresAuth: true,
                    breadcrumb: [''],
                },
            },
            {
                path: Utils.getRoute('dailycalendar'),
                name: 'dailycalendar',
                component: () => import('@/components/DailyCalendar.vue'),
                meta: {
                    requiresAuth: true,
                    breadcrumb: [''],
                },
            },
            {
                path: Utils.getRoute('attendances'),
                name: 'attendances',
                component: () => import('@/components/Attendances.vue'),
                meta: {
                    requiresAuth: true,
                    requiredRoles: [Utils.Roles.Admin, Utils.Roles.Office, Utils.Roles.Teacher],
                    breadcrumb: [''],
                },
            },
            {
                path: Utils.getRoute('treasury'),
                name: 'treasury',
                component: () => import('@/components/Treasury.vue'),
                meta: {
                    requiresAuth: true,
                    requiredRoles: [Utils.Roles.Admin],
                    breadcrumb: [''],
                },
            },
            {
                path: Utils.getRoute('tips'),
                name: 'tips',
                component: () => import('@/components/Tips.vue'),
                meta: {
                    requiresAuth: true,
                    requiredRoles: [Utils.Roles.Admin],
                    breadcrumb: [''],
                },
            },
            {
                path: Utils.getRoute('notifications'),
                name: 'notifications',
                component: () => import('@/components/Notifications.vue'),
                meta: {
                    requiresAuth: true,
                    breadcrumb: [''],
                },
            },
            {
                path: Utils.getRoute('chat'),
                name: 'chat',
                component: () => import('@/components/Chat.vue'),
                meta: {
                    requiresAuth: true,
                    breadcrumb: [''],
                },
            },
            {
                path: Utils.getRoute('stuff'),
                name: 'stuff',
                component: () => import('@/components/Stuff.vue'),
                meta: {
                    requiresAuth: true,
                    requiredRoles: [Utils.Roles.Admin],
                    breadcrumb: [''],
                },
            },
            {
                path: Utils.getRoute('app_settings'),
                name: 'app_settings',
                component: () => import('@/components/AppSettings.vue'),
                meta: {
                    requiresAuth: true,
                    requiredRoles: [Utils.Roles.Admin],
                    breadcrumb: [''],
                },
            },
            {
                path: Utils.getRoute('requests'),
                name: 'requests',
                component: () => import('@/components/Requests.vue'),
                meta: {
                    requiresAuth: true,
                    breadcrumb: [''],
                },
            },
            {
                path: Utils.getRoute('temp_requests'),
                name: 'temp_requests',
                component: () => import('@/components/TempRequests.vue'),
                meta: {
                    requiresAuth: true,
                    breadcrumb: [''],
                },
            },
            {
                path: Utils.getRoute('requests-printer'),
                name: 'requests-printer',
                component: () => import('@/components/RequestsPrinter.vue'),
                meta: {
                    requiresAuth: true,
                    breadcrumb: [''],
                },
            },
            {
                path: Utils.getRoute('invoices-printer'),
                name: 'invoices-printer',
                component: () => import('@/components/InvoicesPrinter.vue'),
                meta: {
                    requiresAuth: true,
                    breadcrumb: [''],
                },
            },
            {
                path: Utils.getRoute('classes'),
                name: 'classes',
                component: () => import('@/components/Classes.vue'),
                meta: {
                    requiresAuth: true,
                    breadcrumb: [''],
                },
            },
            {
                path: Utils.getRoute('students'),
                name: 'students',
                component: () => import('@/components/Students.vue'),
                meta: {
                    requiresAuth: true,
                    requiredRoles: [Utils.Roles.Admin, Utils.Roles.Office],
                    breadcrumb: [''],
                },
            },
            {
                path: Utils.getRoute('teachers'),
                name: 'teachers',
                component: () => import('@/components/Teachers.vue'),
                meta: {
                    requiresAuth: true,
                    requiredRoles: [Utils.Roles.Admin, Utils.Roles.Office],
                    breadcrumb: [''],
                },
            },
            {
                path: Utils.getRoute('teachers-hours'),
                name: 'teachers-hours',
                component: () => import('@/components/TeachersHours.vue'),
                meta: {
                    requiresAuth: true,
                    requiredRoles: [Utils.Roles.Admin, Utils.Roles.Office, Utils.Roles.Teacher],
                    breadcrumb: [''],
                },
            },
            {
                path: Utils.getRoute('schools'),
                name: 'schools',
                component: () => import('@/components/Schools.vue'),
                meta: {
                    requiresAuth: true,
                    requiredRoles: [Utils.Roles.Admin, Utils.Roles.Office],
                    breadcrumb: [''],
                },
            },
            {
                path: Utils.getRoute('courses'),
                name: 'courses',
                component: () => import('@/components/Courses.vue'),
                meta: {
                    requiresAuth: true,
                    requiredRoles: [Utils.Roles.Admin, Utils.Roles.Office],
                    breadcrumb: [''],
                },
            },
            {
                path: Utils.getRoute('periods'),
                name: 'periods',
                component: () => import('@/components/Periods.vue'),
                meta: {
                    requiresAuth: true,
                    requiredRoles: [Utils.Roles.Admin, Utils.Roles.Office],
                    breadcrumb: [''],
                },
            },
            {
                path: Utils.getRoute('classrooms'),
                name: 'classrooms',
                component: () => import('@/components/Classrooms.vue'),
                meta: {
                    requiresAuth: true,
                    requiredRoles: [Utils.Roles.Admin, Utils.Roles.Office],
                    breadcrumb: [''],
                },
            },
            {
                path: Utils.getRoute('assignments'),
                name: 'assignments',
                component: () => import('@/components/Assignments.vue'),
                meta: {
                    requiresAuth: true,
                    breadcrumb: [''],
                },
            },
            {
                path: Utils.getRoute('economics'),
                name: 'economics',
                component: () => import('@/components/Economics.vue'),
                meta: {
                    requiresAuth: true,
                    requiredRoles: [Utils.Roles.Teacher, Utils.Roles.Student],
                    breadcrumb: [''],
                },
            },
            {
                path: Utils.getRoute('todos'),
                name: 'todos',
                component: () => import('@/components/Todos.vue'),
                meta: {
                    requiresAuth: true,
                    requiredRoles: [Utils.Roles.Admin, Utils.Roles.Office],
                    breadcrumb: [''],
                },
            },
            {
                path: Utils.getRoute('job_postings'),
                name: 'job_postings',
                component: () => import('@/components/JobPostings.vue'),
                meta: {
                    requiresAuth: true,
                    requiredRoles: [Utils.Roles.Admin, Utils.Roles.Office, Utils.Roles.Teacher],
                    breadcrumb: [''],
                },
            },
            {
                path: Utils.getRoute('teachers_rating'),
                name: 'teachers_rating',
                component: () => import('@/components/TeachersRating.vue'),
                meta: {
                    requiresAuth: true,
                    requiredRoles: [Utils.Roles.Admin, Utils.Roles.Office],
                    breadcrumb: [''],
                },
            },
            {
                path: Utils.getRoute('departments'),
                name: 'departments',
                component: () => import('@/components/Departments.vue'),
                meta: {
                    requiresAuth: true,
                    requiredRoles: [Utils.Roles.Admin, Utils.Roles.Office],
                    breadcrumb: [''],
                },
            },
            {
                path: Utils.getRoute('quizzes'),
                name: 'quizzes',
                component: () => import('@/components/Quizzes.vue'),
                meta: {
                    requiresAuth: true,
                    requiredRoles: [Utils.Roles.Admin, Utils.Roles.Teacher],
                    breadcrumb: [''],
                },
            },
            {
                path: Utils.getRoute('quizzes_student'),
                name: 'quizzesStudent',
                component: () => import('@/components/QuizzesStudent.vue'),
                meta: {
                    requiresAuth: true,
                    requiredRoles: [Utils.Roles.Student],
                    breadcrumb: [''],
                },
            },
            {
                path: Utils.getRoute('quizzes_teacher'),
                name: 'quizzesTeacher',
                component: () => import('@/components/QuizzesTeacher.vue'),
                meta: {
                    requiresAuth: true,
                    requiredRoles: [Utils.Roles.Teacher],
                    breadcrumb: [''],
                },
            },
        ],
    },

    {
        path: Utils.getRoute('login'),
        name: 'login',
        component: () => import('@/pages/Login.vue'),
        meta: {
            requiresNoAuth: true,
            breadcrumb: [''],
        },
    },
    {
        path: Utils.getRoute('request_password_reset'),
        name: 'request_password_reset',
        component: () => import('@/pages/RequestPasswordReset.vue'),
        meta: {
            requiresNoAuth: true,
            breadcrumb: [''],
        },
    },
    {
        path: Utils.getRoute('password_reset'),
        name: 'password_reset',
        component: () => import('@/pages/ResetPassword.vue'),
        meta: {
            requiresNoAuth: true,
            breadcrumb: [''],
        },
    },
    {
        path: Utils.getRoute('error'),
        name: 'error',
        component: () => import('@/pages/Error.vue'),
        meta: {
            requiresNoAuth: true,
            breadcrumb: [''],
        },
    },
    {
        path: Utils.getRoute('access'),
        name: 'access',
        component: () => import('@/pages/Access.vue'),
        meta: {
            requiresNoAuth: true,
            breadcrumb: [''],
        },
    },
    {
        path: Utils.getRoute('pending_email_password_reset'),
        name: 'pending_email_password_reset',
        component: () => import('@/pages/PendingEmailPasswordReset.vue'),
        meta: {
            requiresNoAuth: true,
            breadcrumb: [''],
        },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(async (to, from, next) => {
    // console.log(Utils.UserInfo, from, to);
    window.scrollTo(0, 0);
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (Utils.UserInfo != null) {
            if (Utils.UserInfo != null && to.matched.some((record) => record.meta.requiredRoles && !record.meta.requiredRoles.includes(Utils.UserInfo.role))) {
                next(Utils.getRoute('access'));
            } else {
                next();
            }
        } else {
            next(Utils.getRoute('login'));
        }
    } else if (to.matched.some((record) => record.meta.requiresNoAuth)) {
        if (Utils.UserInfo != null && to.name !== 'access' && to.name !== 'error') {
            next(Utils.getRoute());
        } else {
            next();
        }
    } else {
        next(Utils.getRoute('error'));
    }
});

export default router;
