<template>
    <Dialog
        v-model:visible="dialogShown"
        class="p-fluid"
        :close-on-escape="false"
        :dismissable-mask="false"
        :modal="true"
        :breakpoints="{ '960px': '75vw', '640px': '95vw' }"
        :style="{ width: '80vw' }"
    >
        <template #header>
            <h4>{{ headerTitle }}</h4>
        </template>

        <div class="formgrid grid">
            <div class="col-12 md:col-6">
                <div class="field">
                    <label for="title" :class="{ 'p-error': v$.title.$invalid && submitted }">Τίτλος*</label>
                    <InputText id="title" v-model="v$.title.$model" autocomplete="off" spellcheck="off" :class="{ 'p-invalid': v$.title.$invalid && submitted }" placeholder="Τίτλος μαθήματος" />
                    <small v-if="(v$.title.$invalid && submitted) || v$.title.$pending.$response" class="p-error">{{ v$.title.required.$message }}</small>
                </div>
                <div class="field">
                    <label for="category" :class="{ 'p-error': v$.category.$invalid && submitted }">Κατηγορία*</label>
                    <AutoComplete
                        id="category"
                        v-model="v$.category.$model"
                        :class="{ 'p-invalid': v$.category.$invalid && submitted }"
                        :suggestions="filteredCategories"
                        force-selection
                        field="name"
                        placeholder="Επιλέξτε κατηγορία"
                        :dropdown="true"
                        @complete="searchCategory($event)"
                    >
                    </AutoComplete>
                    <small v-if="(v$.category.$invalid && submitted) || v$.category.$pending.$response" class="p-error">{{ v$.category.required.$message }}</small>
                </div>
                <div class="field">
                    <label for="subCateg1" :class="{ 'p-error': v$.subCateg1.$invalid && submitted }">Υποκατηγορία Ι*</label>
                    <AutoComplete
                        id="subCateg1"
                        v-model="v$.subCateg1.$model"
                        force-selection
                        :class="{ 'p-invalid': v$.subCateg1.$invalid && submitted }"
                        :suggestions="filteredSubCategories1"
                        placeholder="Επιλέξτε Υποκατηγορία Ι"
                        :dropdown="true"
                        @complete="searchSubCategory1($event)"
                    >
                    </AutoComplete>
                    <small v-if="(v$.subCateg1.$invalid && submitted) || v$.subCateg1.$pending.$response" class="p-error">{{ v$.subCateg1.required.$message }}</small>
                </div>
                <div class="field">
                    <label for="subCateg2" :class="{ 'p-error': v$.subCateg2.$invalid && submitted }">Υποκατηγορία ΙΙ*</label>
                    <AutoComplete
                        id="subCateg2"
                        v-model="v$.subCateg2.$model"
                        force-selection
                        :class="{ 'p-invalid': v$.subCateg2.$invalid && submitted }"
                        :suggestions="filteredSubCategories2"
                        placeholder="Επιλέξτε Υποκατηγορία ΙΙ"
                        :dropdown="true"
                        @complete="searchSubCategory2($event)"
                    >
                    </AutoComplete>
                    <small v-if="(v$.subCateg2.$invalid && submitted) || v$.subCateg2.$pending.$response" class="p-error">{{ v$.subCateg2.required.$message }}</small>
                </div>
                <div class="field">
                    <label for="teacher">Καθηγητής</label>
                    <AutoComplete
                        id="teacher"
                        v-model="state.teacher"
                        field="name"
                        force-selection
                        :suggestions="filteredTeachers"
                        placeholder="Επιλέξτε Καθηγητή"
                        :dropdown="true"
                        @complete="searchTeacher($event)"
                    >
                    </AutoComplete>
                </div>
                <div class="field">
                    <label>Υπεύθυνοι Quiz</label>
                    <AutoComplete
                        v-model="state.quizTeachers"
                        field="name"
                        force-selection
                        :suggestions="filteredTeachers"
                        placeholder="Επιλέξτε Καθηγητές"
                        :dropdown="true"
                        multiple
                        @complete="searchTeacher($event)"
                    >
                    </AutoComplete>
                </div>
                <div class="field">
                    <label for="semester">Εξάμηνο</label>
                    <InputText id="semester" v-model="state.semester" autocomplete="off" spellcheck="off" placeholder="Εξάμηνο" />
                </div>
                <div class="field">
                    <label for="details">Στοιχεία Μαθήματος</label>
                    <Textarea id="details" v-model="state.details" autocomplete="off" spellcheck="off" rows="3" cols="30" placeholder="π.χ. 'μαθηματικα, τριγωνομετρια'" />
                </div>
                <div v-if="state.id != null" class="field">
                    <a class="font-medium no-underline text-left cursor-pointer" style="color: blue" @click="onShowFilesPopup"> Αρχεία </a>
                </div>
            </div>
            <div class="col-12 md:col-6">
                <div class="field">
                    <label for="description">Περιγραφή</label>
                    <Textarea id="description" v-model="state.description" autocomplete="off" spellcheck="off" rows="3" cols="30" placeholder="Περιγραφή" />
                </div>
                <div class="field">
                    <label for="examDate">Ημ/νία εξέτασης</label>
                    <Calendar id="examDate" v-model="state.examDate" date-format="dd/mm/yy" placeholder="dd/mm/yyyy" />
                </div>
                <div class="field">
                    <label for="price">Δίδακτρα</label>
                    <InputNumber id="price" v-model="state.price" mode="currency" currency="EUR" autocomplete="off" spellcheck="off" placeholder="σε Ευρώ" locale="el-GR" :min="1" />
                </div>
                <div class="field">
                    <label for="hours">Ώρες</label>
                    <InputNumber id="hours" v-model="state.hours" mode="decimal" :min-fraction-digits="0" locale="el-GR" autocomplete="off" spellcheck="off" placeholder="Ώρες" />
                </div>
                <div class="field">
                    <label for="price_private">Δίδακτρα - Ιδιαίτερο</label>
                    <InputNumber id="price_private" v-model="state.pricePrivate" mode="currency" currency="EUR" autocomplete="off" spellcheck="off" placeholder="σε Ευρώ" locale="el-GR" :min="1" />
                </div>
                <div class="field">
                    <label for="hours_private">Ώρες - Ιδιαίτερο</label>
                    <InputNumber id="hours_private" v-model="state.hoursPrivate" mode="decimal" :min-fraction-digits="0" locale="el-GR" autocomplete="off" spellcheck="off" placeholder="Ώρες" />
                </div>
                <div class="field">
                    <label for="site">Ιστοσελίδα</label>
                    <InputText id="site" v-model="state.site" autocomplete="off" spellcheck="off" placeholder="Ιστοσελίδα" />
                </div>
                <div class="field-checkbox">
                    <Checkbox id="active" v-model="state.active" :binary="true" />
                    <label for="active">Ενεργό</label>
                </div>
            </div>
        </div>

        <template #footer>
            <Button label="Άκυρο" icon="pi pi-times" class="p-button-outlined w-8rem mr-3" @click="onHide(false)" />
            <Button label="Αποθήκευση" icon="pi pi-check" class="p-button-primary w-10rem" :loading="loading" @click="handleSubmit(!v$.$invalid)" />
        </template>
    </Dialog>
    <Dialog v-model:visible="showUploaderDialog" modal :style="{ width: '80vw' }">
        <template #header>
            <h3>{{ uploaderTitle }}</h3>
        </template>
        <Uploader ref="uploader" />
    </Dialog>
</template>

<script setup>
import { useToast } from 'primevue/usetoast';
import { onMounted, reactive, ref, computed, watchEffect, toRefs, nextTick } from 'vue';
import { required, integer } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import useRPC from '@/composables/useRPC';
import Uploader from '@/components/Uploader.vue';
import Utils from '@/utils/Utils';
const { RPCApi, RPCMethods } = useRPC();
const dialogShown = ref(false);
const submitted = ref(false);
const headerTitle = ref('');
const state = reactive({
    id: null,
    title: '',
    category: '',
    subCateg1: '',
    subCateg2: '',
    teacher: null,
    semester: '',
    examDate: null,
    privateCourse: false,
    price: 1,
    hours: 1,
    pricePrivate: 1,
    hoursPrivate: 1,
    description: '',
    details: '',
    site: '',
    active: true,
    quizTeachers: [],
});
const rules = {
    title: { required },
    category: { required },
    subCateg1: { required },
    subCateg2: { required },
};
const v$ = useVuelidate(rules, state);
const loading = ref(false);
const toast = useToast();
const editMode = ref(false);
const filteredCategories = ref([]);
const filteredSubCategories1 = ref([]);
const filteredSubCategories2 = ref([]);
const filteredTeachers = ref([]);
const schools = ref([]);
const categories = ref([]);
const teachers = ref([]);
const closeCB = ref(null);
const showUploaderDialog = ref(false);
const uploader = ref(null);
const uploaderTitle = ref('');

const show = async (courseID, onCloseCB) => {
    console.log('CoursedDialog:' + courseID);
    let course = null;
    if (courseID != null) {
        let result = await RPCApi(RPCMethods.Courses.FetchCourseInfoById, { id: courseID });
        if (result.status == 'ok') {
            let courseInfo = result.data.result.data.courseInfo;
            course = {
                ...courseInfo.course,
                quizTeachers: courseInfo.quizTeachers || [],
            };
        }
    }

    closeCB.value = onCloseCB;
    console.log(course);
    resetForm();
    dialogShown.value = true;
    submitted.value = false;
    editMode.value = !!course;
    headerTitle.value = editMode.value ? 'Τροποποίηση Μαθήματος' : 'Νέο Μάθημα';

    let res = await RPCApi(RPCMethods.Schools.Fetch);
    if (res.status == 'ok') {
        schools.value = res.data.result.data.schools;
        categories.value = res.data.result.data.categories;
    }

    res = await RPCApi(RPCMethods.User.FetchUsersByRole, { role: Utils.Roles.Teacher });
    if (res.status == 'ok') {
        let d = res.data.result.data.users.filter((u) => u.ban === 0);
        teachers.value = d.map((u) => ({ id: u.id, name: u.lname + ' ' + u.fname }));
        if (course != null) {
            state.teacher = teachers.value.find((x) => x.id === course.teacherID);
        }
    }

    if (course != null) {
        state.id = course.id;
        state.title = course.title;
        state.category = { id: course.categoryID, name: course.categoryName };
        state.subCateg1 = course.subCateg1;
        state.subCateg2 = course.subCateg2;
        state.semester = course.semester;
        state.examDate = course.examDate;
        state.price = course.price;
        state.hours = course.hours;
        state.pricePrivate = course.price_private;
        state.hoursPrivate = course.hours_private;
        state.description = course.description;
        state.details = course.details;
        state.site = course.site;
        state.active = course.active === 1;
        state.teacher = teachers.value.find((x) => x.id === course.teacherID);
        state.quizTeachers = course.quizTeachers.map((x) => ({ id: x.id, name: x.lname + ' ' + x.fname }));
    }
};

const onHide = (action = false) => {
    dialogShown.value = false;
    resetForm();
    closeCB.value(action);
};

const handleSubmit = async (isFormValid) => {
    submitted.value = true;

    if (!isFormValid) return;

    console.log('ok submit');
    loading.value = true;
    let res;
    let school = schools.value.find((x) => x.category === state.category.name && x.subCateg1 === state.subCateg1 && x.subCateg2 === state.subCateg2);
    if (school == null) {
        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Δεν βρέθηκε σχολή!', life: 3000 });
        loading.value = false;
        return;
    }
    let payload = {
        id: state.id,
        title: state.title,
        school_id: school.id,
        teacher_id: state.teacher?.id || null,
        semester: Utils.emptyToNull(state.semester),
        examination_date: state.examDate,
        price: state.price,
        hours: state.hours,
        price_private: state.pricePrivate,
        hours_private: state.hoursPrivate,
        description: Utils.emptyToNull(state.description),
        details: Utils.emptyToNull(state.details),
        site: Utils.emptyToNull(state.site),
        active: state.active ? 1 : 0,
        quizTeachers: state.quizTeachers.map((x) => x.id),
    };

    if (editMode.value) {
        res = await RPCApi(RPCMethods.Courses.Update, payload);
    } else {
        res = await RPCApi(RPCMethods.Courses.Add, payload);
    }

    if (res.status !== 'ok') {
        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Προσπαθήστε ξανά!', life: 3000 });
        loading.value = false;
        return;
    }
    toast.add({ severity: 'success', summary: 'Η διαδικασία ολοκληρώθηκε', detail: 'Επιτυχής διαδικασία!', life: 3000 });

    loading.value = false;
    onHide(true);
};
const resetForm = () => {
    state.id = null;
    state.category = '';
    state.subCateg1 = '';
    state.subCateg2 = '';
    state.title = '';
    state.teacher = null;
    state.semester = '';
    state.examDate = null;
    state.price = 1;
    state.hours = 1;
    state.pricePrivate = 1;
    state.hoursPrivate = 1;
    state.description = '';
    state.details = '';
    state.site = '';
    state.active = true;
    state.quizTeachers = [];

    submitted.value = false;
    loading.value = false;
};

const searchCategory = async (event) => {
    console.log('searchCategory: ' + event.query);

    if (!event.query.trim().length) {
        filteredCategories.value = [...categories.value];
    } else {
        filteredCategories.value = categories.value.filter((d) => {
            return d.name.toLowerCase().startsWith(event.query.toLowerCase());
        });
    }
};

const searchSubCategory1 = async (event) => {
    console.log('searchSubCategory1: ' + event.query);
    console.log(state.category);
    if (state.category == null) {
        filteredSubCategories1.value = [];
        return;
    }

    filteredSubCategories1.value = schools.value.filter((d) => {
        return d.category === state.category.name;
    });

    if (!event.query.trim().length) {
        filteredSubCategories1.value = [...filteredSubCategories1.value];
    } else {
        filteredSubCategories1.value = filteredSubCategories1.value.filter((d) => {
            return d.subCateg1.toLowerCase().startsWith(event.query.toLowerCase());
        });
    }

    filteredSubCategories1.value = [...new Set(filteredSubCategories1.value.map((d) => d.subCateg1))];
};

const searchSubCategory2 = async (event) => {
    console.log('searchSubCategory2: ' + event.query);

    if (state.subCateg1 == null) {
        filteredSubCategories2.value = [];
        return;
    }

    filteredSubCategories2.value = schools.value.filter((d) => {
        return d.subCateg1 === state.subCateg1;
    });

    if (!event.query.trim().length) {
        filteredSubCategories2.value = [...filteredSubCategories2.value];
    } else {
        filteredSubCategories2.value = filteredSubCategories2.value.filter((d) => {
            return d.subCateg2.toLowerCase().startsWith(event.query.toLowerCase());
        });
    }

    filteredSubCategories2.value = [...new Set(filteredSubCategories2.value.map((d) => d.subCateg2))];
};

const searchTeacher = async (event) => {
    console.log('searchTeacher: ' + event.query);

    if (!event.query.trim().length) {
        filteredTeachers.value = [...teachers.value];
    } else {
        filteredTeachers.value = teachers.value.filter((d) => {
            return d.name.toLowerCase().startsWith(event.query.toLowerCase());
        });
    }
};

const onShowFilesPopup = async (data) => {
    console.log('onShowFilesPopup:' + data);
    showUploaderDialog.value = true;
    uploaderTitle.value = 'Αρχεία μαθήματος: ' + state.title;
    await nextTick();
    uploader.value.fetchFiles(`courses/${state.id}/files`);
};

defineExpose({
    show,
});
</script>
